import { IamPermission_Read_Nested } from '@treadinc/horizon-api-spec';

interface PermissionProps {
  name: string;
}

export class Permission {
  public static parse(proto: IamPermission_Read_Nested): Permission {
    return new Permission(proto.name);
  }

  public static deparse(permission: PermissionProps): {
    name: string;
  } {
    return {
      name: permission.name,
    };
  }

  public get name(): string {
    return this._name;
  }

  constructor(private _name: string) {}
}
