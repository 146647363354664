import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import React from 'react';
import { useForm } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
import { CompanyBasic } from '~hooks/useCompany';
import { Department, useDepartment } from '~hooks/useDepartment';
import { departmentFormValidationSchema } from '~pages/Settings/DepartmentManagement/departmentFormValidationSchema';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

interface DepartmentFormProps {
  defaultDepartment: Nullable<Department>;
  onFormDirty?: (isDirty: boolean) => void;
  callBackCloseClicked: () => void;
  callBackCreated: (department: Department) => void;
  connectedCompany?: Nullable<CompanyBasic>;
  sx?: SxProps<Theme>; // Style
}

const DepartmentInlineForm = ({
  defaultDepartment,
  connectedCompany,
  callBackCreated,
  callBackCloseClicked,
}: DepartmentFormProps) => {
  const { userStore } = useStores();
  const { createDepartmentByCompanyId, isLoading } = useDepartment();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(departmentFormValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      id: '',
      name: defaultDepartment?.name || '',
      company: userStore.user.company,
      type: null,
      externalId: '',
      isNotActive: false,
    },
  });

  const handleCreate = () => {
    createDepartmentByCompanyId({
      companyId: connectedCompany?.id || userStore.user.company?.id || '',
      department: {
        ...getValues(),
      } as unknown as Department,
      callBack: (department) => {
        reset();
        callBackCreated(department);
      },
    });
  };

  const handleClose = () => {
    callBackCloseClicked?.();
  };

  return (
    <Box
      component={'form'}
      display={'flex'}
      sx={{ width: '100%', flexGrow: 1 }}
      onSubmit={handleSubmit(handleCreate)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFormField
            control={control}
            errors={errors}
            name="name"
            label={`${$t('form_fields.name')}`}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isLoading}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
            <LoadingButton
              disabled={!isDirty || isLoading}
              loading={isLoading}
              loadingPosition="start"
              startIcon={<></>}
              onClick={handleCreate}
              data-test-id={'create-project-btn'}
              type="submit"
              variant="contained"
              color="primary"
              sx={isLoading ? { pl: 5, pr: 2 } : { pr: 2, pl: 2 }}
            >
              {$t(`actions.create`)}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export { DepartmentInlineForm };
