import * as React from 'react';
import { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={756}
    height={146}
    viewBox={'0 0 756 146'}
    fill="none"
    {...props}
  >
    <path
      d="M297.813 19.1591H372.635V40.246H347.355V124.577H323.102V40.2379H297.813V19.1591Z"
      fill="#132732"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M457.915 50.4654C457.915 29.7658 442.95 19.158 423.212 19.158H385.155V124.576H409.409V82.9185L441.915 124.568H472.753L436.117 81.0069C449.141 76.9165 457.915 66.3087 457.915 50.4654ZM432.364 52.1438C432.364 65.3086 421.401 65.9463 412.241 65.9463H409.402V38.3409H412.241C421.401 38.3409 432.364 38.9786 432.364 52.1438Z"
      fill="#132732"
    />
    <path
      d="M543.575 40.2367H506.93V61.2106H542.019V82.2975H506.93V103.489H543.567V124.576H482.677V19.158H543.567L543.575 40.2367Z"
      fill="#132732"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M635.817 124.577H661.875L618.927 19.1502H596.47L553.505 124.577H579.571L587.439 103.49H627.941L635.817 124.577ZM607.558 51.7423H607.815L619.684 82.3963H595.688L607.558 51.7423Z"
      fill="#132732"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M755.999 71.8022C755.999 41.1401 731.489 19.158 702.335 19.158H671.505V124.576H702.335C731.617 124.576 755.999 102.602 755.999 71.8022ZM729.805 71.9298C729.677 89.5641 717.422 103.109 699.489 103.109H695.751V40.6219H699.489C717.55 40.6219 729.805 54.2956 729.805 71.9298Z"
      fill="#132732"
    />
    <path
      d="M193.555 74.0985L145.54 26.0842L97.5259 74.0985L84.4814 61.054L145.54 -0.000167847L206.594 61.054L193.555 74.0985Z"
      fill="#132732"
    />
    <path
      d="M193.555 107.501L145.54 59.4865L97.5259 107.501L84.4814 94.4563L145.54 33.4021L206.594 94.4563L193.555 107.501Z"
      fill="#132732"
    />
    <path
      d="M206.594 127.859L203.099 131.354L180.344 127.688L145.54 92.8846L110.736 127.688L87.9812 131.354L84.4814 127.859L145.54 66.8001L206.594 127.859Z"
      fill="#132732"
    />
    <path
      d="M171.616 126.281L145.541 122.076L119.461 126.281L145.541 100.202L171.616 126.281Z"
      fill="#132732"
    />
    <path
      d="M80.7115 64.8207L67.6729 77.8593L80.6071 90.7936L93.6457 77.755L80.7115 64.8207Z"
      fill="#132732"
    />
    <path
      d="M64.0113 81.5238L50.9727 94.5625L80.607 124.197L93.6456 111.158L64.0113 81.5238Z"
      fill="#132732"
    />
    <path
      d="M81.491 132.4L59.0308 136.02L34.2744 111.263L47.3189 98.2234L81.491 132.4Z"
      fill="#132732"
    />
    <path d="M52.7314 137.038L0 145.536L30.617 114.924L52.7314 137.038Z" fill="#132732" />
    <path
      d="M210.37 64.8213L197.434 77.7533L210.473 90.7974L223.41 77.8654L210.37 64.8213Z"
      fill="#132732"
    />
    <path
      d="M227.062 81.5063L197.428 111.141L210.47 124.183L240.104 94.5481L227.062 81.5063Z"
      fill="#132732"
    />
    <path
      d="M256.81 111.268L232.054 136.02L209.589 132.4L243.766 98.2234L256.81 111.268Z"
      fill="#132732"
    />
    <path
      d="M291.079 145.536L238.353 137.038L260.467 114.924L291.079 145.536Z"
      fill="#132732"
    />
  </svg>
);
export default Logo;
