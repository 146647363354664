import { yupResolver } from '@hookform/resolvers/yup';
import Check from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { SxProps } from '@mui/system';
import { t as $t, t } from 'i18next';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextFormField } from '~components/FormFields/TextFormField';
import { Company, useCompany } from '~hooks/useCompany';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

interface FuelSurchargeInlineFormProps {
  defaultValue?: number;
  buttonOrder: number;
  inputOrder: number;
  sx?: SxProps;
  userCompany: Company;
}
const FuelSurchargeInlineForm = ({
  defaultValue,
  buttonOrder = 1,
  inputOrder = 2,
  sx,
  userCompany,
}: FuelSurchargeInlineFormProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { updateCompany } = useCompany();
  const { toasterStore } = useStores();
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, defaultValues },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver<any>(
      yup.object().shape({
        fuelSurcharge: yup
          .number()
          .min(0)
          .max(100)
          .typeError(
            `${t('form_validation_errors.should_be_number', {
              field: t('form_fields.fuel_surcharge'),
            })}`,
          ),
      }),
    ),
    mode: 'onChange',
    defaultValues: {
      fuelSurcharge: `${userCompany?.fuelSurcharge}` || 0,
    },
  });

  const currentValue = watch('fuelSurcharge');
  const onSuccess = () => {
    reset({ fuelSurcharge: currentValue });
    toasterStore.push(alert($t('company.fuel_surcharge_updated'), AlertTypes.success));
    setIsEditing(false);
  };
  const saveFuelSurcharge = () => {
    if (userCompany && currentValue) {
      userCompany.fuelSurcharge = `${currentValue}`;
      updateCompany({
        company: { id: userCompany.id, fuelSurcharge: currentValue } as Company,
        callBack: onSuccess,
      });
    }
  };
  return (
    <Box
      display={'flex'}
      alignItems={'flex-end'}
      justifyContent={'center'}
      sx={{ ...sx }}
    >
      <Box
        display={'flex'}
        sx={{
          order: buttonOrder,
          mr: buttonOrder > inputOrder ? 0 : 1,
          ml: buttonOrder > inputOrder ? 1 : 0,
        }}
      >
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
            width: 40,
            mb: 0.5,
          }}
          disabled={isEditing && !isDirty}
          color={'primary'}
          aria-label="update-fuel-surcharge"
          onClick={() => {
            isEditing ? saveFuelSurcharge() : setIsEditing(true);
          }}
        >
          {isEditing ? <Check sx={{ height: 18, width: 18 }} /> : <Edit />}
        </Button>
      </Box>
      <Box display={'flex'}>
        <TextFormField
          disabled={!isEditing}
          sx={{
            order: inputOrder,
            marginTop: 0,
            maxWidth: '100px',
            '& .MuiFormHelperText-root': {
              position: 'absolute',
              bottom: '-20px',
              left: '-14px',
              width: '220px',
            },
          }}
          control={control}
          errors={errors}
          label={`${t('form_fields.fuel_surcharge')}`}
          type={'number'}
          InputProps={{
            endAdornment: (
              <Box display={'flex'}>
                <InputAdornment position="start">{'%'}</InputAdornment>
              </Box>
            ),
          }}
          name={'fuelSurcharge'}
        />
      </Box>
    </Box>
  );
};
export { FuelSurchargeInlineForm };
