import { Service_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';
import { useStores } from '~store';

import { Service } from './models';

export const useServices = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { companyAssetsStore } = useStores();

  const getAllServices = () => {
    setIsLoading(true);
    return connection
      .get<Service_Read[]>(
        `${API_VERSION}/services`,
        {},
        $t('error_messages.services.failed_to_fetch') as string,
      )
      .then((resp) => {
        companyAssetsStore.setServices(resp.map(Service.parse));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    getAllServices,
  };
};
