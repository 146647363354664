import { StyleSheet, Text, TextProps, View } from '@react-pdf/renderer';
import { RateType, SettlementLineItemType } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import { Fragment, ReactNode } from 'react';

import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import {
  SettlementLineItem,
  SettlementLineItemGroup,
} from '~hooks/useSettlementLineItems';
import { Settlement } from '~hooks/useSettlements';

export interface LineItemsProps {
  lineItemGroups: SettlementLineItemGroup[];
  settlement: Settlement;
}

type ColumnDef = {
  cellStyle: TextProps['style'];
  getContent: (settlement: Settlement, lineItem: SettlementLineItem) => ReactNode;
  headerStyle: TextProps['style'];
  text: string;
};

export default function LineItems({ lineItemGroups, settlement }: LineItemsProps) {
  const { currencyFormatter } = useCompanyCurrency();

  const styles = StyleSheet.create({
    root: {
      display: 'flex',
      fontSize: 9,
      width: '100%',
    },
    table: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    tr: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      borderBottomColor: '#E0E0E0',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    trGroupName: {
      width: '100%',
      marginTop: 20,
      borderBottomColor: '#A0A0A0',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      paddingHorizontal: 3,
    },
    trGroupNameText: {
      marginBottom: 5,
    },
    trGroupSubtotal: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 10,
      paddingHorizontal: 3,
    },
    trGroupSubtotalText: {
      textTransform: 'uppercase',
      fontSize: 8,
    },
    trGroupSubtotalAmount: {
      marginLeft: 30,
      fontSize: 8,
    },
    th: {
      backgroundColor: '#F6F6F6',
      borderBottomColor: '#A0A0A0',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      flex: 10,
      fontWeight: 'bold',
      paddingHorizontal: 3,
      paddingVertical: 5,
      fontSize: 8,
    },
    td: {
      flex: 10,
      fontSize: 7,
      paddingHorizontal: 3,
      paddingVertical: 5,
    },
  });

  const columns: ColumnDef[] = [
    {
      text: t('approvals.driver_pay.settlements.pdf.date'),
      getContent: (_, lineItem) => {
        return <Text>{lineItem.date.format('MM/DD/YY')}</Text>;
      },
      headerStyle: [styles.th, { flex: 8 }],
      cellStyle: [styles.td, { flex: 8 }],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.truck_num'),
      getContent: (_, lineItem) => {
        return <Text>{lineItem.truckExternalIds.join(' | ')}</Text>;
      },
      headerStyle: [styles.th],
      cellStyle: [styles.td],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.equipment_type'),
      getContent: (_, lineItem) => {
        return <Text>{lineItem.equipmentTypes.join(' | ')}</Text>;
      },
      headerStyle: [styles.th],
      cellStyle: [styles.td],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.material'),
      getContent: (_, lineItem) => {
        return <Text>{lineItem.materials.join(' | ')}</Text>;
      },
      headerStyle: [styles.th],
      cellStyle: [styles.td],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.ids'),
      getContent: (_, lineItem) => {
        return (
          <Text>
            {lineItem.ids.map(({ friendlyJobId }) => friendlyJobId).join(' | ')}
          </Text>
        );
      },
      headerStyle: [styles.th, { flex: 9 }],
      cellStyle: [styles.td, { flex: 9 }],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.qty'),
      getContent: (_, lineItem) => {
        return (
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>
              {t('approvals.driver_pay.settlements.pdf.num_loads', {
                num: lineItem.loadsCount,
              })}
            </Text>

            <Text>
              {`${lineItem.quantities.map((qty) => `${qty.quantity} ${qty.unitOfMeasure}`).join(' | ')}`}
            </Text>
          </View>
        );
      },
      headerStyle: [styles.th],
      cellStyle: [styles.td],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.description'),
      getContent: (settlement, lineItem) => {
        let extraContent = '';

        if (settlement.settlementLineItemType === SettlementLineItemType.JOB) {
          if (lineItem.payStartTime) {
            extraContent = lineItem.payStartTime.format('h:mmA');
          }

          if (lineItem.payEndTime) {
            extraContent = `${extraContent ? `${extraContent} - ` : ''}${lineItem.payEndTime.format('h:mmA')}`;
          }
        }

        return (
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>
              {lineItem.rateTypes
                .map((type) => `Per ${type.replace(/RatePer/g, '').toLowerCase()}`)
                .join(' | ')}
            </Text>

            {extraContent && <Text>{extraContent}</Text>}
          </View>
        );
      },
      headerStyle: [styles.th, { flex: 12 }],
      cellStyle: [styles.td, { flex: 12 }],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.adjustment'),
      getContent: (__, lineItem) => {
        return (
          <Text>
            {_.isNil(lineItem.billingAdjustmentMinutes)
              ? '-'
              : `${dayjs.duration({ minutes: lineItem.billingAdjustmentMinutes }).asHours().toFixed(2)} ${t('common.hrs')}`}
          </Text>
        );
      },
      headerStyle: [styles.th, { textAlign: 'right', flex: 7 }],
      cellStyle: [styles.td, { textAlign: 'right', flex: 7 }],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.billing_qty'),
      getContent: (_, lineItem) => {
        if (lineItem.rateTypes[0] === RateType.RATE_PER_HOUR) {
          return (
            <Text>
              {lineItem.totalQuantity.toFixed(2)} {t('common.hrs')}
            </Text>
          );
        }
      },
      headerStyle: [styles.th, { textAlign: 'right' }],
      cellStyle: [styles.td, { textAlign: 'right' }],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.pay_rate'),
      getContent: (_, lineItem) => {
        return <Text>{currencyFormatter(lineItem.rateValue)}</Text>;
      },
      headerStyle: [styles.th, { textAlign: 'right' }],
      cellStyle: [styles.td, { textAlign: 'right' }],
    },
    {
      text: t('approvals.driver_pay.settlements.pdf.total'),
      getContent: (_, lineItem) => {
        return <Text>{currencyFormatter(lineItem.totalAmount)}</Text>;
      },
      headerStyle: [styles.th, { textAlign: 'right' }],
      cellStyle: [styles.td, { textAlign: 'right' }],
    },
  ];

  return (
    <View style={styles.root}>
      <View style={styles.table}>
        {columns.map((col) => (
          <Text key={col.text} style={col.headerStyle}>
            {col.text}
          </Text>
        ))}
      </View>

      {lineItemGroups.map((group, groupIndex) => (
        <Fragment key={`group-${groupIndex}`}>
          {group.group && (
            <View style={styles.trGroupName}>
              <Text style={styles.trGroupNameText}>{group.group}</Text>
            </View>
          )}

          {group.lineItems.map((lineItem, rowIndex) => (
            <View style={styles.tr} key={rowIndex}>
              {columns.map((col) => (
                <View key={`${col.text}-row-${rowIndex}`} style={col.cellStyle}>
                  {col.getContent(settlement, lineItem)}
                </View>
              ))}
            </View>
          ))}

          {group.group && (
            <View style={styles.trGroupSubtotal}>
              <Text style={styles.trGroupSubtotalText}>
                {t('approvals.driver_pay.settlements.pdf.group_subtotal', {
                  group: group.group,
                })}
              </Text>

              <Text style={[styles.trGroupSubtotalAmount]}>
                {currencyFormatter(group.subtotal ?? 0)}
              </Text>
            </View>
          )}
        </Fragment>
      ))}
    </View>
  );
}
