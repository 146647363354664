import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AuthMethod } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useCallback, useMemo, useState } from 'react';

import FormWrapperBox from './FormWrapperBox';
import SignInMagicLinkSentScreen from './SignInMagicLinkSentScreen';
import SignInWithMagicLinkForm from './SignInWithMagicLinkForm';
import SignInWithPasswordForm from './SignInWithPasswordForm';

interface SignInWithMagicLinkOrPasswordFormProps {
  availableStrategies: AuthMethod[];
  email: string;
  onBack: () => void;
}

enum SignInWithEmailStep {
  SELECT_STRATEGY = 'select_strategy',
  MAGIC_LINK_SENT = 'magic_link_sent',
}

const SignInWithMagicLinkOrPasswordForm = ({
  availableStrategies,
  email,
  onBack,
}: SignInWithMagicLinkOrPasswordFormProps) => {
  const [currentStep, setCurrentStep] = useState<SignInWithEmailStep>(
    SignInWithEmailStep.SELECT_STRATEGY,
  );

  const isMagicLinkStrategyAvailable = useMemo(() => {
    return availableStrategies.includes(AuthMethod.MAGIC_LINK);
  }, [availableStrategies]);

  const isPasswordStrategyAvailable = useMemo(() => {
    return availableStrategies.includes(AuthMethod.PASSWORD);
  }, [availableStrategies]);

  const handleBackButtonClick = useCallback(() => {
    if (currentStep === SignInWithEmailStep.SELECT_STRATEGY) {
      onBack();
    } else if (currentStep === SignInWithEmailStep.MAGIC_LINK_SENT) {
      setCurrentStep(SignInWithEmailStep.SELECT_STRATEGY);
    }
  }, [currentStep, onBack]);

  return (
    <FormWrapperBox hideLogo onBack={handleBackButtonClick} sx={{ pt: 10 }}>
      {currentStep === SignInWithEmailStep.SELECT_STRATEGY && (
        <>
          {isMagicLinkStrategyAvailable && (
            <SignInWithMagicLinkForm
              email={email}
              onMagicLinkSent={() => setCurrentStep(SignInWithEmailStep.MAGIC_LINK_SENT)}
            />
          )}

          {isMagicLinkStrategyAvailable && isPasswordStrategyAvailable && (
            <Divider flexItem sx={{ my: 3 }}>
              <Typography variant="body2">{t('common.or').toUpperCase()}</Typography>
            </Divider>
          )}

          {isPasswordStrategyAvailable && (
            <SignInWithPasswordForm
              email={email}
              showEmailField={!isMagicLinkStrategyAvailable}
            />
          )}
        </>
      )}

      {currentStep === SignInWithEmailStep.MAGIC_LINK_SENT && (
        <SignInMagicLinkSentScreen email={email} />
      )}
    </FormWrapperBox>
  );
};

export default SignInWithMagicLinkOrPasswordForm;
