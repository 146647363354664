import { Account } from '~hooks/useAccount';
import { Order } from '~hooks/useOrders';
import { User } from '~hooks/useUsers';

export function getOrdersForCustomer(account: Account, currentDayOrders: Order[]) {
  let orders: Order[] = [];
  if (account) {
    orders = currentDayOrders.filter((order) => {
      return order.account?.id === account.id;
    });
  }
  return orders;
}

export function getOrdersForForeman(
  foremanUser: User,
  currentDayOrders: Order[],
  allForemanUsers: User[],
) {
  let orders: Order[] = [];
  if (foremanUser) {
    orders = currentDayOrders.filter((order) => {
      return order.foremen?.some((foreman) => foreman.id === foremanUser.id);
    });
  } else {
    const hasOrdersInForemanColumn = allForemanUsers.some((foreman) => {
      return currentDayOrders.some((order) => {
        return order.foremen?.some((orderForeman) => orderForeman.id === foreman.id);
      });
    });
    if (!hasOrdersInForemanColumn) {
      orders = currentDayOrders;
    }
  }
  return orders;
}
