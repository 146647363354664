import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

import { LiveMapFeature, SiteFeatureProperties } from '~hooks/useLiveMap/models';

interface Props {
  feature: LiveMapFeature;
}

/**
 * Renders the details of a site in a popup.
 *
 * @param {Feature} props.feature - The feature object representing the site.
 *
 * @returns {JSX.Element} The rendered popup content component.
 */
const SiteDetails = ({ feature }: Props) => {
  const theme = useTheme();
  const details = feature.properties as SiteFeatureProperties;

  return (
    <>
      {/* Component header with site name */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
          mb: 1,
        }}
      >
        <Grid item xs={6}>
          <Typography variant="body2">{details.name}</Typography>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 1 }} />

      {/* Site Address */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }}
      >
        <Grid item xs={5}>
          <Typography variant="body2">{t('live_map.pop_up.site.address')}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">{details.address || '-'}</Typography>
        </Grid>
      </Grid>

      {/* Site type */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }}
      >
        <Grid item xs={5}>
          <Typography variant="body2">{t('live_map.pop_up.site.type')}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">{details.type || '-'}</Typography>
        </Grid>
      </Grid>

      {/* Site Geofence shape type */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }}
      >
        <Grid item xs={5}>
          <Typography variant="body2">
            {t('live_map.pop_up.site.geofence_type')}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">{details.geofenceType || '-'}</Typography>
        </Grid>
      </Grid>

      {/* Site External ID */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }}
      >
        <Grid item xs={5}>
          <Typography variant="body2">{t('live_map.pop_up.site.external_id')}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>{details.externalId || '-'}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export { SiteDetails };
