import {
  AccountType,
  Rate_Read,
  Rate_Read_Nested,
  RateOwnerType,
} from '@treadinc/horizon-api-spec';

import { OrderUnitOfMeasure, rateTimeUnits, RateTypes } from '~constants/enums';
import { AccountBasic } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import { BasicEquipment, Equipment } from '~hooks/useEquipment';
import { ProjectBasic } from '~hooks/useProjects/models';
import { Service } from '~hooks/useServices';
import { RatesFormSchemaInterface } from '~pages/Settings/RatesManagement/rateFormValidationSchema';
import { rateTypeLabelColor } from '~pages/Settings/RatesManagement/rateUtils';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

export class ItemColorLabelValue {
  public static parse(proto: any): ItemColorLabelValue {
    return new ItemColorLabelValue(
      proto.color ?? '',
      proto.label ?? '',
      proto.value ?? '',
    );
  }
  public get color(): string {
    return this._color;
  }
  public get label(): string {
    return this._label;
  }
  public get value(): RateTypes {
    return this._value;
  }
  constructor(
    private _color: string,
    private _label: string,
    private _value: RateTypes,
  ) {}
}
//Due to the mix of rates + uOm types, we need to map them
const unitsOfMeasureRateTypesMapped = {
  [RateTypes.RATE_PER_LOAD]: OrderUnitOfMeasure.LOAD,
  [RateTypes.RATE_PER_TON]: OrderUnitOfMeasure.TON,
  [RateTypes.RATE_PER_TONNE]: OrderUnitOfMeasure.TONNE,
  [RateTypes.RATE_PER_YARD]: OrderUnitOfMeasure.YARD,
  [RateTypes.RATE_PER_BUSHEL]: OrderUnitOfMeasure.BUSHEL,
  [RateTypes.RATE_PER_HOUR]: rateTimeUnits.HOURLY,
  [RateTypes.RATE_PER_DAY]: rateTimeUnits.DAILY,
};
interface OptionItem {
  label: string;
  value: string;
  color: string;
}
const getIds = (items: ProjectBasic[] | Service[] | Equipment[]) => {
  return items?.length ? items.map((item) => item?.id) : undefined;
};
export class RateCommissionInputs {
  public static parse(proto: any): RateCommissionInputs {
    return new RateCommissionInputs(proto.commission_percentage ?? 0);
  }
  public static deparse(rate: RatesFormSchemaInterface): any {
    return {
      commission_percentage: Number(rate?.rateInputs?.rate),
    };
  }
  public get commissionPercentage(): number {
    return this._commission_percentage;
  }
  constructor(private _commission_percentage: number) {}
}
export class RatePerUnitInputs {
  public static parse(proto: any): RatePerUnitInputs {
    return new RatePerUnitInputs(
      proto.unit_rate ?? 0,
      proto.unit_minimum ?? 0,
      proto.unit_increment ?? 0,
    );
  }
  public static deparse(rate: RatesFormSchemaInterface): any {
    return {
      unit_rate: rate?.rateInputs?.rate,
      unit_minimum: rate.rateInputs?.minimum,
      unit_increment: 0,
    };
  }
  public get perUnitRate(): number {
    return this._per_unit_rate;
  }
  public get perUnitMinimum(): number {
    return this._per_unit_minimum;
  }
  public get perUnitIncrement(): number {
    return this._per_unit_increment;
  }
  constructor(
    private _per_unit_rate: number,
    private _per_unit_minimum: number,
    private _per_unit_increment: number, // Private _per_unit_unit_of_measure: number, // private _per_unit_round_to_next_increment: number,
  ) {}
}
export class RateTimeInputs {
  public static parse(proto: any): RateTimeInputs {
    return new RateTimeInputs(
      proto.time_rate ?? 0,
      proto.time_minimum_minutes ?? 0,
      proto?.time_increment_minutes ?? 0,
    );
  }
  public static deparse(rate: RatesFormSchemaInterface): any {
    return {
      time_rate: rate.rateInputs?.rate,
      time_minimum_minutes: rate.rateInputs?.minimum?.match(/\d+/)[0],
      time_increment_minutes: rate?.rateInputs?.increment?.match(/\d+/)[0] || 0,
    };
  }
  public get timeRate(): number {
    return this._time_rate;
  }
  public get timeMinimumMinutes(): number {
    return this._time_minimum_minutes;
  }
  public get timeIncrementMinutes(): number {
    return this._time_increment_minutes;
  }
  constructor(
    public _time_rate: number,
    public _time_minimum_minutes: number,
    public _time_increment_minutes: number,
  ) {}
}
const deparseByRateType = (rate: RatesFormSchemaInterface) => {
  let res;
  switch (rate.type.value) {
    case RateTypes.RATE_PER_HOUR:
    case RateTypes.RATE_PER_DAY:
      res = RateTimeInputs.deparse(rate);
      break;
    case RateTypes.RATE_COMMISSION:
      res = RateCommissionInputs.deparse(rate);
      break;
    case RateTypes.RATE_PER_TON:
    case RateTypes.RATE_PER_TONNE:
    case RateTypes.RATE_PER_LOAD:
    case RateTypes.RATE_PER_YARD:
    case RateTypes.RATE_PER_BUSHEL:
      res = RatePerUnitInputs.deparse(rate);
      break;
  }
  return res;
};

const getByRateType = (
  rateType: RateTypes,
  proto: any,
): RatePerUnitInputs | RateTimeInputs | RateCommissionInputs => {
  let res;
  switch (rateType) {
    case RateTypes.RATE_PER_HOUR:
    case RateTypes.RATE_PER_DAY:
      res = RateTimeInputs.parse(proto);
      break;
    case RateTypes.RATE_COMMISSION:
      res = RateCommissionInputs.parse(proto);
      break;
    default:
      res = RatePerUnitInputs.parse(proto);
  }
  return res;
};
const getRateValueByType = (proto: any) => {
  if (proto?.time_rate) return proto.time_rate;
  if (proto?.unit_rate) return proto.unit_rate;
  if (proto?.commission_percentage) return proto.commission_percentage;
};

export class RateBasicWithValue {
  public static parse(proto: any): RateBasicWithValue {
    return new RateBasicWithValue(
      proto.id ?? '',
      proto.name ?? '',
      proto.type ?? '',
      getRateValueByType(proto),
    );
  }
  public get readableTypeValue(): string {
    return rateTypeLabelColor(this._type as unknown as RateTypes)?.label || '';
  }
  public get type(): string {
    return this._type;
  }
  public get rate(): number {
    return this._rate;
  }
  public get id(): string {
    return this._id;
  }
  public get name(): string {
    return this._name;
  }
  constructor(
    private _id: string,
    private _name: string,
    private _type: string,
    private _rate: number,
  ) {}
}
export class Rate {
  public static parse(proto: Rate_Read): Rate {
    return new Rate(
      proto.id ?? '',
      proto.name ?? '',
      proto.account ? AccountBasic.parse(proto.account) : null,
      proto.projects?.length ? proto.projects.map(ProjectBasic.parse) : [],
      proto.services?.length ? proto.services.map(Service.parse) : [],
      proto.equipment?.length ? proto.equipment.map(BasicEquipment.parse) : [],
      proto.primary ?? false,
      proto?.type?.length ? rateTypeLabelColor(proto.type as unknown as RateTypes) : null,
      proto.fuel_surcharge_enabled ?? false,
      proto.type && getByRateType(proto.type as unknown as RateTypes, proto),
      proto?.company?.id?.length ? CompanyBasic.parse(proto.company) : null,
      proto?.account_type ?? AccountType.CUSTOMER,
      proto.owner_type,
    );
  }
  public static deparseCreate(
    rate: RatesFormSchemaInterface,
    companyId: string,
    ownerType: RateOwnerType,
  ): Rate_Read {
    const deParsedInputs = deparseByRateType(rate);
    return {
      name: rate.name,
      company_id: companyId,
      account_id: rate.account?.id || undefined, // If non account, but company id, it will be a default company rate
      project_ids: getIds((rate.projects as ProjectBasic[]) || []),
      service_ids: getIds((rate.services as Service[]) || []),
      equipment_ids: getIds((rate.equipment as Equipment[]) || []),
      owner_type: ownerType,
      fuel_surcharge_enabled: rate.isFuelSurcharge,
      primary: rate.isPrimary,
      type: rate.type?.value,
      driver_id: rate.driverId,
      ...deParsedInputs,
    };
  }
  public static deparseUpdate(
    rate: RatesFormSchemaInterface,
    ownerType: RateOwnerType,
  ): any {
    const deParsedInputs = deparseByRateType(rate);
    return {
      name: rate.name,
      account_id: rate.account?.id || undefined,
      project_ids: getIds(rate.projects as ProjectBasic[]) || [],
      service_ids: getIds(rate.services as Service[]) || [],
      equipment_ids: getIds(rate.equipment as Equipment[]) || [],
      owner_type: ownerType,
      fuel_surcharge_enabled: rate.isFuelSurcharge,
      primary: rate.isPrimary,
      type: rate.type?.value,
      driver_id: rate.driverId,
      ...deParsedInputs,
    };
  }
  public get isTimeBased(): boolean {
    return (
      this._type?.value === RateTypes.RATE_PER_HOUR ||
      this._type?.value === RateTypes.RATE_PER_DAY
    );
  }
  public get isHourly(): boolean {
    return this._type?.value === RateTypes.RATE_PER_HOUR;
  }
  public get isDaily(): boolean {
    return this._type?.value === RateTypes.RATE_PER_DAY;
  }
  public get isCommission(): boolean {
    return this._type?.value === RateTypes.RATE_COMMISSION;
  }
  public get name(): string {
    return this._name;
  }
  public get id(): string | undefined {
    return this._id;
  }
  public get rateInputs(): RateTimeInputs | RatePerUnitInputs | RateCommissionInputs {
    // | RateTimeAndPerUnitInputs
    return this._rate_inputs;
  }
  public get account(): Nullable<AccountBasic> {
    return this._account;
  }

  public get isPrimary(): boolean {
    return this._is_primary;
  }
  public get type(): ItemColorLabelValue {
    return <ItemColorLabelValue>this._type;
  }
  public get projects(): ProjectBasic[] {
    return this._projects;
  }
  public get services(): Service[] {
    return this._services;
  }
  public get equipment(): BasicEquipment[] {
    return this._equipment;
  }
  public get company(): Nullable<CompanyBasic> {
    return this._company;
  }
  public get accountType(): AccountType {
    return this._account_type;
  }
  public get isFuelSurcharge(): boolean {
    return this._is_fuel_surcharge;
  }
  public get ownerType() {
    return this._owner_type;
  }

  constructor(
    private _id: string,
    private _name: string,
    private _account: Nullable<AccountBasic>,
    private _projects: ProjectBasic[],
    private _services: Service[],
    private _equipment: BasicEquipment[],
    private _is_primary: boolean,
    private _type: Nullable<ItemColorLabelValue>,
    private _is_fuel_surcharge: boolean,
    private _rate_inputs: RateCommissionInputs | RatePerUnitInputs | RateTimeInputs,
    private _company: Nullable<CompanyBasic>,
    private _account_type: AccountType,
    private _owner_type: RateOwnerType,
  ) {}
}
