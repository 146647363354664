import { TelematicsProviderSource } from '@treadinc/horizon-api-spec';
import _ from 'lodash';
import { Navigate, RouteObject } from 'react-router-dom';

import { SystemRoles } from '~constants/enums';
import AdminAccountsDataGrid from '~pages/Admin/Accounts/AdminAccountsDataGrid';
import { Admin } from '~pages/Admin/Admin';
import AdminCompaniesDataGrid from '~pages/Admin/Companies/AdminCompaniesDataGrid';
import AdminConnectionsDataGrid from '~pages/Admin/Connections/AdminConnectionsDataGrid';
import AdminUsersDataGrid from '~pages/Admin/Users/AdminUsersDataGrid';
import { Approvals } from '~pages/Approvals/Approvals';
import { DriverDay } from '~pages/Approvals/DriverDay/DriverDay';
import DriverPay from '~pages/Approvals/DriverPay/DriverPay';
import { TicketsReviewDataGrid } from '~pages/Approvals/TicketsReviewDataGrid/TicketsReviewDataGrid';
import { OrdersDataGrid } from '~pages/Sales/Orders/OrdersDataGrid';
import { ProjectsDataGrid } from '~pages/Sales/Projects/ProjectsDataGrid';
import { Sales } from '~pages/Sales/Sales';
import { AccountsDataGrid } from '~pages/Settings/Accounts/AccountsDataGrid';
import { EquipmentManagement } from '~pages/Settings/Administration/EquipmentManagement/EquipmentManagement';
import { MaterialsManagement } from '~pages/Settings/Administration/MaterialsManagement/MaterialsManagement';
import { SitesManagement } from '~pages/Settings/Administration/Sites/SitesManagement';
import ConfigurationManagement, {
  userHasAccessToConfigurationManagement,
} from '~pages/Settings/Configuration/ConfigurationManagement';
import { DepartmentManagement } from '~pages/Settings/DepartmentManagement/DepartmentManagement';
import { EquipmentTypeManagement } from '~pages/Settings/EquipmentTypeManagement/EquipmentTypeManagement';
import { GeneralSettings } from '~pages/Settings/GeneralSettings/GeneralSettings';
import AgaveManagement from '~pages/Settings/Integrations/AgaveManagement';
import { FleetManagement } from '~pages/Settings/Integrations/FleetManagement';
import { IntegrationManagement } from '~pages/Settings/Integrations/IntegrationManagement';
import AddOnCharges from '~pages/Settings/RatesManagement/AddOnCharges/AddOnCharges';
import { RatesManagement } from '~pages/Settings/RatesManagement/RatesManagement';
import { ServiceClassManagement } from '~pages/Settings/ServiceClasses/ServiceClassManagement';
import { Settings } from '~pages/Settings/Settings';
import { UserManagementDataGrid } from '~pages/Settings/UserManagement/UserManagementDataGrid';
import UserProfile from '~pages/Settings/UserProfile/UserProfile';
import { routes } from '~router/routesPaths';
import UserStore from '~store/UserStore';

import { ProtectedRoute } from './ProtectedRoute';

export const ADMIN_WITH_NESTED_ROUTES: RouteObject = {
  path: routes.admin.base,
  element: (
    <ProtectedRoute element={Admin} requiredRoles={[SystemRoles.PLATFORM_ADMIN]} />
  ),
  children: [
    {
      index: true,
      element: <Navigate to={routes.admin.companies} replace />,
    },
    {
      path: routes.admin.users,
      element: <ProtectedRoute element={AdminUsersDataGrid} />,
    },
    {
      path: routes.admin.companies,
      element: <ProtectedRoute element={AdminCompaniesDataGrid} />,
    },
    {
      path: routes.admin.connections,
      element: <ProtectedRoute element={AdminConnectionsDataGrid} />,
    },
    {
      path: routes.admin.accounts,
      element: <ProtectedRoute element={AdminAccountsDataGrid} />,
    },
  ],
};

export const SALES_WITH_NESTED_ROUTES: RouteObject = {
  path: routes.sales.base,
  element: <ProtectedRoute element={Sales} />,
  children: [
    {
      index: true,
      element: <Navigate to={routes.sales.projects} replace />,
    },
    {
      path: routes.sales.projects,
      element: <ProtectedRoute element={ProjectsDataGrid} />,
    },
    {
      path: routes.sales.orders,
      element: <ProtectedRoute element={OrdersDataGrid} />,
    },
  ],
};

export const APPROVAL_WITH_NESTED_ROUTES: RouteObject = {
  path: routes.approvals.base,
  element: <ProtectedRoute element={Approvals} />,
  children: [
    {
      index: true,
      element: <ProtectedRoute element={TicketsReviewDataGrid} />,
    },
    {
      path: routes.approvals.driverPay,
      element: <ProtectedRoute element={DriverPay} />,
    },
    {
      path: routes.approvals.driverDay,
      element: <ProtectedRoute element={DriverDay} />,
    },
  ],
};

export const getSettingsNestedRoutes = (userStore: UserStore): RouteObject => {
  const userAccess = userStore.getAccess();
  return {
    path: routes.settings.base,
    element: <ProtectedRoute element={Settings} />,
    children: [
      {
        index: true,
        element: <Navigate to={routes.settings.userProfile} replace />,
      },
      {
        path: routes.settings.userProfile,
        element: <ProtectedRoute element={UserProfile} />,
      },
      {
        path: routes.settings.generalSettings,
        element: (
          <ProtectedRoute
            element={GeneralSettings}
            canAccess={userAccess.manageCompanies}
          />
        ),
      },
      {
        path: routes.settings.accounts,
        element: (
          <ProtectedRoute
            element={AccountsDataGrid}
            canAccess={userAccess.manageAccounts}
          />
        ),
      },
      {
        path: routes.settings.userManagement,
        element: (
          <ProtectedRoute
            element={UserManagementDataGrid}
            canAccess={userAccess.manageUser}
          />
        ),
      },
      {
        path: routes.settings.equipmentTypeManagement,
        element: (
          <ProtectedRoute
            element={EquipmentTypeManagement}
            canAccess={userAccess.manageEquipment}
          />
        ),
      },
      {
        path: routes.settings.equipmentManagement,
        element: (
          <ProtectedRoute
            element={EquipmentManagement}
            canAccess={userAccess.manageEquipment}
          />
        ),
      },
      {
        path: routes.settings.materialsManagement,
        element: (
          <ProtectedRoute
            element={MaterialsManagement}
            canAccess={userAccess.manageMaterials}
          />
        ),
      },
      {
        path: routes.settings.ratesManagement,
        element: (
          <ProtectedRoute element={RatesManagement} canAccess={userAccess.manageRates} />
        ),
      },
      {
        path: routes.settings.addOnCharges,
        element: (
          <ProtectedRoute element={AddOnCharges} canAccess={userAccess.manageRates} />
        ),
      },
      {
        path: routes.settings.sitesManagement,
        element: (
          <ProtectedRoute element={SitesManagement} canAccess={userAccess.manageSites} />
        ),
      },
      {
        path: routes.settings.departmentsManagement,
        element: (
          <ProtectedRoute
            element={DepartmentManagement}
            canAccess={userAccess.manageDepartments}
          />
        ),
      },
      {
        path: routes.settings.serviceClassesManagement,
        element: (
          <ProtectedRoute
            element={ServiceClassManagement}
            canAccess={userAccess.manageServiceClasses}
          />
        ),
      },
      {
        path: routes.settings.configuration,
        element: (
          <ProtectedRoute
            element={ConfigurationManagement}
            canAccess={userHasAccessToConfigurationManagement(userStore)}
          />
        ),
      },
      {
        path: routes.settings.integrations.base,
        children: [
          {
            index: true,
            element: <ProtectedRoute element={IntegrationManagement} />,
          },
          {
            path: routes.settings.integrations.axle,
            element: (
              <ProtectedRoute
                element={FleetManagement}
                providerSource={TelematicsProviderSource.AXLE}
              />
            ),
          },
          {
            path: routes.settings.integrations.paverTracker,
            element: (
              <ProtectedRoute
                element={FleetManagement}
                providerSource={TelematicsProviderSource.PAVER_TRACKER}
              />
            ),
          },
          {
            path: routes.settings.integrations.agave,
            element: <ProtectedRoute element={AgaveManagement} />,
          },
        ],
      },
    ],
  };
};
