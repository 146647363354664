import { IControl, Map } from '@nbai/nbmap-gl';
import { createRoot } from 'react-dom/client';

import { MapType, MapTypes, MapTypeToggle } from './MapTypeToggle';

/**
 * Represents a custom map control for changing map styles.
 */
class MapStylesControl implements IControl {
  private _map: Map | undefined;
  private _container: HTMLDivElement | undefined;
  private _setStyle: (style: MapType) => void;

  constructor(setStyle: (style: MapType) => void) {
    this._setStyle = setStyle;
  }

  /**
   * Adds the control to the map.
   *
   * @param map The map instance.
   * @returns The HTMLDivElement container for the control.
   */
  onAdd(map: Map): HTMLDivElement {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl';

    // Create a button element
    const currentMapType = this._map.getStyle().name;
    createRoot(this._container).render(
      <MapTypeToggle
        defaultType={
          currentMapType === MapTypes.STREET.name ? MapTypes.STREET : MapTypes.SATELLITE
        }
        onChange={(mapType: MapType) => {
          this._map?.setStyle(mapType.url);
          this._setStyle(mapType);
        }}
      />,
    );

    return this._container;
  }

  /**
   * Removes the control from the map.
   */
  onRemove(): void {
    if (this._container?.parentNode) {
      this._container.parentNode.removeChild(this._container);
    }
    this._map = undefined;
  }
}

export default MapStylesControl;
