import Box, { BoxProps } from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { SxProps } from '@mui/system';

import theme from '~theme/AppTheme';

import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '../../pages/Dispatch/NewDispatch';
import { orderDispatchColumns, OrdersDispatchColumnKey } from './ordersDispatchColumns';

type LoadingSpinnerProps = {
  isVisible?: boolean;
  loadingIndicatorSize?: number;
  sx?: SxProps;
};

export function LoadingSpinner({
  isVisible,
  loadingIndicatorSize = 30,
  sx,
}: LoadingSpinnerProps) {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      visibility={isVisible ? 'visible' : 'hidden'}
      width="100%"
      data-test-id="loading-spinner"
      sx={{ ...sx, display: isVisible ? 'flex' : 'none' }}
    >
      <CircularProgress size={loadingIndicatorSize} />
    </Box>
  );
}

type HeaderPanelProps = BoxProps;
export const HEADER_PANEL_Z_INDEX = 100;

const StyledHeaderPanel = styled(Box)<HeaderPanelProps>(() => {
  return {
    alignContent: 'center',
    backgroundColor: 'white',
    borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
  };
});

export function HeaderPanel(props: HeaderPanelProps) {
  return <StyledHeaderPanel {...props} />;
}

type OverflowAwareTextProps = TypographyProps;

const StyledOverflowAwareText = styled(Typography)<OverflowAwareTextProps>(() => {
  return {
    color: theme.brandV2.colors.treadBlack,
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  };
});

export function OverflowAwareText(props: OverflowAwareTextProps) {
  return <StyledOverflowAwareText {...props} />;
}

type ColumnProps = BoxProps & {
  columnKey: OrdersDispatchColumnKey;
  isHeader?: boolean;
};

const StyledColumn = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isHeader',
})<Omit<ColumnProps, 'columnKey'>>(({ isHeader }) => {
  return {
    overflow: 'hidden',
    padding: theme.spacing(0, 1),
    ...(isHeader ? { display: 'flex', alignItems: 'center' } : {}),
  };
});

export function Column({ columnKey, ...rest }: ColumnProps) {
  return <StyledColumn sx={orderDispatchColumns[columnKey].sx} {...rest} />;
}

type FlexColumnProps = BoxProps;

const StyledFlexColumn = styled(Box)<FlexColumnProps>(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  };
});

export function FlexColumn(props: FlexColumnProps) {
  return <StyledFlexColumn {...props} />;
}

const StyledButton = styled(Button)<ButtonProps>(() => {
  return {
    '&.MuiButtonBase-root': {
      boxShadow: 'none',
      fontSize: '12px',
      fontWeight: 600,
      height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
      minWidth: 'auto',
      padding: `0 ${theme.spacing(1.5)}`,
      whiteSpace: 'nowrap',
    },
  };
});

export function SmallButton(props: ButtonProps) {
  return <StyledButton {...props} />;
}

interface OrdersViewBulkActionsBarButtonProps extends Omit<ButtonProps, 'size'> {}

const StyledOrdersViewBulkActionsBarButton = styled(
  Button,
)<OrdersViewBulkActionsBarButtonProps>(({ color = 'primary' }) => {
  const isInheritColor = color === 'inherit';

  return {
    backgroundColor: isInheritColor ? theme.palette.grey[800] : theme.palette[color].main,
    borderRadius: theme.spacing(0.5),
    color: isInheritColor ? theme.palette.grey[50] : theme.palette[color].contrastText,
    fontSize: '12px',
    height: '28px',
    letterSpacing: '0.01px',
    minWidth: '90px',
    '&.MuiButton-root': {
      '&:hover': {
        backgroundColor: isInheritColor ? theme.palette.grey[600] : undefined,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.grey[400],
        cursor: 'not-allowed !important',
        pointerEvents: 'auto',
        opacity: color ? 0.8 : 1,
      },
      '& .MuiButton-endIcon .MuiSvgIcon-root': {
        fontSize: '16px',
      },
      '& .MuiButton-startIcon .MuiSvgIcon-root': {
        fontSize: '16px',
      },
    },
  };
});

export function OrdersViewBulkActionsBarButton(
  props: OrdersViewBulkActionsBarButtonProps,
) {
  return <StyledOrdersViewBulkActionsBarButton size="small" {...props} />;
}
