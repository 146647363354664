import Typography from '@mui/material/Typography';

import theme from '~theme/AppTheme';

interface FieldProps {
  label: string;
  value: React.ReactNode;
  icon?: React.ReactNode;
  editing?: boolean;
  editField?: React.ReactNode;
}

export const Field = ({ label, value, editField, icon, editing = false }: FieldProps) => {
  return (
    <div className="flex border-b border-gray-200 px-4 py-2 items-center h-[57px]">
      <div className="min-w-[150px] max-w-[150px] truncate">
        <Typography
          variant={'body1'}
          className="truncate"
          title={label}
          sx={{ pr: 1, color: theme.palette.text.secondary, fontWeight: 'bold' }}
        >
          {label}
        </Typography>
      </div>
      <div className="truncate flex flex-1">
        <span className="w-full flex place-content-between items-center space-x-2">
          {editing && editField}
          {!editing && (
            <Typography
              title={typeof value === 'string' ? value : ''}
              className="truncate"
              variant={'body1'}
            >
              {value}
            </Typography>
          )}
          {icon}
        </span>
      </div>
    </div>
  );
};
