import { TelematicsRemoteVehicle_Read } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { BasicEquipment } from '~hooks/useEquipment';
import { Nullable } from '~types/Nullable';

export class TelematicsVehicle {
  public static parse(proto: TelematicsRemoteVehicle_Read): TelematicsVehicle {
    return new TelematicsVehicle(
      proto.id,
      dayjs(proto.created_at),
      dayjs(proto.updated_at),
      proto.telematics_fleet_id,
      proto.equipment ? BasicEquipment.parse(proto.equipment) : null,
      proto.remote_vehicle_external_id,
      proto.remote_vehicle_external_name,
      proto.remote_vehicle_external_model,
      proto.remote_vehicle_external_license_plate_number,
    );
  }

  public get id(): string {
    return this._id;
  }
  public get createdAt(): Dayjs {
    return this._createdAt;
  }
  public get updatedAt(): Dayjs {
    return this._updatedAt;
  }
  public get telematicsFleetId(): string {
    return this._telematicsFleetId;
  }
  public get equipment(): Nullable<BasicEquipment> {
    return this._equipment;
  }
  public get remoteVehicleExternalId(): number {
    return this._remoteVehicleExternalId;
  }
  public get remoteVehicleExternalName(): string {
    return this._remoteVehicleExternalName;
  }
  public get remoteVehicleExternalModel(): Nullable<string> {
    return this._remoteVehicleExternalModel;
  }
  public get remoteVehicleExternalLicensePlateNumber(): Nullable<string> {
    return this._remoteVehicleExternalLicensePlateNumber;
  }

  constructor(
    private _id: string,
    private _createdAt: Dayjs,
    private _updatedAt: Dayjs,
    private _telematicsFleetId: string,
    private _equipment: Nullable<BasicEquipment>,
    private _remoteVehicleExternalId: number,
    private _remoteVehicleExternalName: string,
    private _remoteVehicleExternalModel: Nullable<string>,
    private _remoteVehicleExternalLicensePlateNumber: Nullable<string>,
  ) {}
}
