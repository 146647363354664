import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { CompanyShareableType } from '@treadinc/horizon-api-spec/types/models/CompanyShareableType';
import type { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogActionsWrapper } from '~components/Dialog/DialogActionsWrapper';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { StatusTabFilters } from '~components/StatusTabFilters/StatusTabFilters';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { AccountTypesFilter, data as enums } from '~constants/enums';
import { companyConnectAccountByTreadId } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { Account, ConnectAccountByTreadIdParams, useAccount } from '~hooks/useAccount';
import { useCompany } from '~hooks/useCompany';
import { useCompanyShares } from '~hooks/useCompanyShares/useCompanyShares';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { DriverBasic } from '~hooks/useDrivers';
import { BasicEquipment } from '~hooks/useEquipment';
import { usePermissions } from '~hooks/usePermissions';
import { ConnectedAccountForm as AccountForm } from '~pages/Settings/Accounts/ConnectedAccountForm';
import { useAccountFilters } from '~pages/Settings/Accounts/useAccountFilters';
import { useCompanySharesDetails } from '~pages/Settings/Accounts/useCompanyShareDetails';
import { ErrorResponse, FieldError } from '~src/services/connectionModule';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';
import { isActionClicked } from '~utils/utilFunctions';

import {
  AddConnectionFormRef,
  ConnectAccountByTreadIdForm,
} from './ConnectAccountByTreadIdForm';

export interface AccountFormStateChangeProps extends FormStateChangeProps {
  hasValidEmail: boolean;
  hasValidPhone: boolean;
  inConnectedView: boolean;
}

const AccountTypes = enums.account_type.values.reduce(
  (obj: Record<string, unknown>, value: string) => {
    obj[value] = value;
    return obj;
  },
  {},
);

const ActionsWrapper = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1.5),
}));

const ConnectDialogDescription = styled(Typography)<{
  component?: React.ElementType;
}>(({ theme }) => ({
  marginBottom: '0',
  marginTop: theme.spacing(4),
}));

interface ConnectAccountDialogProps {
  isDialogOpen: boolean;
  onClose: () => void;
}

const ConnectAccountDialog = ({ isDialogOpen, onClose }: ConnectAccountDialogProps) => {
  const { userStore } = useStores();
  const connectAccountFormRef = useRef<AddConnectionFormRef>(null);
  const theme = useTheme();
  const {
    isLoading: isCreatingConnection,
    createConnectedAccountByTreadId,
    getAllCompanyAccounts,
  } = useAccount();
  const { accountTypeParam } = useAccountFilters();
  const { isLoadingCompanies } = useCompany();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const onValidateCompanyForm = ({ isDirty, isValid = false }: FormStateChangeProps) => {
    setIsDirty(isDirty);
    setIsValid(isValid);
  };

  const onSuccess = (account: Account) => {
    // Close Modal
    onClose();

    // Upon successful connection, re-fetch updated
    // Accounts table on main page
    if (account) {
      getAllCompanyAccounts({ accountTypes: accountTypeParam });
      connectAccountFormRef.current?.resetForm();
    }
  };
  const onSubmitAction = (data: ConnectAccountByTreadIdParams) => {
    createConnectedAccountByTreadId({
      ...data,
      callBack: onSuccess,
    });
  };

  const onConnectionSubmit = () => {
    connectAccountFormRef.current?.submit(onSubmitAction);
  };

  return (
    <Dialog
      maxWidth={'sm'}
      open={isDialogOpen}
      onClose={(_: never, reason: DialogCloseReasonType) => {
        isActionClicked(reason) && onClose();
      }}
    >
      <DialogHeader
        closeCallBack={onClose}
        title={t('account.add_connection_with_tread_id_heading')}
      />
      <DialogContent sx={{ backgroundColor: theme.palette.grey[100] }}>
        <ConnectDialogDescription component="p">
          {t('account.connect_with_tread_id_instructions')}
        </ConnectDialogDescription>

        <ConnectAccountByTreadIdForm
          ref={connectAccountFormRef}
          senderCompanyId={userStore.user.company?.id || ''}
          onFormStateChange={onValidateCompanyForm}
        />
      </DialogContent>
      <DialogActionsWrapper>
        <Button
          onClick={onClose}
          sx={{ px: 2 }}
          disabled={isLoadingCompanies}
          color="secondary"
          variant="outlined"
        >
          {$t('actions.cancel')}
        </Button>
        <LoadingButton
          disabled={isLoadingCompanies || isCreatingConnection || !isDirty || !isValid}
          loading={isLoadingCompanies}
          loadingPosition="start"
          startIcon={<></>}
          onClick={onConnectionSubmit}
          data-test-id={'connect-account-btn'}
          type="button"
          variant="contained"
          color="primary"
          sx={isLoadingCompanies ? { pl: 5, pr: 2 } : { pr: 2 }}
        >
          {$t('actions.connect')}
        </LoadingButton>
      </DialogActionsWrapper>
    </Dialog>
  );
};

const ConnectAccountDialogCTA = () => {
  const { userStore } = useStores();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  const { treatments, isReady } = useSplitTreatments({
    names: [companyConnectAccountByTreadId],
  });
  const featureFlag = treatments[companyConnectAccountByTreadId];
  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  return (
    <>
      {isReady && featureFlag.treatment === 'on' && userPermissions.canConnectCompany && (
        <>
          <Button
            data-test-id={'create-company-btn'}
            color={'primary'}
            onClick={openDialog}
            startIcon={<Add />}
          >
            {t('actions.connect')}
          </Button>

          <ConnectAccountDialog isDialogOpen={isDialogOpen} onClose={closeDialog} />
        </>
      )}
    </>
  );
};

const AccountsDataGrid = observer(() => {
  const theme = useTheme();
  const { accountTypeParam, accountTypeFilter, setAccountTypeFilter } =
    useAccountFilters();

  const [currentFormDirty, setCurrentFormDirty] = useState<boolean>(false);
  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState<boolean>(false);
  const [selectedAccountId, setSelectedAccountId] = useState<Nullable<string>>(null);

  const accountActionsRef = useRef<any>(null);
  const modalDialogRef = useRef<any>(null);
  const {
    getAllCompanyAccounts,
    deleteAccount,
    updateAccount,
    createAccount,
    createConnectedAccount,
    isLoading: isAccountLoading,
  } = useAccount();
  const { setSearchQueryValue, searchValue } = useDataGridSearch();
  const { toasterStore, userStore, companyAssetsStore } = useStores();
  const { isLoading: isPermsLoading, getPermissions } = usePermissions();
  const isLoading = isAccountLoading || isPermsLoading;
  const { createCompanyShareByCompanyId, deleteCompanyShare } = useCompanyShares();
  const [asyncErrors, setAsyncErrors] = useState<FieldError[]>([]);

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  const rows = useMemo(() => {
    return companyAssetsStore.userCompanyAccounts || [];
  }, [companyAssetsStore.userCompanyAccounts]);
  const selectedAccount = useMemo(() => {
    return (
      companyAssetsStore.userCompanyAccounts.find(
        (account) => account.id === selectedAccountId,
      ) || null
    );
  }, [selectedAccountId, rows]);

  const {
    drivers,
    equipment,
    sharedDriversCompanyShares,
    sharedEquipmentCompanyShares,
    setEquipment,
    setDrivers,
    setSharedDriversCompanyShares,
    setSharedEquipmentCompanyShares,
  } = useCompanySharesDetails({
    isAccountDialogOpen,
    selectedAccountId,
    selectedAccount,
  });

  const onFormStateChange = ({ isDirty }: AccountFormStateChangeProps) => {
    setCurrentFormDirty(isDirty);
  };
  const createNew = () => {
    setSelectedAccountId(null);
    setIsAccountDialogOpen(true);
  };
  const handleClose = () => {
    setIsAccountDialogOpen(false);
    setEquipment([]);
    setDrivers([]);
    setSharedDriversCompanyShares([]);
    setSharedEquipmentCompanyShares([]);
    setAsyncErrors([]);
  };
  const onSuccess = (account?: Account) => {
    setSelectedAccountId(null);

    handleClose();
    toasterStore.push(
      alert(
        selectedAccountId
          ? $t('account.account_updated', { name: account?.name })
          : $t('account.account_created', { name: account?.name }),
        AlertTypes.success,
      ),
    );
  };
  const deleteAccountCallBack = () => {
    if (selectedAccountId) {
      deleteAccount({
        id: selectedAccountId,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(alert($t('account.account_removed'), AlertTypes.success));
        },
      });
    }
  };

  const onSubmitAccountForm = () => {
    accountActionsRef.current?.submit(onSubmitAccountFormCallBack);
  };
  const onSubmitAccountFormCallBack = async (data: Account | any) => {
    if (selectedAccountId) {
      try {
        const driversPresentOnModalOpen = drivers;
        const driversPresentOnSubmit = data.drivers;

        const equipmentPresentOnModalOpen = equipment;
        const equipmentPresentOnSubmit = data.equipment;

        const updatedAccount = await updateAccount({
          account: data,
        });

        const driversToCreate = driversPresentOnSubmit?.filter(
          (submittedDrivers: { id: string }) =>
            !driversPresentOnModalOpen.some(
              (existingDrivers) => existingDrivers.id === submittedDrivers.id,
            ),
        );

        const driversToDelete = driversPresentOnModalOpen.filter(
          (existingDriver) =>
            driversPresentOnSubmit &&
            !driversPresentOnSubmit.some(
              (submittedDriver: { id: string }) =>
                existingDriver.id === submittedDriver.id,
            ),
        );

        // Get the company shares for the drivers that we need to remove
        const driverCompanySharesToDelete = sharedDriversCompanyShares.filter(
          (companyShare) =>
            driversToDelete.some(
              (driver) => driver.id === companyShare.companyShareable.id,
            ),
        );

        const equipmentToCreate = equipmentPresentOnSubmit?.filter(
          (submittedEquipment: { id: string }) =>
            !equipmentPresentOnModalOpen.some(
              (existingEquipment) => existingEquipment.id === submittedEquipment.id,
            ),
        );

        const equipmentToDelete = equipmentPresentOnModalOpen.filter(
          (existingEquipment) =>
            equipmentPresentOnSubmit &&
            !equipmentPresentOnSubmit.some(
              (submittedEquipment: { id: string }) =>
                existingEquipment.id === submittedEquipment.id,
            ),
        );

        // Get the company shares for the equipment that we need to remove
        const equipmentCompanySharesToDelete = sharedEquipmentCompanyShares.filter(
          (companyShare) =>
            equipmentToDelete.some(
              (equip) => equip.id === companyShare.companyShareable.id,
            ),
        );

        const companySharePromises: Promise<void>[] = [];
        if (updatedAccount) {
          if (
            driversToCreate?.length &&
            updatedAccount?.company?.id &&
            updatedAccount?.connectedCompany?.id
          ) {
            driversToCreate.forEach((driver: DriverBasic) => {
              companySharePromises.push(
                createCompanyShareByCompanyId({
                  companyId: updatedAccount.company?.id || '',
                  receiver_company_id: updatedAccount.connectedCompany?.id || '',
                  company_shareable_id: driver.id,
                  company_shareable_type: 'User' as CompanyShareableType,
                }),
              );
            });
          }

          if (driverCompanySharesToDelete?.length) {
            driverCompanySharesToDelete.forEach((driverCompanyShare) => {
              companySharePromises.push(
                deleteCompanyShare(driverCompanyShare.id as string),
              );
            });
          }

          if (
            equipmentToCreate?.length &&
            updatedAccount?.company?.id &&
            updatedAccount?.connectedCompany?.id
          ) {
            equipmentToCreate.forEach((equip: BasicEquipment) => {
              companySharePromises.push(
                createCompanyShareByCompanyId({
                  companyId: updatedAccount.company?.id || '',
                  receiver_company_id: updatedAccount.connectedCompany?.id || '',
                  company_shareable_id: equip.id,
                  company_shareable_type: 'Equipment' as CompanyShareableType,
                }),
              );
            });
          }

          if (equipmentCompanySharesToDelete?.length) {
            equipmentCompanySharesToDelete.forEach((equipCompanyShare) => {
              companySharePromises.push(
                deleteCompanyShare(equipCompanyShare.id as string),
              );
            });
          }
        }

        Promise.all(companySharePromises).then(() => {
          onSuccess(data);
        });
      } catch (error: AxiosError<ErrorResponse> | any) {
        setAsyncErrors(error.response?.data.error.errors || []);
      }
    } else if (data?.connected?.value) {
      try {
        const newConnectedAccount = await createConnectedAccount({
          account: data,
        });

        const drivers = data.drivers;
        const equipment = data.equipment;

        const companySharePromises: Promise<void>[] = [];

        if (newConnectedAccount) {
          if (
            drivers?.length &&
            newConnectedAccount?.company?.id &&
            newConnectedAccount?.connectedCompany?.id
          ) {
            drivers.forEach((driver: DriverBasic) => {
              companySharePromises.push(
                createCompanyShareByCompanyId({
                  companyId: newConnectedAccount.company?.id || '',
                  receiver_company_id: newConnectedAccount.connectedCompany?.id || '',
                  company_shareable_id: driver.id,
                  company_shareable_type: 'User' as CompanyShareableType,
                }),
              );
            });
          }

          if (
            equipment?.length &&
            newConnectedAccount?.company?.id &&
            newConnectedAccount?.connectedCompany?.id
          ) {
            equipment.forEach((equip: BasicEquipment) => {
              companySharePromises.push(
                createCompanyShareByCompanyId({
                  companyId: newConnectedAccount.company?.id || '',
                  receiver_company_id: newConnectedAccount.connectedCompany?.id || '',
                  company_shareable_id: equip.id,
                  company_shareable_type: 'Equipment' as CompanyShareableType,
                }),
              );
            });
          }
        }

        Promise.all(companySharePromises).then(() => {
          handleClose();
          // Re-fetch all data since there is two accounts is created
          getAllCompanyAccounts({
            accountTypes: accountTypeParam,
            searchQuery: searchValue,
          });
          const message = `Connected Account '${data?.name}' has been created successfully`;
          toasterStore.push(alert(message, AlertTypes.success));
        });
      } catch (error: AxiosError<ErrorResponse> | any) {
        setAsyncErrors(error.response?.data.error.errors || []);
      }
    } else {
      try {
        await createAccount({ account: data, callBack: onSuccess });
      } catch (error: AxiosError<ErrorResponse> | any) {
        setAsyncErrors(error.response?.data.error.errors || []);
      }
    }
  };
  const deleteRow = (id: string) => {
    setSelectedAccountId(id);
    modalDialogRef.current?.open();
  };
  const editRow = (id: string) => {
    setSelectedAccountId(id);
    setIsAccountDialogOpen(true);
  };
  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
  };

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    getAllCompanyAccounts({ accountTypes: accountTypeParam, searchQuery: searchValue });
  }, [accountTypeParam, searchValue]);

  const accountsTypesFilters = [
    {
      value: AccountTypesFilter.CUSTOMER,
      label: 'common.customers',
    },
    { value: AccountTypesFilter.VENDOR, label: 'common.vendors' },
    { value: AccountTypesFilter.ALL, label: 'common.all' },
  ];

  const accountsColumns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.account_name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return params.value;
          },
        },
        {
          field: 'company',
          headerName: $t('form_fields.company'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.company?.legalName))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.legalName;
          },
          renderCell: (params: GridValueGetterParams) => {
            return params.value;
          },
        },
        {
          field: 'company.connected',
          headerName: $t('form_fields.connected'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            const { connectedCompany, id } = params.row;
            const isConnectedLabel = connectedCompany ? 'Yes' : 'No';

            return (
              <BasicTooltip
                title={isConnectedLabel}
                key={`tooltip-account-type-key-${id}`}
              >
                <Chip
                  key={id}
                  label={isConnectedLabel}
                  color={connectedCompany ? 'success' : 'info'}
                  variant={'outlined'}
                />
              </BasicTooltip>
            );
          },
        },
        {
          field: 'accountTypes',
          headerName: $t('form_fields.type'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                {params.value?.sort().map((type: string, index: number) => (
                  <BasicTooltip title={type} key={`tooltip-account-type-key-${type}`}>
                    <Chip
                      sx={{ ml: index !== 0 ? 1 : 0 }}
                      key={type}
                      label={type}
                      color={type === AccountTypes.vendor ? 'info' : 'success'}
                      variant={'outlined'}
                    />
                  </BasicTooltip>
                ))}
              </>
            );
          },
        },

        {
          field: 'address.fullAddress',
          headerName: $t('form_fields.address'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.address) return '';
            const {
              address: { streetAddress, premise, locality, country, administrativeArea },
            } = params.row;

            return `${streetAddress}${premise ? ` ${premise},` : ','} ${locality}, ${administrativeArea}, ${country.name}`;
          },
        },

        // {
        //   Field: 'address.administrativeArea',
        //   HeaderName: 'State',
        //   Flex: 1,
        //   ValueGetter: (params: GridValueGetterParams) => {
        //     Return get(params.row, 'address.administrativeArea');
        //   },
        // },
        //
        // {
        //   Field: 'address.country',
        //   HeaderName: 'Country',
        //   Flex: 1,
        //   ValueGetter: (params: GridValueGetterParams) => {
        //     Return get(params.row, 'address.country.name');
        //   },
        // },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          type: 'date',
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <Typography component="span">
                  {dateFormat(params.row.createdAt)}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'notes',
          headerName: $t('form_fields.notes'),
          flex: 1,
        },

        // {
        //   Field: 'email',
        //   HeaderName: $t('form_fields.email'),
        //   Flex: 1,
        //   ValueGetter: (params: GridValueGetterParams) => {
        //     Const { primaryContact, billingContact } = params.row;
        //     Return [primaryContact, billingContact].map((item) => item.email).join(', ');
        //   },
        // },
        {
          field: 'actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAssetsStore.userCompanyAccounts?.length || 0}
              loading={loadingRef.current}
              pagination={companyAssetsStore.accountsPagination}
              callback={(link, searchQuery) =>
                getAllCompanyAccounts({
                  link,
                  searchQuery,
                  accountTypes: accountTypeParam,
                })
              }
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params) => {
            const options = [];
            if (userPermissions.canDeleteAccount) {
              options.push({
                title: t('actions.delete'),
                icon: <Delete />,
                callBack: () => deleteRow(params.row.id),
              });
            }

            return (
              <>
                {userPermissions.canEditAccount && (
                  <IconButton size="small" onClick={() => editRow(params.row.id)}>
                    <Edit />
                  </IconButton>
                )}
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows, userPermissions],
  );

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          // Hide columns status and traderName, the other columns will remain visible
          notes: false,
        },
      },
    }),
    [],
  );

  return (
    <>
      <Box sx={{ mr: -3 }}>
        <Box sx={{ mb: 2 }}>
          <StatusTabFilters
            value={accountTypeFilter as AccountTypesFilter}
            onChange={(tab) => setAccountTypeFilter(tab as AccountTypesFilter)}
            filters={accountsTypesFilters}
          />
        </Box>
        <DataGrid
          id="admin-accounts-grid"
          sx={{ maxWidth: `calc(100% - ${theme.spacing(3)})` }}
          columns={accountsColumns}
          // HiddenColumns={['id', 'address.country', 'address.zip']}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          initialState={initialState}
          onChangeFilter={onChangeQuickFilter}
          headerActionsComponent={
            <>
              {userPermissions.canCreateAccount && (
                <ActionsWrapper display={'flex'}>
                  <ConnectAccountDialogCTA />

                  <Button
                    data-test-id={'create-company-btn'}
                    color={'primary'}
                    onClick={createNew}
                    startIcon={<Add />}
                  >
                    {t('account.create_account')}
                  </Button>
                </ActionsWrapper>
              )}
            </>
          }
        />
      </Box>
      <Dialog
        open={isAccountDialogOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
        maxWidth={'lg'}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(`account.${selectedAccountId ? 'update_account' : 'create_account'}`)}
        />
        <DialogContent sx={{ backgroundColor: theme.palette.grey[100] }}>
          <AccountForm
            ref={accountActionsRef}
            onFormStateChange={onFormStateChange}
            defaultAccount={selectedAccount}
            sharedDrivers={drivers}
            sharedEquipment={equipment}
            sharedDriversCompanyShares={sharedDriversCompanyShares}
            sharedEquipmentCompanyShares={sharedEquipmentCompanyShares}
            errors={asyncErrors}
          ></AccountForm>
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Button
            onClick={handleClose}
            sx={{ mr: 1, px: 2 }}
            disabled={isLoading}
            color="secondary"
            variant="outlined"
          >
            {$t('actions.cancel')}
          </Button>

          <LoadingButton
            disabled={isLoading || !currentFormDirty}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<></>}
            onClick={onSubmitAccountForm}
            data-test-id={'create-account-btn'}
            type="button"
            variant="contained"
            color="primary"
            sx={isLoading ? { pl: 5, pr: 2 } : { pr: 2 }}
          >
            {$t(`actions.${selectedAccountId ? 'submit' : 'create'}`)}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        loading={isLoading}
        title={$t('account.delete_account_title')}
        content={$t('account.delete_account_text')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteAccountCallBack}
      />
    </>
  );
});
export { AccountsDataGrid };
