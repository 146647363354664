import { AnimatePresence, motion, MotionStyle } from 'framer-motion';
import React, { CSSProperties } from 'react';

interface AnimateComponentPresenceProps {
  isVisible: boolean;
  children?: React.ReactNode | undefined;
  identifier: string | number | undefined;
  animationDuration?: number;
  style?: CSSProperties;
}
const AnimateComponentPresence = ({
  isVisible,
  children,
  identifier,
  animationDuration = 0.6,
  style,
}: AnimateComponentPresenceProps) => (
  <>
    <AnimatePresence initial={false} mode={'popLayout'}>
      {isVisible && (
        <motion.div
          style={style as unknown as MotionStyle}
          key={identifier || ''}
          initial={{ x: 0, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 0, opacity: 0 }}
          transition={{
            ease: 'linear',
            duration: animationDuration,
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  </>
);
export { AnimateComponentPresence };
