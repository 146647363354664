import { Access_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { Access } from '~hooks/useAccess/models';
import connection from '~services/connectionModule';
import { useStores } from '~store';

export const useAccess = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userStore } = useStores();

  const getAccess = () => {
    setIsLoading(true);
    return connection
      .get<Access_Read[]>(
        `${API_VERSION}/users/me/access`,
        {},
        $t('error_messages.access.failed_to_fetch') as string,
      )
      .then((data) => {
        const formatted = data.map(Access.parse);
        userStore.setAccess(formatted);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    getAccess,
  } as const;
};
