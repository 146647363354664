import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Loads } from '~components/Job/Loads';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { Invoice, useInvoices } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob/models';
import { SubtitlesOff } from '~icons/SubtitlesOffIcon';
import TreadLiveMap from '~pages/LiveMap/TreadLiveMap';
import { useStores } from '~store/RootStore';
import theme from '~theme/AppTheme';
import { dateFormat } from '~utils/dateTime';

import DriverPayTab from '../DriverPay/DriverPayTab';
import { ShiftDetails } from './ShiftDetails';

const emDash = '-';
const getFromToLocationString = (from: string, to: string) => `${from} to: ${to}`;
const formatMinutesToHoursString = (minutes: number): string =>
  `${(minutes / 60).toFixed(2)}h`;

export const DriverDayDetails = observer(({ driverDayId }: { driverDayId: string }) => {
  const [toggledJob, setToggledJob] = useState<Job | null>(null);
  const { driverDayStore } = useStores();
  const { fetchJobsForDriverDay, isLoadingDriverDayJobs } = useDriverDays();
  const { approveInvoice, getInvoicesByCategory } = useInvoices();
  const [currentCategory, setCurrentCategory] = useState<InvoiceCategoryFilter>(
    InvoiceCategoryFilter.PAYABLES,
  );
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const getInvoices = async () => {
    const invoices = await getInvoicesByCategory(currentCategory);
    setInvoices(invoices);
  };
  useEffect(() => {
    getInvoices();
  }, [currentCategory]);

  const jobIdsForDay = driverDayStore.jobIdsByDriverDayId[driverDayId];
  const jobsForDay = jobIdsForDay?.map((jobId) => driverDayStore.jobByJobId[jobId]);

  const driverDay = driverDayStore.driverDays.find((dDay) => dDay.id === driverDayId);
  const { currencyFormatter } = useCompanyCurrency();

  const getInvoicesForJob = useCallback(
    (jobId: string) => {
      return invoices.filter((invoice) => invoice.job?.id === jobId);
    },
    [invoices],
  );

  const JobHeader = ({ job, ind }: { job: Job; ind: number }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          bgcolor: 'grey.100',
          p: 1,
        }}
      >
        <Typography
          noWrap
          paragraph
          sx={{ fontWeight: 'bold', m: 0 }}
        >{`${t('approvals.driver_day.job')} ${ind + 1}: ${job.jobId}`}</Typography>
        <div>•</div>
        <Typography noWrap sx={{ maxWidth: '300px' }}>
          {getFromToLocationString(
            job.waypoints[0].site?.name ?? emDash,
            job.waypoints[1].site?.name ?? emDash,
          )}
        </Typography>
        <div>•</div>
        <Typography>{job.driverRateType}</Typography>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={toggledJob?.id === job.id}
              onChange={() => {
                setToggledJob(job);
              }}
            />
          }
          label={t('approvals.driver_day.show_map_toggle')}
          sx={{ ml: 'auto' }}
        />
      </Box>
    );
  };

  const JobsTabContent = () => {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '3fr 2fr',
          gap: 4,
        }}
      >
        <Box
          aria-label={`job list for ${driverDayId}`}
          component="ul"
          sx={{ display: 'grid', gap: 2 }}
        >
          {jobsForDay.map((job, ind) => (
            <Box
              key={job.id}
              component="li"
              aria-label="job details"
              sx={{
                display: 'grid',
                alignContent: 'start',
                gap: 2,
              }}
            >
              <Box aria-label="job header">
                <JobHeader job={job} ind={ind} />
                <Box sx={{ display: 'flex', gap: 2, p: 2, color: 'grey.700' }}>
                  <Typography variant="subtitle2">{`${t('approvals.driver_day.start_time')}: ${
                    driverDayStore.jobSummaryByJobId[job.id]?.loggingStartedAt
                      ? dateFormat(
                          driverDayStore.jobSummaryByJobId[job.id].loggingStartedAt,
                          'hh:mm A',
                        )
                      : emDash
                  }`}</Typography>
                  <Typography variant="subtitle2">{`${t('approvals.driver_day.end_time')}: ${
                    driverDayStore.jobSummaryByJobId[job.id]?.loggingEndedAt
                      ? dateFormat(
                          driverDayStore.jobSummaryByJobId[job.id].loggingEndedAt,
                          'hh:mm A',
                        )
                      : emDash
                  }`}</Typography>
                  <Typography variant="subtitle2">{`${t('approvals.driver_day.total_time')}: ${
                    driverDayStore.jobSummaryByJobId[job.id]?.workTimeMinutes
                      ? formatMinutesToHoursString(
                          driverDayStore.jobSummaryByJobId[job.id]?.workTimeMinutes,
                        )
                      : emDash
                  }`}</Typography>
                </Box>
              </Box>
              <Box sx={{ pl: 1 }}>
                <Loads
                  key={job.id}
                  details={job}
                  reload={() => {
                    fetchJobsForDriverDay(driverDayId, true);
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          aria-label="supplemental information"
          component="section"
          sx={{
            display: 'grid',
            alignContent: 'start',
            gap: 2,
            gridTemplateRows: 'auto minmax(360px, 1fr)',
          }}
        >
          {driverDay && <ShiftDetails driverDay={driverDay} />}
          {toggledJob && <TreadLiveMap job={toggledJob} />}
        </Box>
      </Box>
    );
  };

  const DriverInvoices = () => {
    return jobsForDay.map((job, ind) => {
      const filteredInvoices = getInvoicesForJob(job.id);
      if (!filteredInvoices.length) {
        return (
          <Box display="flex" gap={2} key={job.id}>
            {' '}
            <Box
              key={job.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                mb: 2,
              }}
            >
              <JobHeader job={job} ind={ind} />
              <SubtitlesOff />
              <Typography
                color={theme.brandV2.colors.treadBlack}
                variant="body1"
                sx={{ textAlign: 'center', fontWeight: 600 }}
              >
                {t('approvals.driver_day.no_invoices', {
                  category: currentCategory,
                })}
              </Typography>
            </Box>
          </Box>
        );
      }
      return filteredInvoices.map((invoice) => {
        return (
          <DriverPayTab
            invoice={invoice}
            job={job}
            utils={{
              currencyFormatter,
              approveInvoice: async (invoice: Invoice) => {
                await approveInvoice(invoice);
              },
            }}
            isDayDetailsView={true}
            customHeader={<JobHeader job={job} ind={ind} />}
            key={invoice.id}
            customCategory={currentCategory}
          />
        );
      });
    });
  };

  const tabs = useMemo(() => {
    return [
      {
        id: 'jobs',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_day_jobs'),
        content: <JobsTabContent />,
      },
      {
        id: 'payables',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_day_payables'),
        content: <DriverInvoices />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.PAYABLES);
        },
      },
      {
        id: 'recievables',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_day_receivables'),
        content: <DriverInvoices />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.RECEIVABLES);
        },
      },
    ];
  }, [jobsForDay, invoices]);

  useEffect(() => {
    fetchJobsForDriverDay(driverDayId);
  }, [driverDayId]);

  useEffect(() => {
    if (!toggledJob && jobsForDay && jobsForDay.length > 0) {
      setToggledJob(jobsForDay?.[0]);
    }
  }, [toggledJob, jobsForDay]);

  if (isLoadingDriverDayJobs || !jobsForDay) {
    return <Skeleton height={300} />;
  }

  return (
    <Box px={2} py={1}>
      {jobIdsForDay.length ? <SmallTabs tabs={tabs} /> : null}
    </Box>
  );
});
