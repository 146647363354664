import { Contact_Create, Contact_Read_Nested } from '@treadinc/horizon-api-spec';
import parsePhoneNumber from 'libphonenumber-js';

export class ContactItem {
  public static parse(proto: Contact_Read_Nested): ContactItem {
    return new ContactItem(
      proto.id ?? '',
      proto?.name ?? '',
      proto?.email ?? '',
      proto?.phone ?? '',
    );
  }

  public static deparse(proto: ContactItem): Contact_Create {
    return {
      name: proto.name?.length ? proto.name : undefined,
      email: proto.email?.length ? proto.email : undefined,
      phone: proto.phone?.length ? (proto.phone || '').replace(/\s/g, '') : undefined,
    };
  }

  public static deparseCompany(proto: any): Contact_Create {
    const name = proto.firstName + ' ' + proto.lastName;
    return {
      name: name?.length ? name : undefined,
      email: proto.email?.length ? proto.email : undefined,
      phone: proto.phone?.length ? (proto.phone || '').replace(/\s/g, '') : undefined,
    };
  }

  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  public get email(): string {
    return this._email;
  }
  public set email(value: string) {
    this._email = value;
  }
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }
  public get phone(): string {
    const phone = this._phone.replace(/^\+/g, '');
    return phone ? (parsePhoneNumber(`+${phone}`)?.formatInternational() as string) : '';
  }
  public set phone(value: string) {
    this._phone = value;
  }
  constructor(
    private _id: string,
    private _name: string,
    private _email: string,
    private _phone: string,
  ) {}
}
