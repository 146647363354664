import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

interface TimeIndicatorProps {
  visible: boolean;
  position: number; // Pixels
  time: number; // Unix timestamp
}

const TimeIndicator = ({ visible, position, time }: TimeIndicatorProps) => {
  if (!visible) return null;

  return (
    <Box
      position={'absolute'}
      height={'100%'}
      bottom={0}
      zIndex={3}
      sx={{
        transform: 'translateX(-50%)',
        left: position,
      }}
    >
      <Box
        position={'absolute'}
        bottom={'100%'}
        whiteSpace={'nowrap'}
        sx={{
          transform: 'translateX(-50%)',
        }}
      >
        <Typography variant={'body2'}>{dayjs.tz(time).format('h:mm A')}</Typography>
      </Box>

      <Box height={'100%'} width={2} sx={{ backgroundColor: 'black' }} />
    </Box>
  );
};

export default TimeIndicator;
