import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { JobState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';

import { Status as JobStatus } from '~components/Job/Status';
import { JobFeatureProperties, LiveMapFeature } from '~hooks/useLiveMap/models';
import { formatDuration } from '~utils/dateTime';

interface Props {
  feature: LiveMapFeature;
}

/**
 * Renders the details of a job in a popup.
 *
 * @param {LiveMapFeature} feature - The feature object representing the job.
 *
 * @returns {JSX.Element} The rendered popup content component.
 */
const JobDetails = ({ feature }: Props) => {
  const theme = useTheme();
  const details = feature.properties as JobFeatureProperties;

  return (
    <>
      {/* Popup header with job id, material and equipment */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
          mb: 1,
        }}
      >
        <Grid item xs={4}>
          <Typography variant="body2">{details.jobId}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            {details.material} - {details.equipment}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 1 }}></Divider>

      {/* Job Status with duration since startAt */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }}
      >
        <Grid item xs={4}>
          <Typography variant="body2">{t('live_map.pop_up.job_status')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            {formatDuration(dayjs().diff(details.startedAt, 'minutes'), false)}
          </Typography>
          <JobStatus textOnly={true} status={details.state as JobState} />
        </Grid>
      </Grid>

      {/* Driver Name */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }}
      >
        <Grid item xs={4}>
          <Typography variant="body2">{t('live_map.pop_up.driver')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{details.driverName}</Typography>
        </Grid>
      </Grid>

      {/* Updated At timestamp */}
      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', fontSize: '0.8rem', color: theme.palette.common.white }}
      >
        <Grid item xs={4}>
          <Typography variant="body2">{t('live_map.pop_up.updated_at')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{details.updatedAt}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export { JobDetails };
