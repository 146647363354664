import _ from 'lodash';
import { Navigate, RouteObject } from 'react-router-dom';

import { SystemRoles } from '~constants/enums';
import Layout from '~layout/Layout';
import { FinalConfirmationScreen } from '~pages/Auth/FinalConfirmationScreen';
import ForgotPassword from '~pages/Auth/ForgotPassword';
import ResetPassword from '~pages/Auth/ResetPassword';
import SignIn from '~pages/Auth/SignIn';
import SignUp from '~pages/Auth/SignUp';
import NewDispatch from '~pages/Dispatch/NewDispatch';
import TreadLive from '~pages/LiveMap/TreadLive';
import { OmniReports } from '~pages/OmniReports/OmniReports';
import Payment from '~pages/Payment';
import Reconciliation from '~pages/Reconciliation';
import { routes } from '~router/routesPaths';
import UserStore from '~store/UserStore';

import {
  ADMIN_WITH_NESTED_ROUTES,
  APPROVAL_WITH_NESTED_ROUTES,
  getSettingsNestedRoutes,
  SALES_WITH_NESTED_ROUTES,
} from './nestedRoutes';
import { ProtectedRoute } from './ProtectedRoute';
import { PublicRoute } from './PublicRoute';

const publicRoutes: RouteObject[] = [
  {
    path: routes.signUp,
    element: <PublicRoute element={SignUp} />,
  },
  {
    path: routes.signIn,
    element: <PublicRoute element={SignIn} />,
  },
  {
    path: routes.forgot,
    element: <PublicRoute element={ForgotPassword} />,
  },
  {
    path: routes.reset,
    element: <PublicRoute element={ResetPassword} />,
  },
  {
    path: routes.signUp,
    element: <PublicRoute element={SignUp} />,
  },
  {
    path: routes.authConfirmation,
    element: <PublicRoute element={FinalConfirmationScreen} />,
  },
];

function LegacyDispatchToNewDispatchRedirect() {
  return <Navigate to={`/${routes.dispatch.base}`} replace />;
}

// Todo: add Protected Routes, ACL routes, 404 redirect
export const getRoutes = (userStore: UserStore) => {
  const isPlatformAdmin = userStore.user.userRoles?.some(
    (role) => role.name === SystemRoles.PLATFORM_ADMIN,
  );
  const canViewReports = userStore.user.userRoles?.some(
    (role) => role.name === SystemRoles.REPORTING,
  );

  const appRoutes: RouteObject = {
    path: '/',
    element: <ProtectedRoute element={Layout} />,
    hasErrorBoundary: true,
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={isPlatformAdmin ? routes.admin.base : routes.dispatch.base}
            replace
          />
        ),
      },
      ADMIN_WITH_NESTED_ROUTES,
      SALES_WITH_NESTED_ROUTES,
      APPROVAL_WITH_NESTED_ROUTES,
      getSettingsNestedRoutes(userStore),
      {
        path: routes.live,
        element: <ProtectedRoute element={TreadLive} />,
      },
      {
        path: routes.dispatch.base,
        children: [
          {
            index: true,
            element: <ProtectedRoute element={NewDispatch} />,
          },
          // keep a reference to the legacy Dispatch pages for those users that bookmarked it
          {
            path: routes.dispatch.orders,
            element: <LegacyDispatchToNewDispatchRedirect />,
          },
          {
            path: routes.dispatch.jobs,
            element: <LegacyDispatchToNewDispatchRedirect />,
          },
        ],
      },
      {
        path: routes.reconciliation,
        element: <ProtectedRoute element={Reconciliation} />,
      },
      {
        path: routes.payments,
        element: <ProtectedRoute element={Payment} />,
      },
      {
        path: routes.reports,
        element: <ProtectedRoute element={OmniReports} canAccess={canViewReports} />,
      },
    ],
  };

  const allRoutes = [...publicRoutes, appRoutes];

  return allRoutes;
};
