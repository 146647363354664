import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import MuiMenuIcon from '@mui/icons-material/Menu';
import Search from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';

import DateFilter from '~components/Filters/DateFilter';
import OrdersStatusFilter from '~components/Filters/OrdersStatusFilter';
import Menu, { MenuHandler } from '~components/Menu/Menu';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '../NewDispatch';
import CreateOrderButton from './CreateOrderButton';
import OrdersDispatchFilters from './filters/OrdersDispatchFilters';
import { ExpandedState } from './OrderDispatchCard';

const SEARCH_BAR_MAX_WIDTH_IN_PX = 180;

interface OrdersDispatchFiltersBarProps {
  expandedState: ExpandedState;
  onExpandedStateChange: (expandedState: ExpandedState) => void;
  onNewOrderClick: () => void;
  sx?: SxProps;
}

const OrdersDispatchFiltersBar = observer(
  ({
    expandedState,
    onExpandedStateChange,
    onNewOrderClick,
  }: OrdersDispatchFiltersBarProps) => {
    const { ordersDispatchStore } = useStores();

    return (
      <Box
        alignItems="center"
        display="flex"
        gap={1}
        justifyContent="space-between"
        sx={{ pointerEvents: ordersDispatchStore.isLoadingOrders ? 'none' : 'auto' }}
      >
        {!ordersDispatchStore.filters.search && (
          <Box display="flex" gap={1} alignItems="center">
            <OrdersStatusFilter />
            <DateFilter />
            <OrdersDispatchFilters />
          </Box>
        )}

        <Box display="flex" gap={1} ml="auto">
          <SearchBar />
          <ExpandedStatesMenu
            expandedState={expandedState}
            onExpandedStateChange={onExpandedStateChange}
          />
          <CreateOrderButton onClick={onNewOrderClick} />
        </Box>
      </Box>
    );
  },
);

const SearchBar = observer(() => {
  const { ordersDispatchStore } = useStores();
  const inputRef = useRef<HTMLInputElement>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const inputHasValue = inputValue.length > 0;

  const applyFilter = _.debounce(() => {
    const searchDidChange = ordersDispatchStore.filters.search !== inputValue;

    if (searchDidChange) {
      ordersDispatchStore.setFilters({ search: inputValue }, true);
    }
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  useEffect(() => {
    if (!isExpanded) {
      setInputValue('');
    }
  }, [isExpanded]);

  useEffect(() => {
    applyFilter();

    return () => {
      applyFilter.cancel();
    };
  }, [inputValue]);

  return (
    <Box>
      <Collapse
        collapsedSize={`calc(18px + (${theme.spacing(1)} * 2)`}
        in={isExpanded}
        orientation="horizontal"
        sx={{ '& .MuiCollapse-wrapperInner': { width: '100%' } }}
      >
        <Box
          border={`solid 1px ${theme.brandV2.colors.treadGray7}`}
          borderRadius={theme.brandV2.borderRadius}
          maxWidth={SEARCH_BAR_MAX_WIDTH_IN_PX}
          width="100%"
          onClick={() => {
            setIsExpanded(true);
            inputRef.current?.focus();
          }}
        >
          <OutlinedInput
            inputRef={inputRef}
            fullWidth
            onBlur={() => {
              if (!inputHasValue) {
                setIsExpanded(false);
              }
            }}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Search orders"
            size="small"
            value={inputValue}
            startAdornment={
              <InputAdornment position="start">
                <Search
                  sx={{
                    color: theme.brandV2.colors.treadBlack,
                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end" onClick={() => setInputValue('')}>
                <Close
                  sx={{
                    color: theme.brandV2.colors.treadBlack,
                    cursor: 'pointer',
                    fontSize: '16px',
                    visibility: inputHasValue ? 'visible' : 'hidden',
                  }}
                />
              </InputAdornment>
            }
            sx={{
              '&.MuiInputBase-root': {
                height: `calc(${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px - 2px)`,
                px: 1,
                py: 0.5,
                '.MuiInputBase-input': {
                  fontSize: '12px',
                  p: 0,
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              },
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
});

interface ExpandedStatesMenuProps
  extends Pick<
    OrdersDispatchFiltersBarProps,
    'expandedState' | 'onExpandedStateChange'
  > {}

function ExpandedStatesMenu({
  expandedState,
  onExpandedStateChange,
}: ExpandedStatesMenuProps) {
  const menuHandler = useRef<MenuHandler>(null);

  const handleMenuItemClick = useCallback(
    (expandeState: ExpandedState) => {
      onExpandedStateChange(expandeState);
      menuHandler.current?.onClose?.();
    },
    [onExpandedStateChange],
  );

  return (
    <Menu
      ref={menuHandler}
      menuTrigger={
        <IconButton
          size="small"
          sx={{
            '&.MuiIconButton-root': {
              border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
              borderRadius: theme.spacing(0.5),
              color: theme.brandV2.colors.treadBlack,
              p: '2px',
            },
          }}
        >
          <MuiMenuIcon />
        </IconButton>
      }
    >
      {Object.values(ExpandedState).map((state) => (
        <MenuItem key={state} onClick={() => handleMenuItemClick(state)}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Typography color={theme.brandV2.colors.treadBlack} variant="subtitle2">
              {t(`dispatch.dispatch_v2.expanded_states.${state}`)}
            </Typography>

            {expandedState === state && <Check sx={{ fontSize: '16px' }} />}
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );
}

export default OrdersDispatchFiltersBar;
