import 'react-simple-phone-input/dist/style.css';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { InputBaseProps } from '@mui/material/InputBase';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js';
import { useState } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
} from 'react-hook-form';
import { PhoneInput, PhoneInputResponseType } from 'react-simple-phone-input';

import { FormFieldLabel } from '~components/FormFields/FormFieldLabel';
import theme from '~theme/AppTheme';
import { matchIsValidTelOrTestTel } from '~utils/utilFunctions';

interface PhoneCodeFlagInputProps {
  control: Control | undefined;
  errors: FieldErrors<FieldValues>;
  name: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  inputProps?: InputBaseProps['inputProps'];
  sx?: SxProps;
  defaultCountryCode?: CountryCode | undefined;
}

const buttonStyle = {
  overflow: 'hidden',
  width: 'max-content',
  paddingRight: '6px', // Minimizing the width of the button
  height: '36px', // 38px is the height on the input fields.
  backgroundColor: '#ffffff',
  color: '#000000',
};

const containerStyle = (invalid: boolean, required: boolean, disabled: boolean) => ({
  position: 'relative',
  marginTop: '2px', // 2px margin to match Mui input fields
  boxShadow: invalid
    ? `0 0 0 2px ${theme.palette.error.main}`
    : required && !disabled
      ? `0 0 0 2px ${theme.palette.primary.main}`
      : `0 0 0 1px ${theme.palette.grey[500]}`, // 2px border on invalid state to match Mui error state
  borderRadius: '4px',
  '&:hover': {
    boxShadow: invalid || required ? '' : '0 0 0 1px #000000',
  },
  '&:focus-within': {
    boxShadow: invalid ? `` : `0 0 0 2px ${theme.palette.primary.main}`,
    overflow: 'visible',
  },
});

const inputStyle = {
  fontSize: '14px', // Standard font size for Mui input fields
  padding: '4px',
};

const PhoneCodeFlagInput = ({
  control,
  name,
  label,
  isRequired,
  disabled = false,
  inputProps,
  sx,
  defaultCountryCode,
}: PhoneCodeFlagInputProps) => {
  const [countryCode, setCountryCode] = useState<CountryCode>(defaultCountryCode || 'CA');

  const handlePhoneChange = (
    e: PhoneInputResponseType,
    field: ControllerRenderProps<FieldValues, string>,
  ) => {
    if (matchIsValidTelOrTestTel(e.value)) {
      const parsedNumber = parsePhoneNumberWithError(e.value);
      if (parsedNumber?.country && parsedNumber?.country !== countryCode) {
        setCountryCode(parsedNumber.country);
      }
    }

    if (e.value === '+1') {
      // If the user deletes the phone number in input, the value will be '+1'. We need to set it to ' ' to avoid validation errors.
      field.onChange(' ');
    } else {
      field.onChange(e.value);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: matchIsValidTelOrTestTel }}
      render={({ field, fieldState }) => (
        <FormControl
          sx={{ width: '100%', ...sx } as SxProps<Theme>}
          error={fieldState.invalid}
        >
          <FormFieldLabel label={`${label}${isRequired ? ' *' : ''}`}></FormFieldLabel>
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={containerStyle(fieldState.invalid, isRequired || false, disabled)}
          >
            <PhoneInput
              {...field}
              value={
                field.value && matchIsValidTelOrTestTel(field.value)
                  ? (parsePhoneNumberWithError(field?.value)?.nationalNumber as string)
                  : field.value
              }
              placeholder="9051231234"
              country={
                field.value &&
                matchIsValidTelOrTestTel(field.value) &&
                parsePhoneNumberWithError(field?.value)?.country
                  ? (parsePhoneNumberWithError(field.value).country as string)
                  : 'CA'
              }
              preferredCountries={['CA', 'US']}
              onChange={(e: PhoneInputResponseType) => {
                handlePhoneChange(e, field);
              }}
              inputProps={inputProps}
              inputStyle={inputStyle}
              buttonStyle={buttonStyle}
              dropdownStyle={{ zIndex: '100' }}
              showSearchIcon={false}
              showDropdownIcon={false}
              disableInput={disabled || false}
              disableDropdownOnly={disabled || false}
            />
            {matchIsValidTelOrTestTel((field.value as string) || '') ? (
              <CheckIcon
                style={{
                  color: 'green',
                  height: '16px',
                  width: '16px',
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  transform: 'translateX(35%) translateY(-30%)',
                  backgroundColor: '#ffffff',
                  border: '1px solid #cbcbcb',
                  borderRadius: '50%',
                }}
              />
            ) : null}
          </Box>
          {fieldState.error && (
            <Typography paddingTop="4px" variant="body2" color={'error'}>
              {fieldState.error.message}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
};
export { PhoneCodeFlagInput };
