import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import React from 'react';

import { SimpleCard } from '~components/Cards/SimpleCard';
import { GroupTitle } from '~components/Common/GroupTitle';

interface Props {
  value?: string;
  sx?: SxProps<Theme>; // Style
}

const Notes = ({ value, sx = {} }: Props) => {
  const theme = useTheme();

  return (
    <>
      <GroupTitle>
        <>{$t('form_fields.notes')}</>
      </GroupTitle>

      {value ? (
        <SimpleCard
          sx={{
            background: theme.palette.grey[100],
            borderRadius: 1,
            p: 0,
            ...sx,
          }}
        >
          {value}
        </SimpleCard>
      ) : (
        <Typography variant="body1" sx={{ fontStyle: 'italic', ...sx }}>
          {$t('common.empty')}
        </Typography>
      )}
    </>
  );
};

export { Notes };
