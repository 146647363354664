import { just, Maybe, nothing } from 'maybeasy';
import { makeObservable, observable, runInAction } from 'mobx';

import { RootStore } from '~store/RootStore';
import { Alert } from '~types/AlertTypes';

class ToasterStore {
  rootStore: RootStore;
  alerts: Alert[] = [];
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      alerts: observable,
    });
    this.rootStore = rootStore;
  }

  get current(): Maybe<Alert> {
    return this.alerts.length === 0 ? nothing() : just(this.alerts[0]);
  }

  hide = () => {
    runInAction(() => {
      if (this.alerts.length > 0) {
        this.alerts[0].display = false;
      }
    });
  };
  process = () => {
    this.alerts = this.alerts.slice(1);
  };

  decodeHtml = (html: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
  };

  push = (alert: Alert, isHtml: boolean = false, destroy: boolean = false) => {
    this.hide();

    runInAction(() => {
      if (destroy) {
        this.alerts = [];
      }

      this.alerts.push({
        ...alert,
        message: isHtml ? alert.message : this.decodeHtml(alert.message),
        display: true,
      });
    });
  };

  removeFirst = () => {
    runInAction(() => {
      if (this.alerts.length > 0) {
        this.alerts.shift();
      }
    });
  };
}

export default ToasterStore;
