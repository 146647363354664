import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/system';

interface DataGridSmallButtonProps extends Omit<ButtonProps, 'size' | 'sx'> {
  sendButton?: boolean;
}

const DataGridSmallButtonComponent = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'sendButton',
})<DataGridSmallButtonProps>(({ theme, sendButton }) => ({
  height: '32px',
  width: '70px',
  fontSize: '12px',
  fontWeight: 500,
  borderRadius: '6px',
  boxShadow: 'none',
  color: sendButton ? theme.palette.primary.contrastText : undefined,
  backgroundColor: sendButton ? '#FFE500' : undefined,
  '&:disabled': sendButton
    ? {
        color: 'rgba(5, 5, 5, 0.23)',
        backgroundColor: 'rgba(246, 204, 70, 0.30)',
        fill: 'rgba(5, 5, 5, 0.23)',
        svg: {
          path: {
            fill: 'rgba(5, 5, 5, 0.23)',
          },
        },
      }
    : undefined,
  '.MuiButton-startIcon': {
    width: '20px',
    height: '20px',
    marginRight: '4px',
    '.MuiSvgIcon-root': {
      fontSize: '15px',
    },
  },
}));

const DataGridSmallButton = (props: DataGridSmallButtonProps) => {
  return <DataGridSmallButtonComponent {...props} />;
};

export { DataGridSmallButton };
