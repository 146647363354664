import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { NavigationTabs } from '~components/LayoutComponents/NavigationTabs';
import { useUsersAdmin } from '~hooksAdmin/useUsersAdmin';

const navItems = [
  {
    title: 'Company',
    link: 'companies',
  },
  {
    title: 'Users',
    link: 'users',
  },
  {
    title: 'Connections',
    link: 'connections',
  },
  { title: 'Accounts', link: 'accounts' },
];
export const Admin = () => {
  const theme = useTheme();

  return (
    <>
      <NavigationTabs
        navItems={navItems}
        sx={{ borderBottom: `1px solid ${theme.palette.divider}`, mt: 2 }}
      ></NavigationTabs>
      <Box sx={{ py: 4 }}>
        <Outlet />
      </Box>
    </>
  );
};
