import { uniqBy } from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

import { LiveMapJobEventData } from '~hooks/useLiveMap/models';
import { LiveMapRealTimeEventData } from '~hooks/useLiveMap/models';
import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';
import { RootStore } from '~store/RootStore';
class LiveMapStore {
  jobEventsData: LiveMapJobEventData[] = [];
  isEventReceived = false;
  // Once we deprecate the old live map (the map built into TreadLive), we should remove isFittedOnce in favour of shouldFitMapFeatures.
  // ShouldFitMapFeatures is already used in TreadLiveMap
  isFittedOnce = false;
  shouldFitMapFeatures = true;
  driverRoutePageNumber = 1;

  truckLocations: NextBillionAssetLocation[] = [];

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      jobEventsData: observable,
      isEventReceived: observable,
      isFittedOnce: observable,
      truckLocations: observable,
    });
    this.rootStore = rootStore;
  }

  updateIsEventReceived(val: boolean) {
    this.isEventReceived = val;
  }

  updateIsFittedOnce(val: boolean) {
    this.isFittedOnce = val;
  }
  updateShouldFitMapFeatures(val: boolean) {
    runInAction(() => {
      this.shouldFitMapFeatures = val;
    });
  }
  jobEventDataById(jobId: string) {
    return (
      this.jobEventsData.find((singleEventData) => singleEventData.job.id === jobId) ||
      null
    );
  }
  updateActiveJobEvents(eventsWithJobs: LiveMapJobEventData[]) {
    this.jobEventsData = uniqBy([...this.jobEventsData, ...eventsWithJobs], 'job.id');
  }
  setJobEventsData(jobEvents: LiveMapJobEventData[]) {
    this.jobEventsData = [...jobEvents];
  }
  updateJobEventItem(jobEvent: LiveMapJobEventData) {
    this.jobEventsData = this.jobEventsData.map((eventItem) => {
      if (eventItem.job.id === jobEvent.job.id) {
        return jobEvent;
      }
      return eventItem;
    });
  }
  addActiveJobEventData(jobEventItem: LiveMapJobEventData) {
    this.jobEventsData = uniqBy([jobEventItem, ...this.jobEventsData], 'job.id');
  }
  removeActiveJob(id: string) {
    this.jobEventsData = this.jobEventsData.filter(
      (jobEventItem) => jobEventItem.job.id !== id,
    );
  }

  updateJobLocation(eventItem: LiveMapRealTimeEventData) {
    const existingJobEventData = this.jobEventDataById(
      eventItem.latest_location.meta_data.job_id,
    );
    if (!existingJobEventData) {
      // Job is not among jobs, add it
    } else {
      // Job is among jobs, update it
      const updatedJobEvent = LiveMapJobEventData.parse(
        eventItem.latest_location.location.lat,
        eventItem.latest_location.location.lon,
        existingJobEventData.job,
      );
      this.updateJobEventItem(updatedJobEvent);
    }
  }

  setRoutePageNumber(pageNumber: number) {
    this.driverRoutePageNumber = pageNumber;
  }

  setTruckLocations(truckLocations: NextBillionAssetLocation[]) {
    this.truckLocations = [...truckLocations];
  }

  addTruckLocation(truckLocation: NextBillionAssetLocation) {
    this.truckLocations = uniqBy(
      [truckLocation, ...this.truckLocations],
      'nextBillionAssetId',
    );
  }
}

export default LiveMapStore;
