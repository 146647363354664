import { AddressItem } from '~hooks/useAddress';
import { Geofence, SiteBasic } from '~hooks/useSites';

export interface Form {
  pickUpAddress: SiteBasic | null;
  dropOffAddress: SiteBasic | null;
  pickUpLatLng: string;
  dropOffLatLng: string;
}

export interface AddressOption {
  lat: number | string;
  lng: number | string;
  placeId?: string;
  id: string;
  name: string;
  siteId: string | null;
  type: AddressType;
  geofence?: Geofence | null;
  address?: AddressItem;
  moving_site?: {
    equipment_id: string;
  };
}

export interface AddressOptionForNewSite {
  lat: number;
  lng: number;
  latLon: string;
  name?: string;
  address: AddressItem;
  geofence?: Geofence;
  placeId?: string;
  moving_site?: {
    equipment_id: string;
  };
}

export enum AddressType {
  Suggestions = 'Suggestions',
  Sites = 'Sites',
  DefaultSites = 'Default Sites',
}
