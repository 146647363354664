import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t } from 'i18next';

import { Job } from '~hooks/useJob';
import TreadLiveMap from '~pages/LiveMap/TreadLiveMap';

interface Props {
  job: Job;
  sx?: SxProps<Theme>;
}

const RouteMap = ({ job, sx = {} }: Props) => {
  return (
    <Box sx={{ width: '100%', height: 400, ...sx }}>
      {job ? (
        <TreadLiveMap job={job} defaultFetchRoutePings={false} />
      ) : (
        <>{t('actions.loading')}</>
      )}
    </Box>
  );
};

export { RouteMap };
