import Chip, { ChipOwnProps } from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { OrderState } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import React, { useMemo } from 'react';

import { snakeCaseToHuman } from '~utils/utilFunctions';

interface OrderStateExtended {
  label: string;
  color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}
interface Props {
  size?: ChipOwnProps['size'];
  status: OrderState | undefined;
  sx?: SxProps<Theme>; // Style
  variant?: ChipOwnProps['variant'];
}

const Status = ({ status, variant = 'outlined', sx, size = 'medium' }: Props) => {
  const Statuses = {
    _: {
      label: snakeCaseToHuman(`${status}`),
      color: 'info',
    },

    [OrderState.CREATED]: {
      label: $t('status.pending'),
      color: 'primary',
    },

    [OrderState.PENDING_REQUEST]: {
      label: $t('status.pending'),
      color: 'default',
    },

    [OrderState.ACCEPTED]: {
      label: $t('status.accepted'),
      color: 'default',
    },

    [OrderState.REJECTED]: {
      label: $t('status.rejected'),
      color: 'default',
    },

    [OrderState.IN_PROGRESS]: {
      label: $t('status.in_progress'),
      color: 'info',
    },

    [OrderState.COMPLETED]: {
      label: $t('status.completed'),
      color: 'default',
    },

    [OrderState.CANCELED]: {
      label: $t('status.canceled'),
      color: 'error',
    },
  } as Record<OrderState | string, OrderStateExtended>;

  const value = useMemo(
    () => (status ? Statuses[status] || Statuses._ : Statuses._),
    [status],
  );

  return (
    <Chip
      label={value.label}
      color={value.color}
      variant={variant}
      size={size}
      sx={{ ...sx }}
    />
  );
};

export { Status };
