import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { PageTitle } from '~components/typographyTitles/PageTitle';
import { routes } from '~router';

interface ErrorGeneralViewProps {
  resetError: () => void;
  error: unknown;
  componentStack: string;
}
const ErrorGeneralView = ({ resetError, error }: ErrorGeneralViewProps) => {
  const navigate = useNavigate();
  const isErrorInstance = (error: unknown): error is Error => error instanceof Error;
  const isStringInstance = (error: unknown): error is string => typeof error === 'string';

  if (
    isErrorInstance(error) &&
    error?.message
      ?.toLowerCase?.()
      ?.includes?.('failed to fetch dynamically imported module')
  ) {
    window.location.reload();
    return null;
  }
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      height={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box display={'flex'} sx={{ mt: 2 }}>
        <PageTitle text={'Error'} />
      </Box>
      <Box display={'flex'}>
        <Typography variant={'body2'}>
          {isErrorInstance(error)
            ? error.message
            : isStringInstance(error)
              ? error
              : 'An unknown error occurred.'}
        </Typography>
      </Box>
      <Box display={'flex'} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate(routes.base);
            //Any other ideas here?
            resetError();
          }}
        >
          Click to navigate to base URL!
        </Button>
      </Box>
    </Box>
  );
};
export { ErrorGeneralView };
