import { styled } from '@mui/material/styles';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { t as $t } from 'i18next';
import { useSearchParams } from 'react-router-dom';

import DispatchSearchParam from '~components/Order/Selectors/DispatchSearchParam';
import { FeatureFlags } from '~constants/featureFlags';
import { Nullable } from '~types/Nullable';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

interface StatusTabFiltersProps<T> {
  value: T;
  onChange: (value: T) => void;
  filters: { value: T; label: string }[];
  persistFilterValue?: boolean;
}

const StyledTabFilter = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
  textTransform: 'none',
  color: '#928B8B',
  fontSize: '14px',
  minWidth: '85px',
  fontWeight: 500,
  transition: 'all .3s ease',
  padding: '6px 8px',
  borderRadius: '8px',
  '&.Mui-selected': {
    background: '#F6CC46',
    color: '#000',
    '&:hover': {
      background: '#F6CC46',
    },
  },
  '&:hover': {
    background: theme.palette.action.hover,
  },
}));

export const StatusTabFilters = <T extends string>({
  value,
  onChange,
  filters,
  persistFilterValue = false,
}: StatusTabFiltersProps<T>) => {
  const persistDispatchFiltersEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.persistDispatchFilters,
  });

  const [currentSearchParams, setSearchParams] = useSearchParams();

  const handleOnChange = (
    _event: React.MouseEvent<HTMLElement>,
    value: Nullable<string>,
  ) => {
    if (value) {
      onChange(value as T);
    }

    if (persistDispatchFiltersEnabled && persistFilterValue) {
      if (value) {
        currentSearchParams.set(DispatchSearchParam.STATUS, value);
        setSearchParams(currentSearchParams);
      }
    }
  };

  return (
    <div>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleOnChange}
        aria-label="Status filters"
      >
        {filters.map(({ value, label }) => (
          <StyledTabFilter key={value} value={value}>
            {$t(label)}
          </StyledTabFilter>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
