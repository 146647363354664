import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { t } from 'i18next';

import {
  AssignJobToView,
  shouldShowReadRemoveOnlyView,
  shouldShowUnassignCTA,
} from '~components/Job/AssignJobToView';
import {
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { Account } from '~hooks/useAccount';
import { Job } from '~hooks/useJob';
import { User } from '~hooks/useUsers';

import { canAcceptJob } from '../utils';

export const hasUnassignCTA = (job: Job, canEditJob?: boolean) => {
  const { editable, driver, status, vendorJobAssignment } = job;

  const hasReadRemoveOnlyView = shouldShowReadRemoveOnlyView({
    driver: (driver as unknown as User) ?? undefined,
    vendor: (vendorJobAssignment?.vendorAccount as Account) ?? undefined,
    status,
    valueToDisplay: driver?.fullName ? 'driver' : 'vendor',
  });

  if (hasReadRemoveOnlyView) {
    return shouldShowUnassignCTA({
      driver: (driver as unknown as User) ?? undefined,
      vendor: (vendorJobAssignment?.vendorAccount as Account) ?? undefined,
      status,
      editable: Boolean(editable && canEditJob),
    });
  }

  return false;
};

interface JobAssigneeHandlerProps {
  canEditJob?: boolean;
  job: Job;
  onAssigneeChange: (job: Job) => void;
}

export default function JobAssigneeHandler({
  canEditJob,
  job,
  onAssigneeChange,
}: JobAssigneeHandlerProps) {
  const { id, editable, driver, status, customerJobAssignment, vendorJobAssignment } =
    job;

  const customerCompanyId = customerJobAssignment?.customerAccount?.accountBasicCompanyId;

  const vendorName = vendorJobAssignment?.vendorAccount?.name;
  const driverName = driver?.fullName;
  const hasVendorOrDriver = Boolean(vendorName || driverName);

  const isAcceptable = canAcceptJob(job);
  const isEditable = Boolean(editable && hasVendorOrDriver);
  const isUnassignable = hasUnassignCTA(job, canEditJob);

  if (isAcceptable) {
    return null;
  }

  return (
    <AssignJobToView
      companyId={String(customerCompanyId)}
      driver={(driver as unknown as User) ?? undefined}
      editable={isEditable}
      jobId={id}
      onSuccess={onAssigneeChange}
      status={status}
      valueToDisplay={driverName ? 'driver' : 'vendor'}
      vendor={(vendorJobAssignment?.vendorAccount as Account) ?? undefined}
      customAssignCTA={
        <SmallButton
          color="brandV2Orange"
          variant="text"
          sx={{
            '&.MuiButtonBase-root': {
              borderStyle: 'dashed',
              borderWidth: '1px',
            },
          }}
        >
          <OverflowAwareText>{t('actions.assign')}</OverflowAwareText>
        </SmallButton>
      }
      customUnassignCTA={
        isUnassignable ? (
          <IconButton size="small" sx={{ width: '20px', height: '20px' }}>
            <Close sx={{ width: '12px', height: '12px' }} />
          </IconButton>
        ) : undefined
      }
    />
  );
}
