import { useLocation } from 'react-router-dom';

import { routes } from '~router';

export const useCompanySelectorVisibility = (pagesToDisplayCompanySelector: string[]) => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const settingsIndex = pathParts.indexOf(routes.settings.base);

  if (settingsIndex !== -1 && pathParts.length > settingsIndex + 1) {
    const settingsPage = pathParts[settingsIndex + 1];
    return pagesToDisplayCompanySelector.includes(settingsPage);
  }

  return false;
};
