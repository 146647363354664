import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {
  DateRange,
  DateRangePicker,
  PickersShortcutsItem,
  PickersShortcutsProps,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { FormFieldLabel } from '~components/FormFields/FormFieldLabel';

interface SingleInputDateRangePickerProps {
  control: any; // Form control
  errors: any; // Object of errors
  name: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  isRequired?: boolean;
  sx?: any; // Style
  placeholder?: string;
  InputProps?: any;
  id?: string;
  inputProps?: any;
  multiline?: boolean;
  rows?: number;
  isFullWidth?: boolean;
  defaultOpen?: boolean;
}

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: t('date.yesterday'),
    getValue: () => {
      const today = dayjs().tz();
      const yesterday = today.subtract(1, 'day');
      return [yesterday.startOf('day'), yesterday.endOf('day')];
    },
  },
  {
    label: t('date.today'),
    getValue: () => {
      const today = dayjs().tz();
      return [today.startOf('day'), today.endOf('day')];
    },
  },
  {
    label: t('date.this_week'),
    getValue: () => {
      const today = dayjs().tz();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: t('date.last_week'),
    getValue: () => {
      const today = dayjs().tz();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: t('date.last_seven_days'),
    getValue: () => {
      const today = dayjs().tz();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: t('date.current_month'),
    getValue: () => {
      const today = dayjs().tz();
      return [today.startOf('month'), today.endOf('month')];
    },
  },

  { label: t('actions.reset'), getValue: () => [null, null] },
];
const CustomRangeShortcuts = (props: PickersShortcutsProps<DateRange<Dayjs>>) => {
  const { items, onChange, isValid } = props;

  if (!items || items?.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });
    return {
      label: item.label,
      onClick: () => {
        onChange(newValue);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: 2,
      }}
    >
      <List
        dense
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
          '& .MuiListItem-root': {
            pt: 0.5,
            pl: 0,
            pr: 1,
            justifyContent: 'flex-start',
            '&:last-child': {
              ml: 2,
              mr: -1,
            },
          },
        }}
      >
        {resolvedItems.map((item, index) => {
          return (
            <ListItem
              key={item.label}
              sx={{
                justifySelf: index === resolvedItems?.length - 1 ? 'end' : 'end',
              }}
            >
              <Chip
                {...item}
                color={index === resolvedItems?.length - 1 ? 'default' : 'primary'}
              />
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </Box>
  );
};
const SingleInputDateRangePicker = ({
  InputProps,
  control,
  defaultOpen,
  errors,
  isFullWidth,
  isRequired,
  label,
  name,
  sx,
}: SingleInputDateRangePickerProps) => {
  const [open, setOpen] = useState<boolean>(defaultOpen || false);
  /**
   * Parses the date input and returns an array with the start and end dates.
   * The end date is set to the end of the day.
   * If the end date is not provided, it will be set to null.
   *
   * @param dates - An array containing the start and end dates.
   * @returns An array with the parsed start and end dates.
   */
  const parseDateInput = (dates: any[]) => {
    const endDate = dates[1] ? dayjs.tz(dates[1]).endOf('day') : dates[1];

    return [dates[0], endDate];
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box display={'flex'} flexDirection={'column'} sx={{ ...sx }} width={'100%'}>
          {label && <FormFieldLabel label={`${label}${isRequired ? ' *' : ''}`} />}
          <DateRangePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            slots={{ field: SingleInputDateRangeField, shortcuts: CustomRangeShortcuts }}
            onAccept={(newValues) => onChange(parseDateInput(newValues))}
            value={value?.map((v: any) => dayjs.tz(v))}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              textField: {
                fullWidth: isFullWidth,
                required: isRequired,
                variant: 'outlined',
                sx: { ...sx },
                className: isRequired ? 'required' : '',
                error: !!get(errors, name),
                helperText: get(errors, `${name}.message`, ''),
                InputProps: {
                  sx: { fontSize: '12px', py: '3px', ...InputProps?.sx },
                },
              },
            }}
          />
        </Box>
      )}
    />
  );
};
export { SingleInputDateRangePicker };
