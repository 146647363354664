import { useEffect, useRef, useState } from 'react';

export default function useVisibilityAwareLazyLoader(
  loadAfterMilliseconds: number = 1000,
) {
  const nodeRef = useRef<HTMLElement | null>(null);
  const [shouldLoad, setShouldLoad] = useState(false);

  useEffect(() => {
    const node = nodeRef.current;
    let loadTrigger: ReturnType<typeof setTimeout>;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadTrigger = setTimeout(() => {
            setShouldLoad(true);

            if (node) {
              observer.unobserve(node);
            }
          }, loadAfterMilliseconds);
        } else {
          clearTimeout(loadTrigger);
        }
      },
      { threshold: 0 },
    );

    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) {
        observer.unobserve(node);
        clearTimeout(loadTrigger);
      }
    };
  }, [loadAfterMilliseconds]);

  return { nodeRef, shouldLoad };
}
