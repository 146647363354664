import { cloneDeep } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { AdminRoleItem, AdminUser } from '~hooksAdmin/useUsersAdmin';
import { Pagination, PaginationLink } from '~interfaces/pagination';
import { RootStore } from '~store/RootStore';
import { getPageNumber } from '~utils/pagination';

class UserAdminStore {
  user: AdminUser = {} as AdminUser;
  users: AdminUser[] = [];
  userRoles: AdminRoleItem[] = [];

  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  // Public get userInitials(): string {
  //   Return `${this.user?.firstName?.charAt(0)}${this.user?.lastName?.charAt(0)}` || '';
  // }
  setUserRoles(userRoles: AdminRoleItem[]) {
    this.userRoles = [...userRoles];
  }

  setPagination(pagination: Pagination) {
    this.pagination = {
      ...this.pagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  updatePageNumber(link: PaginationLink) {
    this.pagination = {
      ...this.pagination,
      page: getPageNumber(this.pagination.page, link),
    };
  }

  setUsers(users: AdminUser[]) {
    this.users = [...users];
  }

  setUser(user: AdminUser) {
    this.user = cloneDeep(user);
  }

  addUser(user: AdminUser) {
    this.users = [...this.users, user];
  }

  updateUser(user: AdminUser) {
    this.users = this.users.map((u) => (u.id === user.id ? user : u));
  }

  deleteUser(id: string) {
    this.users = this.users.filter((u) => u.id !== id);
  }
}

export default UserAdminStore;
