import {
  TelematicsRemoteVehicle_Read,
  TelematicsRemoteVehicle_Update,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { GetPaginationParams, PaginationLink } from '~interfaces';
import connection from '~services/connectionModule';
import { useStores } from '~store';

import { TelematicsVehicle } from './models';

export const useTelematicsVehicles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { telematicsStore } = useStores();

  const { addSearchHeaderParam } = useDataGridSearch();

  const getTelematicsVehicleByFleetId = async (
    fleetId: string,
    link?: PaginationLink,
    searchQuery?: string,
    limit?: number,
  ) => {
    try {
      setIsLoading(true);

      let params: GetPaginationParams = {
        'page[limit]': limit || telematicsStore.remoteVehiclesPagination.limit,
      };

      if (link && telematicsStore.remoteVehiclesPagination[link]) {
        params[`page[${link}]`] = telematicsStore.remoteVehiclesPagination[link];
      }

      params = addSearchHeaderParam({ searchValue: searchQuery, params });

      const resp = await connection.getPaginated<TelematicsRemoteVehicle_Read>(
        `${API_VERSION}/telematics/fleets/${fleetId}/remote_vehicles`,
        { params },
        $t('error_messages.telematics.failed_to_fetch_vehicles') as string,
      );

      const { data, pagination } = resp;
      telematicsStore.setRemoteVehicles(data.map(TelematicsVehicle.parse));
      telematicsStore.setRemoteVehiclesPagination(pagination);
      telematicsStore.updateRemoteVehiclesPageNumber(link);
    } catch (error) {
      console.error(error);
      throw new Error('Unable to retrieve telemetry vehicles');
    } finally {
      setIsLoading(false);
    }
  };

  const updateTelematicsVehicleById = async (
    id: string,
    data: Partial<TelematicsRemoteVehicle_Update>,
  ) => {
    try {
      setIsUpdating(true);

      const resp = await connection.patch<TelematicsRemoteVehicle_Read>(
        `${API_VERSION}/telematics/remote_vehicles/${id}`,
        data,
        {},
        $t('error_messages.telematics.failed_to_update_vehicle') as string,
      );

      const updatedVehicle = TelematicsVehicle.parse(resp);
      telematicsStore.updateRemoteVehicle(updatedVehicle);
    } catch (error) {
      console.error(error);
      throw new Error('Unable to update telemetry vehicle');
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    isLoading,
    getTelematicsVehicleByFleetId,
    isUpdating,
    updateTelematicsVehicleById,
  };
};
