import _ from 'lodash';
import { ElementType, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { getTokenProp } from '~constants/consts';

export const PublicRoute = ({
  element: Component,
  ...rest
}: {
  element: ElementType;
}) => {
  const isAuthenticated = !!localStorage.getItem(getTokenProp);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isAuthenticated ? <Navigate to="/" /> : <Component {...rest} />}
    </Suspense>
  );
};
