import { PaginationLink } from '~interfaces';

export const getPageNumber = (currentPage: number, link: PaginationLink) => {
  switch (link) {
    case 'after':
      return currentPage + 1;
    case 'before':
      return currentPage - 1;
    default:
      return 1;
  }
};
