import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ShiftAdjustmentType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { DriverDay } from '~hooks/useDriverDays/models';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { dateFormat } from '~utils/dateTime';

const emDash = '-';
const overrideOptions = [
  {
    value: ShiftAdjustmentType.BREAK,
    label: t('approvals.driver_day.override_option_break'),
  },
  {
    value: ShiftAdjustmentType.BREAKDOWN,
    label: t('approvals.driver_day.override_option_breakdown'),
  },
  {
    value: ShiftAdjustmentType.LUNCH,
    label: t('approvals.driver_day.override_option_lunch'),
  },
  {
    value: ShiftAdjustmentType.WAIT_TIME,
    label: t('approvals.driver_day.override_option_wait_time'),
  },
];

// We assume hours could be 0.01 and need to round this to an integer
const convertHoursToMinutes = (hours: number): number => {
  return Math.round(hours * 60);
};
const convertMinutesToHours = (minutes: number): number => {
  return minutes / 60;
};
const formatMinutesToHoursString = (minutes: number): string =>
  `${convertMinutesToHours(minutes).toFixed(2)}h`;

const calculateAdjustmentMinutes = (
  currentTotalShiftMinutes: number,
  currentShiftAdjustmentMinutes: number,
  newTotalShiftMinutes: number,
) => {
  const baseMinutes = currentTotalShiftMinutes - currentShiftAdjustmentMinutes;
  return newTotalShiftMinutes - baseMinutes;
};

type FormData = {
  shiftTimeInHours: number;
  overrideReason: ShiftAdjustmentType | null;
};

export function ShiftDetails({ driverDay }: { driverDay: DriverDay }) {
  const [isEditing, setIsEditing] = useState(false);
  const reasonDialogRef = useRef<ModalDialogHandler>(null);

  const { updateDriverDay } = useDriverDays();

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      shiftTimeInHours: parseFloat(
        convertMinutesToHours(driverDay.totalShiftMinutes).toFixed(2),
      ),
      overrideReason: null,
    },
    mode: 'all',
    delayError: 500,
  });

  useEffect(() => {
    setValue(
      'shiftTimeInHours',
      parseFloat(convertMinutesToHours(driverDay.totalShiftMinutes).toFixed(2)),
    );
    driverDay.shiftAdjustmentType &&
      setValue('overrideReason', driverDay.shiftAdjustmentType);
  }, [driverDay.totalShiftMinutes, driverDay.shiftAdjustmentType]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    // Handle submit early when only one field is full, by opening the modal
    if (!data.overrideReason) {
      reasonDialogRef.current?.open();
      return;
    }

    await updateDriverDay(
      driverDay.id,
      calculateAdjustmentMinutes(
        driverDay.totalShiftMinutes,
        driverDay.shiftAdjustmentMinutes,
        convertHoursToMinutes(data.shiftTimeInHours),
      ),
      data.overrideReason,
    );
    reasonDialogRef.current?.close();
    setIsEditing(false);
    reset();
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} aria-label="shift details">
      <Box
        aria-label="shift summary"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 3,
          pb: 2,
          borderBottom: (theme) => `1px solid ${theme.brandV2.colors.treadGray6}`,
        }}
      >
        <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
          >
            {t('approvals.driver_day.shift_start_details')}
          </Typography>
          <Typography variant="h6">
            {driverDay?.startedShiftAt
              ? dateFormat(driverDay.startedShiftAt, 'hh:mm A')
              : emDash}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
          >
            {t('approvals.driver_day.shift_end_details')}
          </Typography>
          <Typography variant="h6">
            {driverDay?.endedShiftAt
              ? dateFormat(driverDay.endedShiftAt, 'hh:mm A')
              : emDash}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
          >
            {t('approvals.driver_day.total_hours')}
          </Typography>
          {isEditing ? (
            <TextField
              {...register('shiftTimeInHours', {
                min: 0,
                setValueAs: (v) => (!isNaN(v) ? parseFloat(parseFloat(v).toFixed(2)) : v),
                validate: {
                  number: (v) => !isNaN(v),
                  positive: (v) => v >= 0,
                },
              })}
              error={!!errors.shiftTimeInHours}
            />
          ) : (
            <Typography variant="h6">
              {driverDay.totalShiftMinutes
                ? formatMinutesToHoursString(driverDay.totalShiftMinutes)
                : emDash}
            </Typography>
          )}
          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
          >
            {`${t('approvals.driver_day.breaks')}: ${driverDay?.totalBreakMinutes ? `${driverDay?.totalBreakMinutes}min` : emDash}`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: 4,
          rowGap: 1,
          py: 2,
        }}
      >
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('approvals.driver_day.first_geofence_entry')}
          </Typography>
          <Typography variant="body2">
            {driverDay?.firstGeofenceEntryAt
              ? dateFormat(driverDay?.firstGeofenceEntryAt, 'hh:mm A')
              : emDash}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('approvals.driver_day.last_geofence_entry')}
          </Typography>
          <Typography variant="body2">
            {driverDay?.lastGeofenceExitAt
              ? dateFormat(driverDay?.lastGeofenceExitAt, 'hh:mm A')
              : emDash}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('approvals.driver_day.first_ticket')}
          </Typography>
          <Typography variant="body2">
            {driverDay?.firstTicketAt
              ? dateFormat(driverDay?.firstTicketAt, 'hh:mm A')
              : emDash}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('approvals.driver_day.last_ticket')}
          </Typography>
          <Typography variant="body2">
            {driverDay?.lastTicketAt
              ? dateFormat(driverDay?.lastTicketAt, 'hh:mm A')
              : emDash}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
        {isEditing ? (
          <>
            <Button
              startIcon={<ClearIcon />}
              variant="outlined"
              color="secondary"
              onClick={() => {
                reset();
                setIsEditing(false);
              }}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<CheckIcon />}
              disabled={!!errors.shiftTimeInHours}
              onClick={() => {
                reasonDialogRef.current?.open();
              }}
            >
              {t('actions.save')}
            </Button>
          </>
        ) : (
          <Button
            startIcon={<EditIcon />}
            variant="outlined"
            color="secondary"
            onClick={() => setIsEditing(true)}
          >
            {t('actions.edit')}
          </Button>
        )}
      </Box>
      <ModalDialog
        ref={reasonDialogRef}
        title={t('approvals.driver_day.edit_shift_dialog_title')}
        content={
          <Box sx={{ display: 'grid', gap: 2 }}>
            <Typography>{t('approvals.driver_day.override_reason_prompt')}</Typography>
            <TextField
              {...register('overrideReason')}
              select
              label={t('approvals.driver_day.override_reason')}
              value={watch('overrideReason') ?? ''}
              sx={{ width: '50%' }}
            >
              {overrideOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        }
        confirmButtonText={t('approvals.driver_day.save_changes') as string}
        confirmButtonColor={'success'}
        callBack={handleSubmit(onSubmit)}
      />
    </Box>
  );
}
