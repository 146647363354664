import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
interface OrderCustomerDetailsProps {
  control: Control<any>;
  errors: FieldErrors<FieldValues>;
  disabled?: boolean;
}

export const OrderCustomerDetails = ({
  control,
  errors,
  disabled = false,
}: OrderCustomerDetailsProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextFormField
          control={control}
          errors={errors}
          name={'name'}
          label={`${t('order.form.order_name')}`}
          isRequired={false}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFormField
          control={control}
          errors={errors}
          name={'dispatchNumber'}
          label={`${t('order.form.dispatch_number_full')}`}
          isRequired={false}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFormField
          control={control}
          errors={errors}
          name={'poJobNumber'}
          label={`${t('order.form.order_po')}`}
          isRequired={false}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFormField
          control={control}
          errors={errors}
          name={'zone'}
          label={`${t('order.form.order_zone')}`}
          isRequired={false}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFormField
          control={control}
          errors={errors}
          name={'externalId'}
          label={`${t('form_fields.external_id')}`}
          isRequired={false}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
