enum MapControl {
  Navigation = 'navigation',
  Geolocate = 'geolocate',
  Fullscreen = 'fullscreen',
}

export const mapControls: { [key: string]: MapControl } = {
  navigation: MapControl.Navigation,
  geolocate: MapControl.Geolocate,
  fullscreen: MapControl.Fullscreen,
};

export const TORONTO_OFFICE_COORDINATES = { lng: -79.389193, lat: 43.648947 };
