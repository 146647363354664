import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React from 'react';

type HourMarkerProps = {
  position: string;
  time: string;
};

export const HourMarker = ({ position, time }: HourMarkerProps) => (
  <Box left={position} position={'absolute'} height={'100%'}>
    <Box width={'1px'} height={'100%'} sx={{ backgroundColor: 'gray' }} />

    <Box sx={{ transform: 'translateX(-50%)' }}>
      <Typography whiteSpace={'pre'} variant={'caption'}>
        {dayjs.utc(time).tz().format('h A')}
      </Typography>
    </Box>
  </Box>
);
