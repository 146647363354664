import DeleteOutline from '@mui/icons-material/DeleteOutline';
import UploadFile from '@mui/icons-material/UploadFile';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { DefaultTFuncReturn, t as $t } from 'i18next';
import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { GlassMagnifier } from 'react-image-magnifiers';

import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { Nullable } from '~types/Nullable';
import { kiloMega } from '~utils/kiloMega';

type fileTypes =
  | ('tiff' | 'tif' | 'bmp' | 'jpg' | 'jpeg' | 'gif' | 'png' | 'svg' | 'webp')
  | undefined;

interface Dimensions {
  width: number;
  height: number;
}

export interface ImageUploadHandler {
  reset: () => void;
}

interface ImageUploadProps {
  maxSize?: number;
  types?: fileTypes[];
  required?: boolean;
  disabled?: boolean;
  url?: Nullable<string>;
  sx?: SxProps<Theme>; // Style
  onChange: (file: File) => void;
  removeCallback?: () => void;
  imageViewMaxHeight?: string;
  isCanBeMagnified?: boolean;
  secondaryText?: DefaultTFuncReturn;
  allowDelete?: boolean;
}

const ImageUpload = forwardRef(function LogoUpload(
  {
    maxSize,
    required,
    disabled,
    types,
    url,
    sx,
    onChange,
    removeCallback,
    imageViewMaxHeight,
    isCanBeMagnified = false,
    allowDelete = true,
    secondaryText = $t('file_upload.crop_warning'),
  }: ImageUploadProps,
  ref: Ref<ImageUploadHandler>,
) {
  const theme = useTheme();
  const reader = new FileReader();
  const fileTypes = useMemo(() => {
    return types || ['png', 'jpeg', 'jpg', 'gif'];
  }, [types]);

  const maxFileSize = useMemo(() => {
    return maxSize || 0.1;
  }, [maxSize]);

  const minImageSize = useMemo<Dimensions>(
    () => ({
      width: 125,
      height: 25,
    }),
    [],
  );

  const [file, setFile] = useState<Nullable<File>>(null);
  const [fileURL, setFileURL] = useState<Nullable<string>>(null);
  const [imageSize, setImageSize] = useState<Nullable<Dimensions>>(null);
  const [error, setError] = useState<Nullable<string>>(null);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setFile(null);
      setFileURL(null);
      setImageSize(null);
    },
  }));

  const handleChange = (file: File) => {
    setFile(file);
    onChange(file);
  };

  useEffect(() => {
    setFileURL(url);
    if (url) {
      getImageSizes(url).then((sizes: Dimensions) => {
        setImageSize(sizes);
      });
    }
  }, [url]);

  const onTypeError = () => {
    setError(`${$t('file_upload.errors.type')}`);
  };

  const onFileSizeError = () => {
    // Return rootStore.toasterStore.push(alert('Incorrect file size', 'error'));
    setError($t('file_upload.errors.size', { maxSize: maxFileSize + ' MB' }));
  };

  const getImageSizes = (dataURL: string): Promise<Dimensions> =>
    new Promise((resolve: any) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height as number,
          width: img.width as number,
        } as Dimensions);
      };
      img.src = dataURL;
    });

  const readFile = (file: File) => {
    reader.onloadend = () => {
      const url = URL.createObjectURL(file);
      setError(null);

      getImageSizes(url).then((sizes: Dimensions) => {
        if (
          // If image is SVG, the size is not checked because of vector type
          file?.type !== 'image/svg+xml' &&
          (sizes.width < minImageSize.width || sizes.height < minImageSize.height)
        ) {
          setError(
            $t('file_upload.errors.min_dimensions', {
              width: minImageSize.width,
              height: minImageSize.height,
            }),
          );
        } else {
          setImageSize(sizes);
          setFileURL(url);
        }
      });
    };

    reader.onerror = () => {
      setError($t('file_upload.errors.common') as string);
    };

    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    if (file) {
      readFile(file);
    }
  }, [file]);

  const removeFile = () => {
    setFileURL(null);
    setFile(null);
    // Keep fot now, add handler to handle remove file, without form submission
    removeCallback?.();
  };

  return (
    <Box data-test-id="image-upload" className="border border-gray-200 rounded-md">
      {fileURL ? (
        <Card sx={{ width: '100%', ...sx }} className="!shadow-none">
          <CardHeader
            action={
              allowDelete && (
                <BasicTooltip title={$t('actions.delete')}>
                  <IconButton onClick={removeFile}>
                    <DeleteOutline aria-label={$t('actions.delete')} />
                  </IconButton>
                </BasicTooltip>
              )
            }
            title={`${file?.name || ''}`}
            subheader={file?.size ? kiloMega(file.size, { suffix: 'B', count: 2 }) : ''}
            sx={[
              {
                '& > *': {
                  maxWidth: 'calc(100% - 2em)',
                },
              },
              {
                '& > * > *': {
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              },
            ]}
          />
          {isCanBeMagnified ? (
            <div>
              <Box
                sx={[
                  {
                    img: {
                      maxWidth: 'none',
                    },
                  },
                ]}
              >
                <GlassMagnifier
                  className="flex justify-center"
                  imageSrc={fileURL}
                  magnifierSize={'50%'}
                  square={false}
                />
              </Box>
            </div>
          ) : (
            <CardMedia
              component={'img'}
              image={fileURL}
              alt={file?.name || ''}
              sx={{
                display: 'flex',
                height: '100%',
                maxWidth: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'auto',
                m: 'auto',
                maxHeight: imageViewMaxHeight || 'auto',
              }}
            />
          )}

          <CardContent>
            {imageSize?.width && (
              <Typography>
                {$t('file_upload.image_size')}: {imageSize?.width}
                {'x'}
                {imageSize?.height}
              </Typography>
            )}
            <Typography variant="body2" color="text.secondary">
              {secondaryText}
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <FileUploader
          handleChange={handleChange}
          maxSize={maxFileSize}
          onTypeError={onTypeError}
          onSizeError={onFileSizeError}
          name="file"
          types={fileTypes}
          multiple={false}
          required={required || false}
          disabled={disabled || false}
          hoverTitle={''}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color={error ? 'error.main' : 'text.secondary'}
            sx={{
              borderRadius: 1,
              backgroundColor: theme.palette.grey[100],
              border: `1px dashed ${theme.palette.grey[300]}`,
              p: 2,
              ...sx,
            }}
          >
            <UploadFile color={error ? 'error' : 'primary'} />
            <Typography sx={{ fontWeight: 'medium' }}>
              {$t('file_upload.upload_or_drop')}{' '}
            </Typography>
            <Typography variant="caption">
              {fileTypes?.join(', ').toUpperCase()},
              {$t('file_upload.max_size', { maxSize: maxFileSize + ' MB' })}
              {/*, Min image size:{minImageSize.width}x{minImageSize.height}*/}
            </Typography>
            {error && <Typography sx={{ mt: 1 }}>{error}</Typography>}
          </Box>
        </FileUploader>
      )}
    </Box>
  );
});

export { ImageUpload };
