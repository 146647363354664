import Link from '@mui/material/Link';
import { t } from 'i18next';
import React from 'react';

import { routes } from '~router';

const LinkToSignIn = () => {
  return (
    <Link href={routes.signIn} variant="body2" color={'secondary.main'}>
      {t('actions.signIn')}
    </Link>
  );
};
export default LinkToSignIn;
