import { makeObservable, observable } from 'mobx';

import { InvoiceLineItem } from '~hooks/useInvoiceLineItems';
import { Pagination } from '~interfaces';

export default class InvoiceLineItemsStore {
  invoiceLineItemsByInvoiceId: Record<string, InvoiceLineItem[]> = {};
  invoiceSubtotalByInvoiceId: Record<string, number> = {};
  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  constructor() {
    makeObservable(this, {
      invoiceLineItemsByInvoiceId: observable,
      invoiceSubtotalByInvoiceId: observable,
      pagination: observable,
    });
  }

  setInvoiceLineItemsByInvoiceId(invoiceId: string, invoiceLineItems: InvoiceLineItem[]) {
    this.invoiceLineItemsByInvoiceId[invoiceId] = [...invoiceLineItems];
  }

  setInvoiceSubtotalByInvoiceId(invoiceId: string, subtotal: number) {
    this.invoiceSubtotalByInvoiceId[invoiceId] = subtotal;
  }
}
