interface Props {
  suffix?: string;
  count?: number;
}

const kiloMega = (v: number, { suffix, count }: Props) => {
  const l = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  let res = '';
  let amount = v;
  let sign = 0;
  if (v < 0) {
    res = 'Invalid amount';
  } else if (v > 0 && v < Infinity) {
    while (amount >= 1e3) {
      amount /= 1e3;
      sign += 1;
    }
    // @ts-ignore
    res = `${Math.round(amount * `1e${count || 3}`) / `1e${count || 3}`} ${l[sign]}${
      suffix || ''
    }`;
  }

  return res;
};

export { kiloMega };
