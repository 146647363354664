import React, { ReactNode } from 'react';

interface FooterProps {
  actions?: ReactNode;
}
export const Footer = (props: FooterProps) => {
  return (
    <div className="flex items-end p-4 h-[69px] border-t border-gray-200">
      <div className="flex flex-1 justify-end space-x-4">{props.actions}</div>
    </div>
  );
};
