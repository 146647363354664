import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { get } from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

interface CheckBoxFormFieldProps {
  checkboxSx?: SxProps<Theme>;
  control: Control<any>;
  disabled?: boolean;
  errors?: any; // Object of errors
  isRequired?: boolean;
  label?: string;
  name: string;
  sx?: SxProps<Theme>; // Style
  value: boolean;
}
export const CheckBoxFormField = ({
  checkboxSx,
  control,
  disabled = false,
  errors,
  isRequired = false,
  label,
  name,
  sx,
  value = false,
}: CheckBoxFormFieldProps) => {
  return (
    <FormControl error={get(errors, name)} sx={{ ...sx }}>
      <FormControlLabel
        control={
          <Controller
            rules={{ required: isRequired }}
            name={name}
            control={control}
            defaultValue={value}
            render={({ field: props }) => (
              <Checkbox
                {...props}
                checked={props.value}
                disabled={disabled}
                onChange={(e) => props.onChange(e.target.checked)}
                sx={checkboxSx}
              />
            )}
          />
        }
        label={label || ''}
      />
    </FormControl>
  );
};
