import { SiteState } from '@treadinc/horizon-api-spec';

import { geoFenceCircleOption, geofenceNoneOption } from '~constants/enums';
import { WayPoint } from '~hooks/useSites';
import { CompanyBasic } from '~src/hooks/useCompany';
import { Nullable } from '~types/Nullable';

import { ProjectFormSchemaInterface } from '../Projects/projectFormSchema';
import { OrderFormSchemaInterface } from './orderFormSchema';

export const createSitesInline = async (
  data: OrderFormSchemaInterface | ProjectFormSchemaInterface,
  createNewSite: any,
  defaultRadius?: Nullable<number>,
) => {
  if (data.pickUpWayPoint && !data.pickUpWayPoint?.site?.id) {
    // Site.id is empty when an address from the SiteLocation is selected
    // This site is not in the list of sites, and needs to be added.
    const company = data.company as CompanyBasic;
    const geofence = createCircleGeofence(defaultRadius, data.pickUpWayPoint as WayPoint);
    const pickUpSite = await createNewSite({
      name: data.pickUpWayPoint?.site?.name || '',
      company: { id: company?.id, legal_name: company?.legalName },
      address: data.pickUpWayPoint?.site?.address,
      lat: String(data.pickUpWayPoint?.site?.lat),
      lon: String(data.pickUpWayPoint?.site?.lon),
      state: SiteState.ACTIVE,
      geoFence: geofence,
      geofenceType: geofence ? geoFenceCircleOption : geofenceNoneOption,
      latLon: `${String(data.pickUpWayPoint?.site?.lat)}, ${String(data.pickUpWayPoint?.site?.lon)}`,
      ...geofence,
    });
    data.pickUpWayPoint.site.id = pickUpSite.id;
  }
  if (data.dropOffWayPoint && !data.dropOffWayPoint?.site?.id) {
    // Site.id is empty when an address from the SiteLocation is selected
    // This site is not in the list of sites, and needs to be added.
    const company = data.company as CompanyBasic;
    const geofence = createCircleGeofence(
      defaultRadius,
      data.dropOffWayPoint as WayPoint,
    );
    const dropOffSite = await createNewSite({
      name: data.dropOffWayPoint?.site?.name || '',
      company: { id: company?.id, legal_name: company?.legalName },
      address: data.dropOffWayPoint?.site?.address,
      lat: String(data.dropOffWayPoint?.site?.lat),
      lon: String(data.dropOffWayPoint?.site?.lon),
      state: SiteState.ACTIVE,
      geoFence: geofence,
      latLon: `${String(data.dropOffWayPoint?.site?.lat)},${String(data.dropOffWayPoint?.site?.lon)}`,
      ...geofence,
    });
    data.dropOffWayPoint.site.id = dropOffSite.id;
  }

  return data;
};

const createCircleGeofence = (radius: Nullable<number>, waypoint: WayPoint) => {
  if (!waypoint || !radius) {
    return {};
  }

  return {
    name: waypoint.site?.name,
    radius: Number(radius),
    circleCenter: {
      lat: waypoint.site?.lat,
      lon: waypoint.site?.lon,
    },
    geofenceType: geoFenceCircleOption,
  };
};
