import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/system';
import { GeofenceType } from '@treadinc/horizon-api-spec';

import { GeoFenceIconCircle } from '~icons/GeofenceIconCircle';
import { GeoFenceIconPolygon } from '~icons/GeofenceIconPolygon';
import { GeoFenceMovingIcon } from '~icons/GeofenceMovingIcon';
import { Nullable } from '~types/Nullable';

interface GeofenceStartAdornmentProps {
  isLoading?: boolean;
  geofenceType: Nullable<GeofenceType>;
  sx?: SxProps<Theme>; // Style
}

export const GeofenceStartAdornment = ({
  isLoading = false,
  geofenceType,
  sx,
}: GeofenceStartAdornmentProps) => {
  const theme = useTheme();

  if (isLoading) {
    return <CircularProgress color="inherit" size={20} sx={{ ...sx }} />;
  }

  const isCircle = geofenceType === GeofenceType.CIRCLE;
  const isPolygon = geofenceType === GeofenceType.POLYGON;
  const isMoving = geofenceType === GeofenceType.MOVING;

  if (isMoving) {
    return (
      <Box component="span" sx={{ ...sx }}>
        <GeoFenceMovingIcon textFill={theme.brandV2.colors.treadGreen} />
      </Box>
    );
  } else if (isPolygon) {
    return (
      <Box component="span" sx={{ ...sx }}>
        <GeoFenceIconPolygon textFill={theme.brandV2.colors.treadGreen} />
      </Box>
    );
  }

  return (
    <Box component="span" sx={{ ...sx }}>
      <GeoFenceIconCircle
        textFill={
          isCircle ? theme.brandV2.colors.treadGreen : theme.brandV2.colors.treadGray6
        }
      />
    </Box>
  );
};
