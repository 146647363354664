import { TicketState } from '@treadinc/horizon-api-spec';

import { Ticket } from '~hooks/useTickets';

import { TabFilter } from './types';

export const FilterTickets = (tab: TabFilter, tickets: Ticket[]) => {
  switch (tab) {
    case TabFilter.MISSING:
      return tickets.filter((ticket) => {
        return ticket.imageUrl === '' || ticket.imageUrl === null;
      });
    case TabFilter.PENDING:
      return tickets.filter((ticket) => ticket.loadId === '' || ticket.loadId === null);
    case TabFilter.NEW:
      return tickets.filter((ticket) => ticket.state === TicketState.NEW);
    case TabFilter.APPROVED:
      return tickets.filter((ticket) => ticket.state === TicketState.APPROVED);
    case TabFilter.VOIDED:
      return tickets.filter((ticket) => ticket.state === TicketState.VOIDED);
    case TabFilter.All:
      return tickets;
    default:
      return tickets;
  }
};
