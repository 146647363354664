import { IamRole_Read } from '@treadinc/horizon-api-spec';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { AdminRoleItem } from '~hooksAdmin/useUsersAdmin/models';
import connection from '~services/connectionModule';
import { useStores } from '~store';

export const useUserRolesAdmin = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { userAdminStore } = useStores();

  const getUserRoles = () => {
    setIsLoading(true);
    return connection
      .get<IamRole_Read[]>(`${API_VERSION}/iam_roles`)
      .then((resp) => {
        userAdminStore.setUserRoles(resp.map(AdminRoleItem.parse));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    getUserRoles: getUserRoles,
  };
};
