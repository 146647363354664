import { t } from 'i18next';
import * as yup from 'yup';

export const departmentFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
    ),
  company: yup
    .object()
    .typeError(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    )
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    ),
  id: yup.string(),
});
