import Search from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import { useCallback, useEffect, useRef } from 'react';

import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';

interface SearchFieldProps {
  isLoading?: boolean;
  onChange: (value: string) => void;
  setFocusOnMount?: boolean;
  value: string;
}

export default function SearchField({
  isLoading,
  onChange,
  setFocusOnMount,
  value,
}: SearchFieldProps) {
  const searchFieldRef = useRef<HTMLInputElement>(null);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (setFocusOnMount && searchFieldRef.current) {
      searchFieldRef.current.focus();
    }
  }, [setFocusOnMount, searchFieldRef.current]);

  return (
    <MenuItem
      onClick={(event) => {
        event.stopPropagation();
        searchFieldRef.current?.focus();
      }}
      disableRipple
      sx={{
        '&.MuiMenuItem-root.MuiButtonBase-root': {
          '&:hover': { backgroundColor: 'white' },
          '&.Mui-focusVisible': { backgroundColor: 'white' },
        },
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        gap={1}
        justifyContent="space-between"
        width="100%"
      >
        <Search sx={{ fontSize: '16px' }} />

        <Input
          inputRef={searchFieldRef}
          onChange={handleInputChange}
          onKeyDown={(event) => event.stopPropagation()}
          size="small"
          value={value}
          sx={{
            '&.MuiInputBase-root': {
              flex: 1,
              fontSize: '12px',
              '&:before, &:hover:before, &:after, &:hover:after': {
                borderBottom: 0,
              },
              '& .MuiInputBase-input': {
                height: '20px',
                p: 0,
              },
            },
          }}
        />

        <Box flex={0}>
          <LoadingSpinner loadingIndicatorSize={16} isVisible={isLoading} />
        </Box>
      </Box>
    </MenuItem>
  );
}
