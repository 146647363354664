import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Chip, { ChipOwnProps } from '@mui/material/Chip';
import { OrderState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';

import { Order } from '~hooks/useOrders';
import { snakeCaseToHuman } from '~utils/utilFunctions';

export const PROPS_BY_ORDER_STATE: Partial<
  Record<OrderState, Pick<ChipOwnProps, 'color' | 'label'>>
> = {
  [OrderState.ACCEPTED]: { color: 'default', label: t('status.accepted') },
  [OrderState.CANCELED]: { color: 'error', label: t('status.canceled') },
  [OrderState.COMPLETED]: { color: 'default', label: t('status.completed') },
  [OrderState.CREATED]: { color: 'primary', label: t('status.pending') },
  [OrderState.IN_PROGRESS]: { color: 'info', label: t('status.in_progress') },
  [OrderState.PENDING_REQUEST]: { color: 'default', label: t('status.pending') },
  [OrderState.REJECTED]: { color: 'default', label: t('status.rejected') },
};

interface OrderStatusChipProps {
  hideDropdownArrow?: boolean;
  order: Order;
}

export default function OrderStatusChip({
  hideDropdownArrow,
  order,
}: OrderStatusChipProps) {
  const { color, label } = PROPS_BY_ORDER_STATE[order.state] ?? {
    label: snakeCaseToHuman(order.state),
    color: 'info',
  };

  return (
    <Chip
      color={color}
      icon={hideDropdownArrow ? undefined : <ArrowDropDown />}
      label={label}
      size="small"
      variant="outlined"
      sx={{
        '&.MuiChip-root': {
          '&.MuiChip-outlined': { flexDirection: 'row-reverse', borderRadius: 1 },
          '.MuiChip-label': {
            fontSize: '12px',
            fontWeight: 700,
            ...(hideDropdownArrow ? { px: 0.5 } : { pl: 0.5, pr: 0 }),
          },
          '.MuiChip-icon': { mx: 0 },
        },
      }}
    />
  );
}
