import ArrowBack from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SxProps, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import { Logo } from '~icons';

interface FormWrapperBoxProps {
  hideLogo?: boolean;
  onBack?: () => void;
  sx?: SxProps;
  title?: string | null;
}

const FormWrapperBox = ({
  children,
  hideLogo,
  onBack,
  sx,
  title,
}: React.PropsWithChildren<FormWrapperBoxProps>) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'success.contrastText',
        width: '100%',
        maxWidth: '396px',
        borderRadius: 2,
        padding: 2,
        position: 'relative',
        ...sx,
      }}
    >
      {onBack && (
        <IconButton
          sx={{ position: 'absolute', top: theme.spacing(), left: theme.spacing() }}
          onClick={onBack}
        >
          <ArrowBack />
        </IconButton>
      )}

      {Boolean(title || !hideLogo) && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          sx={{ mt: 2, mb: 2 }}
          gap={3}
        >
          {hideLogo ? null : <Logo height={46} width={240} />}

          {title && (
            <Typography component="h1" variant="h5">
              {title}
            </Typography>
          )}
        </Box>
      )}

      {children}
    </Box>
  );
};
export default FormWrapperBox;
