import { IamPermission_Read_Nested } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { Permission } from '~hooks/usePermissions/models';
import connection from '~services/connectionModule';
import { useStores } from '~store';

export const usePermissions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userStore } = useStores();

  const getPermissions = () => {
    setIsLoading(true);
    return connection
      .get<IamPermission_Read_Nested[]>(
        `${API_VERSION}/users/me/iam_permissions`,
        {},
        $t('error_messages.permissions.failed_to_fetch') as string,
      )
      .then((data) => {
        const formatted = data.map(Permission.parse);
        userStore.setPermissions(formatted);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    getPermissions,
  } as const;
};
