import DrawerMUI from '@mui/material/Drawer';
import React from 'react';

import { DrawerProps } from './types';

export const Drawer: React.FC<DrawerProps> = (props) => {
  const { anchor = 'right', open, onClose, ...rest } = props;
  return (
    <DrawerMUI
      classes={{
        paper: '!shadow-2xl',
      }}
      sx={{
        '& .MuiModal-backdrop': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
      }}
      anchor={anchor}
      open={open}
      onClose={onClose}
      {...rest}
    >
      <div className="flex flex-1 h-screen flex-col">{props.children}</div>
    </DrawerMUI>
  );
};
