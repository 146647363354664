import React, { useCallback, useEffect, useState } from 'react';

export default function useInfiniteScroll(options?: IntersectionObserverInit) {
  const nodeRef = React.createRef<HTMLElement>();
  const [endReached, setEndReached] = useState(false);

  const notify = useCallback(() => {
    setEndReached(false);
  }, []);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setEndReached(true);
      }
    }, options);

    if (nodeRef.current) {
      intersectionObserver.observe(nodeRef.current);
    }

    return () => {
      if (nodeRef.current) {
        intersectionObserver.unobserve(nodeRef.current);
      }
    };
  }, [nodeRef]);

  return { nodeRef, endReached, notify };
}
