import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { useMemo } from 'react';

import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';

const PROGRESS_BAR_WIDTH_IN_PX = 72;

const formatNumber = (input: number | string) => {
  const formattedNumber = Number(input).toFixed(2);

  return formattedNumber.replace(/(.00)$/, '');
};

interface OrderSummaryProps {
  order: Order;
}

export default function OrderSummary({ order }: OrderSummaryProps) {
  const groups = useMemo(() => {
    const {
      activeJobsCount,
      activeLoadsCount,
      deliveredLoadsCount,
      deliveredQuantity,
      jobsCount,
      loadsCount,
      quantity,
      sentJobsCount,
      unassignedJobsCount,
      unitOfMeasure,
    } = order;

    return [
      [
        {
          label: t('dispatch.dispatch_v2.order_summary.unassigned.label'),
          count: unassignedJobsCount,
          total: jobsCount,
          description: t('dispatch.dispatch_v2.order_summary.unassigned.description', {
            num: formatNumber(unassignedJobsCount),
            total: formatNumber(jobsCount),
          }),
        },
        {
          label: t('dispatch.dispatch_v2.order_summary.sent.label'),
          count: sentJobsCount,
          total: jobsCount,
          description: t('dispatch.dispatch_v2.order_summary.sent.description', {
            num: formatNumber(sentJobsCount),
            total: formatNumber(jobsCount),
          }),
        },
        {
          label: t('dispatch.dispatch_v2.order_summary.accepted.label'),
          count: activeJobsCount,
          total: jobsCount,
          description: t('dispatch.dispatch_v2.order_summary.accepted.description', {
            num: formatNumber(activeJobsCount),
            total: formatNumber(jobsCount),
          }),
        },
      ],
      [
        {
          label: t('dispatch.dispatch_v2.order_summary.trucks_live.label'),
          count: activeLoadsCount,
          total: jobsCount,
          description: t('dispatch.dispatch_v2.order_summary.trucks_live.description', {
            num: formatNumber(activeLoadsCount),
            total: formatNumber(jobsCount),
          }),
        },
        {
          label: t('dispatch.dispatch_v2.order_summary.quantity.label'),
          count: deliveredQuantity,
          total: quantity,
          description: t('dispatch.dispatch_v2.order_summary.quantity.description', {
            num: formatNumber(deliveredQuantity),
            total: formatNumber(quantity),
            unit: unitOfMeasure?.name,
          }),
        },
        {
          label: t('dispatch.dispatch_v2.order_summary.loads_delivered.label'),
          count: deliveredLoadsCount,
          total: loadsCount,
          description: t(
            'dispatch.dispatch_v2.order_summary.loads_delivered.description',
            {
              num: formatNumber(deliveredLoadsCount),
              total: formatNumber(loadsCount),
            },
          ),
        },
      ],
    ] as [SummaryProps[], SummaryProps[]];
  }, [order]);

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      gap={20}
      justifyContent="space-around"
      px={5}
      py={2}
    >
      {groups.map((group, index) => (
        <Box
          key={index}
          alignItems="flex-start"
          display="flex"
          flex={1}
          gap={1}
          justifyContent="space-around"
        >
          {group.map((item) => (
            <Summary key={item.label} {...item} />
          ))}
        </Box>
      ))}
    </Box>
  );
}

interface SummaryProps {
  count?: number;
  description: string;
  label: string;
  total?: number;
}

function Summary({ count, description, label, total }: SummaryProps) {
  const hasProgressIndicator = !_.isUndefined(count) && !_.isUndefined(total);

  return (
    <Box display="flex" flexDirection="column" minWidth={PROGRESS_BAR_WIDTH_IN_PX}>
      <Typography
        color={theme.brandV2.colors.treadGray2}
        fontSize="12px"
        whiteSpace="nowrap"
      >
        {label}
      </Typography>

      <Typography
        color={theme.brandV2.colors.treadBlack}
        mb={1}
        mt={0.5}
        variant="subtitle2"
        whiteSpace="nowrap"
      >
        {description}
      </Typography>

      {hasProgressIndicator && (
        <Box
          bgcolor={theme.brandV2.colors.treadGray7}
          borderRadius="2px"
          height="4px"
          overflow="hidden"
          position="relative"
          width={PROGRESS_BAR_WIDTH_IN_PX}
          sx={{
            '&:before': {
              backgroundColor: theme.brandV2.colors.treadBlue,
              bottom: 0,
              content: "''",
              left: 0,
              position: 'absolute',
              top: 0,
              width: `calc((${count} / ${total}) *  100%)`,
            },
          }}
        />
      )}
    </Box>
  );
}
