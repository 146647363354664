import {
  Driver_Read,
  Driver_Read_Typeahead,
  Job_Read,
  JobState,
  UserBulkSendJobsChannel_Read,
} from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t as $t } from 'i18next';

import { API_VERSION } from '~constants/consts';
import { Driver } from '~hooks/useDrivers';
import { Job } from '~hooks/useJob';
import connection from '~services/connectionModule';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';
import { JobsFilters } from '~store/DriverSchedulerStore';
import { Nullable } from '~types/Nullable';

import {
  INCREMENTS_PER_HOUR,
  MINUTES_PER_INCREMENT,
} from '../components/drivers/constants';

interface FetchDriversParams {
  companyId: string;
}

interface GetDriversQueryProps {
  'page[limit]'?: number;
  'page[before]'?: string;
  'page[after]'?: string;
  'filter[shared]'?: boolean;
  'search[datagrid]'?: string;
}

interface FetchJobsParams {
  startDate?: string;
  endDate?: string;
  driverIds?: string[];
  states?: JobState[];
  callback?: (jobs: Job[]) => void;
  companyId?: string;
}

interface GetJobsQueryProps {
  'page[limit]'?: number;
  'page[before]'?: string;
  'page[after]'?: string;
  'filter[customer_account_ids]'?: string[];
  'filter[dispatch_numbers]'?: string[];
  'filter[driver_ids]'?: string[];
  'filter[dropoff_site_ids]'?: string[];
  'filter[end_date]'?: string;
  'filter[external_ids]'?: string[];
  'filter[pickup_site_ids]'?: string[];
  'filter[project_ids]'?: string[];
  'filter[start_date]'?: string;
  'filter[states]'?: JobState[];
  'search[datagrid]'?: string;
}

const chunkArray = <T>(array: T[], size: number): T[][] => {
  const chunkedArray: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
};

export const useDriverSchedulerFetch = () => {
  const { driverSchedulerStore } = useStores();

  const fetchUnassignedJobs = async (options: FetchJobsParams) => {
    driverSchedulerStore.unassignedJobsFetchStart();

    const { unassignedJobsPagination, unassignedJobsFilters, dateFilters } =
      driverSchedulerStore;

    const params: GetJobsQueryProps = {
      'page[limit]': unassignedJobsPagination.limit,
    };

    if (unassignedJobsPagination.before) {
      params['page[before]'] = unassignedJobsPagination.before;
    } else if (unassignedJobsPagination.after) {
      params['page[after]'] = unassignedJobsPagination.after;
    }

    if (dateFilters.startDate) {
      params['filter[start_date]'] = dateFilters.startDate;
    }

    if (dateFilters.endDate) {
      params['filter[end_date]'] = dateFilters.endDate;
    }

    if (options.states) {
      params['filter[states]'] = options.states;
    }

    if (unassignedJobsFilters?.search?.trim().length) {
      params['search[datagrid]'] = unassignedJobsFilters.search.trim();
    }

    if (unassignedJobsFilters?.customers?.length) {
      params['filter[customer_account_ids]'] = unassignedJobsFilters.customers;
    }

    if (unassignedJobsFilters?.dispatchNumbers?.length) {
      params['filter[dispatch_numbers]'] = unassignedJobsFilters.dispatchNumbers;
    }

    if (unassignedJobsFilters?.dropOffSites?.length) {
      params['filter[dropoff_site_ids]'] = unassignedJobsFilters.dropOffSites;
    }

    if (unassignedJobsFilters?.pickUpSites?.length) {
      params['filter[pickup_site_ids]'] = unassignedJobsFilters.pickUpSites;
    }

    if (unassignedJobsFilters?.projects?.length) {
      params['filter[project_ids]'] = unassignedJobsFilters.projects;
    }

    if (unassignedJobsFilters?.projectsExternalIds?.length) {
      params['filter[external_ids]'] = unassignedJobsFilters.projectsExternalIds;
    }

    try {
      const response = await connection.getPaginated<Job_Read>(
        `${API_VERSION}/jobs`,
        { params },
        $t('error_messages.jobs.failed_to_fetch') as string,
      );
      const { data, pagination } = response;
      const parsedJobs = data.map(Job.parse);
      driverSchedulerStore.fetchUnassignedJobsEnd(
        parsedJobs,
        pagination,
        options.companyId,
      );
      options.callback?.(parsedJobs);
    } catch (error) {
      driverSchedulerStore.fetchUnassignedJobsEnd([]);
      console.error(error);
      throw new Error('Unable to fetch unassigned jobs');
    }
  };

  const fetchAssignedJobs = async (options: FetchJobsParams) => {
    if (!options.driverIds || options.driverIds.length === 0) {
      return;
    }

    driverSchedulerStore.assignedJobsFetchStart();

    const { assignedJobsPagination, assignedJobsFilters, dateFilters } =
      driverSchedulerStore;
    const params: GetJobsQueryProps = {
      'page[limit]': assignedJobsPagination.limit,
    };

    if (assignedJobsPagination.before) {
      params['page[before]'] = assignedJobsPagination.before;
    } else if (assignedJobsPagination.after) {
      params['page[after]'] = assignedJobsPagination.after;
    }

    if (dateFilters.startDate) {
      params['filter[start_date]'] = dateFilters.startDate;
    }

    if (dateFilters.endDate) {
      params['filter[end_date]'] = dateFilters.endDate;
    }

    if (assignedJobsFilters?.search?.trim().length) {
      params['search[datagrid]'] = assignedJobsFilters.search.trim();
    }

    if (assignedJobsFilters?.customers?.length) {
      params['filter[customer_account_ids]'] = assignedJobsFilters.customers;
    }

    if (assignedJobsFilters?.dispatchNumbers?.length) {
      params['filter[dispatch_numbers]'] = assignedJobsFilters.dispatchNumbers;
    }

    if (assignedJobsFilters?.dropOffSites?.length) {
      params['filter[dropoff_site_ids]'] = assignedJobsFilters.dropOffSites;
    }

    if (assignedJobsFilters?.pickUpSites?.length) {
      params['filter[pickup_site_ids]'] = assignedJobsFilters.pickUpSites;
    }

    if (assignedJobsFilters?.projects?.length) {
      params['filter[project_ids]'] = assignedJobsFilters.projects;
    }

    if (assignedJobsFilters?.projectsExternalIds?.length) {
      params['filter[external_ids]'] = assignedJobsFilters.projectsExternalIds;
    }

    // Chunk driver IDs into groups of 20
    const driverIdChunks = chunkArray(options.driverIds || [], 20);
    const allFetchedJobs: Job[] = [];
    const newAssignments: Record<string, Nullable<Job>> = {};
    const driverJobCount: Record<string, number> = {};

    try {
      for (const chunk of driverIdChunks) {
        params['filter[driver_ids]'] = chunk.length > 0 ? chunk : undefined;

        const response = await connection.getPaginated<Job_Read>(
          `${API_VERSION}/jobs`,
          { params },
          $t('error_messages.jobs.failed_to_fetch') as string,
        );

        const { data, pagination } = response;
        const parsedJobs = data.map(Job.parse);

        // Collect jobs from each fetch
        allFetchedJobs.push(...parsedJobs);
        driverSchedulerStore.updateAssignedJobsPagination(pagination);
      }

      // Assign jobs to drivers
      allFetchedJobs.forEach((job) => {
        const driverId = job.driver?.id;
        if (driverId) {
          const startDate = dayjs.tz(job.jobStartAt);
          const columnIndex = Math.floor(
            startDate.hour() * INCREMENTS_PER_HOUR +
              startDate.minute() / MINUTES_PER_INCREMENT,
          );
          newAssignments[`${driverId}:${columnIndex}`] = job;

          if (driverJobCount[driverId]) {
            driverJobCount[driverId]++;
          } else {
            driverJobCount[driverId] = 1;
          }
        }
      });

      // Determine if there are active jobs filters
      const hasActiveAssignedJobsFilters = Object.keys(assignedJobsFilters).some(
        (key) => assignedJobsFilters[key as keyof JobsFilters],
      );

      // Update job assignments and filter drivers
      const jobAssignments = {
        ...driverSchedulerStore.jobAssignments,
        ...newAssignments,
      };

      let filteredDrivers = driverSchedulerStore.drivers;
      if (hasActiveAssignedJobsFilters) {
        filteredDrivers = driverSchedulerStore.drivers.filter(
          (driver) => driverJobCount[driver.id],
        );
      }

      driverSchedulerStore.setJobAssignments(jobAssignments);
      driverSchedulerStore.setFilteredDrivers(filteredDrivers);
      driverSchedulerStore.fetchAssignedJobsEnd(allFetchedJobs);
    } catch (error) {
      driverSchedulerStore.fetchAssignedJobsEnd([]);
      console.error(error);
      throw new Error('Unable to fetch assigned jobs');
    }
  };

  const fetchDrivers = async (options: FetchDriversParams) => {
    driverSchedulerStore.driversFetchStart();

    const { driversPagination, driversFilters } = driverSchedulerStore;
    const params: GetDriversQueryProps = {
      'page[limit]': driversPagination.limit,
    };
    if (driversPagination.before) {
      params['page[before]'] = driversPagination.before;
    } else if (driversPagination.after) {
      params['page[after]'] = driversPagination.after;
    }

    if (driversFilters.search) {
      params['search[datagrid]'] = driversFilters.search;
    }

    if (driversFilters.shared && driversFilters.shared.length === 1) {
      if (driversFilters.shared.includes('internal')) {
        params['filter[shared]'] = false;
      } else if (driversFilters.shared.includes('external')) {
        params['filter[shared]'] = true;
      }
    }

    try {
      const response = await connection.getPaginated<Driver_Read_Typeahead>(
        `${API_VERSION}/companies/${options.companyId}/drivers`,
        { params },
        $t('error_messages.drivers.failed_to_fetch_drivers') as string,
      );
      const { data, pagination } = response;
      driverSchedulerStore.fetchDriversEnd(
        (data as Driver_Read[]).map(Driver.parse),
        pagination,
      );
    } catch (error) {
      driverSchedulerStore.fetchDriversEnd([]);
      console.error(error);
      throw new Error('Unable to fetch drivers');
    }
  };

  const assignDriver = async (id: string, driverId: string) => {
    driverSchedulerStore.updateJobStart();

    try {
      const response = await connection.patch<Job_Read>(
        `${API_VERSION}/jobs/${id}`,
        {
          driver_id: driverId,
        },
        {},
        $t('error_messages.jobs.failed_to_assign_driver') as string,
      );
      const formatted = Job.parse(response);
      driverSchedulerStore.updateJob(formatted);
      return formatted;
    } catch (error) {
      console.error('Failed to assign driver:', error);
      throw new Error('Failed to assign driver');
    } finally {
      driverSchedulerStore.updateJobEnd();
    }
  };

  const unassignDriver = async (id: string) => {
    driverSchedulerStore.updateJobStart();

    try {
      const response = await connection.patch<Job_Read>(
        `${API_VERSION}/jobs/${id}`,
        {
          driver_id: null,
        },
        {},
        $t('error_messages.jobs.failed_to_unassign_driver') as string,
      );
      const formatted = Job.parse(response);
      driverSchedulerStore.updateJob(formatted);
    } catch (error) {
      console.error('Failed to unassign driver:', error);
      throw new Error('Failed to unassign driver');
    } finally {
      driverSchedulerStore.updateJobEnd();
    }
  };

  const updateJob = async (id: string, data: any, inline?: boolean) => {
    driverSchedulerStore.updateJobStart();
    const deparsedModel = inline
      ? Job.deparseUpdateFromInlineMode(data)
      : Job.deparseUpdate(data);

    try {
      const response = await connection.patch<Job_Read>(
        `${API_VERSION}/jobs/${id}`,
        deparsedModel,
        {},
        $t('error_messages.jobs.failed_to_update') as string,
      );
      const formatted = Job.parse(response);
      driverSchedulerStore.updateJob(formatted);
      return formatted;
    } catch (error) {
      console.error('Failed to update job:', error);
      throw new Error('Failed to update job');
    } finally {
      driverSchedulerStore.updateJobEnd();
    }
  };

  const subscribeToJobsRTU = (companyId: string) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.CompanyJobUpdateChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: ({ data }: { data: Job_Read }) => {
            const job = Job.parse(data);
            driverSchedulerStore.updateJob(job);
          },
        },
      );
    }

    return subscription;
  };

  const bulkSendJobs = async (jobIds: string[]) => {
    try {
      await connection.put(
        `${API_VERSION}/jobs/bulk_send`,
        {
          job_ids: jobIds,
        },
        {},
        $t('error_messages.jobs.failed_to_bulk_send_jobs') as string,
      );
    } catch (error) {
      console.error(error);
      throw new Error('Unable to bulk send jobs');
    }
  };

  const subscribeToBulkSendJobsRTU = (
    companyId: string,
    onUpdateReceived: (data: UserBulkSendJobsChannel_Read) => void,
  ) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.UserBulkSendJobsChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: onUpdateReceived,
        },
      );
    }

    return subscription;
  };

  return {
    assignDriver,
    unassignDriver,
    fetchDrivers,
    fetchAssignedJobs,
    fetchUnassignedJobs,
    subscribeToJobsRTU,
    updateJob,
    bulkSendJobs,
    subscribeToBulkSendJobsRTU,
  };
};
