import RemoveCircleOutlineOutlined from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import React from 'react';

import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
interface RemoveItemButtonProps {
  action: (event?: React.MouseEvent) => void;
  disabled?: boolean;
  sx?: SxProps;
  icon?: React.ElementType;
  iconStyles?: SxProps;
}
const RemoveItemButton = ({
  action,
  disabled,
  sx,
  icon: Icon = RemoveCircleOutlineOutlined,
  iconStyles,
}: RemoveItemButtonProps) => {
  return (
    <Box
      sx={{
        zIndex: 2,
        width: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <BasicTooltip title={`${t('actions.remove')}`}>
        <Icon
          sx={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            background: '#fff',
            borderRadius: '50%',
            ...iconStyles,
          }}
          onClick={action}
          aria-label={t('aria_label.button.remove', {
            item: t('aria_label.common.item'),
          })}
        />
      </BasicTooltip>
    </Box>
  );
};
export { RemoveItemButton };
