import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { t as $t } from 'i18next';
import { useState } from 'react';

export interface MapType {
  name: string;
  url: string;
}

export const MapTypes = {
  STREET: {
    name: 'NextBillion.ai Default',
    url: 'https://api.nextbillion.io/maps/streets/style.json',
  },
  SATELLITE: {
    name: 'Satellite Hybrid',
    url: 'https://api.nextbillion.io/maps/hybrid/style.json',
  },
};

interface MapTypeToggleProps {
  defaultType: MapType;
  onChange: (style: MapType) => void;
}

export const MapTypeToggle = ({ defaultType, onChange }: MapTypeToggleProps) => {
  const [mapType, setMapType] = useState(defaultType);

  return (
    <ToggleButtonGroup
      size="small"
      value={mapType}
      orientation="horizontal"
      sx={{ background: 'white' }}
      exclusive
      onChange={(_e: React.MouseEvent<HTMLElement>, newType: MapType) => {
        if (newType !== null) {
          setMapType(newType);
          onChange(newType);
        }
      }}
    >
      <ToggleButton value={MapTypes.STREET} sx={{ textTransform: 'none' }}>
        {$t('live_map.map_type.default')}
      </ToggleButton>
      <ToggleButton value={MapTypes.SATELLITE} sx={{ textTransform: 'none' }}>
        {$t('live_map.map_type.satellite')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
