import i18next from 'i18next';

import { data as enums } from '~constants/enums';
import { useStores } from '~store';

const createFormatter = (options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat(i18next.language, options);
};

export const useCompanyCurrency = () => {
  const { userStore } = useStores();
  const { currency } = userStore.userCompany;

  const currencyFormatter = (value: number | string) => {
    return createFormatter({
      style: 'currency',
      currency: currency || enums.currency.default,
    }).format(value as number);
  };

  const numberFormatter = (
    value: number | string,
    options?: Intl.NumberFormatOptions,
  ) => {
    return createFormatter({
      style: 'decimal',
      maximumFractionDigits: 2,
      ...options,
    }).format(value as number);
  };

  return {
    currencyFormatter,
    numberFormatter,
  } as const;
};
