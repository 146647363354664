import { InvoiceLineItem_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { InvoiceLineItem } from '~hooks/useInvoiceLineItems/models';
import { GetPaginationParams } from '~interfaces';
import connection from '~services/connectionModule';
import { useStores } from '~store';

const useInvoiceLineItems = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { invoiceLineItemsStore } = useStores();

  const getAllInvoiceLineItems = async (invoiceId: string) => {
    setIsLoading(true);

    const fetchLineItemsPage = (params: GetPaginationParams) => {
      return connection.getPaginated<InvoiceLineItem_Read>(
        `${API_VERSION}/invoices/${invoiceId}/invoice_line_items`,
        { params },
        $t('error_messages.invoices.failed_to_fetch_line_items') as string,
      );
    };

    let shouldFetchNextPage = true;
    let allLineItems: InvoiceLineItem_Read[] = [];

    const params: GetPaginationParams = {
      'page[limit]': invoiceLineItemsStore.pagination.limit,
    };

    while (shouldFetchNextPage) {
      const { data, pagination } = await fetchLineItemsPage(params);

      allLineItems = [...allLineItems, ...data];
      shouldFetchNextPage = Boolean(pagination.after);

      if (shouldFetchNextPage) {
        params['page[after]'] = pagination.after;
      }
    }

    const parsedLineItems = allLineItems.map((lineItem) =>
      InvoiceLineItem.parse(lineItem),
    );
    invoiceLineItemsStore.setInvoiceLineItemsByInvoiceId(invoiceId, parsedLineItems);

    setIsLoading(false);

    return parsedLineItems;
  };

  const batchUpdateInvoiceLineItems = async (
    invoiceId: string,
    lineItems: Array<Partial<InvoiceLineItem>>,
  ) => {
    try {
      setIsUpdating(true);

      const payload = {
        invoice_line_items: lineItems.map((lineItem) => {
          return InvoiceLineItem.deparseUpdate(lineItem);
        }),
      };

      const response = await connection.patch<InvoiceLineItem_Read[]>(
        `${API_VERSION}/invoices/${invoiceId}/invoice_line_items`,
        payload,
        {},
        $t('error_messages.invoices.failed_to_update_line_items') as string,
      );

      const updatedlineItems = response.map((lineItem) => {
        return InvoiceLineItem.parse(lineItem);
      });
      invoiceLineItemsStore.setInvoiceLineItemsByInvoiceId(invoiceId, updatedlineItems);

      return updatedlineItems;
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    batchUpdateInvoiceLineItems,
    getAllInvoiceLineItems,
    isLoading,
    isUpdating,
  };
};

export default useInvoiceLineItems;
