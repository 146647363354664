import CopyAllOutlined from '@mui/icons-material/CopyAllOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import { AnimateComponentPresence } from '~components/AnimatePresence';
import { CardItemLabelValue } from '~components/Common/CardItemLabelValue';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import {
  Rate,
  RateCommissionInputs,
  RatePerUnitInputs,
  RateTimeInputs,
} from '~hooks/useRates';
import { rateTypeLabelColor } from '~pages/Settings/RatesManagement/rateUtils';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

interface RateCardProps {
  rate: Rate;
  onEdit: (id: string) => void;
  deleteClickCallBack: (id: string) => void;
  copyClickCallBack?: (id: string) => void;
}

const RateCard = observer(
  ({ rate, onEdit, deleteClickCallBack, copyClickCallBack }: RateCardProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const { currencyFormatter } = useCompanyCurrency();
    const { userStore } = useStores();
    const userPermissions = useMemo(() => {
      return userStore.getPermissions();
    }, [userStore.getPermissions()]);
    const rateDataVariables = (rate: Rate) => {
      const projects = rate?.projects.map((p) => p.name).join(', ') || '';
      const equipment = rate.equipment.map((e) => e.name).join(', ') || '';
      const services = rate.services.map((s) => s.name).join(', ') || '';

      const result = [projects, equipment, services].filter(Boolean).join(', ');

      return result;
    };

    const ratePerUnitInputsView = (
      rateInputs: RatePerUnitInputs,
      isFuelSurcharge: boolean,
    ) => {
      return (
        <>
          <CardItemLabelValue
            isInline={true}
            label={t('administration.rates.minimum')}
            value={`${rateInputs?.perUnitMinimum}`}
          ></CardItemLabelValue>
          <Divider flexItem={true} sx={{ mx: 2 }} orientation={'vertical'}></Divider>
          <CardItemLabelValue
            isInline={true}
            label={t('administration.rates.fuel_surcharge')}
            value={`${
              isFuelSurcharge
                ? t('administration.rates.yes')
                : t('administration.rates.no')
            }`}
          ></CardItemLabelValue>
        </>
      );
    };
    const rateHourlyInputsView = (
      rateInputs: RateTimeInputs,
      isFuelSurcharge: boolean,
    ) => {
      return (
        <>
          <CardItemLabelValue
            isInline={true}
            label={t('administration.rates.minimum')}
            value={`${rateInputs?.timeMinimumMinutes} ${t(
              'administration.rates.minutes_abbreviation',
            )}`}
          ></CardItemLabelValue>
          <Divider flexItem={true} sx={{ mx: 1 }} orientation={'vertical'}></Divider>
          <CardItemLabelValue
            isInline={true}
            label={t('administration.rates.increment')}
            value={`${rateInputs?.timeIncrementMinutes} ${t(
              'administration.rates.minutes_abbreviation',
            )}`}
          ></CardItemLabelValue>
          <Divider flexItem={true} sx={{ mx: 1 }} orientation={'vertical'}></Divider>
          <CardItemLabelValue
            isInline={true}
            label={t('administration.rates.fuel_surcharge')}
            value={`${
              isFuelSurcharge
                ? t('administration.rates.yes')
                : t('administration.rates.no')
            }`}
          ></CardItemLabelValue>
        </>
      );
    };
    const rateTimeInputsView = (rateInputs: RateTimeInputs, isFuelSurcharge: boolean) => (
      <>
        <CardItemLabelValue
          isInline={true}
          label={t('administration.rates.minimum')}
          value={rateInputs?.timeMinimumMinutes}
        ></CardItemLabelValue>
        <Divider flexItem={true} sx={{ mx: 2 }} orientation={'vertical'}></Divider>
        <CardItemLabelValue
          isInline={true}
          label={t('administration.rates.fuel_surcharge')}
          value={`${
            isFuelSurcharge ? t('administration.rates.yes') : t('administration.rates.no')
          }`}
        ></CardItemLabelValue>
      </>
    );
    const getRelevantRateInputsView = (rate: Rate) => {
      const inputs = rate.rateInputs;
      if (rate?.isHourly) {
        return rateHourlyInputsView(inputs as RateTimeInputs, rate.isFuelSurcharge);
      } else if (rate?.isDaily) {
        return rateTimeInputsView(inputs as RateTimeInputs, rate.isFuelSurcharge);
      } else if (!rate?.isCommission && !rate?.isTimeBased) {
        return ratePerUnitInputsView(inputs as RatePerUnitInputs, rate.isFuelSurcharge);
      }
    };

    const getRelevantRateValueWithUnitLabel = (rate: Rate) => {
      if (rate?.rateInputs instanceof RateTimeInputs) {
        return (
          <Typography variant={'h6'} color={'grey'}>{`${currencyFormatter(
            `${rate?.rateInputs?.timeRate}`,
          )} / ${rateTypeLabelColor(rate?.type?.value)?.label}`}</Typography>
        );
      } else if (rate?.rateInputs instanceof RateCommissionInputs) {
        return (
          <Typography color={'grey'} variant={'h6'}>{`${
            rate?.rateInputs?.commissionPercentage
          } % ${t('administration.rates.commission_rate')}`}</Typography>
        );
      } else {
        return (
          <Typography variant={'h6'} color={'grey'}>{`${currencyFormatter(
            rate?.rateInputs?.perUnitRate as number,
          )} / ${rateTypeLabelColor(rate?.type?.value)?.label}`}</Typography>
        );
      }
    };

    return (
      <Card
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        elevation={1}
        sx={{
          minWidth: 540,
          maxWidth: 540,
          minHeight: 180,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <CardContent>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ mb: 1 }}
          >
            <Box
              display={'flex'}
              alignItems={'start'}
              flexDirection={'column'}
              width={'100%'}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                width={'100%'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'}>
                  <Tooltip title={`${rate.name}`}>
                    <Typography
                      sx={{
                        maxWidth: '240px',
                        minWidth: '180px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        mr: 2,
                      }}
                      variant="h5"
                    >
                      {rate.name}
                    </Typography>
                  </Tooltip>
                  <Box display={'flex'}>
                    {rate.isPrimary && (
                      <Chip
                        sx={{ ml: 1 }}
                        size={'small'}
                        label={t('administration.rates.primary_rate') || ''}
                        color={'info'}
                        variant={'outlined'}
                      />
                    )}
                  </Box>
                </Box>

                <Box display={'flex'} sx={{ minHeight: '44px' }} justifySelf={'end'}>
                  <AnimateComponentPresence
                    identifier={rate.id}
                    isVisible={isHovered}
                    animationDuration={0.3}
                  >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      {userPermissions?.canEditRate && (
                        <>
                          {copyClickCallBack && (
                            <IconButton
                              aria-label="copy-rate"
                              onClick={() => {
                                copyClickCallBack(rate?.id as string);
                              }}
                            >
                              <CopyAllOutlined sx={{ height: 18, width: 18 }} />
                            </IconButton>
                          )}

                          <IconButton
                            aria-label="edit-rate"
                            onClick={() => {
                              onEdit(rate?.id as string);
                            }}
                          >
                            <EditIcon sx={{ height: 22, width: 22 }} />
                          </IconButton>
                        </>
                      )}

                      {userPermissions?.canDeleteRate && (
                        <IconButton
                          aria-label="delete-rate"
                          onClick={() => {
                            deleteClickCallBack(rate?.id as string);
                          }}
                        >
                          <DeleteOutlined sx={{ height: 22, width: 22 }} />
                        </IconButton>
                      )}
                    </Box>
                  </AnimateComponentPresence>
                </Box>
              </Box>

              <Box display={'flex'} sx={{ mt: 2 }}>
                {getRelevantRateValueWithUnitLabel(rate)}
              </Box>
            </Box>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {rateDataVariables(rate)}
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            alignItems={'center'}
            justifyContent={!rate?.isHourly ? 'flex-start' : 'space-between'}
            height={'16px'}
          >
            {getRelevantRateInputsView(rate)}
          </Box>
        </CardContent>
      </Card>
    );
  },
);
export { RateCard };
