import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React, { ReactNode } from 'react';

import { BasicTooltip } from '~components/Tooltip/BasicTooltip';

interface CardItemLabelValueProps {
  label: string;
  value: string | number | ReactNode;
  isInline?: boolean;
  hint?: string;
  sx?: SxProps<Theme>; // Style
}
const CardItemLabelValue = ({
  label,
  value,
  hint,
  isInline = false,
  sx = {},
}: CardItemLabelValueProps) => {
  const theme = useTheme();
  return (
    <Box
      display={'flex'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      sx={{ mb: 0.5, ...sx }}
    >
      <Box display={'flex'} sx={{ mr: isInline ? 2 : 0 }}>
        <Typography variant={'body1'} sx={{ pr: 1, color: theme.palette.text.secondary }}>
          {label}
        </Typography>
      </Box>
      <Typography variant={'body1'} sx={{ textAlign: 'right' }}>
        {hint && value ? (
          <BasicTooltip title={hint}>
            <>{value}</>
          </BasicTooltip>
        ) : (
          value
        )}
      </Typography>
    </Box>
  );
};
export { CardItemLabelValue };
