import {
  Equipment_Read,
  Equipment_Read_Nested,
  Equipment_Read_Typeahead,
  EquipmentType_Create,
  EquipmentType_Read,
  EquipmentType_Read_Nested,
  EquipmentType_Update,
  OrderUnitOfMeasure,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';
import { t as $t } from 'i18next';

import { EquipmentOwnershipEnum, EquipmentStates } from '~constants/enums';
import { CompanyBasic } from '~hooks/useCompany';
import { CompanyShareBasic } from '~hooks/useCompanyShares';
import { Nullable } from '~src/types/Nullable';
import { ItemNameAndId } from '~types/ItemNameAndId';

const eqStateOptions = [
  { id: 'active', name: $t('common.active') },
  { id: 'inactive', name: $t('common.inactive') },
];

export class EquipmentTypeBasic {
  constructor(
    private _id: string,
    private _name: string,
    private _system: boolean,
  ) {}

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get system() {
    return this._system;
  }

  public static parse(proto: EquipmentType_Read_Nested) {
    return new EquipmentTypeBasic(proto.id, proto.name, proto.system);
  }
}

export class EquipmentTypeItem {
  public static parse(proto: EquipmentType_Read): EquipmentTypeItem {
    return new EquipmentTypeItem(
      proto?.name || '',
      proto?.id ?? '',
      proto?.system ?? false,
      proto?.external_id,
      proto?.source ?? '',
      proto?.gross_capacity ?? '',
      proto?.gross_capacity_unit_of_measure
        ? ItemNameAndId.parse({
            name: proto.gross_capacity_unit_of_measure,
            id: proto.gross_capacity_unit_of_measure,
          })
        : null,
      dayjs(proto?.created_at),
    );
  }

  public static deparse(data: EquipmentTypeItem): EquipmentType_Create {
    return {
      name: data.name,
      external_id: data.externalId,
      gross_capacity: data.grossCapacity ? Number(data.grossCapacity) : null,
      gross_capacity_unit_of_measure: data.grossCapacityUnitOfMeasure
        ?.id as OrderUnitOfMeasure,
    };
  }

  public static deparseUpdate(data: EquipmentTypeItem): EquipmentType_Update {
    return {
      name: data.name,
      external_id: data.externalId,
      gross_capacity: data.grossCapacity ? Number(data.grossCapacity) : null,
      gross_capacity_unit_of_measure: data.grossCapacityUnitOfMeasure
        ?.id as OrderUnitOfMeasure,
    };
  }

  public get system(): boolean {
    return this._system;
  }
  public get name(): string {
    return this._name;
  }
  public get id(): string {
    return this._id;
  }
  public get externalId(): Nullable<string> {
    return this._externalId;
  }
  public get source(): string {
    return this._source;
  }
  public get grossCapacity(): string {
    return this._gross_capacity;
  }
  public get grossCapacityUnitOfMeasure(): Nullable<ItemNameAndId> {
    return this._gross_capacity_unit_of_measure;
  }
  public set grossCapacityUnitOfMeasure(value: Nullable<ItemNameAndId>) {
    this._gross_capacity_unit_of_measure = ItemNameAndId.parse(value);
  }
  public get createdAt(): Dayjs {
    return this._createdAt;
  }
  constructor(
    private _name: string,
    private _id: string,
    private _system: boolean,
    private _externalId: Nullable<string>,
    private _source: string,
    private _gross_capacity: string,
    private _gross_capacity_unit_of_measure: Nullable<ItemNameAndId>,
    private _createdAt: Dayjs,
  ) {}
}

export class EquipmentTypeahead extends ItemNameAndId {
  public static parse(proto: Equipment_Read_Typeahead): EquipmentTypeahead {
    return new EquipmentTypeahead(proto.id, proto.name, proto.external_id ?? null);
  }

  public get externalId(): Nullable<string> {
    return this._external_id;
  }

  constructor(
    _id: string,
    _name: string,
    private _external_id: Nullable<string>,
  ) {
    super(_name, _id);
  }
}
export class BasicEquipment extends EquipmentTypeahead {
  public static parse(proto: Equipment_Read_Nested): BasicEquipment {
    return new BasicEquipment(
      proto.id,
      proto.name,
      proto.external_id ?? null,
      EquipmentTypeBasic.parse(proto.equipment_type),
      proto.company_share ? CompanyShareBasic.parse(proto.company_share) : null,
    );
  }

  constructor(
    _id: string,
    _name: string,
    _external_id: Nullable<string>,
    private _equipment_type: EquipmentTypeBasic,
    private _company_share: Nullable<CompanyShareBasic>,
  ) {
    super(_id, _name, _external_id);
  }

  public get equipmentType(): EquipmentTypeBasic {
    return this._equipment_type;
  }

  public get companyShare(): Nullable<CompanyShareBasic> {
    return this._company_share;
  }
}

// TODO: why doesn't Equipment extend BasicEquipment?
export class Equipment {
  public static parse(proto: Equipment_Read): Equipment {
    const stateOption = eqStateOptions.find((option) => option.id === proto.state);
    return new Equipment(
      proto.id,
      proto.name,
      EquipmentTypeItem.parse(proto.equipment_type as EquipmentType_Read),
      CompanyBasic.parse(proto.company),
      proto.external_id ?? null,
      proto.license_number ?? null,
      proto?.ownership === EquipmentOwnershipEnum.EXTERNAL || false,
      proto.state ? ItemNameAndId.parse(stateOption) : null,
      dayjs(proto.created_at),
      proto.company_share ? CompanyShareBasic.parse(proto.company_share) : null,
    );
  }
  public static deparseUpdate(data: any) {
    return {
      id: data.id,
      name: data.name,
      external_id: data.externalId,
      license_number: data.licenseNumber,
      ownership: data.isExternal
        ? EquipmentOwnershipEnum.EXTERNAL
        : EquipmentOwnershipEnum.INTERNAL,
      state: data.isNotActive ? EquipmentStates.INACTIVE : EquipmentStates.ACTIVE,
      // Company_id: data.company?.id,//cant be updated
      equipment_type_id: data.type?.id,
    };
  }
  public static deparse(data: any) {
    return {
      name: data.name,
      external_id: data.externalId,
      license_number: data.licenseNumber,
      ownership: data.isExternal
        ? EquipmentOwnershipEnum.EXTERNAL
        : EquipmentOwnershipEnum.INTERNAL,
      state: data.isNotActive ? EquipmentStates.INACTIVE : EquipmentStates.ACTIVE,
      company_id: data.company?.id,
      equipment_type_id: data.type?.id,
    };
  }
  public get name(): string {
    return this._name;
  }
  public get id(): string {
    return this._id;
  }
  public get type(): EquipmentTypeItem {
    return this._type;
  }
  public get typeName(): string {
    return this._type.name;
  }

  public get company(): CompanyBasic {
    return this._company;
  }
  public get companyName(): string {
    return this._company.legalName;
  }
  public get isActive(): boolean {
    return this._state?.id === EquipmentStates.ACTIVE;
  }

  public set isActive(value: boolean) {
    this._state = value
      ? ItemNameAndId.parse(eqStateOptions?.[0])
      : ItemNameAndId.parse(eqStateOptions?.[1]);
  }
  public get isNotActive(): boolean {
    return !this.isActive;
  }
  public set isNotActive(value: boolean) {
    this.isActive = !value;
  }
  public get externalId(): Nullable<string> {
    return this._externalId;
  }
  public get licenseNumber(): Nullable<string> {
    return this._licenseNumber;
  }
  public get isExternal(): boolean {
    return this._isExternal;
  }

  public get state(): Nullable<ItemNameAndId> {
    return this._state;
  }
  public get createdAt(): Dayjs {
    return this._createdAt;
  }

  public get companyShare(): Nullable<CompanyShareBasic> {
    return this._company_share;
  }

  constructor(
    private _id: string,
    private _name: string,
    private _type: EquipmentTypeItem, // Temporary ? til we have full model ?
    private _company: CompanyBasic,
    private _externalId: Nullable<string>,
    private _licenseNumber: Nullable<string>,
    private _isExternal: boolean,
    private _state: Nullable<ItemNameAndId>,
    private _createdAt: Dayjs,
    private _company_share: Nullable<CompanyShareBasic>,
  ) {}
}
