import * as React from 'react';
import { SVGProps } from 'react';

interface GeoFenceIconCircleProps extends SVGProps<SVGSVGElement> {
  textFill?: string;
}

export const GeoFenceIconCircle = ({
  textFill = '#BBC4C9',
  ...props
}: GeoFenceIconCircleProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="20" height="20" />
    <circle cx="10" cy="10" r="7.25" stroke={textFill} strokeWidth="1.5" />
    <circle cx="10" cy="10" r="2" fill={textFill} />
  </svg>
);
