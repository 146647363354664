import ListItem from '@mui/material/ListItem';
import { t } from 'i18next';

import { ActiveItemsVirtualLazyLoadList } from '~components/Lists/ActiveItemsVirtualLazyLoadList';
import { User } from '~hooks/useUsers';

interface InternalDriversListProps {
  drivers: User[];
  isLoading?: boolean;
  onSelectedDriverChange: (driverId: string) => void;
  selectedDriverId?: string;
}

const InternalDriversList = ({
  drivers,
  isLoading,
  onSelectedDriverChange,
  selectedDriverId,
}: InternalDriversListProps) => {
  if (isLoading) {
    return <ListItem>{t('administration.rates.loading_drivers')}</ListItem>;
  }

  return (
    <ActiveItemsVirtualLazyLoadList
      activeItemId={selectedDriverId ?? ''}
      hasNextPage={false}
      itemHeight={48}
      items={drivers}
      loadNextPage={() => {}}
      onClickCallBack={(data: User) => onSelectedDriverChange(data.id)}
      titleProperty={'fullName'}
    />
  );
};

export default InternalDriversList;
