import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { TabFilter } from './types';

interface Props {
  value: TabFilter;
  onChange: (value: TabFilter) => void;
  sx?: SxProps<Theme>;
}

export const TicketTabFilter = ({ value, onChange, sx = {} }: Props) => {
  const [selected, setSelected] = useState<string | null>(value);

  const handleOnChange = (
    _event: React.MouseEvent<HTMLElement>,
    value: string | null,
  ) => {
    if (value) {
      setSelected(value);
      onChange(value as TabFilter);
    }
  };
  return (
    <div>
      <ToggleButtonGroup
        value={selected}
        exclusive
        onChange={handleOnChange}
        aria-label="text alignment"
      >
        <ToggleButton
          value={TabFilter.MISSING}
          sx={{ textTransform: 'none', fontSize: 'smaller', minWidth: '85px' }}
        >
          {$t('approvals.missing_tab')}
        </ToggleButton>
        <ToggleButton
          value={TabFilter.PENDING}
          sx={{ textTransform: 'none', fontSize: 'smaller', minWidth: '85px' }}
        >
          {$t('approvals.pending_tab')}
        </ToggleButton>
        <ToggleButton
          value={TabFilter.NEW}
          sx={{ textTransform: 'none', fontSize: 'smaller', minWidth: '85px' }}
        >
          {$t('approvals.new_tab')}
        </ToggleButton>
        <ToggleButton
          value={TabFilter.APPROVED}
          sx={{ textTransform: 'none', fontSize: 'smaller', minWidth: '85px' }}
        >
          {$t('approvals.approved_tab')}
        </ToggleButton>
        <ToggleButton
          value={TabFilter.VOIDED}
          sx={{ textTransform: 'none', fontSize: 'smaller', minWidth: '85px' }}
        >
          {$t('approvals.voided_tab')}
        </ToggleButton>
        <ToggleButton
          value={TabFilter.All}
          sx={{ textTransform: 'none', fontSize: 'smaller', minWidth: '85px' }}
        >
          {$t('approvals.all_tab')}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
