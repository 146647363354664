import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { AppliedFiltersChipsCollectedData } from '~components/Filters/AppliedFiltersChips';
import {
  FilterMenuItemLoadingReason,
  FilterMenuOption,
} from '~components/Filters/FilterMenuItem';
import { useStores } from '~store';
import { JobsFilters as DriverSchedulerStoreJobsFilters } from '~store/DriverSchedulerStore';

import { JobsSelectedFilter } from '../components/constants';

const jobsFilters: Array<
  Extract<
    keyof DriverSchedulerStoreJobsFilters,
    | 'customers'
    | 'dispatchNumbers'
    | 'dropOffSites'
    | 'pickUpSites'
    | 'projects'
    | 'projectsExternalIds'
  >
> = [
  'customers',
  'dispatchNumbers',
  'dropOffSites',
  'pickUpSites',
  'projects',
  'projectsExternalIds',
];
type JobsFilters = (typeof jobsFilters)[number];
type DriverSchedulerFilterKey = JobsFilters;

type AppliedFiltersState = Record<
  JobsSelectedFilter,
  {
    driverSchedulerFilterKey: DriverSchedulerFilterKey;
    search: string;
  }
>;

export const assignedJobsFilterKeyByJobSelectedFilter: Record<
  JobsSelectedFilter,
  DriverSchedulerFilterKey
> = {
  [JobsSelectedFilter.CUSTOMERS]: 'customers',
  [JobsSelectedFilter.PICK_UP_SITES]: 'pickUpSites',
  [JobsSelectedFilter.DROP_OFF_SITES]: 'dropOffSites',
  [JobsSelectedFilter.DISPATCH_NUMBERS]: 'dispatchNumbers',
  [JobsSelectedFilter.PROJECTS]: 'projects',
  [JobsSelectedFilter.PROJECTS_EXTERNAL_IDS]: 'projectsExternalIds',
};

export type TypeaheadedFilterState = {
  cursor?: string;
  loading: FilterMenuItemLoadingReason | false;
  options: FilterMenuOption[];
  searchValue: string;
};

type JobsFiltersAvailableOptionsState = Record<
  JobsSelectedFilter,
  TypeaheadedFilterState
>;

const getAppliedJobsFiltersIninitialState = () => {
  const initialState = Object.entries(assignedJobsFilterKeyByJobSelectedFilter).reduce(
    (obj, [filterKey, storeKey]) => {
      const key = filterKey as JobsSelectedFilter;

      obj[key] = {
        driverSchedulerFilterKey: storeKey,
        search: '',
      };

      return obj;
    },
    {} as AppliedFiltersState,
  );

  return initialState;
};

const JOBS_AVAILABLE_OPTIONS_STORAGE_KEY = 'driverScheduler_jobsAvailableOptions';
type AppliedJobsFiltersStoredAvailableOptions = Partial<
  Record<JobsSelectedFilter, FilterMenuOption[]>
>;

const getJobsAvailableOptionsInitialState = () => {
  const stored = localStorage.getItem(JOBS_AVAILABLE_OPTIONS_STORAGE_KEY);
  const parsed = (
    stored ? JSON.parse(stored) : {}
  ) as AppliedJobsFiltersStoredAvailableOptions;

  const initialState = {
    ...Object.values(JobsSelectedFilter).reduce((obj, filter) => {
      obj[filter] = {
        loading: false,
        options: parsed[filter] ?? [],
        searchValue: '',
      };

      return obj;
    }, {} as JobsFiltersAvailableOptionsState),
  };

  return initialState;
};

const getAppliedJobsFiltersChipsCollectedDataInitialState = (
  availableOptions: JobsFiltersAvailableOptionsState,
) => {
  const collectedData = Object.entries(availableOptions).reduce((obj, [key, values]) => {
    obj[key as JobsSelectedFilter] = values.options;

    return obj;
  }, {} as AppliedFiltersChipsCollectedData);

  return collectedData;
};

export default function useDriverSchedulerFilters() {
  const { driverSchedulerStore } = useStores();

  const [appliedJobsFilters, setAppliedJobsFilters] = useState(
    getAppliedJobsFiltersIninitialState(),
  );
  const [availableJobsOptions, setAvailableJobsOptions] = useState(
    getJobsAvailableOptionsInitialState(),
  );
  const [appliedJobsFilterChipsCollectedData, setAppliedJobsFilterChipsCollectedData] =
    useState(getAppliedJobsFiltersChipsCollectedDataInitialState(availableJobsOptions));

  const updateAppliedJobsFilterChipsCollectedData = useCallback(
    (filter: string, data: FilterMenuOption[]) => {
      const isValidFilter =
        assignedJobsFilterKeyByJobSelectedFilter[filter as JobsSelectedFilter];

      if (isValidFilter) {
        setAppliedJobsFilterChipsCollectedData((state) => ({
          ...state,
          [filter]: _.uniqBy(
            [...(state[filter as JobsSelectedFilter] ?? []), ...data],
            (i) => i.value,
          ),
        }));
      }
    },
    [],
  );

  useEffect(() => {
    driverSchedulerStore.restoreAssignedJobsFilters();
  }, []);

  useEffect(() => {
    const options = Object.entries(availableJobsOptions).reduce((obj, [key, value]) => {
      obj[key as JobsSelectedFilter] = value.options;

      return obj;
    }, {} as AppliedJobsFiltersStoredAvailableOptions);

    localStorage.setItem(JOBS_AVAILABLE_OPTIONS_STORAGE_KEY, JSON.stringify(options));
  }, [JSON.stringify(availableJobsOptions)]);

  return {
    appliedJobsFilters,
    updateAppliedJobsFilters: setAppliedJobsFilters,
    availableJobsOptions,
    updateAvailableJobsOptions: setAvailableJobsOptions,
    appliedJobsFilterChipsCollectedData,
    updateAppliedJobsFilterChipsCollectedData,
  };
}
