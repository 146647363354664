import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

const AppFooter = () => {
  const theme = useTheme();
  const year = useMemo(() => dayjs().year(), []);
  const isDev = import.meta.env.DEV;

  const items = useMemo(
    () => [
      {
        title: `© ${year} Tread Technologies, Inc.`,
        link: 'https://tread.io/',
      },
      {
        title: `Terms`,
        link: 'https://tread.io/tread-terms-of-subscription/',
      },
      {
        title: `Privacy Policy`,
        link: 'https://tread.io/privacy-policy',
      },
    ],
    [],
  );

  return (
    <AppBar
      component="footer"
      sx={{
        px: 1,
        py: 1,
        boxShadow: 'none',
        borderTop: `1px  solid ${theme.palette.divider}`,
      }}
      color="transparent"
      position="static"
      data-test-id="app-footer"
    >
      <Toolbar sx={{ minHeight: 'unset!important' }}>
        {items.map((item, idx) => (
          <Typography key={item.title} variant={'body2'} color={'secondary'}>
            {idx ? (
              <Box component="span" sx={{ px: 2 }}>
                |
              </Box>
            ) : null}
            <Link
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              color={'secondary'}
            >
              {item.title}
            </Link>
          </Typography>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default AppFooter;
