import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React from 'react';

import Loader from '~images/loader.svg?react';
import Logo from '~images/logo_primary.svg?react';

interface Props {
  text?: string;
  sx?: SxProps<Theme>;
}

export const CommonLoader = ({ sx, text }: Props) => {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, .5)',
          zIndex: 10,
        }}
      />
      <Box sx={{ ...sx }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginTop: 'calc(50vh - 120px)',
            textAlign: 'center',
            zIndex: 11,
          }}
        >
          <Logo width={189} height={37} />
          <Loader className="mt-[40px]" width={200} height={25} />

          {text ? <Typography>{text}</Typography> : null}
        </Box>
      </Box>
    </>
  );
};
