import Link from '@mui/material/Link';
import { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';

interface Props {
  sx: SxProps;
}

const termsOfUseLink = 'https://tread.io/terms-of-use/';
const privacyPolicyLink = 'https://tread.io/privacy-policy/';

const TermsAndConditions = ({ sx }: Props) => (
  <Typography variant="body2" textAlign={'center'} sx={{ ...sx }}>
    <Trans
      i18nKey="auth.terms_and_conditions"
      components={{
        termsLink: (
          <Link
            href={termsOfUseLink}
            target="_blank"
            rel="noopener noreferrer"
            color={'secondary'}
            sx={{ fontSize: '12px' }}
          />
        ),
        policyLink: (
          <Link
            href={privacyPolicyLink}
            target="_blank"
            rel="noopener noreferrer"
            color={'secondary'}
            sx={{ fontSize: '12px' }}
          />
        ),
      }}
    />
  </Typography>
);

export default TermsAndConditions;
