import Close from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip, { ChipPropsColorOverrides } from '@mui/material/Chip';
import { InputBaseProps } from '@mui/material/InputBase';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import { t } from 'i18next';
import { get } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { FormFieldLabel } from '~components/FormFields/FormFieldLabel';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';

import { SelectOption } from './Parts/SelectOption';

interface FormFieldProps {
  control: Control<any>; // Form control
  errors: any; // Object of errors
  name: string;
  clearOnBlur?: boolean;
  blurOnSelect?: boolean;
  list: Array<Record<string, any> | any>; //List of options
  label?: string;
  isRequired?: boolean;
  sx?: SxProps<Theme>; // Style
  clearable?: boolean; // IsClearable
  disabled?: boolean;
  multiple?: boolean;
  chipsColor?: OverridableStringUnion<
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    ChipPropsColorOverrides
  >;
  defaultValue?: any;
  loading?: boolean;
  noOptionsText?: string | ReactNode;
  inputProps?: InputBaseProps['inputProps'];
  getValue: (value: any) => string; // Is used for the equal comparison of the values
  getLabel: (value: any) => string; // Is used for the label of the option
  groupBy?: (value: any) => string; // Is used for the groupBy of the option
  isCheckEnabled?: boolean;
  disableCloseOnSelect?: boolean;
  limitTags?: number;
  placeholder?: string;
  isInline?: boolean;
  labelColor?: string;
  hint?: string;
  isShrinkingLabel?: boolean;
  chipMaxWidth?: string;
  isChipSelection?: boolean;
  onInput?: (event: React.SyntheticEvent, value: string) => void;
  // Default value to be set when the clear button is clicked
  // When the value type is an array, this can lead to errors with yup validation
  clearDefaultValue?: any;
  isDenseText?: boolean;
  onClick?: () => void;
  clearOnChange?: (value: any) => boolean;
}

const AutocompleteFormField = ({
  control,
  errors,
  name,
  list,
  label,
  clearable = false,
  getLabel,
  getValue,
  groupBy,
  chipsColor,
  isRequired,
  disabled = false,
  multiple = false,
  defaultValue,
  inputProps,
  loading = false,
  sx,
  clearOnBlur = false,
  blurOnSelect = false,
  isCheckEnabled = true,
  disableCloseOnSelect = true,
  noOptionsText = t('form_fields.no_options') || '',
  limitTags,
  placeholder,
  isInline = false,
  labelColor = 'secondary',
  hint,
  isShrinkingLabel = false,
  chipMaxWidth = '100%',
  isChipSelection = true,
  onInput = (event, value) => {},
  clearDefaultValue = null,
  isDenseText = true,
  onClick = () => {},
  clearOnChange = (value) => true,
}: FormFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          disableCloseOnSelect={disableCloseOnSelect && multiple}
          loading={loading}
          open={isOpen}
          disablePortal
          onOpen={() => {
            onClick?.();
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          noOptionsText={
            <Box
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {noOptionsText}
            </Box>
          }
          limitTags={limitTags}
          disableClearable={!clearable}
          options={list}
          fullWidth
          sx={{ ...sx }}
          multiple={multiple}
          defaultValue={defaultValue}
          disabled={disabled}
          getOptionLabel={(item) => getLabel(item)}
          groupBy={groupBy}
          filterSelectedOptions={false}
          isOptionEqualToValue={(option, value) => {
            return getValue(option) === getValue(value);
          }}
          onInputChange={onInput}
          renderTags={(value: readonly string[], getTagProps) => {
            const numTags = value.length;
            const chipWidthPercentage = limitTags
              ? // 98% is used here to give some room for the hidden tags count
                98 / Math.min(value.length, limitTags)
              : 100;

            return (
              <>
                {value.slice(0, limitTags).map(
                  (option: string, index: number) =>
                    isChipSelection ? (
                      <Box
                        sx={{
                          maxWidth: chipMaxWidth || `${chipWidthPercentage}%`,
                          '& .MuiAutocomplete-tag': {
                            maxWidth: '100%',
                          },
                        }}
                        key={`autocomplete-chip-${option}--${index}`}
                      >
                        <BasicTooltip title={getLabel(option)}>
                          <Chip
                            size={'small'}
                            color={chipsColor || 'primary'}
                            label={getLabel(option)}
                            deleteIcon={<Close />}
                            {...getTagProps({ index })}
                          />
                        </BasicTooltip>
                      </Box>
                    ) : (
                      <Typography key={`autocomplete-chip-${option}--${index}`}>
                        {getLabel(option)}
                      </Typography>
                    ),
                  // eslint-disable-next-line react/jsx-key
                )}
                {/* Show count of hidden tags */}
                {limitTags && (
                  <Typography variant="body2">
                    <Box sx={{ pl: 0.5 }}>
                      {numTags > limitTags && ` +${numTags - limitTags}`}
                    </Box>
                  </Typography>
                )}
              </>
            );
          }}
          renderInput={(params) => {
            return (
              <Box
                display={'flex'}
                flexDirection={isInline ? 'row' : 'column'}
                width={'100%'}
                sx={{ alignItems: isInline ? 'center' : 'flex-start' }}
              >
                {!isShrinkingLabel && label && (
                  <FormFieldLabel
                    label={`${label}${isRequired ? ' *' : ''}`}
                    labelColor={labelColor}
                    sx={{ mr: isInline ? 1 : '' }}
                  ></FormFieldLabel>
                )}

                <TextField
                  {...params}
                  size={'small'}
                  margin={isDenseText ? 'dense' : 'none'}
                  placeholder={placeholder}
                  variant="outlined"
                  className={isRequired && !disabled ? 'required' : ''}
                  error={Boolean(get(errors, name))}
                  data-test-id={get(inputProps, 'data-test-id', '')}
                  sx={{
                    background: '#fff',
                    borderRadius: 1,
                    '& .MuiInputBase-root': {
                      flexFlow: 'row',
                    },
                    ...inputProps?.sx,
                  }}
                  label={isShrinkingLabel ? `${label}${isRequired ? ' *' : ''}` : ''}
                />
              </Box>
            );
          }}
          renderOption={(props, option, { inputValue, selected }) => (
            <SelectOption
              key={`select-option-${option.id || option.name || option.value || option}`}
              isCheckEnabled={isCheckEnabled && multiple}
              selected={selected}
              option={option}
              getLabel={getLabel}
              inputValue={inputValue}
              props={{ ...props, style: { padding: '6px' } }}
            />
          )}
          onChange={(event, values, reason) => {
            if (reason === 'clear') {
              onChange(clearDefaultValue);
            } else {
              if (clearOnChange(values)) {
                onChange(values);
              }
            }
          }}
          value={value}
        />
      )}
    />
  );
};

export { AutocompleteFormField };
