import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';

import Menu from '~components/Menu/Menu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import theme from '~theme/AppTheme';
import { hexToRgba } from '~utils/utilFunctions';

import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '../../pages/Dispatch/NewDispatch';
import { FilterMenuOption } from './FilterMenuItem';

const CHIP_MAX_ITEMS = 1;
const CHIP_MAX_WIDTH_IN_PX = 270;
const MAX_VISIBLE_FILTERS = 1;

export type AppliedFiltersChipsCollectedData = Record<string, FilterMenuOption[]>;

interface AppliedFiltersChipsProps {
  appliedFilters: Record<string, string[]>;
  collectedData: AppliedFiltersChipsCollectedData;
  onRemoveAllFilters: (filters: string[]) => void;
  onRemoveFilter: (filter: string) => void;
  maxVisibleFilters?: number;
  sx?: SxProps;
}

export default function AppliedFiltersChips({
  appliedFilters,
  collectedData,
  onRemoveAllFilters,
  onRemoveFilter,
  maxVisibleFilters = MAX_VISIBLE_FILTERS,
  sx,
}: AppliedFiltersChipsProps) {
  const effectiveFilters = useMemo(() => {
    return Object.entries(appliedFilters).reduce(
      (acc, [filter, filterValues]) => {
        const selections = filterValues ?? [];

        if (selections.length > 0) {
          acc.push({ filter, selections });
        }

        return acc;
      },
      [] as Array<{ filter: string; selections: string[] }>,
    );
  }, [appliedFilters]);

  const [visibleFilters, hiddenFilters] = [
    effectiveFilters
      .slice(0, maxVisibleFilters)
      .sort((a, b) => a.filter.localeCompare(b.filter)),
    effectiveFilters
      .slice(maxVisibleFilters)
      .sort((a, b) => a.filter.localeCompare(b.filter)),
  ];

  const hasFiltersApplied = effectiveFilters.length > 0;

  const handleRemoveAllFilters = useCallback(() => {
    const filters = effectiveFilters.reduce((acc, effectiveFilter) => {
      return acc.concat([effectiveFilter.filter]);
    }, [] as string[]);

    onRemoveAllFilters(filters);
  }, [effectiveFilters, onRemoveAllFilters]);

  if (!hasFiltersApplied) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" sx={sx}>
      {visibleFilters.map(({ filter, selections }) => (
        <AppliedFilterChip
          key={filter}
          filter={filter}
          hasRightSibling={hiddenFilters.length > 0}
          onRemoveFilter={onRemoveFilter}
          selections={selections}
          values={collectedData[filter] ?? []}
        />
      ))}

      {hiddenFilters.length > 0 && (
        <>
          <Menu
            sx={{ '& .MuiPaper-root': { minWidth: '250px' } }}
            menuTrigger={
              <SmallButton
                size="small"
                sx={{
                  '&.MuiButton-root': {
                    backgroundColor: 'white',
                    borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: theme.brandV2.borderRadius,
                    borderLeft: 0,
                    borderRight: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                    borderTop: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: theme.brandV2.borderRadius,
                    fontWeight: 400,
                    px: 1,
                  },
                }}
              >
                {maxVisibleFilters === 0
                  ? t('dispatch.dispatch_v2.filters.filters_', {
                      count: hiddenFilters.length,
                    })
                  : t('dispatch.dispatch_v2.filters.more_filters', {
                      count: hiddenFilters.length,
                    })}
              </SmallButton>
            }
          >
            {hiddenFilters.map(({ filter }) => (
              <MenuItem
                key={filter}
                disableRipple
                sx={{
                  '&.MuiMenuItem-root.MuiButtonBase-root': {
                    '&:hover': { backgroundColor: 'white' },
                    '&.Mui-focusVisible': { backgroundColor: 'white' },
                  },
                }}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography color={theme.brandV2.colors.treadBlack} variant="subtitle2">
                    {t(`dispatch.dispatch_v2.filters.${_.snakeCase(filter)}`)}
                  </Typography>

                  <Close
                    onClick={() => onRemoveFilter(filter)}
                    sx={{ fontSize: '16px' }}
                  />
                </Box>
              </MenuItem>
            ))}
          </Menu>

          <BasicTooltip title={t('dispatch.dispatch_v2.filters.clear_all_filters')}>
            <IconButton
              onClick={handleRemoveAllFilters}
              size="small"
              sx={{
                backgroundColor: hexToRgba(theme.brandV2.colors.treadBlack, 0.04),
                ml: 1,
              }}
            >
              <Close sx={{ fontSize: '16px', color: theme.brandV2.colors.treadBlack }} />
            </IconButton>
          </BasicTooltip>
        </>
      )}
    </Box>
  );
}

const labelBySelectedFilter: Record<string, string> = {
  customerAccountIds: t('dispatch.dispatch_v2.filters.customers'),
  customers: t('dispatch.dispatch_v2.filters.customers'),
  dispatchNumbers: t('dispatch.dispatch_v2.filters.dispatch_numbers'),
  driverIds: t('dispatch.dispatch_v2.filters.drivers'),
  dropOffSiteIds: t('dispatch.dispatch_v2.filters.drop_off_sites'),
  dropOffSites: t('dispatch.dispatch_v2.filters.drop_off_sites'),
  dropoffSites: t('dispatch.dispatch_v2.filters.drop_off_sites'),
  jobStates: t('dispatch.dispatch_v2.filters.job_states'),
  pickUpSiteIds: t('dispatch.dispatch_v2.filters.pick_up_sites'),
  pickUpSites: t('dispatch.dispatch_v2.filters.pick_up_sites'),
  projectIds: t('dispatch.dispatch_v2.filters.projects'),
  projects: t('dispatch.dispatch_v2.filters.projects'),
  projectsExternalIds: t('dispatch.dispatch_v2.filters.projects_external_ids'),
  vendorAccountIds: t('dispatch.dispatch_v2.filters.vendors'),
};

interface AppliedFilterChipProps {
  filter: string;
  hasRightSibling?: boolean;
  onRemoveFilter: AppliedFiltersChipsProps['onRemoveFilter'];
  selections: string[];
  values: FilterMenuOption[];
}

function AppliedFilterChip({
  filter,
  hasRightSibling,
  onRemoveFilter,
  selections,
  values,
}: AppliedFilterChipProps) {
  const label = labelBySelectedFilter[filter];
  const selectionsContent = values
    .filter(({ value }) => selections.includes(value))
    .map(({ label }) => label);
  const content = selectionsContent.slice(0, CHIP_MAX_ITEMS);
  const nonVisibleItemsCount = selectionsContent.length - CHIP_MAX_ITEMS;

  const handleRemoveFilterClick = useCallback(() => {
    onRemoveFilter(filter);
  }, [filter]);

  return (
    <Box
      alignItems="center"
      bgcolor="white"
      borderBottom={`solid 1px ${theme.brandV2.colors.treadGray7}`}
      borderLeft={0}
      borderRight={`solid 1px ${theme.brandV2.colors.treadGray7}`}
      borderTop={`solid 1px ${theme.brandV2.colors.treadGray7}`}
      display="flex"
      height={NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}
      maxWidth={CHIP_MAX_WIDTH_IN_PX}
      px={1}
      sx={{
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: hasRightSibling ? 0 : theme.brandV2.borderRadius,
        borderTopLeftRadius: 0,
        borderTopRightRadius: hasRightSibling ? 0 : theme.brandV2.borderRadius,
      }}
    >
      <Typography
        color={theme.brandV2.colors.treadGray3}
        flex={0}
        fontSize="12px"
        whiteSpace="nowrap"
      >
        {label}
      </Typography>

      <Typography
        color={theme.brandV2.colors.treadBlack}
        flex={1}
        fontSize="12px"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        pl={1}
      >
        {content.join(', ')}
      </Typography>

      {nonVisibleItemsCount > 0 && (
        <Typography
          color={theme.brandV2.colors.treadBlack}
          flex={0}
          fontSize="12px"
          whiteSpace="nowrap"
        >
          {`, +${nonVisibleItemsCount}`}
        </Typography>
      )}

      <Box flex={0} pl={1}>
        <Close
          onClick={handleRemoveFilterClick}
          sx={{
            color: theme.brandV2.colors.treadBlack,
            cursor: 'pointer',
            fontSize: '16px',
            mt: '-2px',
          }}
        />
      </Box>
    </Box>
  );
}
