import { InputBaseProps } from '@mui/material/InputBase';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { CompanyBasic, useCompany } from '~hooks/useCompany';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

interface FormFieldProps {
  control: Control<any>; // Form control
  errors: any; // Object of errors
  name: string;
  // If parentCompanyId not specified, then use `userStore.userCompany.id`
  parentCompanyId?: string;
  label?: string;
  sx?: SxProps<Theme>; // Style
  disabled?: boolean;
  defaultValue?: Nullable<CompanyBasic>;
  noOptionsText?: string | ReactNode;
  hideLabel?: boolean;
  inputProps?: InputBaseProps['inputProps'];
  isRequired?: boolean;
  setOptionsCount?: (count: number) => void;
  onOptionsChange?: (options: Nullable<CompanyBasic[]>) => void;
  clearable?: boolean;
}

const CompanySelectorFormField = ({
  control,
  errors,
  name,
  label = 'Company',
  hideLabel = false,
  parentCompanyId,
  disabled = false,
  defaultValue = null,
  noOptionsText = t('form_fields.no_options'),
  inputProps,
  isRequired = false,
  sx,
  onOptionsChange,
  clearable = false,
}: FormFieldProps) => {
  const [options, setOptions] = useState<Nullable<CompanyBasic[]>>([]);
  const { userStore } = useStores();
  const { getAllUserAvailableCompanies, getAllUserAvailableCompaniesTypeahead } =
    useCompany();

  const [lastFetchedId, setLastFetchedId] = useState<string | null>(null);
  const getCompanies = useCallback(() => {
    const id = parentCompanyId || userStore.userCompany?.id || '';
    if (id && id !== lastFetchedId) {
      getAllUserAvailableCompanies({
        id: id,
        callBack: (response) => {
          if (response.length) {
            setOptions(response);
            if (onOptionsChange) {
              onOptionsChange(response);
            }
          } else {
            setOptions([]);
          }
        },
      });
      setLastFetchedId(id);
    }
  }, [
    parentCompanyId,
    userStore.userCompany?.id,
    getAllUserAvailableCompanies,
    lastFetchedId,
  ]);

  // This useEffect will fetch the companies whenever parentCompanyId changes
  useEffect(() => {
    getCompanies();
  }, [parentCompanyId, getCompanies]);

  // This useEffect will send the updated options to the parent whenever they change
  useEffect(() => {
    onOptionsChange?.(options);
  }, [options, onOptionsChange]);

  return options && options.length > 1 ? (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={() => (
        <AutocompleteAsyncFormField
          control={control}
          errors={errors}
          name={name}
          disabled={disabled}
          noOptionsText={noOptionsText}
          defaultSuggestions={[]}
          multiple={false}
          sx={sx}
          getValue={(company: CompanyBasic) => company.id}
          getLabel={(company: CompanyBasic) => company.legalName}
          asyncCallback={getAllUserAvailableCompaniesTypeahead}
          label={label}
          isRequired={isRequired}
          inputProps={inputProps}
          hideLabel={hideLabel}
          clearable={clearable}
        />
      )}
    />
  ) : null;
};

export { CompanySelectorFormField };
