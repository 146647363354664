import {
  Settlement_Create,
  Settlement_Data,
  Settlement_Read,
  Settlement_Update,
  SettlementLineItemGrouping,
  SettlementLineItemType,
  SettlementState,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { AccountBasic } from '~hooks/useAccount';
import { DriverBasic } from '~hooks/useDrivers';
import { SettlementDTO } from '~pages/Approvals/DriverPay/components/SettlementForm';
import { Nullable } from '~types/Nullable';

function extractGroupingsFromSettlementDTO(dto: SettlementDTO) {
  let groupings: SettlementLineItemGrouping[] = [];

  if (dto.settlementLineItemGroupings) {
    groupings = Object.entries(dto.settlementLineItemGroupings).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc.push(key as SettlementLineItemGrouping);
        }

        return acc;
      },
      [] as SettlementLineItemGrouping[],
    );
  }

  return groupings.length ? groupings : undefined;
}

export class Settlement {
  constructor(
    private _id: Nullable<string>,
    private _adjustments_total_amount: number,
    private _data: SettlementData,
    private _exported_at: Nullable<Dayjs>,
    private _jobs_count: number,
    private _state: SettlementState,
    private _total_amount: number,
    private _customer_account: Nullable<AccountBasic>,
    private _vendor_account: Nullable<AccountBasic>,
    private _driver: Nullable<DriverBasic>,
    private _settlement_id: string,
    private _created_at: Dayjs,
    private _updated_at: Dayjs,
    private _settlement_line_item_type: SettlementLineItemType,
    private _settlement_line_item_groupings: SettlementLineItemGrouping[],
  ) {}

  public get id() {
    return this._id;
  }

  public get adjustmentsTotalAmount() {
    return this._adjustments_total_amount;
  }

  public get data() {
    return this._data;
  }

  public get exportedAt() {
    return this._exported_at;
  }

  public get jobsCount() {
    return this._jobs_count;
  }

  public get state() {
    return this._state;
  }

  public get totalAmount() {
    return this._total_amount;
  }

  public get customerAccount() {
    return this._customer_account;
  }

  public get vendorAccount() {
    return this._vendor_account;
  }

  public get driver() {
    return this._driver;
  }

  public get settlementId() {
    return this._settlement_id;
  }

  public get createdAt() {
    return this._created_at;
  }

  public get updatedAt() {
    return this._updated_at;
  }

  public get settlementLineItemType() {
    return this._settlement_line_item_type;
  }

  public get settlementLineItemGroupings() {
    return this._settlement_line_item_groupings;
  }

  public static parse(proto: Settlement_Read) {
    return new Settlement(
      proto.id,
      Number(proto.adjustments_total_amount),
      SettlementData.parse(proto.data),
      proto.exported_at ? dayjs(proto.exported_at) : null,
      proto.jobs_count,
      proto.state,
      Number(proto.total_amount),
      proto.customer_account ? AccountBasic.parse(proto.customer_account) : null,
      proto.vendor_account ? AccountBasic.parse(proto.vendor_account) : null,
      proto.driver ? DriverBasic.parse(proto.driver) : null,
      proto.settlement_id,
      dayjs(proto.created_at),
      dayjs(proto.updated_at),
      proto.settlement_line_item_type,
      proto.settlement_line_item_groupings,
    );
  }

  public static deparse(proto: SettlementDTO) {
    if (!proto.invoiceIds || !proto.invoiceIds.length) {
      throw new Error('Invoice ids not set');
    }

    const data: Settlement_Create = {
      invoice_ids: proto.invoiceIds,
      settlement_line_item_type: proto.settlementLineItemType,
      settlement_line_item_groupings: extractGroupingsFromSettlementDTO(proto),
    };

    return data;
  }

  public static deparseUpdate(proto: SettlementDTO) {
    const data: Settlement_Update = {
      settlement_line_item_type: proto.settlementLineItemType,
      settlement_line_item_groupings: extractGroupingsFromSettlementDTO(proto) ?? [],
    };

    return data;
  }
}

export class SettlementData {
  constructor(
    private _friendly_order_ids: Nullable<string[]>,
    private _quantities_per_uom: Nullable<string[]>,
  ) {}

  public get friendlyOrderIds() {
    return this._friendly_order_ids;
  }

  public get quantitiesPerUom() {
    return this._quantities_per_uom;
  }

  public static parse(proto: Settlement_Data) {
    return new SettlementData(proto.friendly_order_ids, proto.quantities_per_uom);
  }
}
