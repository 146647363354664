import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import SendTextDialog from '~components/Job/SendTextDialog';
import {
  HEADER_PANEL_Z_INDEX,
  LoadingSpinner,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import { Job } from '~hooks/useJob';
import { Order, useOrdersDispatch } from '~hooks/useOrders';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import useVisibilityAwareLazyLoader from '~utils/hooks/useVisibilityAwareLazyLoader';

import JobDialog from './JobDialog';
import {
  ActionsColumn,
  CheckboxColumn,
  CycleTimeColumn,
  DeliveredColumn,
  JobIdColumn,
  JOBS_TABLE_TEMPLATE_COLUMNS,
  JobsTableColumnKey,
  jobsTableColumns,
  MaterialsColumn,
  StartColumn,
  StatusColumn,
  TruckAndTrailerColumn,
  VendorAndDriverColumn,
  WaypointsColumn,
} from './jobsTableColumns';
import JobsTableExpandedBlock from './JobsTableExpandedBlock';
import { Column } from './jobsTableStyledComponents';
import {
  ORDER_DISPATCH_CARDS_CONTAINER_GUTTER_SIZE,
  ORDERS_DISPATCH_HEADER_ROW_HEIGHT_IN_PX,
} from './OrdersView';

enum JobActionDialog {
  CANCEL_JOB = 'cancel_job',
  FORM = 'form',
  TEXT_DRIVER = 'text_driver',
}

type JobActionDialogState = {
  dialog?: JobActionDialog;
  isOpen: boolean;
  job?: Job;
};

interface JobsTable {
  checkedJobs?: string[];
  onCheckedStateChange?: (jobId: string) => void;
  order: Order;
}

const JobsTable = observer(({ checkedJobs, onCheckedStateChange, order }: JobsTable) => {
  const { ordersDispatchStore, toasterStore } = useStores();
  const { getJobsByOrder, cancelJob, sendJob, acceptJob, rejectJob, duplicateJob } =
    useOrdersDispatch();
  const [expandedJobs, setExpandedJobs] = useState<string[]>([]);
  const [hoveredRow, setHoveredRow] = useState<string>();
  const [jobActionDialog, setJobActionDialog] = useState<JobActionDialogState>({
    isOpen: false,
  });

  const cancelJobDialogRef = useRef<ModalDialogHandler>(null);
  const lazyLoader = useVisibilityAwareLazyLoader();

  const isLoading = ordersDispatchStore.isLoadingOrderJobs.get(order.id) ?? true;
  const jobs = ordersDispatchStore.orderJobs.get(order.id) ?? [];

  const openJobActionDialog = useCallback((dialog: JobActionDialog, job?: Job) => {
    setJobActionDialog((state) => ({ ...state, isOpen: true, dialog, job }));

    if (dialog === JobActionDialog.CANCEL_JOB) {
      cancelJobDialogRef.current?.open();
    }
  }, []);

  const closeJobActionDialog = useCallback(() => {
    setJobActionDialog((state) => ({ ...state, isOpen: false }));
    cancelJobDialogRef.current?.close();
  }, []);

  const handleExpandedStateChange = useCallback((job: Job) => {
    setExpandedJobs((expandedJobs) => {
      const newExpandedJobs = [...expandedJobs];
      const index = newExpandedJobs.findIndex((jobId) => jobId === job.id);

      if (index > -1) {
        newExpandedJobs.splice(index, 1);
      } else {
        newExpandedJobs.push(job.id);
      }

      return newExpandedJobs;
    });
  }, []);

  const handleAcceptJob = useCallback(async (jobId: string) => {
    const sentJob = await acceptJob(jobId);

    toasterStore.push(
      alert(t('dispatch.job.updated', { name: sentJob.jobId }), AlertTypes.success),
      false,
      true,
    );
  }, []);

  const handleCancelJob = useCallback(
    async (jobId: string) => {
      const cancelledJob = await cancelJob(jobId);

      toasterStore.push(
        alert(
          t('dispatch.job.updated', { name: cancelledJob.jobId }),
          AlertTypes.success,
        ),
        false,
        true,
      );
      closeJobActionDialog();
    },
    [closeJobActionDialog],
  );

  const handleDuplicateJob = useCallback(async (job: Job) => {
    const clonedJob = await duplicateJob(job.id);

    if (job.status === JobState.REJECTED) {
      await cancelJob(job.id);
    }

    toasterStore.push(
      alert(t('dispatch.job.copied', { name: clonedJob.jobId }), AlertTypes.success),
      false,
      true,
    );
  }, []);

  const handleRejectJob = useCallback(async (jobId: string) => {
    const sentJob = await rejectJob(jobId);

    toasterStore.push(
      alert(t('dispatch.job.updated', { name: sentJob.jobId }), AlertTypes.success),
      false,
      true,
    );
  }, []);

  const handleSendJob = useCallback(async (jobId: string) => {
    const sentJob = await sendJob(jobId);

    toasterStore.push(
      alert(t('dispatch.job.updated', { name: sentJob.jobId }), AlertTypes.success),
      false,
      true,
    );
  }, []);

  useEffect(() => {
    if (lazyLoader.shouldLoad && !ordersDispatchStore.orderJobs.has(order.id)) {
      getJobsByOrder(order.id);
    }
  }, [lazyLoader.shouldLoad, order.id, ordersDispatchStore.orderJobs.has(order.id)]);

  if (isLoading) {
    return (
      <Box ref={lazyLoader.nodeRef}>
        <LoadingSpinner sx={{ my: 2 }} isVisible />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Box
          bgcolor={theme.brandV2.colors.treadGray9}
          borderBottom={`solid 1px ${theme.brandV2.colors.treadGray7}`}
          borderTop={`solid 1px ${theme.brandV2.colors.treadGray7}`}
          display="grid"
          gridAutoRows={`${ORDERS_DISPATCH_HEADER_ROW_HEIGHT_IN_PX}px`}
          gridTemplateColumns={JOBS_TABLE_TEMPLATE_COLUMNS}
          position="sticky"
          px={1}
          top={`calc(${theme.spacing(ORDER_DISPATCH_CARDS_CONTAINER_GUTTER_SIZE * -1)} - 1px)`}
          zIndex={HEADER_PANEL_Z_INDEX - 1}
        >
          {Object.entries(jobsTableColumns).map(([key, column]) => (
            <Column key={key} columnKey={key as JobsTableColumnKey} isHeader>
              <OverflowAwareText fontWeight={600}>{column.title}</OverflowAwareText>
            </Column>
          ))}
        </Box>

        {jobs.map((job, index) => (
          <React.Fragment key={job.id}>
            <Box
              borderBottom={
                index < jobs.length - 1
                  ? `solid 1px ${theme.brandV2.colors.treadGray7}`
                  : undefined
              }
              onClick={() => handleExpandedStateChange(job)}
              display="grid"
              gridTemplateColumns={JOBS_TABLE_TEMPLATE_COLUMNS}
              p={1}
              onMouseEnter={() => setHoveredRow(job.id)}
              onMouseLeave={() => setHoveredRow(undefined)}
            >
              <CheckboxColumn
                isChecked={checkedJobs?.includes(job.id)}
                job={job}
                onCheckedStateChange={() => onCheckedStateChange?.(job.id)}
              />
              <VendorAndDriverColumn job={job} isHovered={hoveredRow === job.id} />
              <TruckAndTrailerColumn job={job} />
              <StatusColumn
                job={job}
                onAccept={() => handleAcceptJob(job.id)}
                onReject={() => handleRejectJob(job.id)}
                onSend={() => handleSendJob(job.id)}
              />
              <StartColumn job={job} />
              <CycleTimeColumn job={job} />
              <DeliveredColumn job={job} />
              <MaterialsColumn job={job} />
              <WaypointsColumn job={job} />
              <JobIdColumn job={job} />
              <ActionsColumn
                isHovered={hoveredRow === job.id}
                job={job}
                onCancel={() => openJobActionDialog(JobActionDialog.CANCEL_JOB, job)}
                onDuplicate={() => handleDuplicateJob(job)}
                onEdit={() => openJobActionDialog(JobActionDialog.FORM, job)}
                onTextDriver={() => openJobActionDialog(JobActionDialog.TEXT_DRIVER, job)}
                isOrderEditable={order.editable}
              />
            </Box>

            <Collapse mountOnEnter unmountOnExit in={expandedJobs.includes(job.id)}>
              <Box borderBottom={`solid 1px ${theme.brandV2.colors.treadGray7}`}>
                <JobsTableExpandedBlock job={job} />
              </Box>
            </Collapse>
          </React.Fragment>
        ))}
      </Box>

      <Box onClick={(event) => event.stopPropagation()}>
        <JobDialog
          isOpen={
            jobActionDialog.dialog === JobActionDialog.FORM && jobActionDialog.isOpen
          }
          job={jobActionDialog.job}
          onClose={closeJobActionDialog}
        />

        <ModalDialog
          ref={cancelJobDialogRef}
          callBack={() => handleCancelJob(`${jobActionDialog.job?.id}`)}
          confirmButtonColor="error"
          confirmButtonText={`${t('actions.confirm')}`}
          loading={ordersDispatchStore.cancellingJobId === jobActionDialog.job?.id}
          content={t('dispatch.job.cancel_description')}
          title={t('dispatch.job.cancel_job')}
        />

        <SendTextDialog
          isOpen={
            jobActionDialog.dialog === JobActionDialog.TEXT_DRIVER &&
            jobActionDialog.isOpen
          }
          job={jobActionDialog.job}
          onClose={closeJobActionDialog}
        />
      </Box>
    </>
  );
});

export default JobsTable;
