import * as React from 'react';
import { SVGProps } from 'react';
interface GeoFenceMovingIconProps extends SVGProps<SVGSVGElement> {
  textFill?: string;
}
export const InfoIcon = ({ textFill = '#132732', ...props }: GeoFenceMovingIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.1665 14.1667H10.8332V9.16675H9.1665V14.1667ZM9.99984 7.50008C10.2359 7.50008 10.4339 7.42022 10.5936 7.2605C10.7533 7.10078 10.8332 6.90286 10.8332 6.66675C10.8332 6.43064 10.7533 6.23272 10.5936 6.073C10.4339 5.91328 10.2359 5.83342 9.99984 5.83342C9.76373 5.83342 9.56581 5.91328 9.40609 6.073C9.24637 6.23272 9.1665 6.43064 9.1665 6.66675C9.1665 6.90286 9.24637 7.10078 9.40609 7.2605C9.56581 7.42022 9.76373 7.50008 9.99984 7.50008ZM9.99984 18.3334C8.84706 18.3334 7.76373 18.1147 6.74984 17.6772C5.73595 17.2397 4.854 16.6459 4.104 15.8959C3.354 15.1459 2.76025 14.264 2.32275 13.2501C1.88525 12.2362 1.6665 11.1529 1.6665 10.0001C1.6665 8.8473 1.88525 7.76397 2.32275 6.75008C2.76025 5.73619 3.354 4.85425 4.104 4.10425C4.854 3.35425 5.73595 2.7605 6.74984 2.323C7.76373 1.8855 8.84706 1.66675 9.99984 1.66675C11.1526 1.66675 12.2359 1.8855 13.2498 2.323C14.2637 2.7605 15.1457 3.35425 15.8957 4.10425C16.6457 4.85425 17.2394 5.73619 17.6769 6.75008C18.1144 7.76397 18.3332 8.8473 18.3332 10.0001C18.3332 11.1529 18.1144 12.2362 17.6769 13.2501C17.2394 14.264 16.6457 15.1459 15.8957 15.8959C15.1457 16.6459 14.2637 17.2397 13.2498 17.6772C12.2359 18.1147 11.1526 18.3334 9.99984 18.3334ZM9.99984 16.6667C11.8609 16.6667 13.4373 16.0209 14.729 14.7292C16.0207 13.4376 16.6665 11.8612 16.6665 10.0001C16.6665 8.13897 16.0207 6.56258 14.729 5.27092C13.4373 3.97925 11.8609 3.33341 9.99984 3.33341C8.13873 3.33341 6.56234 3.97925 5.27067 5.27092C3.979 6.56258 3.33317 8.13897 3.33317 10.0001C3.33317 11.8612 3.979 13.4376 5.27067 14.7292C6.56234 16.0209 8.13873 16.6667 9.99984 16.6667Z"
      fill={textFill}
    />
  </svg>
);
