import { t } from 'i18next';
import { get } from 'lodash';
import * as yup from 'yup';

import { Material } from '~hooks/useMaterials';
import { CompanyBasic } from '~src/hooks/useCompany';
import { Nullable } from '~types/Nullable';

export const materialFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
    ),
  id: yup.string(),
  company: yup
    .object()
    .typeError(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    )
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    ),
  type: yup.object().notRequired(),
  externalId: yup.string(),
  isNotActive: yup.boolean(),
});

export const setMaterialDefaults = (
  data: Nullable<Material>,
  altCompany: Nullable<CompanyBasic>,
) => {
  return {
    id: data?.id || '',
    name: data?.name || '',
    company: data?.company || altCompany,
    type: get(data, 'type.id')
      ? {
          name: data?.type?.name || '',
          id: data?.type?.id || '',
          system: data?.type?.system || false,
        }
      : null,

    externalId: data?.externalId || '',
    isNotActive: data?.isNotActive || false,
  };
};
