import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { InputBaseProps } from '@mui/material/InputBase';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { capitalize, get } from 'lodash';
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';

import theme from '~theme/AppTheme';

interface FormFieldProps<T extends FieldValues> extends UseControllerProps<T> {
  errors: FieldErrors<T>;
  inputProps?: InputBaseProps['inputProps'];
  label?: string;
  list: string[];
  radioGroupSx?: RadioGroupProps['sx'];
  sx?: FormControlProps['sx'];
}

const RadioGroupFormField = <T extends FieldValues>({
  control,
  disabled,
  errors,
  inputProps,
  label,
  list,
  name,
  radioGroupSx,
  sx,
}: FormFieldProps<T>) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const hasError = Boolean(get(errors, name)?.message);

          return (
            <FormControl error={Boolean(hasError)} sx={{ ...sx }}>
              {label && <FormLabel>{label}</FormLabel>}

              <RadioGroup
                row
                {...field}
                onChange={(event) => field.onChange(event.target.value)}
                sx={radioGroupSx}
              >
                {list.map((unit, idx) => (
                  <FormControlLabel
                    key={unit}
                    value={unit}
                    control={
                      <Radio
                        disabled={disabled}
                        inputProps={
                          {
                            ...inputProps,
                            'data-test-id': `${get(
                              inputProps,
                              'data-test-id',
                              '',
                            )}__${idx}`,
                          } as unknown as InputBaseProps['inputProps']
                        }
                        sx={
                          hasError
                            ? { '&.MuiRadio-root': { color: theme.palette.error.main } }
                            : undefined
                        }
                      />
                    }
                    label={capitalize(unit)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          );
        }}
      />
    </>
  );
};

export { RadioGroupFormField };
