import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { OrderState } from '@treadinc/horizon-api-spec';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import {
  ActionsColumn,
  AlertsColumn,
  CheckboxColumn,
  CycleColumn,
  DispatchColumn,
  ForColumn,
  MaterialColumn,
  ORDER_DISPATCH_CARD_TEMPLATE_COLUMNS,
  StartColumn,
  StatusColumn,
  TrucksAndTypeColumn,
  WaypointsColumn,
} from '~components/Order/ordersDispatchColumns';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import OrderDispatchCardExpandedBlock from './OrderDispatchCardExpandedBlock';
import OrderSummary from './OrderSummary';

export enum ExpandedState {
  CLOSED = 'closed',
  EXPANDED = 'expanded',
  OPEN = 'open',
}

export const ORDER_DISPATCH_CARD_INNER_GUTTER_SIZE = 1;
export const ORDER_DISPATCH_CARD_BORDER_WIDTH_IN_PX = 1;
export const ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE = ExpandedState.OPEN;

interface OrderDispatchCardProps {
  checkedJobs?: string[];
  expandedState?: ExpandedState;
  isChecked?: boolean;
  nonRoutableAlertsFeatureFlagEnabled?: boolean;
  onAcceptOrder: () => void;
  onCheckedStateChange?: (orderId: string, jobId?: string) => void;
  onCloneOrderClick: () => void;
  onCreateJobFromOrderClick: () => void;
  onEditClick: () => void;
  onExpandedStateChange: (orderId: string) => void;
  onOrderStateChange: (nextOrderState: OrderState) => Promise<void>;
  onRejectOrder: () => void;
  onTextAllDriversClick: () => void;
  order: Order;
}

const OrderDispatchCard = observer(
  ({
    checkedJobs,
    expandedState = ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
    isChecked,
    nonRoutableAlertsFeatureFlagEnabled,
    onAcceptOrder,
    onCheckedStateChange,
    onCloneOrderClick,
    onCreateJobFromOrderClick,
    onEditClick,
    onExpandedStateChange,
    onOrderStateChange,
    onRejectOrder,
    onTextAllDriversClick,
    order,
  }: OrderDispatchCardProps) => {
    const isExpanded = expandedState === ExpandedState.EXPANDED;
    const isOpen = expandedState === ExpandedState.OPEN;

    const { ordersDispatchStore } = useStores();

    const handleCheckedOrderStateChange = useCallback(() => {
      onCheckedStateChange?.(order.id);
    }, [onExpandedStateChange, order.id]);

    const handleCheckedJobStateChange = useCallback(
      (jobId: string) => {
        onCheckedStateChange?.(order.id, jobId);
      },
      [onExpandedStateChange, order.id],
    );

    const handleExpandedStateChange = useCallback(() => {
      onExpandedStateChange(order.id);
    }, [onExpandedStateChange, order.id]);

    return (
      <Box
        bgcolor="white"
        border={`solid ${ORDER_DISPATCH_CARD_BORDER_WIDTH_IN_PX}px ${theme.brandV2.colors.treadGray7}`}
        borderRadius={theme.spacing()}
        boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.20)"
        width="100%"
      >
        <Box
          display="grid"
          gridTemplateColumns={ORDER_DISPATCH_CARD_TEMPLATE_COLUMNS}
          p={ORDER_DISPATCH_CARD_INNER_GUTTER_SIZE}
          onClick={handleExpandedStateChange}
        >
          <CheckboxColumn
            isChecked={isChecked}
            isDisabled={ordersDispatchStore.isLoadingOrderJobs.get(order.id) ?? true}
            onCheckedStateChange={handleCheckedOrderStateChange}
          />
          <DispatchColumn order={order} />
          <ForColumn order={order} />
          <TrucksAndTypeColumn order={order} />
          <StartColumn order={order} />
          <CycleColumn order={order} />
          <StatusColumn
            onAccept={onAcceptOrder}
            onOrderStateChange={onOrderStateChange}
            onReject={onRejectOrder}
            order={order}
          />
          <WaypointsColumn order={order} />
          <MaterialColumn order={order} />
          <AlertsColumn
            nonRoutableAlertsFeatureFlagEnabled={nonRoutableAlertsFeatureFlagEnabled}
            order={order}
          />
          <ActionsColumn
            onCloneOrder={onCloneOrderClick}
            onCreateJobFromOrder={onCreateJobFromOrderClick}
            onEdit={onEditClick}
            onTextAllDrivers={onTextAllDriversClick}
            order={order}
          />
        </Box>

        <Collapse mountOnEnter unmountOnExit in={isExpanded || isOpen}>
          {order.editable && (
            <Box borderTop={`solid 1px ${theme.brandV2.colors.treadGray7}`}>
              <OrderSummary order={order} />
            </Box>
          )}
        </Collapse>

        <Collapse mountOnEnter unmountOnExit in={isOpen}>
          <Box borderTop={`solid 1px ${theme.brandV2.colors.treadGray7}`}>
            <OrderDispatchCardExpandedBlock
              checkedJobs={checkedJobs}
              onCheckedJobStateChange={handleCheckedJobStateChange}
              order={order}
            />
          </Box>
        </Collapse>
      </Box>
    );
  },
);

export default OrderDispatchCard;
