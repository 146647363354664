import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { Theme, useTheme } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled, SxProps } from '@mui/system';
import { JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { FeatureFlags } from '~constants/featureFlags';
import { Account } from '~hooks/useAccount';
import { DriverBasic, GetDriversTypeaheadParams, useDrivers } from '~hooks/useDrivers';
import { Job, useJob } from '~hooks/useJob';
import { User } from '~hooks/useUsers';
import { allJobStates } from '~pages/Dispatch/constants/jobStates';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';
import { isActionClicked } from '~utils/utilFunctions';

import { FILTER_MENU_ITEM_HEIGHT_IN_PX } from '../Filters/FilterMenuItem';
import Menu from '../Menu/Menu';
import { OverflowAwareText } from '../Order/ordersDispatchStyledComponents';
import { SearchBar } from '../Search/SearchBar';
import { AssignJobForm } from './AssignJobForm';

interface AssignedJobToViewProps {
  companyId: string;
  customAssignCTA?: ReactElement;
  customUnassignCTA?: ReactElement;
  driver?: User;
  editable?: boolean;
  jobId: string;
  onSuccess?: (job: Job) => void;
  status: JobState;
  sx?: SxProps<Theme>; // Style
  valueToDisplay?: 'driver' | 'vendor';
  vendor?: Account;
}
const LIST_LIMIT = 10;

interface UpdateJobViewProps {
  customUnassignCTA?: ReactElement;
  driver?: User;
  editable?: boolean;
  onEditClick: () => void;
  status: JobState;
  sx?: SxProps<Theme>; // Style
  valueToDisplay?: 'driver' | 'vendor';
  vendor?: Account;
}

const OverflowAwareTypography = styled(Typography)<TypographyProps>(() => ({
  '&.MuiTypography-root': {
    fontSize: '12px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

type ShouldShowReadRemoveOnlyViewFnArgs = Pick<
  UpdateJobViewProps,
  'driver' | 'vendor' | 'status' | 'valueToDisplay'
>;
export const shouldShowReadRemoveOnlyView = ({
  driver,
  vendor,
  status,
  valueToDisplay,
}: ShouldShowReadRemoveOnlyViewFnArgs) => {
  // Not relying on status as assigned state may go away
  const isJobAssigned = !!vendor || !!driver;

  return isJobAssigned || valueToDisplay === 'driver' || status === JobState.CANCELED;
};

type ShouldShowUnassignCTAFnArgs = Pick<
  UpdateJobViewProps,
  'driver' | 'vendor' | 'status' | 'editable'
>;
export const shouldShowUnassignCTA = ({
  driver,
  vendor,
  status,
  editable,
}: ShouldShowUnassignCTAFnArgs) => {
  const jobAssigned = !!(driver || vendor) && !allJobStates.includes(status);
  const jobSent = status === JobState.SENT;

  return editable && (jobAssigned || (jobSent && !vendor));
};

// TODO: Need Read only/Update view
const ReadRemoveOnlyView = ({
  customUnassignCTA,
  driver,
  editable,
  onEditClick,
  status,
  sx,
  valueToDisplay = 'vendor',
  vendor,
}: UpdateJobViewProps) => {
  const theme = useTheme();
  const { isUpdating } = useJob();
  const canBeUnassigned = useMemo(
    () => shouldShowUnassignCTA({ driver, vendor, status, editable }),
    [driver, vendor, status, editable],
  );
  const displayName = useMemo(() => {
    if (driver && valueToDisplay === 'driver') {
      return `${driver.firstName} ${driver.lastName}`;
    } else if (vendor && valueToDisplay === 'vendor') {
      return vendor?.name || '';
    }
    return '';
  }, [driver, vendor, valueToDisplay]);

  if (canBeUnassigned && customUnassignCTA) {
    return (
      <BasicTooltip
        title={
          valueToDisplay === 'driver'
            ? t('dispatch.job.edit_driver')
            : t('dispatch.job.edit_vendor')
        }
      >
        {React.cloneElement(customUnassignCTA, {
          onClick(event: React.MouseEvent) {
            event?.stopPropagation();
            onEditClick();
          },
        })}
      </BasicTooltip>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        ...sx,
      }}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}>
        <BasicTooltip
          title={displayName}
          {...(valueToDisplay === 'driver' ? { 'data-sentry-mask': true } : {})}
        >
          <OverflowAwareTypography>{displayName}</OverflowAwareTypography>
        </BasicTooltip>

        {canBeUnassigned ? (
          <BasicTooltip
            title={
              valueToDisplay === 'driver'
                ? t('dispatch.job.edit_driver')
                : t('dispatch.job.edit_vendor')
            }
          >
            <IconButton
              size="small"
              onClick={onEditClick}
              sx={{ color: `${theme.palette.grey[500]}` }}
              disabled={isUpdating}
            >
              <Close sx={{ fontSize: 20 }} />
            </IconButton>
          </BasicTooltip>
        ) : null}
      </Box>
    </Box>
  );
};

const AssignJobToView = ({
  companyId,
  customAssignCTA,
  customUnassignCTA,
  driver,
  editable = false,
  jobId,
  onSuccess,
  status,
  valueToDisplay,
  vendor,
}: AssignedJobToViewProps) => {
  const theme = useTheme();
  const { toasterStore, userStore } = useStores();
  const [isAssignToModalOpen, setIsAssignToModalOpen] = useState<boolean>(false);
  const [showAssignPopover, setShowAssignPopover] = useState<boolean>(false);
  const isDriverAssignmentPopoverEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.driverAssignmentPopover,
  });
  const openModal = () => {
    if (isDriverAssignmentPopoverEnabled) {
      setShowAssignPopover(true);
    } else {
      setIsAssignToModalOpen(true);
    }
  };
  const closeModal = () => {
    if (isDriverAssignmentPopoverEnabled) {
      setShowAssignPopover(false);
    } else {
      setIsAssignToModalOpen(false);
    }
  };

  const { getDriversByCompanyIdTypeahead } = useDrivers();
  const [resultsList, setResultsList] = useState<DriverBasic[]>([]);

  const fetchInternalDrivers = useCallback(
    async ({
      searchParams,
    }: {
      searchParams?: Omit<GetDriversTypeaheadParams['searchParams'], 'limit'>;
    }) => {
      const response = await getDriversByCompanyIdTypeahead({
        companyId,
        shared: false,
        searchParams: {
          limit: LIST_LIMIT,
          ...searchParams,
        },
      });

      setResultsList(response?.data || []);
    },
    [companyId, getDriversByCompanyIdTypeahead, setResultsList],
  );
  const [searchFieldValue, setSearchFieldValue] = useState('');

  useEffect(() => {
    const fetchDrivers = async () => {
      await fetchInternalDrivers({
        searchParams: {
          query: searchFieldValue,
        },
      });
    };

    fetchDrivers();
  }, []);

  const hasReadRemoveOnlyView = shouldShowReadRemoveOnlyView({
    driver,
    vendor,
    status,
    valueToDisplay,
  });

  const isJobAssigned = !!vendor || !!driver;
  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);
  const isEditable = useMemo(
    () => editable && userPermissions.canEdtJob,
    [editable, isJobAssigned],
  );

  const handleSuccess = (job: Job) => {
    closeModal();
    toasterStore.push(
      alert(t('dispatch.job.job_assigned_successfully'), AlertTypes.success),
    );

    onSuccess?.(job);
  };

  const AssignCTA = customAssignCTA ? (
    React.cloneElement(customAssignCTA, {
      onClick(event: React.MouseEvent<HTMLElement>) {
        event?.stopPropagation();
        openModal();
      },
      sx: () => ({
        ...(customAssignCTA.props.sx || {}),
        backgroundColor: showAssignPopover ? theme.brandV2.colors.treadOrange : 'inherit',
        '& > p': {
          color: showAssignPopover ? '#FFFFFF' : 'inherit',
        },
      }),
    })
  ) : (
    <Button color="primary" onClick={openModal}>
      {t('dispatch.job.assign_to')}
    </Button>
  );

  const searchBarRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {hasReadRemoveOnlyView ? (
        <ReadRemoveOnlyView
          customUnassignCTA={customUnassignCTA}
          driver={driver}
          editable={isEditable}
          onEditClick={openModal}
          status={status}
          valueToDisplay={valueToDisplay}
          vendor={vendor}
        />
      ) : (
        !isDriverAssignmentPopoverEnabled && AssignCTA
      )}
      {isDriverAssignmentPopoverEnabled && (
        <Menu
          menuTrigger={AssignCTA}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
          }}
          onOpenStateChanged={(open) => {
            setShowAssignPopover(open);
            if (!open) {
              closeModal();
            }
          }}
          sx={{
            '& .MuiList-root': {
              padding: '12px',
            },
          }}
        >
          <Box className="flex flex-col">
            <SearchBar
              ref={searchBarRef}
              focusOnMount={true}
              placeHolder="Search for a driver"
              alwaysExpanded
              setFilter={(value) => setSearchFieldValue(value)}
            />

            <Divider sx={{ '&.MuiDivider-root': { mt: '8px', mb: 0 } }} />

            {resultsList.length > 0 && (
              <Box maxHeight={FILTER_MENU_ITEM_HEIGHT_IN_PX * 5} overflow="auto">
                {resultsList.map((driver, index) => (
                  <React.Fragment key={driver.id}>
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <OverflowAwareText>{driver.fullName}</OverflowAwareText>
                      </Box>
                    </MenuItem>
                  </React.Fragment>
                ))}
              </Box>
            )}
          </Box>
        </Menu>
      )}

      <Dialog
        open={isAssignToModalOpen}
        onClick={(event) => event.stopPropagation()}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && closeModal();
        }}
        maxWidth={'md'}
        sx={{ '& .MuiPaper-root': { height: '100%' } }}
      >
        <DialogHeader
          closeCallBack={closeModal}
          title={
            <>
              <Typography component={'span'} variant={'h5'}>
                {t('dispatch.job.assign_job_to')}
              </Typography>
              <Typography component={'p'} variant={'body1'}>
                {t('dispatch.job.assign_job_form_instructions')}
              </Typography>
            </>
          }
        />
        <DialogContent
          sx={{ backgroundColor: theme.palette.grey[100], overflowY: 'hidden' }}
        >
          <AssignJobForm
            isJobAssigned={isJobAssigned}
            jobId={jobId}
            companyId={companyId}
            driver={driver}
            vendor={vendor}
            onSuccess={handleSuccess}
            onCancel={closeModal}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export { AssignJobToView };
