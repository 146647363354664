import _ from 'lodash';
import { ComponentProps, ElementType, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { getTokenProp } from '~constants/consts';
import { SystemRoles } from '~constants/enums';
import { routes } from '~router/routesPaths';
import { useStores } from '~store';

interface ProtectedRouteProps {
  canAccess?: boolean;
  element: ElementType;
  requiredRoles?: SystemRoles[];
}

export const ProtectedRoute = ({
  canAccess,
  element: Component,
  requiredRoles,
  ...rest
}: ProtectedRouteProps & ComponentProps<ElementType>) => {
  const { userStore } = useStores();
  const isAuthenticated = !!localStorage.getItem(getTokenProp);

  let isAllowed = true;

  if (requiredRoles?.length) {
    isAllowed = !!userStore.user.userRoles?.some(
      (role) => role.name === SystemRoles.PLATFORM_ADMIN,
    );
  }

  if (!_.isUndefined(canAccess)) {
    isAllowed = canAccess;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isAuthenticated ? (
        isAllowed ? (
          <Component {...rest} />
        ) : (
          <Navigate to="/" />
        )
      ) : (
        <Navigate to={`/${routes.signIn}`} />
      )}
    </Suspense>
  );
};
