import { OrderState } from '@treadinc/horizon-api-spec';
import _ from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import { Pagination } from '~interfaces';

const defaultPaginationState = {
  limit: 10,
  page: 1,
};

export type OrderJobsFilters = {
  jobStates?: string[];
};

export type Filters = {
  customerAccountIds?: string[];
  dispatchNumbers?: string[];
  driverIds?: string[];
  dropoffSites?: string[];
  endDate?: string;
  jobStates?: string[];
  orderStates?: OrderState[];
  pickUpSites?: string[];
  projectIds?: string[];
  projectsExternalIds?: string[];
  search?: string;
  startDate?: string;
  vendorAccountIds?: string[];
};

export type GetJobsByOrderQueryParams = {
  'filter[states]'?: string[];
};

export type GetCompanyOrdersQueryParams = {
  'page[after]'?: string;
  'page[before]'?: string;
  'page[limit]': number;
  'filter[job][customer_account_ids]'?: string[];
  'filter[job][dispatch_numbers]'?: string[];
  'filter[job][driver_ids]'?: string[];
  'filter[job][dropoff_site_ids]'?: string[];
  'filter[job][end_date]'?: string;
  'filter[job][external_ids]'?: string[];
  'filter[job][pickup_site_ids]'?: string[];
  'filter[job][project_ids]'?: string[];
  'filter[job][start_date]'?: string;
  'filter[job][states]'?: string[];
  'filter[job][vendor_account_ids]'?: string[];
  'filter[states]'?: Array<`${OrderState}`>;
  'search[job][datagrid]'?: string;
};

enum LiveMapStorageKeys {
  APPLIED_FILTERS = 'liveMap_appliedFilters',
}

class LiveMapStoreNew {
  filters: Filters = { orderStates: [OrderState.IN_PROGRESS], search: '' };
  hasMore: boolean = false;
  isLoadingOrders: boolean = false;
  isLoadingOrderJobs: Map<string, boolean> = new Map();
  isSavingOrder: boolean = false;
  orders: Order[] = [];
  orderJobs: Map<string, Job[]> = new Map();
  ordersPendingToShown: Order[] = [];
  pagination: Pagination = { ...defaultPaginationState };

  constructor() {
    makeObservable(this, {
      filters: observable,
      hasMore: observable,
      isLoadingOrders: observable,
      isLoadingOrderJobs: observable,
      orders: observable,
      orderJobs: observable,
      ordersPendingToShown: observable,
      pagination: observable,
    });
  }

  ordersFetchStart() {
    runInAction(() => {
      this.isLoadingOrders = true;
    });
  }

  ordersFetchEnd(orders: Order[], pagination?: Pagination) {
    runInAction(() => {
      this.isLoadingOrders = false;
      this.orders = _.uniqBy(this.orders.concat(orders), (order) => order.id);
      this.pagination = {
        limit: defaultPaginationState.limit,
        after: pagination?.after ?? '',
        page: defaultPaginationState.page,
      };
      this.hasMore = Boolean(pagination?.after);

      // Remove from the list of orders pending to be shown those that are now visible
      orders.forEach((newOrder) => {
        const indexOfOrderPendingToBeShown = this.ordersPendingToShown.findIndex(
          (pendingOrder) => {
            return pendingOrder.id === newOrder.id;
          },
        );

        if (indexOfOrderPendingToBeShown > -1) {
          this.ordersPendingToShown.splice(indexOfOrderPendingToBeShown, 1);
        }
      });
    });
  }

  orderJobsFetchStart(orderId: string) {
    runInAction(() => {
      this.isLoadingOrderJobs.set(orderId, true);
    });
  }

  orderJobsFetchEnd(orderId: string, jobs: Job[]) {
    runInAction(() => {
      this.isLoadingOrderJobs.set(orderId, false);
      this.orderJobs.set(orderId, jobs);
    });
  }

  orderUpdateEnd(order?: Order) {
    runInAction(() => {
      this.isSavingOrder = false;

      if (order) {
        this.upsertOrder(order);
      }
    });
  }

  orderMatchesCurrentFilteringCriteria(order: Order) {
    if (this.filters.orderStates?.length) {
      return Boolean(this.filters.orderStates.includes(order.state));
    }

    return true;
  }

  resetOrders(filters: Filters = {}) {
    runInAction(() => {
      this.filters = filters;
      this.hasMore = false;
      this.isLoadingOrderJobs = new Map();
      this.isLoadingOrders = false;
      this.orderJobs = new Map();
      this.orders = [];
      this.pagination = { ...defaultPaginationState };
    });
  }

  setFilters(filters: Filters, merge?: boolean) {
    this.resetOrders(merge ? { ...this.filters, ...filters } : filters);
  }

  upsertOrder(order: Order) {
    runInAction(() => {
      const index = this.orders.findIndex(({ id }) => id === order.id);

      if (index > -1) {
        this.orders.splice(index, 1, order);
      } else {
        const isMatchingFilters = this.orderMatchesCurrentFilteringCriteria(order);

        if (isMatchingFilters) {
          this.ordersPendingToShown = _.uniqBy(
            this.ordersPendingToShown.concat([order]),
            (order) => order.id,
          );
        }
      }
    });
  }

  upsertJob(job: Job) {
    if (!job.order?.id) {
      return;
    }

    const orderJobs = this.orderJobs.get(job.order.id);

    if (orderJobs) {
      const index = orderJobs.findIndex(({ id }) => id === job.id);

      runInAction(() => {
        if (index > -1) {
          orderJobs.splice(index, 1, job);
        } else {
          orderJobs.push(job);
        }
      });
    }
  }

  restoreFilters() {
    runInAction(() => {
      this.filters = { ...this.filters, ...this.retrieveStoredFilters() };
    });
  }

  retrieveStoredFilters() {
    const storedFilters = localStorage.getItem(LiveMapStorageKeys.APPLIED_FILTERS);
    const parsedFilters = (storedFilters ? JSON.parse(storedFilters) : {}) as Filters;

    return parsedFilters;
  }
}

export default LiveMapStoreNew;
