import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { useStores } from '~store/RootStore';

import { DateFilter } from './DateFilter';

export const DriverDayDateFilter = observer(function () {
  const { fetchDriverDays } = useDriverDays();
  const { driverDayStore } = useStores();

  // Filter updates trigger driver day rehydration
  useEffect(() => {
    fetchDriverDays();
  }, [JSON.stringify(driverDayStore.dateFilters)]);

  return (
    <DateFilter
      startDate={driverDayStore.dateFilters.startDate}
      endDate={driverDayStore.dateFilters.endDate}
      onDateSelect={(startDate, endDate) => {
        driverDayStore.setDateFilters(startDate, endDate);
      }}
    />
  );
});
