import { makeObservable, observable } from 'mobx';

import { SettlementLineItemGroup } from '~hooks/useSettlementLineItems';

export default class SettlementLineItemsStore {
  settlementLineItemGroupsBySettlementId: Record<string, SettlementLineItemGroup[]> = {};

  constructor() {
    makeObservable(this, {
      settlementLineItemGroupsBySettlementId: observable,
    });
  }

  setSettlementLineItemGroupsBySettlementId(
    settlementId: string,
    settlementLineItemGroups: SettlementLineItemGroup[],
  ) {
    this.settlementLineItemGroupsBySettlementId[settlementId] = [
      ...settlementLineItemGroups,
    ];
  }
}
