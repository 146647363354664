import { Document, Page, StyleSheet, View } from '@react-pdf/renderer';

import Footer from './Footer';
import Heading, { HeadingProps } from './Heading';
import LineItems, { LineItemsProps } from './LineItems';
import Totals, { TotalsProps } from './Totals';

interface SettlementPDFProps extends HeadingProps, LineItemsProps, TotalsProps {}

export default function SettlementPDF({
  company,
  forCompany,
  lineItemGroups,
  settlement,
}: SettlementPDFProps) {
  const styles = StyleSheet.create({
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 10,
      paddingHorizontal: 15,
      paddingVertical: 20,
      position: 'relative',
    },
    heading: {
      width: '100%',
    },
    lineItems: {
      marginTop: 20,
      width: '100%',
    },
    totals: {
      marginLeft: 'auto',
      width: '40%',
    },
    footer: {
      position: 'absolute',
      bottom: 20,
      left: 15,
      right: 15,
    },
    hiddenFooter: {
      marginTop: 30,
      opacity: 0,
    },
  });

  return (
    <Document>
      <Page size="LETTER" style={styles.root} dpi={72}>
        <View style={styles.heading}>
          <Heading company={company} settlement={settlement} forCompany={forCompany} />
        </View>

        <View style={styles.lineItems}>
          <LineItems lineItemGroups={lineItemGroups} settlement={settlement} />
        </View>

        <View style={styles.totals}>
          <Totals lineItemGroups={lineItemGroups} />
        </View>

        <View style={styles.footer} fixed>
          <Footer company={company} />
        </View>

        {/* Non-visible footer whose sole purpose is to push up the content and prevent it
        from overlapping with the real footer (which is absolutely positioned) */}
        <View style={styles.hiddenFooter} fixed>
          <Footer company={company} />
        </View>
      </Page>
    </Document>
  );
}
