import { t } from 'i18next';
import { useSearchParams } from 'react-router-dom';

import { useLogin } from '~hooks/useLogin';

import SignUpResetForm from './SignUpResetForm';
import { ResetSignupProps } from './types';

const tokenUrl = 'token';

const SignUp = () => {
  const { signUp, isLoading } = useLogin();
  const [searchParams] = useSearchParams();
  const token = searchParams.get(tokenUrl)!;
  const handleSubmitSignUp = (data: ResetSignupProps) => {
    signUp({ magic_links_token: token, password: data.password });
  };
  return (
    <SignUpResetForm
      formTitle={t('actions.signUp')}
      callBackAction={handleSubmitSignUp}
      isLoading={isLoading}
    ></SignUpResetForm>
  );
};
export default SignUp;
