import Check from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FilterList from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AppliedFiltersChips, {
  AppliedFiltersChipsCollectedData,
} from '~components/Filters/AppliedFiltersChips';
import FilterMenuItem, {
  FilterMenuItemLoadingReason,
  FilterMenuOption,
} from '~components/Filters/FilterMenuItem';
import useFetchers, {
  FetchDriversFunction,
  FetchFunction,
} from '~components/Filters/useFetchers';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { TypeaheadedFilterState } from '~hooks/useLiveMap/useLiveMapFilters';
import { DriversRequestCursors } from '~pages/Dispatch/hooks/useOrdersDispatchFilters';
import { NextPageCursorsState } from '~pages/LiveMap/SideBarComponents/LiveMapFilters';
import { DriverDayPageFilterKey } from '~store/DriverDayStore';
import { useStores } from '~store/RootStore';

enum SelectedFilter {
  CUSTOMERS = 'customers',
  DRIVERS = 'drivers',
  VENDORS = 'vendors',
}

const driverDayFilterKeyBySelectedFilter: Record<SelectedFilter, DriverDayPageFilterKey> =
  {
    [SelectedFilter.CUSTOMERS]: 'customerAccountIds',
    [SelectedFilter.DRIVERS]: 'driverIds',
    [SelectedFilter.VENDORS]: 'vendorAccountIds',
  };

type AvailableOptionsByFilter = Record<SelectedFilter, TypeaheadedFilterState>;

const availableOptionsInitialState = Object.values(SelectedFilter).reduce(
  (obj, filter) => {
    obj[filter] = {
      loading: false,
      options: [],
      searchValue: '',
    };

    return obj;
  },
  {} as AvailableOptionsByFilter,
);

export const DriverDayMainFilters = observer(() => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchor);
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>();
  const [availableOptions, setAvailableOptions] = useState(availableOptionsInitialState);
  const [nextPageCursors, setNextPageCursors] = useState<NextPageCursorsState>({});

  const { fetchDriverDays } = useDriverDays();
  const { userStore, driverDayStore } = useStores();
  const companyId = userStore.userCompany?.id;
  const fetchers = useFetchers(companyId);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFilter(undefined);
    }
  }, [isOpen]);

  // Filter updates trigger driver day rehydration
  useEffect(() => {
    fetchDriverDays();
  }, [JSON.stringify(driverDayStore.filters)]);

  // Kickoff initial hydration of a filter option
  useEffect(() => {
    if (selectedFilter) {
      setAvailableOptions((state) => ({
        ...state,
        [selectedFilter]: {
          loading: FilterMenuItemLoadingReason.SEARCH_VALUE,
          options: [],
          searchValue: '',
        },
      }));
    }
  }, [selectedFilter]);

  const fetchersBySelectedFilter = useMemo(
    () => ({
      [SelectedFilter.CUSTOMERS]: fetchers.fetchCustomers,
      [SelectedFilter.DRIVERS]: fetchers.fetchDrivers,
      [SelectedFilter.VENDORS]: fetchers.fetchVendors,
    }),
    [fetchers.fetchCustomers, fetchers.fetchDrivers, fetchers.fetchVendors],
  );

  const applySearchResponse = useCallback(
    (key: SelectedFilter, data: FilterMenuOption[]) => {
      setAvailableOptions((state) => ({
        ...state,
        [key]: {
          ...state[key],
          loading: false,
          options: _.uniqBy([...state[key].options, ...data], (i) => i.value),
        },
      }));
    },
    [setAvailableOptions],
  );

  // All filter option rehydration is triggered by setting the loading attribute
  useEffect(() => {
    if (selectedFilter && availableOptions[selectedFilter].loading) {
      const fetcher = fetchersBySelectedFilter[selectedFilter];

      if (selectedFilter === SelectedFilter.DRIVERS) {
        const { searchValue, sharedDriversCursor, nonSharedDriversCursor } =
          availableOptions[selectedFilter];

        (fetcher as FetchDriversFunction)(
          searchValue,
          sharedDriversCursor,
          nonSharedDriversCursor,
        ).then((response) => {
          setNextPageCursors((state) => ({
            ...state,
            [selectedFilter]: {
              sharedDriversCursor: response.nextSharedDriversPageCursor,
              nonSharedDriversCursor: response.nextNonSharedDriversPageCursor,
            },
          }));
          applySearchResponse(selectedFilter, response.data);
        });
      } else {
        const { searchValue, cursor } = availableOptions[selectedFilter];

        (fetcher as FetchFunction)(searchValue, cursor as string).then((response) => {
          setNextPageCursors((state) => ({
            ...state,
            [selectedFilter]: response.nextPageCursor,
          }));
          applySearchResponse(selectedFilter, response.data);
        });
      }
    }
  }, [
    selectedFilter,
    fetchersBySelectedFilter,
    availableOptions.customers.loading,
    availableOptions.drivers.loading,
    availableOptions.vendors.loading,
  ]);

  const handleSearchValueChange = useCallback(
    (value: string) => {
      setAvailableOptions((state) => ({
        ...state,
        [selectedFilter as SelectedFilter]: {
          loading: FilterMenuItemLoadingReason.SEARCH_VALUE,
          options: [],
          searchValue: value,
        },
      }));
    },
    [selectedFilter, setAvailableOptions],
  );

  const handleSelectedOptionsChange = useCallback(
    (selectedOptions: string[]) => {
      driverDayStore.updateFilter(
        driverDayFilterKeyBySelectedFilter[selectedFilter as SelectedFilter],
        selectedOptions.length > 0 ? selectedOptions : undefined,
      );
    },
    [selectedFilter, driverDayStore.updateFilter],
  );

  const fetchMoreDriverOptions = useCallback(() => {
    const driversRequestCursors = nextPageCursors.drivers as DriversRequestCursors;
    setAvailableOptions((state) => ({
      ...state,
      drivers: {
        ...state.drivers,
        sharedDriversCursor: driversRequestCursors.sharedDriversCursor,
        nonSharedDriversCursor: driversRequestCursors.nonSharedDriversCursor,
        loading: FilterMenuItemLoadingReason.INFINITE_SCROLL,
      },
    }));
  }, [nextPageCursors.drivers, setAvailableOptions]);

  const fetchMoreOptions = useCallback(() => {
    // This will not occur, but we need type refinement, because selectedFilter can always be empty, and this is explicit
    if (!selectedFilter) {
      return;
    }
    setAvailableOptions((state) => ({
      ...state,
      [selectedFilter]: {
        ...state[selectedFilter],
        cursor: nextPageCursors[selectedFilter],
        loading: FilterMenuItemLoadingReason.INFINITE_SCROLL,
      },
    }));
  }, [selectedFilter, nextPageCursors, setAvailableOptions]);

  const isFetchMoreEmpty = useMemo(() => {
    if (!selectedFilter) {
      return true;
    }
    const allDriversPagesFetched =
      'sharedDriversCursor' in availableOptions.drivers &&
      _.isUndefined(availableOptions.drivers.sharedDriversCursor) &&
      'nonSharedDriversCursor' in availableOptions.drivers &&
      _.isUndefined(availableOptions.drivers.nonSharedDriversCursor);

    const allOtherPagesFetched =
      'cursor' in availableOptions[selectedFilter] &&
      _.isUndefined(availableOptions[selectedFilter].cursor);

    if (
      (selectedFilter === SelectedFilter.DRIVERS && allDriversPagesFetched) ||
      (selectedFilter !== SelectedFilter.DRIVERS && allOtherPagesFetched)
    ) {
      return true;
    }
    return false;
  }, [selectedFilter, availableOptions]);

  const onFetchMore = useMemo(() => {
    if (isFetchMoreEmpty) {
      return undefined;
    }
    if (selectedFilter === SelectedFilter.DRIVERS) {
      return fetchMoreDriverOptions;
    } else {
      return fetchMoreOptions;
    }
  }, [isFetchMoreEmpty, fetchMoreDriverOptions, fetchMoreOptions]);

  /* Chips configuration */
  const filterChipsData = useMemo(() => {
    const appliedFilters = _.pick(
      driverDayStore.filters,
      Object.values(driverDayFilterKeyBySelectedFilter),
    );

    const collectedData = Object.entries(availableOptions).reduce((obj, [key, val]) => {
      const pageFilterKey = driverDayFilterKeyBySelectedFilter[key as SelectedFilter];
      obj[pageFilterKey] = val.options;
      return obj;
    }, {} as AppliedFiltersChipsCollectedData);
    return { appliedFilters, collectedData };
  }, [JSON.stringify(driverDayStore.filters), JSON.stringify(availableOptions)]);

  const hasFiltersApplied = useMemo(
    () =>
      Object.values(filterChipsData.appliedFilters).filter((value) => {
        return Boolean(value && value.length > 0);
      }).length > 0,
    [filterChipsData.appliedFilters],
  );

  const handleRemoveAllFilters = useCallback(() => {
    driverDayStore.resetFilters();
  }, []);

  const handleRemoveOneFilter = useCallback((filter: string) => {
    driverDayStore.updateFilter(filter as DriverDayPageFilterKey, undefined);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <SmallButton
        onClick={(e) => setAnchor(e.currentTarget)}
        size="small"
        startIcon={<FilterList />}
        sx={(theme) => ({
          '&.MuiButton-root': {
            backgroundColor: 'white',
            border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            ...(hasFiltersApplied
              ? {
                  borderBottomLeftRadius: theme.brandV2.borderRadius,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: theme.brandV2.borderRadius,
                  borderTopRightRadius: 0,
                }
              : { borderRadius: theme.brandV2.borderRadius }),
          },
        })}
      >
        {t('common.filters')}
      </SmallButton>
      <Menu
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        open={isOpen}
        sx={(theme) => ({
          '& .MuiPaper-root': {
            border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            boxShadow: '0px 1px 10px 0px rgba(60, 64, 67, 0.15)',
            mt: '3px',
            width: '250px',
          },
          '& .MuiItem-root': {
            px: theme.spacing(1.5),
          },
        })}
      >
        {selectedFilter && (
          <FilterMenuItem
            focusSearchFieldOnMount
            searchValue={availableOptions[selectedFilter].searchValue}
            onSearchValueChange={handleSearchValueChange}
            loadingReason={availableOptions[selectedFilter].loading || undefined}
            selectAllOptionLabel={`${t('dispatch.dispatch_v2.filters.all_entities', { entity: t(`dispatch.dispatch_v2.filters.${_.snakeCase(selectedFilter)}`) })}`}
            options={availableOptions[selectedFilter].options}
            selectedOptions={
              driverDayStore.filters[
                driverDayFilterKeyBySelectedFilter[selectedFilter]
              ] ?? []
            }
            onSelectedOptionsChange={handleSelectedOptionsChange}
            onFetchMore={onFetchMore}
          />
        )}

        {!selectedFilter &&
          Object.values(SelectedFilter).map((filter) => (
            <MenuItem key={filter} onClick={() => setSelectedFilter(filter)}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={(theme) => ({ color: theme.brandV2.colors.treadBlack })}
                >
                  {t(`dispatch.dispatch_v2.filters.${_.snakeCase(filter)}`)}
                </Typography>

                <Box display="flex" alignItems="center" gap={1}>
                  {(
                    driverDayStore.filters[driverDayFilterKeyBySelectedFilter[filter]] ??
                    []
                  ).length > 0 && (
                    <Check
                      sx={{
                        fontSize: '16px',
                        color: (theme) => theme.brandV2.colors.treadOrange,
                      }}
                    />
                  )}

                  <ChevronRight sx={{ fontSize: '16px' }} />
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Menu>
      <AppliedFiltersChips
        {...filterChipsData}
        onRemoveAllFilters={handleRemoveAllFilters}
        onRemoveFilter={handleRemoveOneFilter}
      />
    </Box>
  );
});
