export const autoAcceptOrdersOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const connectionTypeOptions = [
  { value: true, label: 'Connected Account' },
  { value: false, label: 'Account' },
];

export const inviteTypeOptions = ['email', 'sms'];
