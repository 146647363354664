import {
  Equipment_Read_Typeahead,
  Job_Read,
  Job_Update,
  Order_Read,
  UserBulkAssignJobsChannel_Read,
  UserBulkCopyAssignmentsChannel_Read,
  UserBulkSendJobsChannel_Read,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';

import { API_VERSION } from '~constants/consts';
import { Job } from '~hooks/useJob';
import { OrderFormSchemaInterface } from '~pages/Sales/Orders/orderFormSchema';
import connection from '~services/connectionModule';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';
import {
  GetCompanyOrdersQueryParams,
  GetJobsByOrderQueryParams,
} from '~store/OrdersDispatchStore';

import { EquipmentTypeahead } from '../useEquipment';
import { JobEventType } from '../useJob/useJob';
import { Order } from './models';
import { OrderEventType } from './useOrders';

type GetJobsByOrderQueryParamsWithAfterLink = GetJobsByOrderQueryParams & {
  'page[after]'?: string;
};

type GetCompanyEquipmentsTypeaheadQueryParams = {
  'filter[dispatchable]': boolean;
  'filter[shared]'?: boolean;
  'page[after]'?: string;
  'page[limit]': number;
  'search[query]'?: string;
};

export default function useOrdersDispatch() {
  const { ordersDispatchStore } = useStores();

  const acceptJob = async (jobId: string) => {
    ordersDispatchStore.orderJobAcceptStart(jobId);

    const event: JobEventType = 'accept';

    try {
      const response = await connection.put<Job_Read>(
        `${API_VERSION}/jobs/${jobId}/${event}`,
        {},
        {},
        t('error_messages.jobs.failed_to_do_event', { event }) as string,
      );

      const acceptedJob = Job.parse(response);
      ordersDispatchStore.orderJobAcceptEnd(jobId, acceptedJob);

      return acceptedJob;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to accept job');
    } finally {
      ordersDispatchStore.orderJobAcceptEnd(jobId);
    }
  };

  const acceptOrder = async (orderId: string) => {
    ordersDispatchStore.orderAcceptStart(orderId);

    const event: OrderEventType = 'accept';

    try {
      const response = await connection.put<Order_Read>(
        `${API_VERSION}/orders/${orderId}/${event}`,
        {},
        {},
        t('error_messages.orders.failed_to_do_order_event', { event }) as string,
      );

      const acceptedOrder = Order.parse(response);
      ordersDispatchStore.orderAcceptEnd(acceptedOrder);

      return acceptedOrder;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to accept order');
    } finally {
      ordersDispatchStore.orderAcceptEnd();
    }
  };

  const bulkAssignJobs = async (jobIds: string[], vendorAccountId: string) => {
    try {
      await connection.put(
        `${API_VERSION}/jobs/bulk_assign`,
        {
          job_ids: jobIds,
          vendor_account_id: vendorAccountId,
        },
        {},
        t('error_messages.jobs.failed_to_bulk_assign_jobs') as string,
      );
    } catch (error) {
      console.error(error);
      throw new Error('Unable to bulk assign jobs');
    }
  };

  const bulkCopyAssignments = async (
    jobIds: string[],
    orderId: string,
    deepCopy?: boolean,
  ) => {
    try {
      await connection.put(
        `${API_VERSION}/jobs/bulk_copy_assignments`,
        {
          deep_copy: deepCopy,
          job_ids: jobIds,
          order_id: orderId,
        },
        {},
        t('error_messages.jobs.failed_to_bulk_copy_assignments') as string,
      );
    } catch (error) {
      console.error(error);
      throw new Error('Unable to bulk assign jobs');
    }
  };

  const bulkSendJobs = async (jobIds: string[], orderIds: string[]) => {
    try {
      await connection.put(
        `${API_VERSION}/jobs/bulk_send`,
        {
          job_ids: jobIds,
          order_ids: orderIds,
        },
        {},
        t('error_messages.jobs.failed_to_bulk_send_jobs') as string,
      );
    } catch (error) {
      console.error(error);
      throw new Error('Unable to bulk send jobs');
    }
  };

  const cancelJob = async (jobId: string) => {
    ordersDispatchStore.orderJobCancelStart(jobId);

    const event: JobEventType = 'cancel';

    try {
      const response = await connection.put<Job_Read>(
        `${API_VERSION}/jobs/${jobId}/${event}`,
        {},
        {},
        t('error_messages.jobs.failed_to_do_event', { event }) as string,
      );

      const cancelledJob = Job.parse(response);
      ordersDispatchStore.orderJobCancelEnd(cancelledJob);

      return cancelledJob;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to cancel job');
    } finally {
      ordersDispatchStore.orderJobCancelEnd();
    }
  };

  const cancelOrder = async (orderId: string) => {
    ordersDispatchStore.orderCancelStart();

    const event: OrderEventType = 'cancel';

    try {
      const response = await connection.put<Order_Read>(
        `${API_VERSION}/orders/${orderId}/${event}`,
        {},
        {},
        t('error_messages.orders.failed_to_do_order_event', { event }) as string,
      );

      const cancelledOrder = Order.parse(response);
      ordersDispatchStore.orderCancelEnd(cancelledOrder);

      return cancelledOrder;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to cancel order');
    } finally {
      ordersDispatchStore.orderCancelEnd();
    }
  };

  const cloneOrder = async (orderId: string, includeAssignees?: boolean) => {
    ordersDispatchStore.orderCloneStart();

    try {
      const response = await connection.post<Order_Read>(
        `${API_VERSION}/orders/${orderId}/copy`,
        { include_assignee: Boolean(includeAssignees) },
        {},
        t('error_messages.orders.failed_to_duplicate') as string,
      );

      const clonedOrder = Order.parse(response);
      ordersDispatchStore.orderCloneEnd(clonedOrder);

      return clonedOrder;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to duplicate order');
    } finally {
      ordersDispatchStore.orderCloneEnd();
    }
  };

  const completeOrder = async (orderId: string) => {
    ordersDispatchStore.orderCompleteStart();

    const event: OrderEventType = 'complete';

    try {
      const response = await connection.put<Order_Read>(
        `${API_VERSION}/orders/${orderId}/${event}`,
        {},
        {},
        t('error_messages.orders.failed_to_do_order_event', { event }) as string,
      );

      const completedOrder = Order.parse(response);
      ordersDispatchStore.orderCompleteEnd(completedOrder);

      return completedOrder;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to complete order');
    } finally {
      ordersDispatchStore.orderCompleteEnd();
    }
  };

  const createJobFromOrder = async (orderId: string) => {
    ordersDispatchStore.jobCreateFromOrderStart(orderId);

    try {
      const response = await connection.post<Job_Read>(
        `${API_VERSION}/orders/${orderId}/jobs`,
        {},
        {},
        t('error_messages.jobs.failed_to_create') as string,
      );

      const createdJob = Job.parse(response);
      ordersDispatchStore.jobCreateFromOrderEnd(orderId, createdJob);

      return createdJob;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to create job from order');
    } finally {
      ordersDispatchStore.jobCreateFromOrderEnd(orderId);
    }
  };

  const createOrder = async (order: OrderFormSchemaInterface) => {
    ordersDispatchStore.orderCreateStart();

    try {
      const response = await connection.post<Order_Read>(
        `${API_VERSION}/orders`,
        Order.deparse(order),
        {},
        t('error_messages.orders.failed_to_create') as string,
      );

      const createdOrder = Order.parse(response);
      ordersDispatchStore.orderCreateEnd(createdOrder);

      return createdOrder;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to create order');
    } finally {
      ordersDispatchStore.orderCreateEnd();
    }
  };

  const duplicateJob = async (jobId: string) => {
    ordersDispatchStore.orderJobDuplicateStart(jobId);

    try {
      const response = await connection.post<Job_Read>(
        `${API_VERSION}/jobs/${jobId}/copy`,
        {},
        {},
        t('error_messages.jobs.failed_to_duplicate') as string,
      );

      const job = Job.parse(response);
      ordersDispatchStore.orderJobDuplicateEnd(job);

      return job;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to duplicate job');
    } finally {
      ordersDispatchStore.orderJobDuplicateEnd();
    }
  };

  const getCompanyEquipmentsTypeahead = async (
    companyId: string,
    query: string,
    pagination: { after?: string; limit: number },
    shared?: boolean,
  ) => {
    const params: GetCompanyEquipmentsTypeaheadQueryParams = {
      'page[limit]': pagination.limit,
      'filter[dispatchable]': true,
      'search[query]': query || undefined,
    };

    if (shared && pagination.after) {
      params['page[after]'] = pagination.after;
    } else if (pagination.after) {
      params['page[after]'] = pagination.after;
    }

    if (!_.isUndefined(shared)) {
      params['filter[shared]'] = shared;
    }

    try {
      const response = await connection.getPaginated<Equipment_Read_Typeahead>(
        `${API_VERSION}/companies/${companyId}/equipment/typeahead`,
        { params },
        t('error_messages.equipment.failed_to_fetch') as string,
      );

      const equipments = response.data.map((equipment) => {
        return EquipmentTypeahead.parse(equipment);
      });

      return { data: equipments, pagination: { after: response.pagination.after } };
    } catch (error) {
      console.error(error);
      throw new Error('Unable to fetch equipment');
    }
  };

  const getCompanyOrdersForCopyVendorAssignment = async () => {
    ordersDispatchStore.copyVendorAssignmentsOrdersFetchStart();

    const {
      copyVendorAssignmentsPagination: pagination,
      copyVendorAssignmentsFilters: filters,
    } = ordersDispatchStore;

    const params: GetCompanyOrdersQueryParams = {
      'page[limit]': pagination.limit,
    };

    if (pagination.before) {
      params['page[before]'] = pagination.before;
    } else if (pagination.after) {
      params['page[after]'] = pagination.after;
    }

    if (filters?.startDate?.length) {
      params['filter[job][start_date]'] = filters.startDate;
    }

    if (filters?.endDate?.length) {
      params['filter[job][end_date]'] = filters.endDate;
    }

    if (filters?.customerAccountIds?.length) {
      params['filter[job][customer_account_ids]'] = filters.customerAccountIds;
    }

    if (filters?.search?.trim().length) {
      params['search[job][datagrid]'] = filters.search.trim();
    }

    try {
      const response = await connection.getPaginated<Order_Read>(
        `${API_VERSION}/orders/dispatch`,
        { params },
        t('error_messages.orders.failed_to_fetch') as string,
      );

      const orders = response.data.map((order) => Order.parse(order));
      ordersDispatchStore.copyVendorAssignmentsOrdersFetchEnd(
        orders,
        response.pagination,
      );
    } catch (error) {
      ordersDispatchStore.copyVendorAssignmentsOrdersFetchEnd([]);
      console.error(error);
      throw new Error('Unable to fetch orders');
    }
  };

  const getCompanyOrders = async () => {
    ordersDispatchStore.ordersFetchStart();

    const { pagination, filters } = ordersDispatchStore;

    const params: GetCompanyOrdersQueryParams = {
      'page[limit]': pagination.limit,
    };

    if (pagination.before) {
      params['page[before]'] = pagination.before;
    } else if (pagination.after) {
      params['page[after]'] = pagination.after;
    }

    if (filters?.search?.trim().length) {
      params['search[job][datagrid]'] = filters.search.trim();
    }

    if (filters?.customerAccountIds?.length) {
      params['filter[job][customer_account_ids]'] = filters.customerAccountIds;
    }

    if (filters?.dispatchNumbers?.length) {
      params['filter[job][dispatch_numbers]'] = filters.dispatchNumbers;
    }

    if (filters?.driverIds?.length) {
      params['filter[job][driver_ids]'] = filters.driverIds;
    }

    if (filters?.projectsExternalIds?.length) {
      params['filter[job][external_ids]'] = filters.projectsExternalIds;
    }

    if (filters?.projectIds?.length) {
      params['filter[job][project_ids]'] = filters.projectIds;
    }

    if (filters?.vendorAccountIds?.length) {
      params['filter[job][vendor_account_ids]'] = filters.vendorAccountIds;
    }

    if (filters?.orderStates?.length) {
      params['filter[states]'] = filters.orderStates;
    }

    if (filters?.startDate?.length) {
      params['filter[job][start_date]'] = filters.startDate;
    }

    if (filters?.endDate?.length) {
      params['filter[job][end_date]'] = filters.endDate;
    }

    if (filters?.jobStates?.length) {
      params['filter[job][states]'] = filters.jobStates;
    }

    if (filters?.pickUpSites?.length) {
      params['filter[job][pickup_site_ids]'] = filters.pickUpSites;
    }

    if (filters?.dropoffSites?.length) {
      params['filter[job][dropoff_site_ids]'] = filters.dropoffSites;
    }

    try {
      const response = await connection.getPaginated<Order_Read>(
        `${API_VERSION}/orders/dispatch`,
        { params },
        t('error_messages.orders.failed_to_fetch') as string,
      );

      const orders = response.data.map((order) => Order.parse(order));
      ordersDispatchStore.ordersFetchEnd(orders, response.pagination);
    } catch (error) {
      ordersDispatchStore.ordersFetchEnd([]);
      console.error(error);
      throw new Error('Unable to fetch orders');
    }
  };

  const getJobsByOrder = async (orderId: string) => {
    ordersDispatchStore.orderJobsFetchStart(orderId);

    const getPage = (params: GetJobsByOrderQueryParamsWithAfterLink) => {
      return connection.getPaginated<Job_Read>(
        `${API_VERSION}/orders/${orderId}/jobs`,
        { params },
        t('error_messages.orders.failed_to_fetch_jobs_by_order') as string,
      );
    };

    let jobs: Job[] = [];
    let shouldFetchNextPage = true;

    const { filters } = ordersDispatchStore;

    const params: GetJobsByOrderQueryParamsWithAfterLink = {};

    if (filters?.jobStates?.length) {
      params['filter[states]'] = filters.jobStates;
    }

    try {
      while (shouldFetchNextPage) {
        const response = await getPage(params);

        const parsedJobs = response.data.map((job) => Job.parse(job));
        jobs = jobs.concat(parsedJobs);

        shouldFetchNextPage = Boolean(response.pagination.after);

        if (shouldFetchNextPage) {
          params['page[after]'] = response.pagination.after;
        }
      }

      ordersDispatchStore.orderJobsFetchEnd(orderId, jobs);
    } catch (error) {
      ordersDispatchStore.orderJobsFetchEnd(orderId, []);
      console.error(error);
      throw new Error('Unable to fetch jobs by order');
    }
  };

  const rejectJob = async (jobId: string) => {
    ordersDispatchStore.orderJobRejectStart(jobId);

    const event: JobEventType = 'reject';

    try {
      const response = await connection.put<Job_Read>(
        `${API_VERSION}/jobs/${jobId}/${event}`,
        {},
        {},
        t('error_messages.jobs.failed_to_do_event', { event }) as string,
      );

      const rejectedJob = Job.parse(response);
      ordersDispatchStore.orderJobRejectEnd(jobId, rejectedJob);

      return rejectedJob;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to reject job');
    } finally {
      ordersDispatchStore.orderJobRejectEnd(jobId);
    }
  };

  const rejectOrder = async (orderId: string) => {
    ordersDispatchStore.orderRejectStart(orderId);

    const event: OrderEventType = 'cancel';

    try {
      const response = await connection.put<Order_Read>(
        `${API_VERSION}/orders/${orderId}/${event}`,
        {},
        {},
        t('error_messages.orders.failed_to_do_order_event', { event }) as string,
      );

      const rejectedOrder = Order.parse(response);
      ordersDispatchStore.orderRejectEnd(rejectedOrder);

      return rejectedOrder;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to reject order');
    } finally {
      ordersDispatchStore.orderRejectEnd();
    }
  };

  const sendJob = async (jobId: string) => {
    ordersDispatchStore.orderJobSendStart(jobId);

    const event: JobEventType = 'request';

    try {
      const response = await connection.put<Job_Read>(
        `${API_VERSION}/jobs/${jobId}/${event}`,
        {},
        {},
        t('error_messages.jobs.failed_to_do_event', { event }) as string,
      );

      const sentJob = Job.parse(response);
      ordersDispatchStore.orderJobSendEnd(jobId, sentJob);

      return sentJob;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to send job');
    } finally {
      ordersDispatchStore.orderJobSendEnd(jobId);
    }
  };

  const subscribeToBulkAssignJobsRTU = (
    companyId: string,
    onUpdateReceived: (data: UserBulkAssignJobsChannel_Read) => void,
  ) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.UserBulkAssignJobsChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: onUpdateReceived,
        },
      );
    }

    return subscription;
  };

  const subscribeToBulkCopyAssignmentsRTU = (
    companyId: string,
    onUpdateReceived: (data: UserBulkCopyAssignmentsChannel_Read) => void,
  ) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.UserBulkCopyAssignmentsChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: onUpdateReceived,
        },
      );
    }

    return subscription;
  };

  const subscribeToBulkSendJobsRTU = (
    companyId: string,
    onUpdateReceived: (data: UserBulkSendJobsChannel_Read) => void,
  ) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.UserBulkSendJobsChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: onUpdateReceived,
        },
      );
    }

    return subscription;
  };

  const subscribeToJobsRTU = (companyId: string) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.CompanyJobUpdateChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: ({ data }: { data: Job_Read }) => {
            const job = Job.parse(data);

            ordersDispatchStore.upsertJob(job);
          },
        },
      );
    }

    return subscription;
  };

  const subscribeToOrdersRTU = (companyId: string) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.CompanyOrderUpdateChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: ({ data }: { data: Order_Read }) => {
            const order = Order.parse(data);

            ordersDispatchStore.orderUpdateEnd(order);
          },
        },
      );
    }

    return subscription;
  };

  const updateJobEquipment = async (
    jobId: string,
    equipmentId?: string | null,
    additionalEquipmentIds?: string[],
  ) => {
    const isUpdatingEquipment = !_.isUndefined(equipmentId);
    const isUpdatingAdditionalEquipment = !_.isUndefined(additionalEquipmentIds);

    if (!isUpdatingEquipment && !isUpdatingAdditionalEquipment) {
      throw new Error('Please define either equipment or additional equipment.');
    }

    ordersDispatchStore.orderJobUpdateStart(jobId);

    const payload: Job_Update = {};

    if (isUpdatingEquipment) {
      payload.equipment_id = equipmentId;
    }

    if (isUpdatingAdditionalEquipment) {
      payload.additional_equipment_ids = additionalEquipmentIds;
    }

    try {
      const response = await connection.patch<Job_Read>(
        `${API_VERSION}/jobs/${jobId}`,
        payload,
        {},
        t('error_messages.jobs.failed_to_update') as string,
      );
      const updatedJob = Job.parse(response);
      ordersDispatchStore.orderJobUpdateEnd(jobId, updatedJob);

      return updatedJob;
    } catch (error) {
      ordersDispatchStore.orderJobUpdateEnd(jobId);
      console.error(error);
      throw new Error('Unable to update job');
    }
  };

  const updateOrder = async (order: OrderFormSchemaInterface) => {
    ordersDispatchStore.orderUpdateStart();

    try {
      const response = await connection.patch<Order_Read>(
        `${API_VERSION}/orders/${order.id}`,
        Order.deparseUpdate(order),
        {},
        t('error_messages.orders.failed_to_update') as string,
      );

      const updatedOrder = Order.parse(response);
      ordersDispatchStore.orderUpdateEnd(updatedOrder);

      return updatedOrder;
    } catch (error) {
      ordersDispatchStore.orderUpdateEnd();
      console.error(error);
      throw new Error('Unable to update order');
    }
  };

  return {
    acceptJob,
    acceptOrder,
    bulkAssignJobs,
    bulkCopyAssignments,
    bulkSendJobs,
    cancelJob,
    cancelOrder,
    cloneOrder,
    completeOrder,
    createJobFromOrder,
    createOrder,
    duplicateJob,
    getCompanyEquipmentsTypeahead,
    getCompanyOrders,
    getCompanyOrdersForCopyVendorAssignment,
    getJobsByOrder,
    rejectJob,
    rejectOrder,
    sendJob,
    subscribeToBulkAssignJobsRTU,
    subscribeToBulkCopyAssignmentsRTU,
    subscribeToBulkSendJobsRTU,
    subscribeToJobsRTU,
    subscribeToOrdersRTU,
    updateJobEquipment,
    updateOrder,
  };
}
