import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React from 'react';

interface Props {
  title: string;
  action: () => void;
  disabled?: boolean;
  sx?: SxProps;
}
const PseudoLink = ({ title, action, disabled = false, sx = {} }: Props) => {
  const theme = useTheme();
  return (
    <Typography
      component={'span'}
      onClick={action}
      sx={{
        display: 'block',
        ...sx,
      }}
    >
      <Typography
        component={'span'}
        variant={'body2'}
        sx={{
          cursor: 'pointer',
          textDecoration: 'underline',
          color: !disabled ? theme.palette.info.main : theme.palette.text.disabled,

          '&:hover': {
            textDecoration: 'none',
            textDecorationStyle: 'none',
          },
        }}
      >
        {title}
      </Typography>
    </Typography>
  );
};

export { PseudoLink };
