import { InvoiceLineItem_Read } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { JobLoadBasic } from '~hooks/useJob/models';
import { Nullable } from '~types/Nullable';

export class InvoiceLineItem {
  constructor(
    private _id: Nullable<string>,
    private _quantity: number,
    private _rate: number,
    private _rate_type: string,
    private _total_amount: number,
    private _billing_adjustment_minutes: number,
    private _pay_start_time: Nullable<Dayjs>,
    private _pay_end_time: Nullable<Dayjs>,
    private _invoice_id: string,
    private _load: Nullable<JobLoadBasic>,
    private _name: Nullable<string>,
  ) {}

  public get id() {
    return this._id;
  }

  public get quantity() {
    return this._quantity;
  }

  public get rate() {
    return this._rate;
  }

  public get rateType() {
    return this._rate_type;
  }

  public get totalAmount() {
    return this._total_amount;
  }

  public get billingAdjustmentMinutes() {
    return this._billing_adjustment_minutes;
  }

  public get payStartTime() {
    return this._pay_start_time;
  }

  public get payEndTime() {
    return this._pay_end_time;
  }

  public get invoiceId() {
    return this._invoice_id;
  }

  public get load() {
    return this._load;
  }

  public get name() {
    return this._name;
  }

  public static parse(proto: InvoiceLineItem_Read) {
    return new InvoiceLineItem(
      proto.id,
      Number(proto.quantity),
      Number(proto.rate),
      proto.rate_type,
      Number(proto.total_amount),
      proto.billing_adjustment_minutes,
      proto.pay_start_time ? dayjs(proto.pay_start_time) : null,
      proto.pay_end_time ? dayjs(proto.pay_end_time) : null,
      proto.invoice_id,
      proto.load ? JobLoadBasic.parse(proto.load) : null,
      proto.name,
    );
  }

  public static deparseUpdate(proto: Partial<InvoiceLineItem & { isDeleted?: boolean }>) {
    const deparsed: Partial<InvoiceLineItem_Read & { _destroy?: 1 }> = {};

    deparsed.id = proto.id ?? undefined;

    if (proto.isDeleted) {
      deparsed._destroy = 1;
    } else {
      deparsed.billing_adjustment_minutes = proto.billingAdjustmentMinutes;
      deparsed.pay_start_time = proto.payStartTime?.toString();
      deparsed.pay_end_time = proto.payEndTime?.toString();
      deparsed.rate = proto.rate?.toString();
      deparsed.quantity = proto.quantity?.toString();
    }

    return deparsed;
  }
}
