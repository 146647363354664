import { JobState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import _ from 'lodash';
import { uniqBy } from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { Account } from '~hooks/useAccount';
import { Driver } from '~hooks/useDrivers';
import { Job } from '~hooks/useJob';
import { Pagination } from '~interfaces';
import { Nullable } from '~types/Nullable';

export const DEFAULT_PAGINATION_STATE: Pagination = { limit: 50, page: 1 };

enum DriverSchedulerStoreStorageKeys {
  APPLIED_ASSIGNED_JOBS_FILTERS = 'driverScheduler_appliedAssignedJobsFilters',
}

type DateFilters = {
  startDate?: string;
  endDate?: string;
};

export type JobsFilters = {
  customers?: string[];
  dispatchNumbers?: string[];
  dropOffSites?: string[];
  pickUpSites?: string[];
  projects?: string[];
  projectsExternalIds?: string[];
  search?: string;
};
type DriversFilters = {
  shared?: string[];
  search?: string;
};

type AppliedFiltersState = Record<
  keyof DriversFilters,
  {
    driverFilterKey: keyof DriversFilters;
    selected: string[];
  }
>;

export const appliedFiltersInitialState: AppliedFiltersState = {
  shared: {
    driverFilterKey: 'shared',
    selected: [],
  },
  search: {
    driverFilterKey: 'search',
    selected: [],
  },
};

class DriverSchedulerStore {
  unassignedJobs: Job[] = [];
  assignedJobs: Job[] = [];
  drivers: Driver[] = [];
  filteredDrivers: Driver[] = [];
  vendors: Account[] = [];
  isLoadingUnassignedJobs: boolean = false;
  isLoadingAssignedJobs: boolean = false;
  isLoadingDrivers: boolean = false;
  isLoadingVendors: boolean = false;
  isUpdatingJob: boolean = false;
  unassignedJobsPagination: Pagination = DEFAULT_PAGINATION_STATE;
  assignedJobsPagination: Pagination = DEFAULT_PAGINATION_STATE;
  driversPagination: Pagination = DEFAULT_PAGINATION_STATE;
  vendorsPagination: Pagination = DEFAULT_PAGINATION_STATE;
  jobAssignments: Record<string, Nullable<Job>> = {};
  unassignedJobsFilters: JobsFilters = {};
  assignedJobsFilters: JobsFilters = {};
  driversFilters: DriversFilters = {};
  dateFilters: DateFilters = {};
  draggedJob: Nullable<Job> = null;
  driverSearch: string = '';
  appliedDriverFilters: AppliedFiltersState = appliedFiltersInitialState;

  constructor() {
    makeObservable(this, {
      unassignedJobs: observable,
      assignedJobs: observable,
      drivers: observable,
      filteredDrivers: observable,
      setFilteredDrivers: action,
      vendors: observable,
      unassignedJobsPagination: observable,
      assignedJobsPagination: observable,
      driversPagination: observable,
      vendorsPagination: observable,
      isLoadingUnassignedJobs: observable,
      isLoadingAssignedJobs: observable,
      isLoadingDrivers: observable,
      isLoadingVendors: observable,
      isUpdatingJob: observable,
      jobAssignments: observable,
      unassignedJobsFilters: observable,
      assignedJobsFilters: observable,
      driversFilters: observable,
      dateFilters: observable,
      unassignedJobsFetchStart: action,
      assignedJobsFetchStart: action,
      driversFetchStart: action,
      vendorsFetchStart: action,
      updateJobStart: action,
      updateJobEnd: action,
      fetchUnassignedJobsEnd: action,
      fetchAssignedJobsEnd: action,
      fetchDriversEnd: action,
      fetchVendorsEnd: action,
      updateJob: action,
      setJobAssignments: action,
      setUnassignedJobsFilters: action,
      setAssignedJobsFilters: action,
      setDriversFilters: action,
      clearDrivers: action,
      clearUnassignedJobs: action,
      setDateFilters: action,
      draggedJob: observable,
      setDraggedJob: action,
      driverSearch: observable,
      setDriversSearch: action,
      appliedDriverFilters: observable,
      setAppliedDriverFilters: action,
    });
  }

  unassignedJobsFetchStart() {
    runInAction(() => {
      this.isLoadingUnassignedJobs = true;
    });
  }

  assignedJobsFetchStart() {
    runInAction(() => {
      this.isLoadingAssignedJobs = true;
    });
  }

  driversFetchStart() {
    runInAction(() => {
      this.isLoadingDrivers = true;
    });
  }

  vendorsFetchStart() {
    runInAction(() => {
      this.isLoadingVendors = true;
    });
  }

  updateJobStart() {
    runInAction(() => {
      this.isUpdatingJob = true;
    });
  }

  updateJobEnd() {
    runInAction(() => {
      this.isUpdatingJob = false;
    });
  }

  fetchUnassignedJobsEnd(jobs: Job[], pagination?: Pagination, companyId?: string) {
    runInAction(() => {
      this.isLoadingUnassignedJobs = false;
      const filteredJobs = jobs.filter((job) => {
        const vendorCompanyIsCurrentCompany =
          job.vendorJobAssignment?.vendorAccount?.id === companyId;
        return (
          !job.driver ||
          (!job.driver && job.vendorJobAssignment && vendorCompanyIsCurrentCompany)
        );
      });

      this.unassignedJobs = uniqBy(
        [...this.unassignedJobs, ...filteredJobs],
        (job) => job.id,
      );

      this.unassignedJobsPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination?.after ?? '',
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }

  fetchAssignedJobsEnd(jobs: Job[], pagination?: Pagination) {
    runInAction(() => {
      this.isLoadingAssignedJobs = false;
      this.assignedJobs = uniqBy([...this.assignedJobs, ...jobs], (job) => job.id);
      this.assignedJobsPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination?.after ?? '',
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }

  updateAssignedJobsPagination(pagination: Pagination) {
    runInAction(() => {
      this.assignedJobsPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination.after,
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }

  fetchDriversEnd(drivers: Driver[], pagination?: Pagination) {
    runInAction(() => {
      this.isLoadingDrivers = false;
      this.drivers = uniqBy(this.drivers.concat(drivers), (driver) => driver.id);
      this.filteredDrivers = this.drivers;

      this.driversPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination?.after ?? '',
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }

  fetchVendorsEnd(vendors: Account[], pagination?: Pagination) {
    runInAction(() => {
      this.isLoadingVendors = false;
      this.vendors = uniqBy(this.vendors.concat(vendors), (vendor) => vendor.id);
      this.vendorsPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination?.after ?? '',
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }

  addUnassignedJob(job: Job) {
    runInAction(() => {
      this.unassignedJobs = uniqBy([...this.unassignedJobs, job], (job) => job.id);
    });
  }

  removeUnassignedJob(job: Job) {
    runInAction(() => {
      const unassignedIndex = this.unassignedJobs.findIndex((j) => j.id === job.id);

      if (unassignedIndex > -1) {
        this.unassignedJobs.splice(unassignedIndex, 1);
      }
    });
  }

  updateJob(job: Job) {
    if (!job) return;

    const unassignedIndex = this.unassignedJobs.findIndex((j) => j.id === job.id);
    const assignedIndex = this.assignedJobs.findIndex((j) => j.id === job.id);

    runInAction(() => {
      const isCanceled = job.status === JobState.CANCELED;
      const isUnassigned = !job.driver && !job.vendorJobAssignment;

      if (unassignedIndex > -1) {
        this.unassignedJobs.splice(unassignedIndex, 1, job);
      } else if (assignedIndex > -1) {
        this.assignedJobs.splice(assignedIndex, 1, job);
      } else if (
        this.dateFilters.startDate &&
        this.dateFilters.endDate &&
        isUnassigned &&
        !isCanceled
      ) {
        const jobStartDate = dayjs.tz(job.jobStartAt);
        const selectedStartDate = dayjs.tz(dayjs(this.dateFilters.startDate));
        const selectedEndDate = dayjs.tz(dayjs(this.dateFilters.endDate));
        const matchesCurrentlySelectedDateRange = jobStartDate.isBetween(
          selectedStartDate,
          selectedEndDate,
          'seconds',
          '[]',
        );

        if (matchesCurrentlySelectedDateRange) {
          this.unassignedJobs.push(job);
        }
      }
    });
  }

  setJobAssignments(newAssignments: Record<string, Nullable<Job>>) {
    runInAction(() => {
      this.jobAssignments = newAssignments;
    });
  }

  setUnassignedJobsFilters(filters: JobsFilters, merge?: boolean) {
    runInAction(() => {
      this.unassignedJobsFilters = merge
        ? { ...this.unassignedJobsFilters, ...filters }
        : filters;
    });
  }

  retrieveAssignedJobsStoredFilters() {
    const storedFilters = localStorage.getItem(
      DriverSchedulerStoreStorageKeys.APPLIED_ASSIGNED_JOBS_FILTERS,
    );
    const parsedFilters = (storedFilters ? JSON.parse(storedFilters) : {}) as JobsFilters;

    return parsedFilters;
  }

  restoreAssignedJobsFilters() {
    runInAction(() => {
      this.assignedJobsFilters = {
        ...this.assignedJobsFilters,
        ...this.retrieveAssignedJobsStoredFilters(),
      };
    });
  }

  persistAssignedJobsFilters(filters: JobsFilters) {
    runInAction(() => {
      const toPersist: Array<keyof JobsFilters> = [
        'customers',
        'dispatchNumbers',
        'dropOffSites',
        'pickUpSites',
        'projects',
        'projectsExternalIds',
      ];

      const shouldPersistFilters = Object.keys(filters).some((key) => {
        return toPersist.includes(key as keyof JobsFilters);
      });

      if (!shouldPersistFilters) {
        return;
      }

      const values = _.pick(filters, toPersist);
      localStorage.setItem(
        DriverSchedulerStoreStorageKeys.APPLIED_ASSIGNED_JOBS_FILTERS,
        JSON.stringify(values),
      );
    });
  }

  setAssignedJobsFilters(filters: JobsFilters, merge?: boolean) {
    const newFilters = merge ? { ...this.assignedJobsFilters, ...filters } : filters;

    if (filters.search) {
      this.resetAssignedJobs({ search: filters.search });
    } else {
      const previousFilters = this.retrieveAssignedJobsStoredFilters();

      this.resetAssignedJobs({
        ...previousFilters,
        ...newFilters,
        search: '',
      });
    }

    this.persistAssignedJobsFilters(newFilters);
  }

  resetAssignedJobs(assignedJobsFilters: JobsFilters = {}) {
    runInAction(() => {
      this.assignedJobs = [];
      this.isLoadingAssignedJobs = false;
      this.assignedJobsPagination = DEFAULT_PAGINATION_STATE;
      this.assignedJobsFilters = assignedJobsFilters;
      this.jobAssignments = {};
    });
  }

  setDriversFilters(filters: DriversFilters, merge?: boolean) {
    runInAction(() => {
      this.driversFilters = merge ? { ...this.driversFilters, ...filters } : filters;
    });
  }

  setFilteredDrivers(drivers: Driver[]) {
    runInAction(() => {
      this.filteredDrivers = drivers;
    });
  }

  clearDrivers() {
    runInAction(() => {
      this.drivers = [];
      this.driversPagination = DEFAULT_PAGINATION_STATE;
    });
  }

  clearUnassignedJobs() {
    runInAction(() => {
      this.unassignedJobs = [];
      this.unassignedJobsPagination = DEFAULT_PAGINATION_STATE;
    });
  }

  setDateFilters(filters: DateFilters) {
    runInAction(() => {
      this.dateFilters = filters;
      this.resetAssignedJobs(this.assignedJobsFilters);
    });
  }

  setDraggedJob(job: Nullable<Job>) {
    runInAction(() => {
      this.draggedJob = job;
    });
  }

  setDriversSearch(search: string) {
    runInAction(() => {
      this.driverSearch = search;
    });
  }

  setAppliedDriverFilters(filters: AppliedFiltersState) {
    runInAction(() => {
      this.appliedDriverFilters = filters;
    });
  }
}

export default DriverSchedulerStore;
