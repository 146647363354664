import { Ticket_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { GetPaginationParams, PaginationLink } from '~interfaces/pagination';
import connection from '~services/connectionModule';
import { useStores } from '~store';

import { Ticket } from './models';

export type TicketEventType = 'approve' | 'void' | 'flag' | 'unflag';

interface updateTicketByIdProps {
  id: string;
  data: any;
  callBack?: (data: Ticket) => void;
}
interface DeleteIdCallBackProps {
  id: string;
  callBack?: () => void;
}
interface UploadTicketImageCallBackProps {
  id: string;
  file: File;
  callBack?: () => void;
}
interface GetTicketByIdProps {
  id: string;
  callBack?: (ticket: Ticket) => void;
}
interface CreateTicketProp {
  data: any;
  callBack?: (ticket: Ticket) => void;
}

export const useTickets = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isUpdatingTicket, setIsUpdatingTicket] = useState<boolean>(false);
  const { ticketsStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();

  const updateTicketById = ({ id, data, callBack }: updateTicketByIdProps) => {
    setIsUpdatingTicket(true);
    return connection
      .patch<Ticket_Read>(
        `${API_VERSION}/tickets/${id}`,
        Ticket.deparseUpdate(data),
        {},
        $t('error_messages.tickets.failed_to_update') as string,
      )
      .then((resp) => {
        const formatted = Ticket.parse(resp);
        callBack?.(formatted);
        ticketsStore.updateTicket(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdatingTicket(false);
      });
  };
  const deleteTicketImageById = ({ id, callBack }: DeleteIdCallBackProps) => {
    setIsUpdatingTicket(true);
    return connection
      .delete<Ticket_Read>(
        `${API_VERSION}/tickets/${id}/image`,
        {},
        $t('error_messages.tickets.failed_to_delete_ticket_image') as string,
      )
      .then((resp) => {
        callBack?.();
        return resp;
      })
      .finally(() => {
        setIsUpdatingTicket(false);
      });
  };
  const uploadTicketImageById = ({
    id,
    file,
    callBack,
  }: UploadTicketImageCallBackProps) => {
    setIsUpdatingTicket(true);
    const formData = new FormData();
    formData.append('image', file);
    return connection
      .post<Ticket_Read>(
        `${API_VERSION}/tickets/${id}/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        $t('error_messages.tickets.failed_to_upload_ticket_image') as string,
      )
      .then((resp) => {
        const formatted = Ticket.parse(resp);
        callBack?.();
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const getTicketById = async ({ id, callBack }: GetTicketByIdProps) => {
    setIsLoading(true);
    try {
      const resp = await connection.get<Ticket_Read>(
        `${API_VERSION}/tickets/${id}`,
        {},
        $t('error_messages.tickets.failed_to_fetch_ticket') as string,
      );
      const formatted = Ticket.parse(resp);
      callBack?.(formatted);
      return formatted;
    } finally {
      setIsLoading(false);
    }
  };
  const createTicket = async ({ data, callBack }: CreateTicketProp) => {
    setIsLoading(true);
    try {
      const resp = await connection.post<Ticket_Read>(
        `${API_VERSION}/tickets`,
        data,
        {},
        $t('error_messages.tickets.failed_to_create') as string,
      );
      const formatted = Ticket.parse(resp);
      callBack?.(formatted);
      return formatted;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Retrieves tickets based on the provided parameters.
   *
   * @param link - The pagination link to fetch the next or previous page of tickets.
   * @param searchQuery - The search query to filter the tickets.
   * @param filterParams - Additional filter parameters to narrow down the tickets.
   */
  const getTickets = async (
    link?: PaginationLink,
    searchQuery?: string,
    filterParams?: Record<string, any>,
  ) => {
    setIsLoading(true);

    // Set pagination params
    let params: GetPaginationParams = {
      'page[limit]': ticketsStore.ticketsReviewPagination.limit,
    };
    if (link && ticketsStore.ticketsReviewPagination[link]) {
      params[`page[${link}]`] = ticketsStore.ticketsReviewPagination[link];
    }

    // Add search and filter params
    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });

    return await connection
      .getPaginated<Ticket_Read>(
        `${API_VERSION}/tickets`,
        { params },
        $t('error_messages.tickets.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(Ticket.parse);
        ticketsStore.setTickets(formatted);
        ticketsStore.setTicketsReviewPagination(pagination);
        ticketsStore.updateTicketsReviewPageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateState = async (id: string, event: TicketEventType) => {
    setIsUpdating(true);
    try {
      const resp = await connection.put<Ticket_Read>(
        `${API_VERSION}/tickets/${id}/${event}`,
        {},
        {},
        $t('error_messages.tickets.failed_to_do_ticket_event', { event }) as string,
      );
      const formatted = Ticket.parse(resp);
      ticketsStore.updateTicket(formatted);
      return formatted;
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    isLoading,
    isUpdating,
    updateTicketById,
    deleteTicketImageById,
    uploadTicketImageById,
    isUpdatingTicket,
    getTicketById,
    createTicket,
    getTickets,
    updateState,
  } as const;
};
