import Box from '@mui/material/Box';
import { t } from 'i18next';

import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';

import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '../NewDispatch';

interface CreateOrderButtonProps {
  onClick: () => void;
}

export default function CreateOrderButton({ onClick }: CreateOrderButtonProps) {
  return (
    <Box>
      <SmallButton
        color="brandV2Yellow"
        onClick={onClick}
        sx={{
          '&.MuiButtonBase-root': {
            height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
          },
        }}
      >
        {t('dispatch.dispatch_v2.new_order')}
      </SmallButton>
    </Box>
  );
}
