import AttachmentRounded from '@mui/icons-material/AttachmentRounded';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Control, FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { FormFieldLabel } from '~components/FormFields/FormFieldLabel';
import { TextFormField } from '~components/FormFields/TextFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { FileAttachment, useFileAttachment } from '~hooks/useFileAttachment';
import {
  FileAttachableType,
  UpdateFileAttachmentParams,
} from '~hooks/useFileAttachment/useFileAttachment';
import theme from '~theme/AppTheme';

/**
 * For the time being we're hard coding these options.
 */
const ATTACHMENT_OPTIONS = [
  'Inspection Report',
  'Site Map',
  'Scale Ticket (Internal)',
  'Scale Ticket (External)',
  'Timesheet',
  'Other',
];

const maxFileSize = 10;

export const fileAttachmentSchema = yup.array().of(
  yup.object().shape({
    id: yup.string().required(),
    category: yup.string().required('Category is required'),
    description: yup.string().notRequired(),
  }),
);

export interface FileAttachmentsHandler {
  onCancel: () => Promise<boolean>;
  onSubmit: (projectId: string) => Promise<boolean>;
}

interface FileAttachmentsProps {
  fileAttachableId?: string;
  fileAttachableType: FileAttachableType;
  control: Control<any>;
  errors: FieldErrors<FieldValues>;
  name: string;
  form: UseFormReturn<any>;
  allowPerItemUpdates?: boolean;
}

interface FileAttachmentUpdate {
  id: string;
  category: string;
  description: string;
}

/**
 * This component is used to handle file attachments for a `Project`, `Order`, `Job`, or `Load`.
 * It allows the user to upload files, update the category and description of the file, and delete the file.
 *
 * @requires `fileAttachableId` if and only if `allowPerItemUpdates` is true, otherwise does not require `fileAttachableId`
 */
const FileAttachments = forwardRef(function FileAttachments(
  {
    fileAttachableId,
    fileAttachableType,
    control,
    errors,
    name,
    form,
    allowPerItemUpdates = false,
  }: FileAttachmentsProps,
  ref: Ref<FileAttachmentsHandler>,
) {
  const {
    getAllFileAttachmentsById,
    deleteFileAttachmentById,
    createFileAttachment,
    updateFileAttachmentById,
  } = useFileAttachment();
  const watchedAttachments: FileAttachmentUpdate[] = form.watch(name);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachments, setAttachments] = useState<Partial<FileAttachment>[]>([]);
  const [isEditingIds, setIsEditingIds] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  /**
   * On form submission, we'll update all the files in the queue.
   */
  const [updateQueue, setUpdateQueue] = useState<FileAttachmentUpdate[]>([]);

  /**
   * On form submission, we'll delete all the files in the queue.
   */
  const [deleteQueue, setDeleteQueue] = useState<string[]>([]);

  /**
   * If a user cancels/closes, we need to delete the attachments
   * that were created during the form session.
   */
  const [createdList, setCreatedList] = useState<string[]>([]);

  /**
   * If a user tries to create file attachments on a new project
   * we need to keep track of the attachments that were created.
   * We'll create them on form submission once we have project_id.
   */
  const [createQueue, setCreateQueue] = useState<File[]>([]);

  useImperativeHandle(ref, () => ({
    /**
     * On form close/cancel, we need to delete all the attachments that were created
     * since attachments are created on file upload, not on form submission.
     */
    onCancel: async () => {
      await Promise.all(createdList.map((id) => deleteFileAttachmentById(id)));
      return true;
    },

    /**
     * On form submission, we need to process all items that were in the queues
     */
    async onSubmit(attachableId: string) {
      return await handleFormSubmission(attachableId);
    },
  }));

  useEffect(() => {
    if (!fileAttachableId) return;

    setIsFetching(true);
    getAllFileAttachmentsById(fileAttachableId, fileAttachableType).then((data) => {
      form.setValue(
        name,
        data.map((attachment) => {
          return {
            id: attachment.id,
            category: attachment.category,
            description: attachment.description,
          };
        }),
      );
      setAttachments(data);
      setIsFetching(false);
    });
  }, [fileAttachableId, fileAttachableType]);

  /**
   * This is a hacky way to get the ID and file_url in the update queue by using the dirtyFields
   */
  useEffect(() => {
    const indices: number[] = form.formState.dirtyFields[name]
      ? form.formState.dirtyFields[name]
          .map((field: UpdateFileAttachmentParams, index: number) =>
            field.description || field.category ? index : null,
          )
          .filter((index: number | null) => index !== null)
      : [];

    const formValues = form.getValues(name);

    const updatedAttachments = attachments
      .map((attachment, index) => {
        if (indices.includes(index)) {
          const updatedAttachment = formValues[index];
          return {
            id: attachment.id,
            category: updatedAttachment.category,
            description: updatedAttachment.description,
          };
        }
        return null;
      })
      .filter((attachment) => attachment !== null);

    setUpdateQueue(updatedAttachments as FileAttachmentUpdate[]);
  }, [form.formState, name]);

  const handleFormSubmission = async (attachableId: string) => {
    // 1. I need to create all the attachments that were created
    const createdQueuePromises = createQueue.map((file) => {
      return createFileAttachment(file, attachableId, fileAttachableType);
    });

    const newCreatedAttachments = await Promise.all(createdQueuePromises);

    // 2. I need to find the files created within the updatedQueue list and reassign their ID
    // Since, if they were just created they have an invalid ID in the updatedQueue array
    const newCreatedAttachmentUpdates = updateQueue.map((attachment) => {
      const { id, category, description } = attachment;
      const updatedAttachment = newCreatedAttachments.find((createdAttachment) => {
        const fileName = createdAttachment?.fileName;
        // If the file name matches the ID, then it was just created.
        return fileName === id;
      });

      if (updatedAttachment) {
        const fileName = updatedAttachment.fileName;
        return {
          id: updatedAttachment.id,
          fileName,
          category,
          description,
        };
      }

      return null;
    });

    // 3. I need to update all the attachments that were created and then updated
    newCreatedAttachmentUpdates.forEach((attachment) => {
      if (attachment) {
        updateFileAttachmentById(attachment.id, {
          category: attachment.category,
          description: attachment.description,
        } as UpdateFileAttachmentParams);
      }
    });

    // Removing the attachments that were created and updated already from the updateQueue
    const newUpdatedQueue = updateQueue.filter(
      (attachment) =>
        !newCreatedAttachmentUpdates.some(
          (createdAttachment) =>
            createdAttachment && createdAttachment.fileName === attachment.id,
        ),
    );

    newUpdatedQueue.forEach((attachment) => {
      updateFileAttachmentById(attachment.id, {
        category: attachment.category,
        description: attachment.description,
      } as UpdateFileAttachmentParams);
    });

    deleteQueue.forEach((id) => {
      deleteFileAttachmentById(id);
    });

    return true;
  };

  const handleCreateFileAttachment = (file: File) => {
    if (!file) return;
    if (file.size / 1024 / 1024 > maxFileSize) {
      alert(t('file_upload.max_size', { maxSize: maxFileSize + ' MB' }));
      return;
    }

    if (!fileAttachableId) {
      const newAttachment: Partial<FileAttachment> = {
        fileName: file.name,
        id: file.name,
        category: 'Other',
        description: '',
      };

      const existingAttachments = watchedAttachments || [];
      const updatedAttachments = [...existingAttachments, newAttachment];
      setCreateQueue((prevCreateQueue) => [...prevCreateQueue, file]);
      setAttachments(updatedAttachments);

      form.setValue(name, updatedAttachments);
      handleToggleEdit(newAttachment?.id ?? '');
      return;
    }

    createFileAttachment(file, fileAttachableId, fileAttachableType).then((data) => {
      const newCreatedList = [...createdList, data.id];
      const updatedAttachments = [...attachments, data];

      setCreatedList(newCreatedList);
      setAttachments(updatedAttachments);

      form.setValue(name, [
        ...watchedAttachments,
        { id: data.id, category: data.category, description: data.description },
      ]);

      handleToggleEdit(data.id);
    });
  };

  const handleDeleteFileAttachment = (id: string) => {
    if (allowPerItemUpdates) {
      form.setValue(
        name,
        watchedAttachments.filter((att) => att.id !== id),
        {
          shouldDirty: true,
        },
      );
      setAttachments((prevAttachments) =>
        prevAttachments.filter((attachment) => attachment.id !== id),
      );
      deleteFileAttachmentById(id);
      return;
    }
    const deletedAttachment = attachments.find((attachment) => attachment.id === id);
    if (!deletedAttachment) return;

    if (fileAttachableId) {
      setDeleteQueue((prevQueue) => [...prevQueue, id]);
    } else {
      setCreateQueue((prevQueue) =>
        prevQueue.filter((file) => file.name !== deletedAttachment.fileName),
      );
    }
    setAttachments((prevAttachments) =>
      prevAttachments.filter((attachment) => attachment.id !== id),
    );

    form.setValue(
      name,
      watchedAttachments.filter((att) => att.id !== id),
      {
        shouldDirty: true,
      },
    );
  };

  const handleToggleEdit = (id: string) => {
    if (isEditingIds.includes(id)) {
      setIsEditingIds(isEditingIds.filter((i) => i !== id));
    } else {
      setIsEditingIds([...isEditingIds, id]);
    }
  };

  return (
    <>
      <Box display="flex" flexDirection={'row'} width={'100%'} alignItems={'center'}>
        <FormSectionTitle
          sx={{ mb: 0, mr: 'auto' }}
          title={t('form_fields.attachments')}
        />
        <Typography mr={1} color={'grey'} variant="body2">
          {t('file_upload.max_size', { maxSize: maxFileSize + ' MB' })}
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => fileInputRef && fileInputRef.current?.click()}
        >
          <AttachmentRounded
            sx={{ width: '16px', height: '16px', mr: 1, rotate: '-90deg' }}
          />
          {t('project.form.attach_files')}
          <input
            ref={fileInputRef}
            type="file"
            accept=".pdf,.png,.jpg,.jpeg"
            hidden
            onChange={(e) =>
              e.target.files && handleCreateFileAttachment(e.target.files[0])
            }
          />
        </Button>
      </Box>
      <Box mt={1}>
        {(!allowPerItemUpdates || (allowPerItemUpdates && fileAttachableId)) &&
          attachments &&
          attachments.map((attachment, index) => (
            <Grid
              container
              key={attachment?.fileUrl}
              sx={{
                borderTop: 1,
                py: 2,
                borderColor: theme.palette.grey[300],
                ':last-child': { pb: 0 },
              }}
            >
              <Grid item display={'flex'} gap={2} xs={12} alignItems={'center'} mb={1}>
                <Grid xs={11}>
                  <FormFieldLabel label="Attachment" />
                  {attachment?.fileUrl ? (
                    <PseudoLink
                      title={attachment?.fileName || '-'}
                      action={() => window.open(attachment?.fileUrl)}
                    />
                  ) : (
                    <Typography variant="body2" mr="auto">
                      {attachment?.fileName ?? '-'}
                    </Typography>
                  )}
                </Grid>
                <Grid xs={1}>
                  {attachment?.id && isEditingIds.includes(attachment.id) ? (
                    <IconButton
                      size="small"
                      onClick={() => attachment?.id && handleToggleEdit(attachment.id)}
                      disabled={
                        !watchedAttachments[index]?.category ||
                        (allowPerItemUpdates &&
                          (form.formState.dirtyFields[name]?.[index]?.category ||
                            form.formState.dirtyFields[name]?.[index]?.description))
                      }
                    >
                      <Close
                        sx={{
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => attachment?.id && handleToggleEdit(attachment.id)}
                    >
                      <Edit
                        sx={{
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4} mr={0}>
                    {attachment?.id && isEditingIds.includes(attachment.id) ? (
                      <AutocompleteFormField
                        list={ATTACHMENT_OPTIONS}
                        control={control}
                        errors={errors}
                        name={`${name}[${index}].category`}
                        getLabel={(item) => item}
                        getValue={(item) => item}
                        label="Category"
                        isRequired
                      />
                    ) : (
                      <>
                        <FormFieldLabel
                          label={t('common.category')}
                          labelColor={
                            Object(errors[name])?.[index]?.category ? 'error' : 'default'
                          }
                        />
                        <Chip
                          label={watchedAttachments[index]?.category || '-'}
                          color={
                            Object(errors[name])?.[index]?.category ? 'error' : 'default'
                          }
                          size="small"
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={8}>
                    {attachment?.id && isEditingIds.includes(attachment.id) ? (
                      <TextFormField
                        multiline
                        control={control}
                        errors={errors}
                        name={`${name}[${index}].description`}
                        label="Description"
                      />
                    ) : (
                      <>
                        <FormFieldLabel
                          label={t('common.description')}
                          labelColor={
                            Object(errors[name])?.[index]?.description
                              ? theme.palette.error.main
                              : 'black'
                          }
                        />
                        <Typography variant="body1">
                          {watchedAttachments[index]?.description}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                {attachment?.id && isEditingIds.includes(attachment.id) && (
                  <Grid item xs={12} display={'flex'} flexDirection={'row'} mt={1}>
                    <Button
                      color="error"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        attachment?.id ? handleDeleteFileAttachment(attachment.id) : null;
                      }}
                      sx={{ mr: 'auto' }}
                    >
                      {t('actions.remove')}
                    </Button>
                    <Button
                      onClick={() => {
                        attachment?.id && handleToggleEdit(attachment?.id);
                        allowPerItemUpdates &&
                          attachment?.id &&
                          updateFileAttachmentById(
                            attachment.id,
                            watchedAttachments[index],
                          );
                      }}
                      disabled={!watchedAttachments[index]?.category}
                    >
                      {t('actions.confirm')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        <LoadingSpinner isVisible={isFetching} sx={{ py: 2 }} />
      </Box>
    </>
  );
});

export default FileAttachments;
