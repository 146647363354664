import { OrderState } from '@treadinc/horizon-api-spec';

export const activeOrderStates = [
  OrderState.CREATED,
  OrderState.ACCEPTED,
  OrderState.IN_PROGRESS,
];

export const pendingOrderStates = [OrderState.CREATED, OrderState.ACCEPTED];

export const externalOrderStates = [OrderState.PENDING_REQUEST];

export const inProgressOrderStates = [OrderState.IN_PROGRESS];

export const doneOrderStates = [
  OrderState.COMPLETED,
  OrderState.CANCELED,
  OrderState.REJECTED,
];
