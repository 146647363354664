import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { NoteTypes } from '~constants/enums';
import { Job } from '~src/hooks/useJob';
import { Order } from '~src/hooks/useOrders';
import { Project } from '~src/hooks/useProjects';

enum NoteFieldNames {
  ORDER = 'orderNotes',
  JOB = 'jobNotes',
  INTERNAL = 'internalNotes',
  PROJECT = 'projectNotes',
}

const NoteTypeToFieldName = {
  [NoteTypes.PROJECT]: NoteFieldNames.PROJECT,
  [NoteTypes.ORDER]: NoteFieldNames.ORDER,
  [NoteTypes.JOB]: NoteFieldNames.JOB,
  [NoteTypes.INTERNAL]: NoteFieldNames.INTERNAL,
};

interface NotesProps {
  defaultData?: Project | Order | Job | null;
  defaultTab: NoteTypes;
}

export const Notes = ({ defaultTab }: NotesProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [activeNotesField, setActiveNotesField] = useState<{
    type: NoteTypes;
    field: NoteFieldNames;
  }>({ type: defaultTab, field: NoteTypeToFieldName[defaultTab] });

  return (
    <Card sx={{ mt: 2, p: 2 }}>
      <FormSectionTitle sx={{ mb: 1 }} title={`${t('form_fields.notes')}`} />
      <Grid container xs={12} display={'flex'} spacing={1}>
        <Grid item>
          <PseudoLink
            title={`${t('form_fields.project')}`}
            action={() =>
              setActiveNotesField({
                type: NoteTypes.PROJECT,
                field: NoteFieldNames.PROJECT,
              })
            }
            disabled={activeNotesField.field === NoteFieldNames.PROJECT}
          />
        </Grid>
        <Grid item>
          <PseudoLink
            title={`${t('form_fields.order')}`}
            action={() =>
              setActiveNotesField({
                type: NoteTypes.ORDER,
                field: NoteFieldNames.ORDER,
              })
            }
            disabled={activeNotesField.field === NoteFieldNames.ORDER}
          />
        </Grid>
        <Grid item>
          <PseudoLink
            title={`${t('form_fields.job')}`}
            action={() =>
              setActiveNotesField({
                type: NoteTypes.JOB,
                field: NoteFieldNames.JOB,
              })
            }
            disabled={activeNotesField.field === NoteFieldNames.JOB}
          />
        </Grid>
        <Grid item>
          <PseudoLink
            title={`${t('form_fields.internal')}`}
            action={() =>
              setActiveNotesField({
                type: NoteTypes.INTERNAL,
                field: NoteFieldNames.INTERNAL,
              })
            }
            disabled={activeNotesField.field === NoteFieldNames.INTERNAL}
          />
        </Grid>
      </Grid>
      {activeNotesField.field === NoteFieldNames.PROJECT && (
        <TextFormField
          control={control}
          errors={errors}
          name={NoteFieldNames.PROJECT}
          label={''}
          sx={{ width: 'auto' }}
          multiline={true}
          rows={3}
          isRequired={false}
          disabled={defaultTab !== NoteTypes.PROJECT}
        />
      )}
      {activeNotesField.field === NoteFieldNames.ORDER && (
        <TextFormField
          control={control}
          errors={errors}
          name={NoteFieldNames.ORDER}
          label={''}
          sx={{ width: 'auto' }}
          multiline={true}
          rows={3}
          isRequired={false}
          disabled={defaultTab !== NoteTypes.ORDER && defaultTab !== NoteTypes.PROJECT}
        />
      )}
      {activeNotesField.field === NoteFieldNames.JOB && (
        <TextFormField
          control={control}
          errors={errors}
          name={NoteFieldNames.JOB}
          label={''}
          sx={{ width: 'auto' }}
          multiline={true}
          rows={3}
          isRequired={false}
        />
      )}
      {activeNotesField.field === NoteFieldNames.INTERNAL && (
        <TextFormField
          control={control}
          errors={errors}
          name={NoteFieldNames.INTERNAL}
          label={''}
          sx={{ width: 'auto' }}
          multiline={true}
          rows={3}
          isRequired={false}
          disabled={defaultTab === NoteTypes.JOB}
        />
      )}
    </Card>
  );
};
