import { User_Create, User_Me_Read as UserProto } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { AdminUser } from '~hooksAdmin/useUsersAdmin/models';
import { GetPaginationParams, PaginationLink } from '~interfaces/pagination';
import connection from '~services/connectionModule';
import { UserNotificationsPreferenceDTO } from '~src/components/UserForm/UserNotificationsPreferenceForm';
import { useStores } from '~store';

type CallbackType = (user?: AdminUser) => void;

export const useUsersAdmin = () => {
  const [isLoadingAllUsers, setIsLoadingAllUsers] = useState(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { userAdminStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();

  const getCompanyUsers = (
    companyId: string,
    link?: PaginationLink,
    searchQuery?: string,
  ) => {
    let params: GetPaginationParams = {
      'page[limit]': userAdminStore.pagination.limit,
    };
    if (link && userAdminStore.pagination[link]) {
      params[`page[${link}]`] = userAdminStore.pagination[link];
    }

    params = addSearchHeaderParam({ searchValue: searchQuery, params });
    const url = `${API_VERSION}/companies/${companyId}/users`;

    setIsLoadingAllUsers(true);
    return connection
      .getPaginated<UserProto>(url, { params })
      .then(({ data, pagination }) => {
        // TODO: refactor this, prevent use the same store for different entities and search by type_ahead
        userAdminStore.setUsers(data?.map((user: UserProto) => AdminUser.parse(user)));
        userAdminStore.setPagination(pagination);
        userAdminStore.updatePageNumber(link);
      })
      .finally(() => {
        setIsLoadingAllUsers(false);
      });
  };

  const updateUser = async (
    userData: AdminUser,
    userNotificationsPreferenceData?: UserNotificationsPreferenceDTO,
  ) => {
    setIsUpdating(true);

    try {
      const response = await connection.patch<UserProto>(
        `${API_VERSION}/users/${userData.id}`,
        AdminUser.deparse(userData, undefined, userNotificationsPreferenceData),
        {},
        $t('error_messages.users.failed_to_update') as string,
        [422],
      );
      const user = AdminUser.parse(response);

      userAdminStore.updateUser(user);

      return user;
    } finally {
      setIsUpdating(false);
    }
  };

  const createUser = async (
    userData: AdminUser,
    invitesToSend: User_Create['send_invite'] = {},
  ) => {
    setIsUpdating(true);

    try {
      const response = await connection.post<UserProto>(
        `${API_VERSION}/users`,
        AdminUser.deparse(userData, invitesToSend),
        {},
        $t('error_messages.users.failed_to_create') as string,
        [422],
      );
      const user = AdminUser.parse(response);

      userAdminStore.addUser(user);

      return user;
    } finally {
      setIsUpdating(false);
    }
  };

  const deleteUser = (id: string, callBack?: CallbackType) => {
    setIsUpdating(true);
    return connection
      .delete(`${API_VERSION}/users/${id}`)
      .then(() => {
        userAdminStore.deleteUser(id);
        callBack?.();
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const revokeAllUserSessions = (id: string, callBack?: CallbackType) => {
    setIsUpdating(true);
    return connection
      .delete(`${API_VERSION}/users/${id}/sessions`)
      .then(() => callBack?.())
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return {
    isLoadingAllUsers,
    isUpdating,
    getCompanyUsers,
    // GetUser,
    updateUser,
    createUser,
    deleteUser,
    revokeAllUserSessions,
  } as const;
};
