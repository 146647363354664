import React, { ReactNode } from 'react';

interface HeaderProps {
  title: ReactNode;
  actions?: ReactNode;
}
export const Header = (props: HeaderProps) => {
  return (
    <div className="flex items-center p-4 justify-between border-b border-gray-200">
      <div>{props.title}</div>
      <div>{props.actions}</div>
    </div>
  );
};
