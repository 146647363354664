import Box from '@mui/material/Box';

import { FeatureFlags } from '~constants/featureFlags';
import { useAwaitableFeatureFlag } from '~utils/hooks/useFeatureFlag';

export default function OrdersViewDriverPanel() {
  const driverPanelEnabledFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.driverPanelEnabled,
  });

  const isReady =
    driverPanelEnabledFeatureFlag.isReady &&
    driverPanelEnabledFeatureFlag.isFeatureFlagEnabled;

  if (!isReady) {
    return null;
  }

  return <Box bgcolor="red">{/* FIXME @todo Panel content to be added next */}</Box>;
}
