import { AccountType } from '@treadinc/horizon-api-spec';
import { useEffect, useState } from 'react';

import { Account } from '~hooks/useAccount';
import { CompanyShare, CompanyShareableBasic } from '~hooks/useCompanyShares';
import { useCompanyShares } from '~hooks/useCompanyShares/useCompanyShares';
import { Nullable } from '~types/Nullable';

interface UseCompanySharesDetailsProps {
  isAccountDialogOpen: boolean;
  selectedAccountId: Nullable<string>;
  selectedAccount: Nullable<Account>;
}

export const useCompanySharesDetails = ({
  isAccountDialogOpen,
  selectedAccountId,
  selectedAccount,
}: UseCompanySharesDetailsProps) => {
  const { getCompanySharesByCompanyId } = useCompanyShares();
  const [sharedDriversCompanyShares, setSharedDriversCompanyShares] = useState<
    CompanyShare[]
  >([]);
  const [sharedEquipmentCompanyShares, setSharedEquipmentCompanyShares] = useState<
    CompanyShare[]
  >([]);
  const [drivers, setDrivers] = useState<CompanyShareableBasic[]>([]);
  const [equipment, setEquipment] = useState<CompanyShareableBasic[]>([]);
  const isCustomerAndVendorAccount =
    selectedAccount?.accountTypes?.includes(AccountType.CUSTOMER) &&
    selectedAccount?.accountTypes?.includes(AccountType.VENDOR);

  useEffect(() => {
    if (
      isAccountDialogOpen &&
      selectedAccountId &&
      selectedAccount?.company?.id &&
      selectedAccount?.connectedCompany?.id
    ) {
      getCompanySharesByCompanyId({
        companyId: selectedAccount.company.id,
        company_shareable_type: 'Equipment',
        between_company_id: selectedAccount.connectedCompany.id,
        callBack: setSharedEquipmentCompanyShares,
      });

      getCompanySharesByCompanyId({
        companyId: selectedAccount.company.id,
        company_shareable_type: 'User',
        between_company_id: selectedAccount.connectedCompany.id,
        callBack: setSharedDriversCompanyShares,
      });
    }
  }, [
    selectedAccount?.connectedCompany?.id,
    selectedAccount?.company?.id,
    selectedAccountId,
    isAccountDialogOpen,
  ]);

  useEffect(() => {
    if (isCustomerAndVendorAccount && selectedAccount?.company?.id) {
      const customerDrivers: CompanyShareableBasic[] = sharedDriversCompanyShares
        .filter((share) => share.senderCompany.id === selectedAccount?.company?.id)
        .map((share) => share.companyShareable);

      const customerEquipment: CompanyShareableBasic[] = sharedEquipmentCompanyShares
        .filter((share) => share.senderCompany.id === selectedAccount?.company?.id)
        .map((share) => share.companyShareable);

      setDrivers(customerDrivers);
      setEquipment(customerEquipment);
    } else {
      setDrivers(
        sharedDriversCompanyShares.map((companyShare) => companyShare.companyShareable),
      );
      setEquipment(
        sharedEquipmentCompanyShares.map((companyShare) => companyShare.companyShareable),
      );
    }
  }, [
    isCustomerAndVendorAccount,
    selectedAccount,
    sharedDriversCompanyShares,
    sharedEquipmentCompanyShares,
  ]);

  return {
    drivers,
    equipment,
    sharedDriversCompanyShares,
    sharedEquipmentCompanyShares,
    setSharedEquipmentCompanyShares,
    setSharedDriversCompanyShares,
    setEquipment,
    setDrivers,
  };
};
