// FIXME @todo Replace with type coming from @treadinc/horizon-api-spec once those are ready

enum AgaveLinkedAccountStatus {
  LINKED = 'linked',
}

type AgaveSourceSystem = {
  name: string;
  slug: string;
};

export type AgaveLinkedAccount_Read = {
  id: string;
  status: AgaveLinkedAccountStatus;
  source_system: AgaveSourceSystem;
};

export class AgaveLinkedAccount {
  constructor(
    private _id: string,
    private _status: AgaveLinkedAccountStatus,
    private _source_system: AgaveSourceSystem,
  ) {}

  public get id() {
    return this._id;
  }

  public get status() {
    return this._status;
  }

  public get sourceSystem() {
    return this._source_system;
  }

  public static parse(proto: AgaveLinkedAccount_Read) {
    return new AgaveLinkedAccount(proto.id, proto.status, {
      name: proto.source_system.name,
      slug: proto.source_system.slug,
    });
  }
}
