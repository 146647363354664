import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React from 'react';

interface Props {
  children: React.ReactNode | string;
  sx?: SxProps<Theme>; // Style
}

const GroupTitle = ({ children, sx = {} }: Props) => {
  const theme = useTheme();

  return (
    <>
      <Typography
        variant={'body1'}
        sx={{
          color: theme.palette.text.secondary,
          fontWeight: 'bold',
          mb: 1,
          pb: 0.5,
          borderBottom: `1px solid ${theme.palette.divider}`,
          ...sx,
        }}
      >
        {children}
      </Typography>
    </>
  );
};

export { GroupTitle };
