import { IamRole_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { RoleItem } from '~hooks/useUsers/models';
import connection from '~services/connectionModule';
import { useStores } from '~store';

export const useUserRoles = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { userStore } = useStores();

  const getUserRoles = () => {
    setIsLoading(true);

    return connection
      .get<IamRole_Read[]>(
        `${API_VERSION}/iam_roles`,
        {},
        $t('error_messages.user_roles.failed_to_fetch') as string,
      )
      .then((resp) => {
        userStore.setUserRoles(resp.map(RoleItem.parse));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    getUserRoles: getUserRoles,
  };
};
