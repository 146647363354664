import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { TextFormField } from '~components/FormFields/TextFormField';
import { Order, useOrders } from '~hooks/useOrders';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

interface SendTextDialogProps {
  isOpen: boolean;
  order?: Order;
  onClose: () => void;
}

const sendTextSchema = yup.object().shape({
  message: yup
    .string()
    .trim()
    .min(
      1,
      `${t('form_validation_errors.required', { field: t('form_fields.message') })}`,
    )
    .required(),
});

type SendTextDTO = yup.InferType<typeof sendTextSchema>;

export default function SendTextDialog({ isOpen, order, onClose }: SendTextDialogProps) {
  const { sendSmsToAllDrivers, isSendingSms } = useOrders();
  const { toasterStore } = useStores();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<SendTextDTO>({
    resolver: yupResolver(sendTextSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { message: '' },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      handleSubmit(async ({ message }) => {
        if (order?.id) {
          await sendSmsToAllDrivers(order.id, message);

          toasterStore.push(
            alert(t('dispatch.job.send_text.text_sent'), AlertTypes.success),
          );
          onClose();
        }
      })();
    },
    [order?.id, onClose],
  );

  useEffect(() => {
    if (isOpen) {
      setValue('message', '');
    }
  }, [isOpen, setValue]);

  if (!order?.jobsCount) {
    return <></>;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={(__: never, reason: DialogCloseReasonType) => {
        if (isActionClicked(reason)) {
          onClose();
        }
      }}
    >
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <DialogHeader
          closeCallBack={onClose}
          title={
            <Typography component="span" variant="h5">
              {`${order.orderId}: ${t('actions.send_text')}`}
            </Typography>
          }
        />

        <DialogContent>
          <TextFormField
            control={control}
            errors={errors}
            isRequired
            multiline
            name="message"
            placeholder={`${t('dispatch.job.send_text.send_message_to', { name: 'all drivers' })}`}
            rows={5}
          />
        </DialogContent>

        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LoadingButton
            color="primary"
            disabled={isSendingSms}
            loading={isSendingSms}
            loadingPosition="start"
            startIcon={<></>}
            sx={isSendingSms ? { pl: 5, pr: 2 } : { pr: 2 }}
            type="submit"
            variant="contained"
          >
            {t('actions.send')}
          </LoadingButton>

          <Button
            color="secondary"
            disabled={isSendingSms}
            onClick={onClose}
            sx={{ mr: 2, px: 2 }}
            variant="outlined"
          >
            {t('actions.cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
