import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import React, { ReactNode } from 'react';

interface NoDataFoundProps {
  noDataText?: string;
  action?: ReactNode;
}
const NoDataFound = ({ noDataText, action }: NoDataFoundProps) => {
  const theme = useTheme();
  const space = 2;

  return (
    <Stack height="100%" sx={{ px: space }}>
      <Alert
        severity="warning"
        sx={{ width: `calc(100% - ${theme.spacing(space)})`, my: space }}
        data-test-id="grid__no-data-found"
      >
        {noDataText || t('common.no_data')}
        <Box display={'inline-flex'} sx={{ ml: 2 }}>
          {action}
        </Box>
      </Alert>
    </Stack>
  );
};

export { NoDataFound };
