import {
  AuthForgotPassword_Create,
  AuthLogin_Create,
  AuthMethod_List,
  AuthMethod_Query,
  AuthResetPassword_Create,
  AuthSignup_Create,
  AuthToken,
  MagicLink_Create,
  MagicLink_Login,
  OtpSms_Create,
  OtpSms_Login,
  OtpSms_Read,
} from '@treadinc/horizon-api-spec';
import { deleteToken } from 'firebase/messaging';
import { t } from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { API_VERSION } from '~constants/consts';
import { messaging } from '~notifications/firebase';
import { routes } from '~router';
import connection from '~services/connectionModule';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

export const useLogin = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { authStore, toasterStore } = useStores();
  const navigate = useNavigate();

  const getAuthMethods = async ({ email }: AuthMethod_Query) => {
    setIsLoading(true);

    return await connection
      .post<AuthMethod_List>(`${API_VERSION}/auth_methods`, { email })
      .catch(() => {
        toasterStore.push(
          alert(t('form_validation_errors.unable_get_auth_methods'), AlertTypes.error),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const triggerResetPassword = ({ email }: AuthForgotPassword_Create) => {
    setIsLoading(true);
    connection
      .post(`${API_VERSION}/auth/forgot_password`, { email })
      .then(() => {
        setIsLoading(false);
        toasterStore.push(
          alert(t('actions.reset_email_will_be_sent'), AlertTypes.success),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
    return email;
  };
  const login = async ({ email, password }: AuthLogin_Create) => {
    setIsLoading(true);
    await connection
      .post<AuthToken>(`${API_VERSION}/auth/login`, {
        email: email,
        password: password,
      })
      .then((resp: AuthToken) => {
        if (!resp.session_jwt) return;
        authStore.login(resp.session_jwt);
        navigate(`${routes.base}`);
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loginWithMagicLink = async ({ magic_links_token }: MagicLink_Login) => {
    setIsLoading(true);

    await connection
      .post<AuthToken>(`${API_VERSION}/auth/magic_link/login`, { magic_links_token })
      .then((resp: AuthToken) => {
        if (!resp.session_jwt) return;
        authStore.login(resp.session_jwt);
        navigate(`${routes.base}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loginWithOtp = async ({ phone_id, code }: OtpSms_Login) => {
    setIsLoading(true);

    await connection
      .post<AuthToken>(`${API_VERSION}/auth/otp_sms/login`, { phone_id, code })
      .then((resp: AuthToken) => {
        if (!resp.session_jwt) return;
        authStore.login(resp.session_jwt);
        navigate(`${routes.base}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetPassword = async ({
    password,
    password_reset_token,
  }: AuthResetPassword_Create) => {
    setIsLoading(true);
    await connection
      .post<AuthToken>(`${API_VERSION}/auth/reset_password`, {
        password,
        password_reset_token,
      })
      .then((resp: AuthToken) => {
        if (!resp.session_jwt) return;
        toasterStore.push(alert(t('actions.password_updated'), AlertTypes.success));
        authStore.login(resp.session_jwt);
        if (authStore.isRedirectToMobileNeeded) {
          navigate(`/${routes.authConfirmation}`);
        } else {
          navigate(`${routes.base}`);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendMagicLink = async ({ email }: MagicLink_Create) => {
    setIsLoading(true);

    await connection.post(`${API_VERSION}/auth/magic_link`, { email }).finally(() => {
      setIsLoading(false);
    });
  };

  const sendOtpSms = async ({ phone }: OtpSms_Create) => {
    setIsLoading(true);

    return await connection
      .post<OtpSms_Read>(`${API_VERSION}/auth/otp_sms`, { phone })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const signUp = async ({ password, magic_links_token }: AuthSignup_Create) => {
    setIsLoading(true);
    await connection
      .post<AuthToken>(`${API_VERSION}/auth/signup`, {
        magic_links_token,
        password,
      })
      .then((resp: AuthToken) => {
        if (!resp.session_jwt) return;
        if (authStore.isRedirectToMobileNeeded) {
          navigate(`/${routes.authConfirmation}`);
        } else {
          authStore.login(resp.session_jwt);
          navigate(`${routes.base}`);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const logOut = async () => {
    setIsLoading(true);
    try {
      await deleteToken(messaging);
      await connection.delete(`${API_VERSION}/auth/login`);
      authStore.logout();
      navigate(`${routes.signIn}`);
    } catch (error) {
      toasterStore.push(alert(t('actions.logout_failed'), AlertTypes.error));
    } finally {
      setIsLoading(false);
      window.location.reload();
    }
  };
  return {
    getAuthMethods,
    isLoading,
    login,
    loginWithMagicLink,
    loginWithOtp,
    signUp,
    logOut,
    triggerResetPassword,
    resetPassword,
    sendMagicLink,
    sendOtpSms,
  };
};
