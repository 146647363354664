import CancelOutlined from '@mui/icons-material/CancelOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { cloneElement, useContext, useMemo, useRef } from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { Job } from '~hooks/useJob';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import { JOB_CARD_INNER_GUTTER_SIZE } from './JobCard';
import JobDialogsContext from './JobDialogsContext';

interface JobCardActionButtonProps {
  job: Job;
  onMenuOpenedStateChange: (isOpen: boolean) => void;
}

const JobCardActionButton = observer(
  ({ job, onMenuOpenedStateChange }: JobCardActionButtonProps) => {
    const { userStore } = useStores();
    const jobDialogs = useContext(JobDialogsContext);

    const menuHandler = useRef<MenuHandler>(null);

    const availebleActions = useMemo(() => {
      const actions = [];

      const { canCancelJob, canEdtJob: canEditJob } = userStore.getPermissions();
      const isEditable = job.editable && canEditJob;
      const hasPhone = Boolean(job.driver?.phone);
      const isCancellable = canCancelJob && job.stateEvents.includes('cancel');

      if (isEditable) {
        actions.push({
          icon: <EditOutlined sx={{ color: theme.brandV2.colors.treadOrange }} />,
          label: t('dispatch.job.edit_job'),
          onClick: () => jobDialogs.openEditJobFormDialog(job),
        });
      }

      if (job.copyable) {
        actions.push({
          icon: <FileCopyOutlined sx={{ color: theme.brandV2.colors.treadOrange }} />,
          label: t('dispatch.job.clone_job'),
          onClick: () => jobDialogs.duplicateJob(job),
        });
      }

      if (hasPhone) {
        actions.push({
          icon: <SmsOutlined sx={{ color: theme.brandV2.colors.treadYellowDark }} />,
          label: t('actions.send_text'),
          onClick: () => jobDialogs.openSendTextDialog(job),
        });
      }

      if (isCancellable) {
        actions.push({
          icon: <CancelOutlined sx={{ color: theme.brandV2.colors.treadRed }} />,
          label: t('dispatch.job.cancel_job'),
          onClick: () => jobDialogs.openCancelJobDialog(job),
        });
      }

      return actions;
    }, [job]);

    if (!availebleActions.length) {
      return null;
    }

    return (
      <Box
        data-no-dnd="true"
        sx={{
          position: 'absolute',
          right: theme.spacing(JOB_CARD_INNER_GUTTER_SIZE * -1),
          bottom: theme.spacing(JOB_CARD_INNER_GUTTER_SIZE * -1),
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Menu
          ref={menuHandler}
          onOpenStateChanged={onMenuOpenedStateChange}
          menuTrigger={
            <SmallButton
              color="brandV2TreadGray3"
              variant="text"
              startIcon={<MoreHoriz />}
              sx={{
                '&.MuiButtonBase-root': {
                  border: 'solid 1px transparent',
                  borderRadius: '50%',
                  height: 24,
                  minWidth: 24,
                  p: 0,
                  width: 24,
                  '&:hover': { borderColor: theme.brandV2.colors.treadGray3 },
                  '.MuiButton-startIcon': { mx: 0 },
                },
              }}
            />
          }
        >
          {availebleActions.map((action) => (
            <MenuItem
              key={action.label}
              onClick={(event) => {
                event.stopPropagation();
                action.onClick();
                menuHandler.current?.onClose?.();
              }}
            >
              <Box alignItems="center" display="flex" width="100%" gap={1}>
                {cloneElement(action.icon, {
                  sx: { ...action.icon.props.sx, fontSize: '16px' },
                })}

                <Typography color={theme.brandV2.colors.treadBlack} fontSize="12px">
                  {action.label}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  },
);

export default JobCardActionButton;
