import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import _ from 'lodash';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~pages/Dispatch/NewDispatch';
import theme from '~theme/AppTheme';

const SEARCH_BAR_MAX_WIDTH_IN_PX = 180;

interface SearchBarProps {
  focusOnMount?: boolean;
  alwaysExpanded?: boolean;
  placeHolder?: string;
  setFilter?: (value: string) => void;
}

import React, { forwardRef } from 'react';

type SearchBarRef = {
  focus: () => void;
};

export const SearchBar = forwardRef<SearchBarRef, SearchBarProps>((props, ref) => {
  const { setFilter, alwaysExpanded, placeHolder, focusOnMount = false } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [isExpanded, setIsExpanded] = useState(Boolean(alwaysExpanded));
  const [inputValue, setInputValue] = useState('');

  const inputHasValue = inputValue.length > 0;
  const applyFilter = _.debounce(() => {
    setFilter?.(inputValue);
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  useEffect(() => {
    if (!isExpanded) {
      setInputValue('');
    }
  }, [isExpanded]);

  useEffect(() => {
    applyFilter();

    return () => {
      applyFilter.cancel();
    };
  }, [inputValue]);

  useEffect(() => {
    if (inputRef.current && focusOnMount) {
      inputRef.current.focus();
    }
  }, [focusOnMount]);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }),
    [],
  );

  return (
    <Collapse
      collapsedSize={`calc(18px + (${theme.spacing(1)} * 2)`}
      in={isExpanded}
      orientation="horizontal"
      sx={{ '& .MuiCollapse-wrapperInner': { width: '100%' } }}
    >
      <Box
        borderRadius={theme.brandV2.borderRadius}
        maxWidth={alwaysExpanded ? undefined : SEARCH_BAR_MAX_WIDTH_IN_PX}
        width="100%"
        onClick={() => {
          setIsExpanded(true);
          inputRef.current?.focus();
        }}
      >
        <OutlinedInput
          inputRef={inputRef}
          fullWidth
          onBlur={() => {
            if (!inputHasValue && !alwaysExpanded) {
              setIsExpanded(false);
            }
          }}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeHolder}
          size="small"
          value={inputValue}
          startAdornment={
            <InputAdornment position="start">
              <Search
                sx={{
                  color: theme.brandV2.colors.treadBlack,
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end" onClick={() => setInputValue('')}>
              <Close
                sx={{
                  color: theme.brandV2.colors.treadBlack,
                  cursor: 'pointer',
                  fontSize: '16px',
                  visibility: inputHasValue ? 'visible' : 'hidden',
                }}
              />
            </InputAdornment>
          }
          sx={{
            '&.MuiInputBase-root': {
              height: `calc(${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px - 2px)`,
              px: 1,
              py: 0.5,
              '.MuiInputBase-input': {
                fontSize: '12px',
                p: 0,
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.brandV2.colors.treadGray3}`,
              },
            },
          }}
        />
      </Box>
    </Collapse>
  );
});

SearchBar.displayName = 'SearchBar';
