import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';

import { DialogCloseButton } from '~components/Buttons/DialogCloseButton';
interface DialogHeaderProps {
  title: string | ReactNode;
  closeCallBack: () => void;
}
const DialogHeader = ({ title, closeCallBack }: DialogHeaderProps) => {
  const theme = useTheme();
  return (
    <DialogTitle
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        justifyContent: 'space-between',
        display: 'flex',
      }}
    >
      <Typography component={'span'} variant={'h5'} sx={{ width: '100%' }}>
        {title}
      </Typography>
      <DialogCloseButton callBack={closeCallBack}></DialogCloseButton>
    </DialogTitle>
  );
};
export { DialogHeader };
