import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React from 'react';

const treadWebSiteLink = 'https://tread.io/';

interface CopyrightProps {
  sx: SxProps;
}

const Copyright = ({ sx }: CopyrightProps) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ ...sx }}>
      {'Copyright © '}
      <Link color="inherit" href={treadWebSiteLink}>
        Tread
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
export default Copyright;
