import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';

import OrderStatusChip from '~components/Order/OrderStatusChip';
import { Job, useJob } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';

import OrderInfo from './OrderInfo';
import { OrderJobList } from './OrderJobList';
import OrderSummaryRow from './OrderSummaryRow';

interface OrderCardProps {
  order: Order;
  onSelectOrder: (order: Order, isExpanded: boolean) => void;
  onSelectJob: (job: Job) => void;
  onViewJobRoute: (job: Job) => void;
  selectedOrder?: Order;
  selectedJob?: Job;
}

const OrderCard = observer(
  ({
    order,
    onSelectOrder,
    onSelectJob,
    onViewJobRoute,
    selectedOrder,
    selectedJob,
  }: OrderCardProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [jobs, setJobs] = useState<Job[]>([]);
    const hasFetchedJobs = useRef(false);
    const { getJobsByOrder } = useJob();

    const handleOrderCardClick = () => {
      onSelectOrder(order, selectedOrder?.id === order.id);
      setIsExpanded((prev) => !prev);
      if (!hasFetchedJobs.current) {
        getJobsByOrder(order.id).then(setJobs);
        hasFetchedJobs.current = true;
      }
    };

    return (
      <Box
        bgcolor={'white'}
        borderRadius={theme.brandV2.borderRadius}
        border={`1px solid ${theme.brandV2.colors.treadGray7}`}
        borderLeft={`2px solid ${isExpanded ? theme.palette.info.main : theme.brandV2.colors.treadGray7}`}
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        p={1}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection={'column'}
          gap={1}
          onClick={handleOrderCardClick}
          sx={{ cursor: 'pointer', pl: 0.5 }}
          width="100%"
        >
          <Box display="flex" alignItems="center" width={'100%'}>
            <KeyboardArrowDown
              htmlColor={theme.brandV2.colors.treadGray6}
              sx={{
                transition: 'transform .2s linear',
                ml: -0.5,
                ...(isExpanded ? {} : { transform: 'rotate(-90deg)' }),
              }}
            />
            <Typography fontWeight={600} ml={0.5} mr={'auto'}>
              {order.orderId}
              {order.name ? ` · ${order.name}` : ''}
            </Typography>
            <OrderStatusChip order={order} hideDropdownArrow />
          </Box>

          <OrderInfo order={order} />
        </Box>

        <Collapse in={isExpanded}>
          <OrderSummaryRow order={order} />
          <OrderJobList
            jobs={jobs}
            selectedJobId={selectedJob?.id}
            onSelect={onSelectJob}
            onViewRoute={onViewJobRoute}
          />
        </Collapse>
      </Box>
    );
  },
);

export default OrderCard;
