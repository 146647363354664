import Chip, { ChipOwnProps } from '@mui/material/Chip';
import { SettlementState as SettlementStateEnum } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { memo } from 'react';

type PickedChipProps = Required<Pick<ChipOwnProps, 'label' | 'color'>>;

const chipPropsBySettlementState: Record<SettlementStateEnum, PickedChipProps> = {
  [SettlementStateEnum.PENDING]: {
    label: t('status.pending'),
    color: 'info',
  },
  [SettlementStateEnum.APPROVED]: {
    label: t('status.approved'),
    color: 'success',
  },
  [SettlementStateEnum.PAID]: {
    label: t('status.paid'),
    color: 'success',
  },
  [SettlementStateEnum.CUSTOMER_PENDING]: {
    label: t('status.sent'),
    color: 'primary',
  },
  [SettlementStateEnum.CUSTOMER_APPROVED]: {
    label: t('status.approved'),
    color: 'success',
  },
  [SettlementStateEnum.CUSTOMER_PAID]: {
    label: t('status.paid'),
    color: 'success',
  },
} as const;

interface SettlementStateProps {
  size?: ChipOwnProps['size'];
  state: SettlementStateEnum;
  variant?: ChipOwnProps['variant'];
}

function SettlementState({
  size = 'medium',
  state,
  variant = 'outlined',
}: SettlementStateProps) {
  const { color, label } = chipPropsBySettlementState[state];

  return <Chip color={color} label={label} size={size} variant={variant} />;
}

export default memo(SettlementState);
