import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import ToggleOffOutlined from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlined from '@mui/icons-material/ToggleOnOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { WaypointType } from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import { uniqBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { StatusTabFilters } from '~components/StatusTabFilters/StatusTabFilters';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { ProjectStatusFilter } from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { CompanyBasic, useCompany } from '~hooks/useCompany';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { usePermissions } from '~hooks/usePermissions';
import { Project, ProjectState, useProjects } from '~hooks/useProjects';
import { Site, useSites } from '~hooks/useSites';
import { PaginationLink } from '~interfaces';
import { ProjectForm } from '~pages/Sales/Projects/ProjectForm';
import { ProjectFormSchemaInterface } from '~pages/Sales/Projects/projectFormSchema';
import { ErrorResponse, FieldError } from '~src/services/connectionModule';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';
import { isActionClicked } from '~utils/utilFunctions';

import { FilterByMultipleValues } from '../FilterByMultipleValues';
import { createSitesInline } from '../Orders/helpers';

interface GetCompaniesProps {
  id: string;
}

interface AddDefaultSiteType {
  siteId: string;
  waypointType: WaypointType;
}

type NewProjectFormStateChange = FormStateChangeProps & Partial<AddDefaultSiteType>;

const ProjectsDataGrid = observer(() => {
  const projectActionsRef = useRef<any>(null);
  const modalDialogRef = useRef<any>(null);

  const { getAllUserAvailableCompanies } = useCompany();
  const {
    deleteProject,
    updateProject,
    createProject,
    getCompanyProjects,
    activateProject,
    deActivateProject,
    isLoadingProjects,
  } = useProjects();
  const [asyncErrors, setAsyncErrors] = useState<FieldError[]>([]);
  const { addSiteToProjectDefaultSites, createNewSite } = useSites();
  const { isLoading: isPermsLoading } = usePermissions();
  const { searchValue, setSearchQueryValue } = useDataGridSearch();
  const { toasterStore, userStore, projectsStore } = useStores();
  const [options, setOptions] = useState<Nullable<CompanyBasic[]>>([]);
  const [currentQuickFilter, setCurrentQuickFilter] = useState<ProjectStatusFilter>(
    ProjectStatusFilter.ACTIVE,
  );
  const [companyFilterValues, setCompanyFilterValues] = useState<string[]>([]);
  const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false);
  const [currentFormDirty, setCurrentFormDirty] = useState<boolean>(false);
  const [isProjectDialogOpen, setIsProjectDialogOpen] = useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] = useState<Nullable<string>>(null);
  const [newDefaultPickUpSites, setNewDefaultPickUpSites] = useState<
    AddDefaultSiteType[]
  >([]);
  const [newDefaultDropOffSites, setNewDefaultDropOffSites] = useState<
    AddDefaultSiteType[]
  >([]);

  const theme = useTheme();
  const isLoading = isPermsLoading || isLoadingProjects;
  const filterOptions = currentQuickFilter !== 'all' ? currentQuickFilter : null;
  const rows = projectsStore.projects;
  const statusFilters = [
    { value: ProjectStatusFilter.ACTIVE, label: 'common.active' },
    { value: ProjectStatusFilter.INACTIVE, label: 'common.inactive' },
    { value: ProjectStatusFilter.All, label: 'common.all' },
  ];

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);
  const companiesOptions = useMemo(() => {
    return [...new Set(projectsStore.projects.map((row) => row.company?.legalName))];
  }, [projectsStore.projects]);

  const resetTempDefaultSitesLists = () => {
    setNewDefaultPickUpSites([]);
    setNewDefaultDropOffSites([]);
  };

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    if (userStore.userCompany?.id) {
      getCompanies({
        id: userStore.userCompany.id,
      });
    }
  }, []);

  useEffect(() => {
    getCompanyProjects({} as PaginationLink, searchValue, {
      states: [filterOptions],
    });
  }, [filterOptions]);

  const updateFilters = (values: string[]) => {
    setCompanyFilterValues(values);
  };

  const onFormStateChange = ({
    isDirty,
    siteId,
    waypointType,
  }: NewProjectFormStateChange) => {
    setCurrentFormDirty(isDirty);
    // Need to store the saved sites that a user added to the
    // New order so that we can save them to the order when
    // It is created.
    if (siteId) {
      waypointType === WaypointType.PICKUP &&
        setNewDefaultPickUpSites((pickUpSites) =>
          uniqBy([...pickUpSites, { siteId, waypointType }], 'siteId'),
        );
      waypointType === WaypointType.DROP_OFF &&
        setNewDefaultDropOffSites((dropOffSites) =>
          uniqBy([...dropOffSites, { siteId, waypointType }], 'siteId'),
        );
    }
  };

  const selectedProject = useMemo(() => {
    return (
      projectsStore.projects.find(
        (project: Project) => project.id === selectedProjectId,
      ) || null
    );
  }, [selectedProjectId, rows]);

  const createNew = () => {
    setSelectedProjectId(null);
    setIsProjectDialogOpen(true);
  };

  const handleClose = async (onSubmitSuccess: boolean | null = null) => {
    if (onSubmitSuccess) {
      setShowConfirmCloseDialog(false);
      setIsProjectDialogOpen(false);
    } else if (currentFormDirty) {
      setShowConfirmCloseDialog(true);
    } else {
      await projectActionsRef.current?.fileAttachmentsOnClose();
      setIsProjectDialogOpen(false);
    }
    resetTempDefaultSitesLists();
    setAsyncErrors([]);
  };

  const confirmClose = async () => {
    await projectActionsRef.current?.fileAttachmentsOnClose();
    setIsProjectDialogOpen(false);
    setShowConfirmCloseDialog(false);
  };

  const cancelClose = () => {
    setShowConfirmCloseDialog(false);
  };

  const onNewProjectSuccess = async (project?: Project) => {
    if (project) {
      await onSuccess(project);

      const newDefaultSites = [...newDefaultPickUpSites, ...newDefaultDropOffSites];

      // Add reduce loop and make sequential calls
      // To save sites to new orders to avoid race condition
      // https://jrsinclair.com/articles/2019/how-to-run-async-js-in-parallel-or-sequential/
      if (newDefaultSites.length > 0) {
        await newDefaultSites.reduce(
          async (acc, site) => {
            await acc;
            return await addSiteToProjectDefaultSites({
              projectId: project.id,
              siteId: site.siteId,
              waypointType: site.waypointType,
            });
          },
          Promise.resolve({} as Site),
        );

        resetTempDefaultSitesLists();
      }
    }
  };

  const onSuccess = async (project: Project) => {
    await projectActionsRef.current?.fileAttachmentsOnSubmit(project.id);
    setSelectedProjectId(null);
    handleClose(true);
    toasterStore.push(
      alert(
        selectedProjectId
          ? $t('account.account_updated', { name: project.name })
          : $t('account.account_created', { name: project.name }),
        AlertTypes.success,
      ),
    );
  };

  const deleteProjectCallBack = () => {
    if (selectedProjectId) {
      deleteProject({
        id: selectedProjectId,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(alert('deleted', AlertTypes.success)); //Veni add translations
        },
      });
    }
  };

  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
    getCompanyProjects({} as PaginationLink, searchQuery, { states: [filterOptions] });
  };

  const onSubmitProjectForm = () => {
    projectActionsRef.current?.submit(onSubmitProjectFormCallBack);
  };

  const onSubmitProjectFormCallBack = async (data: ProjectFormSchemaInterface) => {
    if (selectedProjectId) {
      const newData = await createSitesInline(
        data,
        createNewSite,
        userStore.userCompany?.siteRadius,
      );
      updateProject({
        project: newData as ProjectFormSchemaInterface,
        callBack: onSuccess,
      }).catch((error: AxiosError<ErrorResponse>) => {
        setAsyncErrors(error.response?.data.error.errors || []);
      });
    } else {
      const newData = await createSitesInline(
        data,
        createNewSite,
        userStore.userCompany?.siteRadius,
      );
      createProject({
        project: newData as ProjectFormSchemaInterface,
        callBack: onNewProjectSuccess,
      }).catch((error: AxiosError<ErrorResponse>) => {
        setAsyncErrors(error.response?.data.error.errors || []);
      });
    }
  };

  const deleteRow = (id: string) => {
    setSelectedProjectId(id);
    modalDialogRef.current?.open();
  };

  const editRow = (id: string) => {
    setSelectedProjectId(id);
    setIsProjectDialogOpen(true);
  };

  const projectColumns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params.row.name}>
                  <Typography component="span">{params.value}</Typography>
                </BasicTooltip>
              </>
            );
          },
          valueGetter: (params: GridValueGetterParams) => {
            const { name } = params.row;
            return name;
          },
        },
        {
          field: 'projectId',
          headerName: $t('project.form.project_id'),
          flex: 1,
        },
        {
          field: 'poJobNumber',
          headerName: $t('project.form.po_job_number'),
          flex: 1,
        },
        {
          field: 'externalId',
          headerName: $t('project.form.project_external_id'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params?.row?.externalId || 'No External Id'}>
                  <Typography component="span">{params?.row?.externalId}</Typography>
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'company',
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row?.company?.legalName))],
          headerName: $t('form_fields.company'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.legalName;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params.value}>
                  <Typography component="span">{params.value}</Typography>
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'account',
          headerName: $t('form_fields.account'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.account?.name))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.name;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params.value}>
                  <Typography component="span">{params.value}</Typography>
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'department',
          type: 'singleSelect',
          valueOptions: [
            ...new Set(
              rows.filter((row) => !!row?.department).map((row) => row?.department?.name),
            ),
          ],
          headerName: $t('form_fields.department'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.name;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params.value}>
                  <Typography component="span">{params.value}</Typography>
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'state',
          headerName: $t('form_fields.status'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.state))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <BasicTooltip title={params.value}>
                  <Chip
                    label={params.value}
                    color={params.value === ProjectState.ACTIVE ? 'success' : 'default'}
                    variant={'outlined'}
                  />
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'effectiveAt',
          headerName: $t('project.form.effective_at'),
          flex: 1,
          type: 'dateTime',
          valueGetter: (params: GridValueGetterParams) => {
            return params.row?.effectiveAt ? new Date(params.row.effectiveAt) : null;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                title={`${$t('project.form.effective_at')}: ${dateFormat(
                  params?.row?.effectiveAt,
                )}`}
              >
                <Typography component="span">
                  {params?.row?.effectiveAt ? dateFormat(params.row.effectiveAt) : null}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'expiresAt',
          headerName: $t('project.form.expires_at'),
          flex: 1,
          type: 'dateTime',
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.expiresAt ? new Date(params.row.expiresAt) : null;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                title={`${$t('project.form.expires_at')}: ${dateFormat(
                  params?.row?.expiresAt,
                )}`}
              >
                <Typography component="span">
                  {params.row.expiresAt ? dateFormat(params.row.expiresAt) : null}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'notes',
          headerName: $t('form_fields.notes'),
          flex: 1,
        },
        {
          field: 'actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={projectsStore.projects?.length || 0}
              loading={loadingRef.current}
              pagination={projectsStore.projectsPagination}
              callback={getCompanyProjects}
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params) => {
            const isActive = params.row.state === ProjectState.ACTIVE;
            const options = [];
            if (userPermissions.canDeleteProject) {
              options.push({
                title: `${t('actions.delete')}`,
                icon: <Delete />,
                callBack: () => deleteRow(params.row.id),
              });
            }

            if (userPermissions.canEditProject) {
              options.push({
                title: `${isActive ? t('actions.deactivate') : t('actions.activate')}`,
                icon: isActive ? (
                  <ToggleOffOutlined />
                ) : (
                  <ToggleOnOutlined color={'primary'} />
                ),
                callBack: () =>
                  !isActive
                    ? activateProject(params.row.id)
                    : deActivateProject(params.row.id),
              });
            }

            return (
              <>
                {userPermissions.canEditProject && (
                  <IconButton size="small" onClick={() => editRow(params.row.id)}>
                    <Edit />
                  </IconButton>
                )}
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows, userPermissions],
  );

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          poJobNumber: false,
          // Hide columns status and traderName, the other columns will remain visible
          dbaName: false,
          notes: false,
          department: false,
        },
      },
    }),
    [],
  );

  const getCompanies = ({ id }: GetCompaniesProps) => {
    getAllUserAvailableCompanies({
      id: id,
      callBack: setOptions,
    });
  };

  return (
    <>
      <Box mb={2}>
        <StatusTabFilters
          value={currentQuickFilter as ProjectStatusFilter}
          onChange={(tab) => setCurrentQuickFilter(tab)}
          filters={statusFilters}
        />
      </Box>
      <Box>
        <DataGrid
          id="projects-grid"
          // Sx={{ maxWidth: `calc(100% - ${theme.spacing(3)})` }}
          columns={projectColumns}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          initialState={initialState}
          onChangeFilter={onChangeQuickFilter}
          headerSidebarComponent={
            <Box display={'flex'} alignItems={'center'} width={'100%'}>
              {options && options?.length > 1 && companiesOptions?.length ? (
                <FilterByMultipleValues
                  values={companyFilterValues}
                  options={companiesOptions}
                  placeholder={`${$t('form_fields.company')}`}
                  label={''}
                  name={'companyFilter'}
                  callBack={updateFilters}
                />
              ) : null}
            </Box>
          }
          headerActionsComponent={
            <>
              {userPermissions.canCreateProject && (
                <Box display={'flex'}>
                  <Button
                    data-test-id={'create-company-btn'}
                    color={'primary'}
                    onClick={createNew}
                    startIcon={<Add />}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {t('project.create_project')}
                  </Button>
                </Box>
              )}
            </>
          }
        />
      </Box>
      <Dialog
        open={isProjectDialogOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
        maxWidth={'lg'}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={
            <>
              <Typography component={'span'} variant={'h5'}>
                {t(`project.${selectedProjectId ? 'update_project' : 'create_project'}`)}
              </Typography>
              <Typography>{selectedProject?.projectId}</Typography>
            </>
          }
        />
        <DialogContent
          sx={{
            backgroundColor: theme.palette.grey[100],
          }}
        >
          <ProjectForm
            ref={projectActionsRef}
            onFormStateChange={onFormStateChange}
            defaultProject={selectedProject}
            company={options?.length === 1 ? options[0] : null}
            errors={asyncErrors}
          ></ProjectForm>
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LoadingButton
            disabled={isLoading || !currentFormDirty}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<></>}
            onClick={onSubmitProjectForm}
            data-test-id={'create-project-btn'}
            type="button"
            variant="contained"
            color="primary"
            sx={isLoading ? { pl: 5, pr: 2 } : { pr: 2 }}
          >
            {$t(`actions.${selectedProjectId ? 'submit' : 'create'}`)}
          </LoadingButton>
          <Button
            onClick={() => handleClose(null)}
            sx={{ mr: 2, px: 2 }}
            disabled={isLoading}
            color="secondary"
            variant="outlined"
          >
            {$t('actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showConfirmCloseDialog}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && cancelClose();
        }}
      >
        <DialogHeader
          closeCallBack={cancelClose}
          title={t(`project.confirm_exit_title`)}
        />
        <DialogContent>{t(`project.confirm_exit_text`)}</DialogContent>
        <DialogActions>
          <Button onClick={cancelClose} color="secondary" variant="outlined">
            {t(`actions.no`)}
          </Button>
          <Button onClick={confirmClose} color="primary">
            {t(`actions.exit`)}
          </Button>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        loading={isLoading}
        title={$t('project.delete_project_title')}
        content={$t('project.delete_project_text')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteProjectCallBack}
      />
    </>
  );
});
export { ProjectsDataGrid };
