import FormControl from '@mui/material/FormControl';
import { SxProps } from '@mui/material/styles';
import { MuiOtpInput, MuiOtpInputProps } from 'mui-one-time-password-input';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';

enum OtpType {
  ALPHANUMERIC = 'alphanumeric',
  NUMERIC = 'numeric',
}

interface OtpFormFieldProps extends Omit<MuiOtpInputProps, 'onChange' | 'value'> {
  control: Control | undefined;
  errors: FieldErrors<FieldValues>;
  length: number;
  name: string;
  otpType?: `${OtpType}`;
  sx?: SxProps;
}

const validateCharFnByOtpType: Record<OtpType, MuiOtpInputProps['validateChar']> = {
  [OtpType.ALPHANUMERIC]: (value) => /[0-9a-zA-Z]/.test(value),
  [OtpType.NUMERIC]: (value) => /[0-9]/.test(value),
};

const OtpFormField = ({
  control,
  errors,
  length,
  name,
  otpType = 'numeric',
  sx,
}: OtpFormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <FormControl error={fieldState.invalid} sx={{ ...sx }}>
            <MuiOtpInput
              length={length}
              value={field.value}
              onChange={(value) => field.onChange(value)}
              validateChar={validateCharFnByOtpType[otpType]}
            />
          </FormControl>
        );
      }}
    />
  );
};

export { OtpFormField };
