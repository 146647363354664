import { User_Create, User_Me_Read as UserProto } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { UserNotificationsPreferenceDTO } from '~components/UserForm/UserNotificationsPreferenceForm';
import { API_VERSION } from '~constants/consts';
import { SystemRoles } from '~constants/enums';
import { User } from '~hooks/useUsers/models';
import { GetPaginationParams, PaginationLink } from '~interfaces/pagination';
import connection from '~services/connectionModule';
import { useStores } from '~store';
import { humanToSnakeCase } from '~utils/utilFunctions';

type CallbackType = (user?: User) => void;
interface GetFilteredUsersParams {
  search?: string;
  access?:
    | 'execute_jobs'
    | 'manage_sites'
    | 'manage_materials'
    | 'manage_equipment'
    | 'manage_accounts'
    | 'manage_users';
  roleNames?: Array<SystemRoles>;
}

interface GetFilteredUsersQueryProps {
  'page[limit]'?: number;
  'filter[access]'?: string;
  'search[typeahead]'?: string;
  'filter[iam_roles][]'?: Array<string>;
}

export const useUsers = () => {
  const [isLoadingAllUsers, setIsLoadingAllUsers] = useState(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const { userStore, jobStore } = useStores();
  //Async to make sure we have user on initial app load
  const getUser = async () => {
    setIsLoadingUser(true);
    try {
      const resp: UserProto = await connection.get<UserProto>(
        `${API_VERSION}/users/me`,
        {},
        $t('error_messages.users.failed_to_fetch_current_user') as string,
      );
      const parsedUser = User.parse(resp);

      userStore.setUser(parsedUser);

      return parsedUser;
    } catch (error) {
      // Shouldn't happen will be caught in the interceptor
      console.error('Error fetching user:', error);
    } finally {
      setIsLoadingUser(false);
    }
  };

  const getUserById = async (id: string) => {
    setIsLoadingUser(true);
    try {
      const resp: UserProto = await connection.get<UserProto>(
        `${API_VERSION}/users/${id}`,
        {},
        $t('error_messages.users.failed_to_fetch_selected') as string,
      );
      return User.parse(resp);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUser(false);
    }
  };

  interface GetCompanyUsersProps {
    companyId: string;
    limit?: number;
    link?: PaginationLink;
    callback?: (users: User[]) => void;
  }
  interface GetCompanyUsersTypeaheadProps {
    callback?: (users: User[]) => void;
    companyId: string;
    limit?: number;
    query?: string;
    cursor?: string;
    roleName?: SystemRoles;
  }

  interface GetCompanyUsersTypeaheadQueryProps {
    'filter[iam_roles][]': string;
    'page[limit]': number;
    'search[query]': string;
    'page[after]': string;
  }

  const getCompanyUsers = (options: GetCompanyUsersProps) => {
    const params: GetPaginationParams = {
      'page[limit]': options.limit || userStore.userManagementPagination.limit,
    };
    if (options.link && userStore.userManagementPagination[options.link]) {
      params[`page[${options.link}]`] = userStore.userManagementPagination[options.link];
    }
    setIsLoadingAllUsers(true);

    return connection
      .getPaginated<UserProto>(
        `${API_VERSION}/companies/${options.companyId}/users`,
        { params },
        $t('error_messages.users.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        userStore.setUsers(data.map((user: UserProto) => User.parse(user)));
        userStore.setUserManagementPagination(pagination);
        userStore.updateUserManagementPageNumber(options.link);
      })
      .finally(() => {
        setIsLoadingAllUsers(false);
      });
  };

  const getCompanyUsersTypeahead = (options: GetCompanyUsersTypeaheadProps) => {
    const params: Partial<GetCompanyUsersTypeaheadQueryProps> = {
      'page[limit]': options.limit ?? 100,
    };

    if (options.roleName) {
      params['filter[iam_roles][]'] = humanToSnakeCase(options.roleName);
    }

    if (options.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    return connection
      .getPaginated<UserProto>(
        `${API_VERSION}/companies/${options.companyId}/users/typeahead`,
        { params },
        $t('error_messages.users.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const users = data.map((user) => User.parse(user));

        options.callback?.(users);
        return { data: users, pagination };
      });
  };

  const getFilteredUsers = (options: GetFilteredUsersParams) => {
    const params: GetFilteredUsersQueryProps = {
      'page[limit]': 100,
      'filter[access]': options.access,
      'filter[iam_roles][]': options.roleNames?.map((role) => humanToSnakeCase(role)),
    };

    if (options.search) {
      params['search[typeahead]'] = options.search;
    }

    return connection
      .get<
        UserProto[]
      >(`${API_VERSION}/users`, { params }, $t('error_messages.users.failed_to_fetch') as string)
      .then((resp) => {
        return resp.map((user: UserProto) => User.parse(user));
      });
  };

  const getFilteredCompanyUsers = (
    companyId: string,
    options: GetFilteredUsersParams,
  ) => {
    const params: GetFilteredUsersQueryProps = {
      'page[limit]': 100,
      'filter[access]': options.access,
    };

    if (options.search) {
      params['search[typeahead]'] = options.search;
    }

    const url = `${API_VERSION}/companies/${companyId}/users`;

    return connection
      .get<
        UserProto[]
      >(url, { params }, $t('error_messages.users.failed_to_fetch') as string)
      .then((resp) => {
        const users = resp.map((user: UserProto) => User.parse(user));
        if (options.access === 'execute_jobs') {
          jobStore.setDrivers(companyId, users);
        }
        return resp.map((user: UserProto) => User.parse(user));
      });
  };

  const updateUser = async (
    userData: User,
    userNotificationsPreferenceData?: UserNotificationsPreferenceDTO,
  ) => {
    setIsUpdating(true);

    try {
      const response = await connection.patch<UserProto>(
        `${API_VERSION}/users/${userData.id}`,
        User.deparse(userData, undefined, userNotificationsPreferenceData),
        {},
        $t('error_messages.users.failed_to_update') as string,
        [422],
      );
      const user = User.parse(response);

      userStore.updateUser(user);

      return user;
    } finally {
      setIsUpdating(false);
    }
  };

  const createUser = async (
    userData: User,
    invitesToSend: User_Create['send_invite'] = {},
  ) => {
    setIsUpdating(true);

    try {
      const response = await connection.post<UserProto>(
        `${API_VERSION}/users`,
        User.deparse(userData, invitesToSend),
        {},
        $t('error_messages.users.failed_to_create') as string,
        [422],
      );
      const user = User.parse(response);

      userStore.addUser(user);

      return user;
    } finally {
      setIsUpdating(false);
    }
  };

  const deleteUser = (id: string, callBack?: CallbackType) => {
    setIsUpdating(true);
    return connection
      .delete(
        `${API_VERSION}/users/${id}`,
        {},
        $t('error_messages.users.failed_to_delete') as string,
      )
      .then(() => {
        userStore.deleteUser(id);
        callBack?.();
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const sendInvitation = (id: string, method: 'email' | 'sms') => {
    setIsUpdating(true);

    return connection
      .put(
        `${API_VERSION}/users/${id}/invite/${method}`,
        {},
        {},
        $t('error_messages.users.failed_to_send_invite', { method }) as string,
      )
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const addUserDeviceToken = async (token: string) => {
    try {
      const response = await connection.post(
        `${API_VERSION}/users/me/device_tokens`,
        {
          token: token,
        },
        {},
        $t('error_messages.users.failed_to_add_device_token') as string,
      );
      return response;
    } catch (error) {
      console.error('Error adding device token:', error);
    }
  };

  return {
    addUserDeviceToken,
    createUser,
    deleteUser,
    getCompanyUsers,
    getCompanyUsersTypeahead,
    getFilteredCompanyUsers,
    getFilteredUsers,
    getUser,
    getUserById,
    isLoadingAllUsers,
    isLoadingUser,
    isUpdating,
    sendInvitation,
    updateUser,
  } as const;
};
