enum DispatchSearchParam {
  ACTIVE = 'active',
  DAY_RANGE = 'day_range',
  DONE = 'done',
  DATE_RANGE = 'date_range',
  DISPATCH_NUMBER = 'dispatch_numbers',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  PROJECT = 'project_ids',
  EXTERNAL_ID = 'external_ids',
  SERVICE = 'service_ids',
  CUSTOMER_ACCOUNT = 'customer_account_ids',
  VENDOR_ACCOUNT = 'vendor_account_ids',
  PICKUP_SITE = 'pickup_site_ids',
  DROPOFF_SITE = 'dropoff_site_ids',
  EQUIPMENT_TYPE = 'equipment_type_ids',
  MATERIAL = 'material_ids',
  DRIVER = 'driver_ids',
  STATE = 'states',
  STATUS = 'status',
}

export default DispatchSearchParam;
