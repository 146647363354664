import { t as $t } from 'i18next';
import React, { useEffect, useState } from 'react';

import { SmallTabs } from '~components/Tabs/SmallTabs';
import { Job, useJob } from '~hooks/useJob';

import { JobDetails } from './JobDetails';
import { LoadDetails } from './LoadDetails';

interface Props {
  details: Job;
}

const JobDetailsContainer = ({ details }: Props) => {
  const { getJobById } = useJob();
  const [detailedJob, setDetailedJob] = useState<Job>(details);

  // Set detailedJob if the details passed in changes
  // We shouldn't need to store the state at this level, but see note below
  useEffect(() => {
    setDetailedJob(details);
  }, [details]);

  // Not sure what case we would have to reload the job details given that the parentshould
  // Be responsible for providing the updated details
  // However, will keep this here for now until there is more time to investigate
  useEffect(() => {
    if (!details.id) return;
    getJobById(details.id).then((job) => {
      setDetailedJob(job);
    });
  }, [details.id]);

  return (
    <SmallTabs
      sx={{ p: 2 }}
      tabs={[
        {
          label: $t('order.form.loads'),
          id: `loads_${details.id}`,
          content: (
            <LoadDetails
              details={detailedJob}
              reload={() => {
                getJobById(details.id).then((job) => {
                  setDetailedJob(job);
                });
              }}
            />
          ),
        },
        {
          label: $t('dispatch.job.details'),
          id: `details_${details.id}`,
          content: <JobDetails details={detailedJob} />,
        },
      ]}
    />
  );
};

export { JobDetailsContainer };
