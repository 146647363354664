import { t } from 'i18next';
import * as yup from 'yup';

import { FORM_ERRORS } from '~constants/errorMessagesConsts';
import { emailRegex, phoneSchema } from '~constants/regexConst';

export const SIGN_IN_OTP_LENGTH = 6;

const emailSchema = yup
  .string()
  .email(`${t('form_validation_errors.required', { field: t('form_fields.email') })}`)
  .matches(emailRegex, `${t('form_validation_errors.invalid_email')}`)
  .required(() => t('form_validation_errors.required', { formField: 'email' }));

const getAuthMethodsValidationSchema = yup.object().shape({
  email: emailSchema,
});

const sendMagicLinkValidationSchema = yup.object().shape({
  email: emailSchema,
});

const forgotPasswordValidationSchema = yup.object().shape({
  email: emailSchema,
});

const makeOtpSchema = (length: number) => {
  const errorMessage = `${t('form_validation_errors.otp_numeric_length', { length })}`;

  return yup.string().length(length, errorMessage);
};

const signInWithEmailAndPasswordValidationSchema = yup.object().shape({
  email: emailSchema,
  password: yup.string().min(8).max(44).required(),
});

const signInWithPhoneNumberValidationSchema = yup.object().shape({
  phoneNumber: phoneSchema.required(),
});

const signInWithOtpValidationSchema = yup.object().shape({
  otp: makeOtpSchema(SIGN_IN_OTP_LENGTH).required(
    `${t('form_validation_errors.required', { field: 'Code' })}`,
  ),
});

const signUpResetSchema = yup.object().shape({
  // Email: yup.string().matches(emailRegex).required(),
  password: yup
    .string()
    .required(
      t('form_validation_errors.required', {
        field: t('form_field_labels.password'),
      }) || '',
    )
    // Check minimum characters
    .min(8, t('form_validation_errors.passwordLength', { length: 8 }) || ''),
  // Keep here, we will enforce our policy once onboardng is done
  // Different error messages for different requirements
  // .matches(/[0-9]/, getCharacterValidationError('digit'))
  // .matches(/[a-z]/, getCharacterValidationError('lowercase'))
  // .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
  // .matches(
  //   /.*[!@#$%^&*()\-=+{}[\]|\\;:'",.<>/?`~].*/,
  //   GetCharacterValidationError('special (!@#$%^&*())=+{};:\'",.<>/?`~) '),
  // ),
  passwordConfirmation: yup
    .string()
    .required(FORM_ERRORS.required)
    .oneOf(
      [yup.ref('password'), ''],
      t('form_validation_errors.match', {
        field: t('form_field_labels.password'),
      }) || '',
    ),
});
export {
  emailSchema,
  forgotPasswordValidationSchema,
  getAuthMethodsValidationSchema,
  sendMagicLinkValidationSchema,
  signInWithEmailAndPasswordValidationSchema,
  signInWithOtpValidationSchema,
  signInWithPhoneNumberValidationSchema,
  signUpResetSchema,
};
