import { t } from 'i18next';
import _ from 'lodash';
import * as yup from 'yup';

import {
  nameIdNonRequiredSchema,
  nameIdSchemaRequired,
  nameIdTypeSchema,
  notesSchema,
  personNameSchema,
  phoneSchema,
  waypointSchema,
} from '~constants/regexConst';
import { CompanyBasic } from '~hooks/useCompany';
import { Order } from '~hooks/useOrders';
import { EmptyContactItem } from '~pages/Sales/Projects/projectFormSchema';
import { Nullable } from '~types/Nullable';

import { fileAttachmentSchema } from '../../../formsShared/components/FileAttachments';

export const newOrderFormSchema = yup.object().shape({
  id: yup.string(),
  company: yup.object().when('id', {
    // @ts-ignore
    is: '',
    then: () => {
      yup.object().shape({
        legalName: yup.string().required(),
        id: yup.string().required(),
      });
    },
    otherwise: () => {
      yup.object().notRequired();
    },
  }),
  jobStartAt: yup.string().required(),
  loadAt: yup.string().required(),
  loadAtTime: yup.string().nullable(),
  loadAtDate: yup.string().nullable(),
  orderId: yup.string(),
  notes: notesSchema,
  jobNotes: notesSchema,
  projectNotes: notesSchema,
  orderNotes: notesSchema,
  internalNotes: notesSchema,
  jobTime: yup.number().nullable(),
  truckCount: yup.number().typeError('Numbers only.').required(),
  truckDelay: yup.number().nullable(),
  trucksPerDelay: yup
    .number()
    .min(1, () => t('form_fields.trucks_per_delay_min'))
    .nullable()
    .test(
      'is-not-greater-than-truckCount',
      `${t('form_validation_errors.trucks_per_delay_greater_than_truck_count')}`,
      (value, context) => {
        if (_.isNil(value)) return true;
        if (!context.parent.truckCount) return true;
        return value <= context.parent.truckCount;
      },
    ),
  loadsPerTruck: yup.number().nullable(),
  unitOfMeasure: nameIdSchemaRequired.required(),
  account: nameIdSchemaRequired.required(),
  project: nameIdNonRequiredSchema.nullable(),
  material: yup.object().required(),
  equipmentType: yup.object().notRequired(),
  service: nameIdTypeSchema.required(),
  quantity: yup.number().typeError('Numbers only.').required(),
  state: yup.string(),
  pickUpWayPoint: waypointSchema.required(),
  dropOffWayPoint: waypointSchema.required(),
  phase: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
      code: yup.string(),
    })
    .notRequired(),
  phaseName: yup.string().notRequired(),
  foremen: yup.array().of(yup.object().shape({ id: yup.string().required() }).required()),
  allContacts: yup.array().of(
    yup.object().shape({
      name: personNameSchema(`${t('form_fields.name')}`).notRequired(),
      phone: phoneSchema,
      type: nameIdNonRequiredSchema,
      isDeleted: yup.boolean().notRequired(),
    }),
  ),
  projectMaterialTotals: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          material: nameIdNonRequiredSchema,
          unitOfMeasure: nameIdNonRequiredSchema,
          totalQuantity: yup.string().notRequired(),
          id: yup.string(),
        })
        .notRequired(),
    )
    .notRequired(),
  allPhaseCodes: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          id: yup.string(),
          name: yup.string(),
          code: yup.string(),
        })
        .notRequired(),
    )
    .notRequired(),
  department: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  name: yup.string().notRequired(),
  poJobNumber: yup.string().notRequired(),
  zone: yup.string().notRequired(),
  externalId: yup.string().notRequired(),
  serviceClass: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  grossCapacity: yup.string().notRequired(),
  unitsPerHour: yup.number().nullable(),
  jobQuantity: yup.string(),
  dispatchNumber: yup.string().notRequired(),
  fileAttachments: fileAttachmentSchema,
});

export const setDefaultOrderValues = (
  defaultOrder: Order,
  company?: Nullable<CompanyBasic>,
) => {
  const pickUpWayPoint = defaultOrder?.pickUpWayPoints?.[0];
  const dropOffWayPoint = defaultOrder?.dropOffWayPoints?.[0];
  return {
    id: defaultOrder?.id || '',
    company: defaultOrder?.company || company || null,
    jobStartAt: defaultOrder?.jobStartAt || '',
    loadAt: defaultOrder?.loadAt || '',
    loadAtDate: defaultOrder?.loadAt || '',
    loadAtTime: defaultOrder?.loadAt || '',
    notes: defaultOrder?.notes || '',
    jobNotes: defaultOrder?.jobNotes || '',
    projectNotes: '',
    orderNotes: defaultOrder?.notes || '',
    internalNotes: defaultOrder?.internalNotes || '',
    jobTime: defaultOrder?.jobTime || null,
    jobQuantity: defaultOrder?.jobQuantity || '',
    state: defaultOrder?.state || '',
    truckCount: defaultOrder?.orderId ? defaultOrder?.jobsCount : 1,
    truckDelay: defaultOrder?.truckDelay || 0,
    trucksPerDelay: defaultOrder?.trucksPerDelay || 1,
    loadsPerTruck: defaultOrder?.loadsPerTruck || 1,
    unitOfMeasure: {
      name: defaultOrder?.unitOfMeasure?.name || '',
      id: defaultOrder?.unitOfMeasure?.id || '',
    },
    account: {
      name: defaultOrder?.account?.name || '',
      id: defaultOrder?.account?.id || '',
    },
    orderId: defaultOrder?.orderId || '',
    project: {
      name: defaultOrder?.project?.name || '',
      id: defaultOrder?.project?.id || '',
      externalId: defaultOrder?.project?.externalId || '',
    },
    projectExternalId: defaultOrder?.project?.externalId || null,
    material: defaultOrder?.material || null,
    equipmentType: defaultOrder?.equipmentType || null,
    service: defaultOrder?.service || null,
    quantity: defaultOrder?.quantity,
    pickUpWayPoint: pickUpWayPoint?.id
      ? {
          id: pickUpWayPoint?.id || '',
          type: pickUpWayPoint?.type || '',
          ordinality: pickUpWayPoint?.ordinality ?? 10,
          contact: {
            name: pickUpWayPoint?.contact?.name || '',
            phone: pickUpWayPoint?.contact?.phone || '',
          },
          site: {
            id: pickUpWayPoint?.site?.id || '',
            name: pickUpWayPoint?.site?.name || '',
          },
        }
      : null,
    dropOffWayPoint: dropOffWayPoint?.id
      ? {
          id: dropOffWayPoint?.id || '',
          type: dropOffWayPoint?.type || '',
          ordinality: dropOffWayPoint?.ordinality ?? 10,
          contact: {
            name: dropOffWayPoint?.contact?.name || '',
            phone: dropOffWayPoint?.contact?.phone || '',
          },
          site: {
            id: dropOffWayPoint?.site?.id || '',
            name: dropOffWayPoint?.site?.name || '',
          },
        }
      : null,
    allContacts: defaultOrder?.allContacts?.length
      ? defaultOrder.allContacts.map((single) => {
          return {
            id: single.id || undefined,
            name: single.name || '',
            phone: single.phone || '',
            type: single.type || undefined,
            isDeleted: false,
          };
        })
      : [EmptyContactItem],
    phase: {
      id: defaultOrder?.phase?.id || '',
      name: defaultOrder?.phase?.name || '',
      code: defaultOrder?.phase?.code || '',
    },
    phaseCode: defaultOrder?.phase?.code || '',
    department: defaultOrder?.department || {},
    foremen: defaultOrder?.foremen || [],
    name: defaultOrder?.name || '',
    poJobNumber: defaultOrder?.poJobNumber || '',
    zone: defaultOrder?.zone || '',
    externalId: defaultOrder?.externalId || '',
    serviceClass: defaultOrder?.serviceClass || {},
    grossCapacity: defaultOrder?.equipmentType?.grossCapacity || '',
    unitsPerHour: defaultOrder?.unitsPerHour || null,
    dispatchNumber: defaultOrder?.dispatchNumber || '',
  };
};
export type NewOrderFormSchemaInterface = yup.InferType<typeof newOrderFormSchema>;
