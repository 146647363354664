import { useEffect, useState } from 'react';

import { GetPaginationParams } from '~interfaces';

enum SubFilterKey {
  Job = 'job',
}

interface AddSearchHeaderParamsProps {
  params: GetPaginationParams;
  searchValue: string | undefined;
  filterParams?: Record<string, any>;
  // Sub filter allows for the filter params to be in to form of filter[_subFilter_][_key_]
  subFilterKey?: SubFilterKey;
}
export const useDataGridSearch = () => {
  const [searchValue, setSearchValue] = useState('');

  const addSearchHeaderParam = ({
    params,
    searchValue,
    filterParams,
    subFilterKey,
  }: AddSearchHeaderParamsProps) => {
    const subFilterText = subFilterKey ? `[${subFilterKey}]` : '';

    if (searchValue?.length) {
      params = { [`search${subFilterText}[datagrid]`]: searchValue || '', ...params };
    }

    // Handle filter params
    if (filterParams) {
      const filters: Record<string, string> = {};

      Object.keys(filterParams).forEach((key) => {
        filters[`filter${subFilterText}[${key}]`] = filterParams[key];
      });

      params = { ...params, ...filters };
    }

    return params;
  };

  const setSearchQueryValue = (searchValue?: string) => {
    setSearchValue(searchValue || '');
  };

  return {
    addSearchHeaderParam,
    setSearchQueryValue,
    searchValue,
    SubFilterKey,
  } as const;
};
