import _ from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

import { AgaveLinkedAccount } from '~hooks/useAgave/models';

export default class AgaveIntegrationStore {
  isDeletingLinkedAccount: Map<string, boolean> = new Map<string, boolean>();
  isLoadingLinkedAccounts: boolean = false;
  isSavingLinkedAccount: boolean = false;
  linkedAccounts?: AgaveLinkedAccount[];

  constructor() {
    makeObservable(this, {
      isDeletingLinkedAccount: observable,
      isLoadingLinkedAccounts: observable,
      isSavingLinkedAccount: observable,
      linkedAccounts: observable,
    });
  }

  createLinkedAccountStart() {
    runInAction(() => {
      this.isSavingLinkedAccount = true;
    });
  }

  createLinkedAccountEnd(linkedAccount?: AgaveLinkedAccount) {
    runInAction(() => {
      this.isSavingLinkedAccount = false;

      if (linkedAccount) {
        this.linkedAccounts = _.uniqBy(
          [...(this.linkedAccounts ?? []), linkedAccount],
          (account) => account.id,
        );
      }
    });
  }

  deleteLinkedAccountStart(linkedAccountId: string) {
    runInAction(() => {
      this.isDeletingLinkedAccount.set(linkedAccountId, true);
    });
  }

  deleteLinkedAccountEnd(linkedAccountId: string, deleteSuccess?: boolean) {
    runInAction(() => {
      this.isDeletingLinkedAccount.set(linkedAccountId, false);

      if (deleteSuccess) {
        const index = (this.linkedAccounts ?? []).findIndex((linkedAccount) => {
          return linkedAccount.id === linkedAccountId;
        });

        if (index > -1) {
          this.linkedAccounts?.splice(index, 1);
        }
      }
    });
  }

  fetchLinkedAccountsStart() {
    runInAction(() => {
      this.isLoadingLinkedAccounts = true;
      this.linkedAccounts = undefined;
    });
  }

  fetchLinkedAccountsEnd(linkedAccounts?: AgaveLinkedAccount[]) {
    runInAction(() => {
      this.isLoadingLinkedAccounts = false;
      this.linkedAccounts = linkedAccounts;
    });
  }
}
