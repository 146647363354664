import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React from 'react';

interface FormFieldLabelProps {
  label: string;
  isRequired?: boolean;
  labelColor?: string;
  sx?: SxProps;
  variant?:
    | 'caption'
    | 'body1'
    | 'body2'
    | 'button'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
    | undefined;
}
const FormFieldLabel = ({
  label,
  variant = 'caption',
  sx,
  labelColor = 'secondary',
}: FormFieldLabelProps) => {
  return (
    <FormLabel
      sx={{
        display: 'flex',
        alignItems: 'start',
        ...sx,
      }}
    >
      <Typography variant={variant} fontWeight={700} color={labelColor}>
        {label}
      </Typography>
    </FormLabel>
  );
};
export { FormFieldLabel };
