import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { SingleInputDateRangePicker } from '~components/FormFields/SingleInputDateRangePicker';
import { SearchParams } from '~constants/enums';

interface DateRangeSelectorProps {
  defaultOpen?: boolean;
}

export default function DateRangeSelector({
  defaultOpen = true,
}: DateRangeSelectorProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialStartDate = searchParams.get(SearchParams.START_DATE)
    ? dayjs(searchParams.get(SearchParams.START_DATE))
    : null;
  const initialEndDate = searchParams.get(SearchParams.END_DATE)
    ? dayjs(searchParams.get(SearchParams.END_DATE))
    : null;

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: { dateRange: [initialStartDate, initialEndDate] },
  });

  const [selectedStartDate, selectedEndDate] = watch('dateRange') as [
    Dayjs | null,
    Dayjs | null,
  ];

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      setSearchParams((params) => {
        params.set(SearchParams.START_DATE, selectedStartDate.format('YYYY-MM-DD'));
        params.set(SearchParams.END_DATE, selectedEndDate.format('YYYY-MM-DD'));

        return params;
      });
    } else if (selectedStartDate) {
      setSearchParams((params) => {
        params.set(SearchParams.START_DATE, selectedStartDate.format('YYYY-MM-DD'));

        return params;
      });
    } else if (selectedEndDate) {
      setSearchParams((params) => {
        params.set(SearchParams.END_DATE, selectedEndDate.format('YYYY-MM-DD'));

        return params;
      });
    } else {
      setSearchParams((params) => {
        params.delete(SearchParams.START_DATE);
        params.delete(SearchParams.END_DATE);
        return params;
      });
    }
  }, [selectedStartDate, selectedEndDate]);

  return (
    <SingleInputDateRangePicker
      control={control}
      errors={errors}
      name="dateRange"
      sx={{ width: '100%' }}
      defaultOpen={defaultOpen}
    />
  );
}
