import { Service_Read, ServiceType_Read_Nested } from '@treadinc/horizon-api-spec';

import { ItemNameAndId } from '~types/ItemNameAndId';

export class Service extends ItemNameAndId {
  public get system(): boolean {
    return this._system;
  }
  public get type(): string {
    return this._type?.name || '';
  }
  constructor(
    proto: Service_Read,
    private _system: boolean,
    private _type?: ServiceType_Read_Nested,
  ) {
    super(proto.name, proto.id ?? '');
  }

  public static parse(proto: any): Service {
    return new Service(
      proto,
      proto.system,
      proto.service_type as ServiceType_Read_Nested,
    );
  }

  public static deparse(proto: Service): Record<string, unknown> {
    return {
      id: proto.id,
      name: proto.name,
      system: proto.system,
    };
  }
}
