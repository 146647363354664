import {
  TelematicsFleet_Read,
  TelematicsProviderSource,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { Nullable } from '~types/Nullable';

export class TelematicsFleet {
  public static parse(proto: TelematicsFleet_Read): TelematicsFleet {
    return new TelematicsFleet(
      proto.id,
      dayjs(proto.created_at),
      dayjs(proto.updated_at),
      proto.provider_source,
      proto.external_remote_fleet_id,
      proto.company_id,
    );
  }

  public get id(): string {
    return this._id;
  }
  public get createdAt(): Dayjs {
    return this._createdAt;
  }
  public get updatedAt(): Dayjs {
    return this._updatedAt;
  }
  public get providerSource(): TelematicsProviderSource {
    return this._provider_source;
  }
  public get externalRemoteFleetId(): Nullable<string> {
    return this._external_remote_fleet_id;
  }
  public get companyId(): string {
    return this._company_id;
  }

  constructor(
    private _id: string,
    private _createdAt: Dayjs,
    private _updatedAt: Dayjs,
    private _provider_source: TelematicsProviderSource,
    private _external_remote_fleet_id: Nullable<string>,
    private _company_id: string,
  ) {}
}
