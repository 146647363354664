import { makeObservable, observable, runInAction } from 'mobx';

import { Ticket } from '~hooks/useTickets';
import { Pagination, PaginationLink } from '~interfaces';
import { RootStore } from '~store/RootStore';
import { getPageNumber } from '~utils/pagination';

class TicketsStore {
  tickets: Ticket[] = [];
  ticketsReviewPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      tickets: observable,
    });
    this.rootStore = rootStore;
  }

  setTicketsReviewPagination(pagination: Pagination) {
    this.ticketsReviewPagination = {
      ...this.ticketsReviewPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  updateTicketsReviewPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.ticketsReviewPagination = {
        ...this.ticketsReviewPagination,
        page: getPageNumber(this.ticketsReviewPagination.page, link),
      };
    });
  }

  setTickets(tickets: Ticket[]) {
    this.tickets = [...tickets];
  }

  updateTicket(order: Ticket) {
    this.tickets = this.tickets.map((t) => (t.id === order.id ? order : t));
  }
}

export default TicketsStore;
