import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { LoadState } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import React, { useMemo } from 'react';

import { snakeCaseToHuman } from '~utils/utilFunctions';

interface LoadStateExtended {
  label: string;
  color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}
interface Props {
  status: LoadState | undefined;
  textOnly?: boolean;
  sx?: SxProps<Theme>; // Style
}

const Status = ({ status, textOnly, sx }: Props) => {
  const Statuses = {
    _: {
      label: snakeCaseToHuman(`${status}`),
      color: 'info',
    },
    [LoadState.CREATED]: {
      label: $t('status.planned'),
      color: 'primary',
    },
    [LoadState.TO_PICKUP]: {
      label: $t('status.to_pickup'),
      color: 'info',
    },
    [LoadState.ARRIVED_PICKUP]: {
      label: $t('status.arrived_pickup'),
      color: 'info',
    },
    [LoadState.LOADED]: {
      label: $t('status.loaded'),
      color: 'info',
    },
    [LoadState.TO_DROPOFF]: {
      label: $t('status.to_dropoff'),
      color: 'info',
    },
    [LoadState.ARRIVED_DROPOFF]: {
      label: $t('status.arrived_dropoff'),
      color: 'info',
    },
    [LoadState.UNLOADED]: {
      label: $t('status.unloaded'),
      color: 'info',
    },
    [LoadState.COMPLETED]: {
      label: $t('status.delivered'),
      color: 'success',
    },
    [LoadState.CANCELED]: {
      label: $t('status.canceled'),
      color: 'error',
    },
  } as Record<LoadState | string, LoadStateExtended>;

  const value = useMemo(
    () => (status ? Statuses[status] || Statuses._ : Statuses._),
    [status],
  );

  return textOnly ? (
    <>{value.label}</>
  ) : (
    <Chip
      label={value.label}
      size="small"
      variant={'outlined'}
      color={value.color}
      sx={{
        '&.MuiChip-root': {
          textTransform: 'none',
          '&.MuiChip-outlined': { flexDirection: 'row-reverse', borderRadius: 1 },
          '.MuiChip-label': {
            fontSize: '12px',
            fontWeight: 700,
            pl: 0.5,
            pr: 0.5,
          },
          '.MuiChip-icon': { mx: 0 },
        },
      }}
    />
  );
};

export { Status };
