import { t } from 'i18next';
import * as yup from 'yup';

import { RateTypes } from '~constants/enums';
import { nameIdNonRequiredSchema, nameIdSchemaRequired } from '~constants/regexConst';
import { Account, AccountBasic } from '~hooks/useAccount';
import {
  ItemColorLabelValue,
  Rate,
  RatePerUnitInputs,
  RateTimeInputs,
} from '~hooks/useRates';
import { rateTypeLabelColor } from '~pages/Settings/RatesManagement/rateUtils';
import { Nullable } from '~types/Nullable';

const timeRateSchema = yup.object().shape({
  rate: yup
    .number()
    .required(`${t('form_validation_errors.required_simple')}`)
    .typeError(t('form_validation_errors.should_be_number_simple') || ''),
  minimum: yup.string().required(`${t('form_validation_errors.required_simple')}`),
  increment: yup.string().required(`${t('form_validation_errors.required_simple')}`),
});
const generalSchema = yup.object().shape({
  rate: yup
    .number()
    .required(`${t('form_validation_errors.required_simple')}`)
    .typeError(t('form_validation_errors.should_be_number_simple') || ''),
  minimum: yup.string().required(`${t('form_validation_errors.required_simple')}`),
  increment: yup.string().notRequired(),
});
const commissionRateSchema = yup.object().shape({
  commissionPercentage: yup
    .number()
    .max(100, `${t('form_validation_errors.max', { field: '100' })}`)
    .min(
      0,
      `${t('form_validation_errors.min', {
        field: t('administration.rates.commission_rate'),
        count: 0,
      })}`,
    )
    .required(`${t('form_validation_errors.required_simple')}`)
    .typeError(t('form_validation_errors.should_be_number_simple') || ''),
});
const rateTypedSchemas = {
  [RateTypes.RATE_PER_DAY]: generalSchema,
  [RateTypes.RATE_PER_HOUR]: timeRateSchema,
  [RateTypes.RATE_COMMISSION]: commissionRateSchema,
  [RateTypes.RATE_PER_TON]: generalSchema,
  [RateTypes.RATE_PER_TONNE]: generalSchema,
  [RateTypes.RATE_PER_LOAD]: generalSchema,
  [RateTypes.RATE_PER_YARD]: generalSchema,
  [RateTypes.RATE_PER_BUSHEL]: generalSchema,
};
export const rateFormValidationSchema = yup.object().shape({
  id: yup.string().notRequired(),
  name: yup.string().required(`${t('form_validation_errors.required_simple')}`),
  account: nameIdNonRequiredSchema,
  projects: yup.array().of(nameIdNonRequiredSchema).notRequired(),
  services: yup.array().of(nameIdNonRequiredSchema).notRequired(),
  equipment: yup.array().of(nameIdNonRequiredSchema).notRequired(),
  company: nameIdNonRequiredSchema,
  isFuelSurcharge: yup.boolean(),
  type: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
      color: yup.string(),
    })
    .required(`${t('form_validation_errors.required_simple')}`),
  isPrimary: yup.boolean(),
  rateInputs: yup.object().when('type', ([type], schema) => {
    return rateTypedSchemas[type.value as RateTypes];
  }) as yup.ObjectSchema<any>,
  driverId: yup.string().uuid().notRequired(),
});
const setDefaultRateTypes = (defaultRate: Rate) => {
  if (defaultRate.rateInputs instanceof RateTimeInputs) {
    return {
      rate: defaultRate?.rateInputs?.timeRate || '',
      minimum: defaultRate?.rateInputs?.timeMinimumMinutes || '',
      increment: defaultRate?.rateInputs?.timeIncrementMinutes || '',
    };
  } else if (defaultRate.rateInputs instanceof RatePerUnitInputs) {
    return {
      rate: defaultRate?.rateInputs?.perUnitRate || '',
      minimum: defaultRate?.rateInputs?.perUnitMinimum || '',
      increment: defaultRate?.rateInputs?.perUnitIncrement || '',
    };
  } else {
    return {
      rate: defaultRate?.rateInputs?.commissionPercentage || '',
    };
  }
};
export const setDefaultRateValues = (
  defaultRate: Rate,
  account?: Nullable<Account>,
  driverId?: string,
) => {
  const accountData = defaultRate?.account?.id?.length
    ? defaultRate?.account
    : (account as AccountBasic);
  return {
    id: defaultRate?.id || '',
    name: defaultRate?.name || '',
    accountType: defaultRate?.accountType || '',
    account: {
      name: accountData?.name || '',
      id: accountData?.id || '',
    },
    company: {
      legalName: defaultRate?.company?.legalName || '',
      id: defaultRate?.company?.id || '',
    },
    projects: defaultRate?.projects?.map((item) => ({
      name: item?.name || '',
      id: item?.id || '',
    })),
    services: defaultRate?.services?.map((item) => ({
      name: item?.name || '',
      id: item?.id || '',
    })),
    equipment: defaultRate?.equipment?.map((item) => ({
      name: item?.name || '',
      id: item?.id || '',
    })),
    isFuelSurcharge: defaultRate.isFuelSurcharge,
    isPrimary: defaultRate.isPrimary,
    type: defaultRate?.type?.value
      ? (rateTypeLabelColor(defaultRate.type?.value) as ItemColorLabelValue)
      : ({} as ItemColorLabelValue),

    rateInputs: defaultRate ? setDefaultRateTypes(defaultRate) : {},
    driverId,
  };
};
export const setDefaultRateValuesForCopy = (defaultRate: Rate) => {
  return {
    id: defaultRate?.id || '',
    name: defaultRate?.name || '',
    account: null,
    projects: [],
    services: [],
    equipment: [],
  };
};
export const copyRateValidationSchema = yup.object().shape({
  name: yup.string().required(),
  account: nameIdSchemaRequired,
  projects: yup.array().of(nameIdNonRequiredSchema).notRequired(),
  services: yup.array().of(nameIdNonRequiredSchema).notRequired(),
  equipment: yup.array().of(nameIdNonRequiredSchema).notRequired(),
});
export type RatesFormSchemaInterface = yup.InferType<typeof rateFormValidationSchema>;
