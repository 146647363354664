import Search from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import theme from '~theme/AppTheme';

import { CONTROLS_HEIGHT_IN_PX } from '../CopyVendorAssignmentsForm';

interface SearchBarProps {
  onChange: (value: string) => void;
}

export default function SearchBar({ onChange }: SearchBarProps) {
  const [value, setValue] = useState('');

  const applyDebouncedFilter = _.debounce((callback: () => void) => {
    callback();
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  useEffect(() => {
    applyDebouncedFilter(() => {
      onChange(value);
    });

    return () => {
      applyDebouncedFilter.cancel();
    };
  }, [onChange, value]);

  return (
    <Box flex={1}>
      <OutlinedInput
        fullWidth
        onChange={(e) => setValue(e.target.value)}
        placeholder={`${t('dispatch.dispatch_v2.copy_vendor_assignments.search_by_any_column')}`}
        size="small"
        value={value}
        startAdornment={
          <InputAdornment position="start">
            <Search
              sx={{
                color: theme.brandV2.colors.treadBlack,
                cursor: 'pointer',
                fontSize: '16px',
              }}
            />
          </InputAdornment>
        }
        sx={{
          '&.MuiInputBase-root': {
            height: CONTROLS_HEIGHT_IN_PX,
            px: 1,
            py: 0.5,
            '.MuiInputBase-input': { fontSize: '12px', p: 0 },
            '.MuiOutlinedInput-notchedOutline': {
              border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            },
          },
        }}
      />
    </Box>
  );
}
