import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';

import { CycleTimeline } from './components/CycleTimeline';
import { useIndicatorValues } from './components/hooks/useIndicatorValues';
import { SpeedTimeline } from './components/SpeedTimeline';

const TimelineTypes = {
  CYCLE: 'CYCLE',
  SPEED: 'SPEED',
};

const timelineTypeOptions = [
  {
    label: 'Cycle Times',
    value: TimelineTypes.CYCLE,
  },
  {
    label: 'Driver Speed',
    value: TimelineTypes.SPEED,
  },
];

interface TimelineProps {
  pings?: NextBillionAssetLocation[];
  onHover?: (type: number) => void;
}

interface TimelineComponentProps extends TimelineProps {
  pings: Required<TimelineProps>['pings'];
}

// This is the amount of time, in milliseconds, within which a marker is considered
// Hovered over based on the user's mouse position in the timeline graph
const HOVERED_THRESHOLD = 10000;

export const Timeline = ({ pings, onHover }: TimelineProps) => {
  if (!pings?.length) {
    return null;
  }

  return <TimelineComponent pings={pings} onHover={onHover} />;
};

const TimelineComponent = ({ pings, onHover }: TimelineComponentProps) => {
  const [timelineType, setTimelineType] = useState(TimelineTypes.CYCLE);

  const minTime = pings[0].createdAt.toISOString();
  const maxTime = pings[pings.length - 1].createdAt.toISOString();

  const { indicatorValues } = useIndicatorValues(minTime, maxTime, []);

  const timePingMap = useMemo(() => {
    return reduce(
      pings,
      (pingMap, p, index) => {
        const createdAtThreshold = Math.round(p.createdAt.valueOf() / HOVERED_THRESHOLD);
        pingMap[createdAtThreshold] = index;
        return pingMap;
      },
      {} as Record<number, number>,
    );
  }, [pings.length]);

  useEffect(() => {
    if (indicatorValues.indicatorTime && indicatorValues.indicatorVisible) {
      const idxPing =
        timePingMap[Math.round(indicatorValues.indicatorTime / HOVERED_THRESHOLD)];

      if (idxPing) {
        onHover?.(idxPing);
      }
    }
  }, [indicatorValues.indicatorTime, indicatorValues.indicatorVisible]);

  return (
    <>
      <Box sx={{ mt: 2, mb: 2 }} mx={4}>
        {timelineType === TimelineTypes.CYCLE ? (
          <CycleTimeline pings={pings} />
        ) : (
          <SpeedTimeline pings={pings} />
        )}
      </Box>
      <Box
        p={1}
        display={'flex'}
        justifyContent={'flex-start'}
        zIndex={3}
        height={'fit-content'}
      >
        <Select
          value={timelineType}
          onChange={(event) => setTimelineType(event.target.value)}
          margin={'dense'}
          size={'small'}
        >
          {timelineTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};
