import Chip, { ChipOwnProps } from '@mui/material/Chip';
import { InvoiceState as InvoiceStateEnum } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { memo } from 'react';

type PickedChipProps = Required<Pick<ChipOwnProps, 'label' | 'color'>>;

const chipPropsByInvoiceState: Record<InvoiceStateEnum, PickedChipProps> = {
  [InvoiceStateEnum.CREATED]: {
    label: t('status.pending'),
    color: 'primary',
  },
  [InvoiceStateEnum.IN_PROGRESS]: {
    label: t('status.in_progress'),
    color: 'info',
  },
  [InvoiceStateEnum.PENDING]: {
    label: t('status.pending'),
    color: 'info',
  },
  [InvoiceStateEnum.APPROVED]: {
    label: t('status.approved'),
    color: 'success',
  },
  [InvoiceStateEnum.PAID]: {
    label: t('status.paid'),
    color: 'success',
  },
  [InvoiceStateEnum.EXPORTED]: {
    label: t('status.exported'),
    color: 'default',
  },
  [InvoiceStateEnum.VOIDED]: {
    label: t('status.voided'),
    color: 'error',
  },
  [InvoiceStateEnum.CUSTOMER_PENDING]: {
    label: t('status.sent'),
    color: 'primary',
  },
  [InvoiceStateEnum.CUSTOMER_APPROVED]: {
    label: t('status.approved'),
    color: 'success',
  },
  [InvoiceStateEnum.CUSTOMER_PAID]: {
    label: t('status.paid'),
    color: 'success',
  },
} as const;

interface InvoiceStateProps {
  size?: ChipOwnProps['size'];
  state: InvoiceStateEnum;
  variant?: ChipOwnProps['variant'];
}

const InvoiceState = memo(function InvoiceState({
  size = 'medium',
  state,
  variant = 'outlined',
}: InvoiceStateProps) {
  const { color, label } = chipPropsByInvoiceState[state];

  return <Chip color={color} label={label} size={size} variant={variant} />;
});

export default InvoiceState;
