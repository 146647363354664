import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import { capitalize } from 'lodash';
import { useForm } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { data as enums } from '~constants/enums';
import { Account, useAccount } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import {
  accountInlineFormSchema,
  setDefaultAccountValues,
} from '~pages/Sales/Projects/accountInlineFormSchema';
import { Nullable } from '~types/Nullable';

interface CreateUpdateAccountFormProps {
  defaultAccount: Account | null | undefined;
  callBackCloseClicked: () => void;
  callBackCreateAccount: (account: Account) => void;
  connectedCompany?: Nullable<CompanyBasic>;
  sx?: SxProps<Theme>; // Style
}

const AccountInlineCreateForm = ({
  defaultAccount,
  callBackCreateAccount,
  callBackCloseClicked,
  connectedCompany,
  sx = {},
}: CreateUpdateAccountFormProps) => {
  const { createAccount, isLoading } = useAccount();
  const handleCreateAccount = () => {
    createAccount({
      account: {
        ...getValues(),
        connectedCompany: connectedCompany || undefined,
      } as unknown as Account, //Get via getValues() instead of data because of the autocomplete field
      callBack: callBackCreateAccount,
    });
  };
  const handleCloseClick = () => {
    callBackCloseClicked?.();
  };
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid, isDirty, touchedFields, dirtyFields },
  } = useForm({
    resolver: yupResolver(accountInlineFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setDefaultAccountValues(defaultAccount as Account),
  });

  return (
    <Box
      component="div"
      onSubmit={handleSubmit(handleCreateAccount)}
      data-test-id="account-inline-form"
      width={'100%'}
      sx={{ ...sx }}
    >
      <FormSectionTitle sx={{ mb: 2 }} title={$t('account.form.account_information')} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="name"
            label={`${$t('form_fields.legal_name')}`}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteFormField
            control={control}
            multiple={true}
            name="accountTypes"
            errors={errors}
            list={enums.account_type.values}
            label={`${$t('account.form.account_type')}`}
            isRequired={true}
            getValue={(item) => item}
            getLabel={(item) => capitalize(item)}
            defaultValue={[]}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
            <Button
              onClick={handleCloseClick}
              sx={{ mr: 2, px: 2 }}
              disabled={isLoading}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
            <LoadingButton
              disabled={!isDirty}
              loading={isLoading}
              loadingPosition="start"
              startIcon={<></>}
              onClick={handleCreateAccount}
              data-test-id={'create-project-btn'}
              type="submit"
              variant="contained"
              color="primary"
              sx={isLoading ? { pl: 5, pr: 2 } : { pr: 2, pl: 2 }}
            >
              {$t(`actions.create`)}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export { AccountInlineCreateForm };
