import Check from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import FilterList from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import FilterMenuItem from '~components/Filters/FilterMenuItem';
import Menu from '~components/Menu/Menu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { useStores } from '~store';
import { appliedFiltersInitialState } from '~store/DriverSchedulerStore';
import theme from '~theme/AppTheme';

import { DriversSelectedFilter } from '../../constants';

const DriverFilters = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DriversSelectedFilter>();

  const { driverSchedulerStore } = useStores();

  const appliedFilters = driverSchedulerStore.appliedDriverFilters;

  const numberOfActiveFilters = Object.values(appliedFilters).reduce(
    (acc, filter) => acc + filter.selected.length,
    0,
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedFilter(undefined);
    }
  }, [isOpen]);

  useEffect(() => {
    const filters = {
      shared:
        appliedFilters.shared.selected.length === 0
          ? undefined
          : appliedFilters.shared.selected,
    };

    driverSchedulerStore.setDriversFilters(filters, true);
  }, [appliedFilters.shared.selected]);

  const handleTypesOptionsChange = (options: string[]) => {
    const newFilters = {
      ...appliedFilters,
      shared: {
        ...appliedFilters.shared,
        selected: options,
      },
    };
    driverSchedulerStore.setAppliedDriverFilters(newFilters);
  };

  return (
    <Box flex={1} display="flex" alignItems="center">
      <Menu
        sx={{ '& .MuiPaper-root': { width: '250px' } }}
        onOpenStateChanged={setIsOpen}
        menuTrigger={
          <SmallButton
            size="small"
            startIcon={<FilterList />}
            sx={{
              '&.MuiButton-root': {
                p: `0 ${theme.spacing(1)}`,
                backgroundColor: 'white',
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                borderRadius: theme.brandV2.borderRadius,
              },
              '.MuiButton-startIcon': {
                margin: 0,
              },
            }}
          >
            {numberOfActiveFilters > 0 && (
              <Box ml={0.4} display={'flex'} alignItems={'center'}>
                {t('common.filters')} +{numberOfActiveFilters}
                <IconButton
                  sx={{
                    position: 'relative',
                    top: '1px',
                    left: '2px',
                    p: '4px',
                    cursor: 'pointer',
                    borderRadius: 'none',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    driverSchedulerStore.setAppliedDriverFilters(
                      appliedFiltersInitialState,
                    );
                  }}
                >
                  <Close
                    sx={{
                      width: '16px',
                      height: '16px',
                      color: theme.brandV2.colors.treadBlack,
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </SmallButton>
        }
      >
        {selectedFilter === DriversSelectedFilter.SHARED ? (
          <FilterMenuItem
            focusSearchFieldOnMount={false}
            loadingReason={undefined}
            onSearchValueChange={undefined}
            onSelectedOptionsChange={handleTypesOptionsChange}
            options={[
              { label: t(`dispatch.dispatch_v2.filters.internal`), value: 'internal' },
              { label: t(`dispatch.dispatch_v2.filters.external`), value: 'external' },
            ]}
            selectAllOptionLabel={`${t('dispatch.dispatch_v2.filters.all_entities', { entity: t(`dispatch.dispatch_v2.filters.types`) })}`}
            selectedOptions={appliedFilters.shared.selected}
          />
        ) : (
          <MenuItem onClick={() => setSelectedFilter(DriversSelectedFilter.SHARED)}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Typography
                color={theme.brandV2.colors.treadBlack}
                fontSize="12px"
                fontWeight={600}
              >
                {t('dispatch.dispatch_v2.filters.types')}
              </Typography>

              <Box display="flex" alignItems="center" gap={1}>
                {appliedFilters.shared.selected.length > 0 && (
                  <Check
                    sx={{ fontSize: '16px', color: theme.brandV2.colors.treadOrange }}
                  />
                )}

                <ChevronRight sx={{ fontSize: '16px' }} />
              </Box>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
});

export default DriverFilters;
