import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import React from 'react';

interface Props {
  title?: string;
  sx?: SxProps<Theme>; // Style
}

const NotSpecified = ({ title, sx }: Props) => {
  return (
    <Typography
      variant={'body2'}
      sx={{ fontWeight: 'medium', fontStyle: 'italic', ...sx }}
    >
      {title || $t('common.not_specified')}
    </Typography>
  );
};

export { NotSpecified };
