import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { get } from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

interface FieldProps {
  name: string;
  control: Control<any>;
  errors?: any; // Object of errors
  disabled?: boolean;
  isRequired?: boolean;
  value: boolean;
  label?: string;
  altLabel?: string;
  sx?: SxProps<Theme>; // Style
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}
export const SwitchFormField = ({
  errors,
  name,
  control,
  disabled = false,
  isRequired = false,
  label,
  altLabel,
  labelPlacement = 'end',
  sx,
}: FieldProps) => {
  return (
    <FormControl error={get(errors, name)} sx={{ ...sx }}>
      <Stack direction="row" spacing={0} sx={{ alignItems: 'center' }}>
        {altLabel && (
          <Typography
            variant="body1"
            sx={{ mr: 1, color: disabled ? 'text.disabled' : 'text.primary' }}
            component={'label'}
          >
            {altLabel}
          </Typography>
        )}
        <FormControlLabel
          disabled={disabled}
          labelPlacement={labelPlacement}
          control={
            <Controller
              rules={{ required: isRequired }}
              name={name}
              control={control}
              render={({ field: props }) => (
                <Switch
                  {...props}
                  checked={props.value}
                  disabled={disabled}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label={label || ''}
        />
      </Stack>
    </FormControl>
  );
};
