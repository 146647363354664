import { t } from 'i18next';
import { get } from 'lodash';
import * as yup from 'yup';

import { Account } from '~hooks/useAccount';

export const accountInlineFormSchema = yup.object().shape({
  id: yup.string(),
  connectedCompany: yup
    .object()
    .shape({
      id: yup.string(),
      legalName: yup.string(),
    })
    .notRequired(),
  name: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.legal_name'),
    })}`,
  ),
  accountTypes: yup
    .array()
    .of(yup.string())
    .min(1, `${t('form_validation_errors.required', { field: t('form_fields.role') })}`),
});
export const setDefaultAccountValues = (defaultAccount: Account) => {
  return {
    id: defaultAccount?.id || '',
    name: defaultAccount?.name || '',
    accountTypes: get(defaultAccount, 'accountTypes', []),
  };
};
