import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useEffect, useRef } from 'react';

import { useTimeIndicatorContext } from './useTimeIndicatorContext';

const useIndicatorValues = (
  minTime: string,
  maxTime: string,
  noSignalBlocks: Dayjs[][],
) => {
  const unixStart = dayjs(minTime).valueOf();
  const unixEnd = dayjs(maxTime).valueOf();

  const graphRef = useRef<HTMLDivElement>();
  const { indicatorValues, setIndicatorValues } = useTimeIndicatorContext();
  dayjs.extend(isBetween);

  useEffect(() => {
    const updateIndicatorValues = (e: MouseEvent) => {
      const newIndicatorProps = {
        indicatorVisible: false,
        indicatorPosition: 0,
        indicatorTime: 0,
      };

      if (!graphRef.current) return;

      const graphRect = graphRef.current.getBoundingClientRect();
      const mouseX = e.clientX - graphRect.left;

      newIndicatorProps.indicatorPosition = mouseX;

      newIndicatorProps.indicatorTime =
        unixStart + (unixEnd - unixStart) * (mouseX / graphRect.width);

      const inNoSignal = noSignalBlocks.some(([blockStart, blockEnd]) =>
        dayjs(newIndicatorProps.indicatorTime).isBetween(blockStart, blockEnd),
      );

      if (!inNoSignal) {
        newIndicatorProps.indicatorVisible = true;
      }

      setIndicatorValues(newIndicatorProps);
    };

    const showIndicator = () =>
      setIndicatorValues({
        indicatorVisible: true,
        indicatorPosition: 0,
        indicatorTime: 0,
      });

    const hideIndicator = () =>
      setIndicatorValues({
        indicatorVisible: false,
        indicatorPosition: 0,
        indicatorTime: 0,
      });

    if (graphRef.current) {
      graphRef.current.addEventListener('mousemove', updateIndicatorValues);
      graphRef.current.addEventListener('mouseenter', showIndicator);
      graphRef.current.addEventListener('mouseleave', hideIndicator);
    }

    return () => {
      if (graphRef.current) {
        graphRef.current.removeEventListener('mousemove', updateIndicatorValues);
        graphRef.current.removeEventListener('mouseenter', showIndicator);
        graphRef.current.removeEventListener('mouseleave', hideIndicator);
      }
    };
  }, [setIndicatorValues, unixStart, unixEnd, noSignalBlocks]);

  return { graphRef, indicatorValues };
};

export { useIndicatorValues };
