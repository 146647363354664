export class ItemNameAndId {
  public static parse(proto: any): ItemNameAndId {
    return new ItemNameAndId(proto.name, proto.id);
  }
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  constructor(
    private _name: string,
    private _id: string,
  ) {}
}
