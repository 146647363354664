import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverOrigin, PopoverPosition } from '@mui/material/Popover';
import { SxProps, Theme } from '@mui/material/styles';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uid } from 'uuid';

import { BasicTooltip } from '../Tooltip/BasicTooltip';

const ITEM_HEIGHT = 48;

interface OptionsProps {
  callBack: () => void;
  disabled?: boolean;
  icon?: React.ReactElement;
  title: string;
}

interface SimpleMenuProps {
  options: Array<OptionsProps>;
  children?: React.ReactNode | undefined;
  sx?: SxProps<Theme>; // Style
  anchorOrigin?: PopoverOrigin;
  anchorPosition?: PopoverPosition;
  transformOrigin?: PopoverOrigin;
  keepMounted?: boolean;
  disabled?: boolean;
}
const SimpleMenu = forwardRef(function SimpleMenu(
  {
    children,
    options,
    sx,
    anchorPosition,
    keepMounted,
    transformOrigin,
    anchorOrigin,
    disabled,
  }: SimpleMenuProps,
  ref: Ref<any>,
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasClickedMenu, setHasClickedMenu] = useState(false);
  const open = Boolean(anchorEl);
  const iconButtonRef = useRef(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setHasClickedMenu(false);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const rnd = useMemo(() => uid(), []);
  const handleClose = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => ({
    toggle() {
      // @ts-ignore
      iconButtonRef?.current?.click();
    },
  }));

  if (!options.length) {
    return null;
  }

  return (
    <Box data-test-id="simple-menu">
      <IconButton
        ref={iconButtonRef}
        aria-label="more"
        id={`menu-button_${rnd}`}
        aria-controls={open ? `menu_${rnd}` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        {children || 'Menu'}
      </IconButton>
      <Menu
        transformOrigin={transformOrigin}
        id={`menu_${rnd}`}
        MenuListProps={{
          'aria-labelledby': `menu-button_${rnd}`,
        }}
        anchorOrigin={anchorOrigin}
        anchorEl={anchorEl}
        anchorPosition={anchorPosition}
        keepMounted={keepMounted}
        open={open}
        onClose={handleClose}
        PaperProps={{
          ...sx,
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: '20ch',
          },
        }}
      >
        {options.map((option, index) => (
          <BasicTooltip
            title={option.title}
            enterDelay={600}
            leaveDelay={50}
            key={`menu-item-${option.title}-${index}`}
          >
            <MenuItem
              // Prevent clicks to other menu items after one is clicked
              disabled={option.disabled || hasClickedMenu}
              onClick={() => {
                // Prevent double click on menu item
                if (hasClickedMenu) {
                  return;
                }
                setHasClickedMenu(true);
                option.callBack.apply(null, []);
                handleClose();
              }}
            >
              {option.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : null}
              {option.title}
            </MenuItem>
          </BasicTooltip>
        ))}
      </Menu>
    </Box>
  );
});

export { SimpleMenu };
