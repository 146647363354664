import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';

import { SearchParams } from '~constants/enums';
import { useSites } from '~hooks/useSites';
import { useStores } from '~store';

import { TypeaheadSelector } from './TypeaheadSelector';

interface Props {
  allSearchParams: Record<string, any>;
  sx?: SxProps<Theme>; // Style
}

const DropoffSiteSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  const { getSiteById, getCompanySitesTypeahead } = useSites();
  const { userStore } = useStores();

  const fetchSitesByCompanyId = async (additionalProps = {}) => {
    const company = userStore.userCompany;

    if (!company) {
      return [];
    }

    const sites = await getCompanySitesTypeahead({
      companyId: company.id,
      ...additionalProps,
    });

    return sites;
  };

  return (
    <>
      <Box sx={{ ...sx }}>
        <TypeaheadSelector
          asyncGet={getSiteById}
          asyncCallback={fetchSitesByCompanyId}
          fieldName={SearchParams.DROPOFF_SITE}
          label={$t('form_fields.drop_off_site')}
          defaultValues={[]}
          allSearchParams={allSearchParams}
          sx={{
            ...sx,
            ...{
              width: '150px',
              fontSize: '12px',
              mr: 1,
              '& label:not(.Mui-focused)': {
                color: '#000',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
              },
            },
          }}
          getOptionLabel={(item) => item.name || ''}
        />
      </Box>
    </>
  );
});

export { DropoffSiteSelector };
