import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useCallback } from 'react';

import { useLogin } from '~hooks/useLogin/useLogin';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

interface SignInMagicLinkSentScreenProps {
  email: string;
}

const SignInMagicLinkSentScreen = ({ email }: SignInMagicLinkSentScreenProps) => {
  const { sendMagicLink, isLoading } = useLogin();
  const { toasterStore } = useStores();

  const handleResendButtonClick = useCallback(() => {
    sendMagicLink({ email }).then(() => {
      toasterStore.push(alert(t('actions.new_magic_link_sent'), AlertTypes.success));
    });
  }, [email]);

  return (
    <>
      <Typography variant="body1" textTransform="uppercase" fontWeight={600}>
        {t('actions.check_your_email')}
      </Typography>

      <Typography variant="body1" mt={3} textAlign="center">
        {t('actions.an_email_was_sent', { email: '' })}
        <br />
        <Typography component="span" fontWeight={600}>
          {email}
        </Typography>
      </Typography>

      <Typography variant="body2" mt={10}>
        {t('actions.did_not_receive_it')}
      </Typography>

      <LoadingButton
        sx={{ mt: 1 }}
        fullWidth
        size="large"
        color="primary"
        variant="contained"
        onClick={handleResendButtonClick}
        loading={isLoading}
      >
        {t('actions.resend')}
      </LoadingButton>
    </>
  );
};

export default SignInMagicLinkSentScreen;
