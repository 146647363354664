const SOMETHING_WRONG = 'Something went wrong, please try again';
export const FORM_ERRORS = {
  required: 'This field is required *',
  futureDate: 'Date must be in the future',
  minimumOne: "Can't be less then 1",
  validInteger: 'Numeric positive values only',
};
//Add translations
export const STATUS_MESSAGES = {
  400: 'Bad request',
  401: 'Wrong credentials, or not authorized',
  403: 'Forbidden',
  404: 'Not Found',
  409: 'Conflict',
  422: 'Unprocessable Entity',
  500: SOMETHING_WRONG,
};
