import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { useCallback } from 'react';

interface SearchInputProps {
  onChange: (value: string) => void;
  value: string;
}

export const SearchInput = ({ onChange, value }: SearchInputProps) => {
  const theme = useTheme();
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <OutlinedInput
      fullWidth
      onChange={handleInputChange}
      placeholder={`${t('dispatch.drivers.search')}`}
      size="small"
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <Search
            sx={{
              color: theme.brandV2.colors.treadBlack,
              cursor: 'pointer',
              fontSize: '16px',
            }}
          />
        </InputAdornment>
      }
      sx={{
        '&.MuiInputBase-root': {
          height: `30px`,
          px: 1,
          py: 0.5,
          background: '#fff',
          borderRadius: theme.spacing(0.75),
          border: `1px solid ${theme.brandV2.colors.treadGray7}`,
          '.MuiInputBase-input': {
            fontSize: '12px',
            p: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        },
      }}
    />
  );
};
