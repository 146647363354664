import { action, makeObservable, observable } from 'mobx';

import { DATAGRID_ID_PREFIX } from '~components/DataGrid/DataGrid';
import { getTokenProp } from '~constants/consts';
import { RootStore } from '~store/RootStore';

class AuthStore {
  isAuthenticated = false;
  token: string | null | undefined;
  isRedirectToMobileNeeded = false;
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isAuthenticated: observable,
      token: observable,
      login: action,
      logout: action,
      setRedirectToMobileNeeded: action,
    });
    this.rootStore = rootStore;
  }

  login(token: string): void {
    this.isAuthenticated = true;
    this.token = token;
    localStorage.setItem(getTokenProp, token);
  }

  logout(): void {
    this.isAuthenticated = false;
    this.token = null;
    this.#clearLocalStorage();
    this.rootStore.userStore.clearUser();
  }

  #clearLocalStorage() {
    Object.keys(localStorage).forEach((key) => {
      if (!key.startsWith(DATAGRID_ID_PREFIX)) {
        localStorage.removeItem(key);
      }
    });
  }

  setRedirectToMobileNeeded(param: boolean): void {
    this.isRedirectToMobileNeeded = param;
  }
}

export default AuthStore;
