import { JobState } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';

import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';

export type Segment = {
  start: Dayjs;
  end: Dayjs;
  type: string;
};

export const SegmentTypes = {
  PICK_UP: 'PICK_UP',
  EN_ROUTE: 'EN_ROUTE',
  DROP_OFF: 'DROP_OFF',
  LOGGING: 'LOGGING',
};

const jobStateSegmentTypeMap = {
  [SegmentTypes.PICK_UP]: [JobState.ARRIVED_PICKUP, JobState.LOADED],
  [SegmentTypes.EN_ROUTE]: [JobState.TO_DROPOFF, JobState.TO_PICKUP],
  [SegmentTypes.DROP_OFF]: [JobState.ARRIVED_DROPOFF, JobState.UNLOADED],
};

const getType = (ping: NextBillionAssetLocation) => {
  const jobState = ping?.jobState;

  for (const [type, states] of Object.entries(jobStateSegmentTypeMap)) {
    if (states.includes(jobState as JobState)) {
      return type;
    }
  }

  return SegmentTypes.LOGGING;
};

const NO_SIGNAL_THRESHOLD = 15;

const useGetCycleSegments = (pings: NextBillionAssetLocation[]) =>
  useMemo(() => {
    const segments: NextBillionAssetLocation[][] = [[]];
    pings.forEach((p) => {
      const currentSegment: NextBillionAssetLocation[] = segments[segments.length - 1];
      if (!currentSegment.length) {
        currentSegment.push(p);
        return;
      }

      const lastPing = currentSegment[currentSegment.length - 1];

      if (
        getType(lastPing) !== getType(p) ||
        dayjs(p.createdAt).diff(dayjs(lastPing.createdAt), 'minutes') >=
          NO_SIGNAL_THRESHOLD
      ) {
        segments.push([p]);
        return;
      }

      currentSegment.push(p);
    });

    const formattedSegments: Segment[] = segments
      .filter((s) => s.length > 1)
      .map((s) => ({
        start: s[0].createdAt,
        end: s[s.length - 1].createdAt,
        type: getType(s[0]),
      }));

    return formattedSegments;
  }, [pings.length]);

export { useGetCycleSegments };
