import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';

import theme from '~theme/AppTheme';

interface CheckboxFilterMenuItemProps {
  checked?: boolean;
  id?: string;
  label: string;
  onClick: (event: React.MouseEvent) => void;
}

export default function CheckboxFilterMenuItem({
  checked,
  id,
  label,
  onClick,
}: CheckboxFilterMenuItemProps) {
  return (
    <MenuItem onClick={onClick} id={id}>
      <Box
        alignItems="center"
        display="flex"
        gap={1}
        justifyContent="space-between"
        width="100%"
      >
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              checked={Boolean(checked)}
              size="small"
              sx={{
                '&.MuiCheckbox-root': {
                  py: 0,
                  color: theme.brandV2.colors.treadGray6,
                },
                '&.Mui-checked': { color: theme.brandV2.colors.treadOrange },
              }}
            />
          }
          sx={{
            '&.MuiFormControlLabel-root': {
              mr: 0,
              overflow: 'hidden',
              width: '100%',
              '& .MuiFormControlLabel-label': {
                color: theme.brandV2.colors.treadBlack,
                fontSize: '12px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              },
            },
          }}
        />
      </Box>
    </MenuItem>
  );
}
