import { t } from 'i18next';
import * as yup from 'yup';

import { emailRegex, personNameSchema, phoneSchema } from '~constants/regexConst';

export const companyCreateSchema = yup.object().shape({
  id: yup.string(),
  parentCompany: yup.object().shape({
    name: yup.string(),
    id: yup.string(),
  }),
  legalName: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.legal_name'),
    })}`,
  ),
  doingBusinessName: yup.string().notRequired(),
  companyType: yup
    .string()
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.role') })}`,
    ),

  address: yup.object().shape({
    streetAddress: yup
      .string()
      .required(
        `${t('form_validation_errors.required', { field: t('form_fields.address') })}`,
      ),
    premise: yup.string().typeError(
      `${t('form_validation_errors.should_be_string', {
        field: t('form_fields.address_2'),
      })}`,
    ),
    locality: yup
      .string()
      .required(
        `${t('form_validation_errors.required', { field: t('form_fields.city') })}`,
      ),
    administrativeArea: yup.string().typeError(
      `${t('form_validation_errors.should_be_string', {
        field: t('form_fields.state'),
      })}`,
    ),
    postalCode: yup
      .string()
      .required(
        `${t('form_validation_errors.required', { field: t('form_fields.zip') })}`,
      ),
    country: yup
      .object()
      .typeError(
        `${t('form_validation_errors.required', { field: t('form_fields.country') })}`,
      )
      .required(
        `${t('form_validation_errors.required', { field: t('form_fields.country') })}`,
      ),
  }),

  primaryContact: yup.object().shape({
    firstName: personNameSchema(`${t('form_fields.first_name')}`).required(
      `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
    ),
    lastName: personNameSchema(`${t('form_fields.last_name')}`).required(
      `${t('form_validation_errors.required', { field: t('form_fields.last_name') })}`,
    ),
    email: yup
      .string()
      .email(`${t('form_validation_errors.required', { field: t('form_fields.email') })}`)
      .matches(emailRegex, `${t('form_validation_errors.invalid_email')}`)
      .required(
        `${t('form_validation_errors.required', { field: t('form_fields.email') })}`,
      ),
    phone: phoneSchema.required(
      `${t('form_validation_errors.required', { field: t('form_fields.phone') })}`,
    ),
  }),

  billingContact: yup.object().shape({
    firstName: personNameSchema(`${t('form_fields.first_name')}`).required(
      `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
    ),
    lastName: personNameSchema(`${t('form_fields.last_name')}`).required(
      `${t('form_validation_errors.required', { field: t('form_fields.last_name') })}`,
    ),
    email: yup
      .string()
      .email(`${t('form_validation_errors.required', { field: t('form_fields.email') })}`)
      .matches(emailRegex, () => t('form_validation_errors.invalid_email'))
      .required(() => t('form_validation_errors.required', { formField: 'email' })),
    phone: phoneSchema.required(
      `${t('form_validation_errors.required', { field: t('form_fields.phone') })}`,
    ),
  }),

  default_lat: yup.number().notRequired(),
  default_lon: yup.number().notRequired(),

  timeZone: yup.string().required(),
  dateFormat: yup.string().required(),
  timeFormat: yup.string().required(),
  systemOfMeasure: yup.string().required(),
  unitOfDistance: yup.string().required(),
  usageStatus: yup
    .string()
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.usage_status') })}`,
    ),
  billingStatus: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.billing_status'),
    })}`,
  ),
  saasBillingPlan: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.saas_billing_plan'),
    })}`,
  ),
});
