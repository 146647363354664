// given that the main grid is virtualized, job card containers will be removed
// from the DOM once they go off the viewable area. intent with these dummy
// columns is to enlarge the viewable area and hide them behind the drivers
// list, which will prevent job cards from disappearing.
const DUMMY_COLUMNS_COUNT = 2;

const generateHours = () => {
  const hours = Array.from({ length: DUMMY_COLUMNS_COUNT }, () => '');

  for (let i = 0; i < 24; i++) {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const period = i < 12 ? 'AM' : 'PM';
    hours.push(`${hour} ${period}`);
  }

  return hours;
};
export const HOURS = generateHours();
export const FULL_HOUR_WIDTH = 114;
export const DUMMY_COLUMNS_SIZE = DUMMY_COLUMNS_COUNT * FULL_HOUR_WIDTH;
export const INCREMENTS_PER_HOUR = 4;
export const MINUTES_PER_INCREMENT = 60 / INCREMENTS_PER_HOUR;
export const GRID_ITEM_WIDTH = FULL_HOUR_WIDTH / INCREMENTS_PER_HOUR;
export const HEADER_HEIGHT = 110;
export const FOOTER_HEIGHT = 40;
export const ROW_HEIGHT = 125;
export const JOB_COLUMN_WIDTH = 250;
export const JOB_WIDTH = 224;
export const STICKY_HEADER_HEIGHT = 81;
export const JOB_CARD_OFFSET = 230;
