import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { contactTypesOptions, SystemRoles } from '~constants/enums';
import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import { ExtendedContactItem, Project } from '~hooks/useProjects/models';
import { User, useUsers } from '~hooks/useUsers';
import { EmptyContactItem } from '~pages/Sales/Projects/projectFormSchema';
import { ContactArrayItem } from '~pages/Sales/sharedSales/ContactArrayItem';
import { ItemNameAndId } from '~types/ItemNameAndId';

interface ContactsProps {
  parentData?: Project | Order | null;
  defaultData?: Project | Order | Job | null;
}

const getContactFormData = (contact: ExtendedContactItem) => {
  return {
    id: contact.id,
    name: contact.name,
    phone: contact.phone,
    type: contact.type as ItemNameAndId,
    isDeleted: false,
  };
};

export const Contacts = ({ parentData, defaultData }: ContactsProps) => {
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const { company, allContacts: watchedContactFields } = watch();

  const { getCompanyUsersTypeahead } = useUsers();

  const [contactTypesOptionsFiltered, setContactTypesOptionsFiltered] =
    useState(contactTypesOptions);

  // Handle the default values from the parentData if fields are not filled in
  const { salesContact, foremanContact, collaboratorContact, supervisorContact } =
    useMemo(
      () => ({
        salesContact: parentData?.isSalesContact ? parentData.salesContact : null,
        foremanContact: parentData?.isForemanContact ? parentData.foremanContact : null,
        collaboratorContact: parentData?.isCollaboratorContact
          ? parentData.collaboratorContact
          : null,
        supervisorContact: parentData?.isSupervisorContact
          ? parentData.supervisorContact
          : null,
      }),
      [parentData],
    );

  useEffect(() => {
    // Only load default values if the form is for create
    if (!defaultData?.id) {
      collaboratorContact && !defaultData?.isCollaboratorContact
        ? prependContact(getContactFormData(collaboratorContact))
        : null;
      foremanContact && !defaultData?.isForemanContact
        ? prependContact(getContactFormData(foremanContact))
        : null;
      salesContact && !defaultData?.isSalesContact
        ? prependContact(getContactFormData(salesContact))
        : null;
      supervisorContact && !defaultData?.isSupervisorContact
        ? prependContact(getContactFormData(supervisorContact))
        : null;
    }
  }, [
    collaboratorContact,
    foremanContact,
    salesContact,
    supervisorContact,
    defaultData?.id,
  ]);

  const {
    fields: contactFields,
    append: appendContact,
    prepend: prependContact,
    remove: removeContact,
    update: updateAllContacts,
  } = useFieldArray({
    control,
    name: 'allContacts',
  });

  useEffect(() => {
    if (watchedContactFields) {
      const res = contactTypesOptions.filter((singleType) => {
        // Check if any element in contactFields has the same type.id as singleType
        const found = watchedContactFields.some(
          (field: ExtendedContactItem) => field?.type?.id === singleType?.id,
        );
        return !found;
      });
      setContactTypesOptionsFiltered(res);
    }
  }, [watchedContactFields]);

  // Load foremen list from parentData
  useEffect(() => {
    if (parentData?.id) {
      const foremen = parentData.foremen || [];
      const currentlySelectedForemen = getValues('foremen');
      const newForemen = [...currentlySelectedForemen];

      foremen.forEach((foreman) => {
        const foremanIsAlreadySelected = currentlySelectedForemen.some(
          (f: User) => f.id === foreman.id,
        );

        if (!foremanIsAlreadySelected) {
          newForemen.push(foreman);
        }
      });

      setValue('foremen', newForemen);
    }
  }, [parentData]);

  const removeContactItem = (index: number) => {
    //New item, not sored in DB yet, just remove it
    if (!getValues('allContacts')?.[index].id) {
      removeContact(index);
    } else {
      // Previously saved item, mark it as deleted, in order to have their ids in the api call with destroy:1
      updateAllContacts(index, {
        ...contactFields[index],
        id: getValues('allContacts')?.[index]?.id as string,
        isDeleted: true,
      });
    }
  };

  return (
    <Card sx={{ mb: 2, p: 2, overflow: 'visible' }}>
      <FormSectionTitle title={t('order.form.contacts')} />
      <Grid container sx={{ mt: 1 }}>
        <AutocompleteAsyncFormField
          control={control}
          errors={errors}
          disabled={!company?.id}
          multiple
          filterSelectedOptions={false}
          name="foremen"
          getValue={(user: User) => user.id}
          getLabel={(user: User) => `${user.firstName} ${user.lastName}`}
          label={`${t('form_fields.assigned_foreman')}`}
          asyncCallback={getCompanyUsersTypeahead}
          extraRequestOptions={{
            companyId: company?.id,
            roleName: SystemRoles.FOREMAN,
          }}
        />
      </Grid>

      <Grid container sx={{ mt: 1 }}>
        {contactFields.map((contact, index) => {
          //@ts-ignore
          return contact.isDeleted ? null : (
            <ContactArrayItem
              key={contact.id}
              index={index}
              arrName={'allContacts'}
              errors={errors}
              control={control}
              removeAction={removeContactItem}
              typeOptions={contactTypesOptionsFiltered}
            />
          );
        })}
        <Grid item xs={12}>
          <PseudoLink
            disabled={contactFields.length === contactTypesOptions.length}
            title={t('project.form.add_contact')}
            sx={{ mt: 1, display: 'inline-flex' }}
            action={
              contactFields.length !== contactTypesOptions.length
                ? () => {
                    appendContact(EmptyContactItem);
                  }
                : () => {}
            }
          />
        </Grid>
      </Grid>
    </Card>
  );
};
