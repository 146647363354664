import {
  Rate,
  RateBasicWithValue,
  RateCommissionInputs,
  RatePerUnitInputs,
  RateTimeInputs,
} from '~hooks/useRates';

export const rateToRateBasicWithValue = (rate: Rate) => {
  let rateValue = 0;

  if (rate.isCommission) {
    rateValue = (rate.rateInputs as RateCommissionInputs).commissionPercentage;
  } else if (rate.isTimeBased) {
    rateValue = (rate.rateInputs as RateTimeInputs).timeRate;
  } else {
    rateValue = (rate.rateInputs as RatePerUnitInputs).perUnitRate;
  }

  return new RateBasicWithValue(String(rate.id), rate.name, rate.type.value, rateValue);
};
