import { AccountType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { get } from 'lodash';
import * as yup from 'yup';

import {
  userEmailSchema,
  userPhoneSchema,
} from '~components/UserForm/userFormValidationSchema';
import { countries } from '~constants/enums';
import {
  emailRegex,
  notRequiredEmailSchema,
  notRequiredPhoneSchema,
  personNameSchema,
  phoneSchema,
} from '~constants/regexConst';
import { Account } from '~hooks/useAccount';
import { autoAcceptOrdersOptions } from '~pages/Settings/Accounts/AccountFormParts/constants';
const truckCountSchema = yup
  .number()
  .integer(
    `${t('form_validation_errors.should_be_integer', {
      field: t('account.form.truck_count'),
    })}`,
  )
  .typeError(
    t('form_validation_errors.should_be_number', {
      field: t('account.form.truck_count'),
    }) || '',
  )
  .notRequired();

const companySharesSchema = yup
  .array()
  .of(
    yup.object().shape({
      id: yup.string(),
      name: yup.string(),
    }),
  )
  .notRequired();

const contact = yup.object().shape({
  name: personNameSchema(`${t('form_fields.name')}`).notRequired(),
  email: notRequiredEmailSchema,
  phone: notRequiredPhoneSchema,
});

const userContact = yup.object().shape({
  firstName: personNameSchema(`${t('form_fields.first_name')}`).required(
    `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
  ),
  lastName: personNameSchema(`${t('form_fields.last_name')}`).required(
    `${t('form_validation_errors.required', { field: t('form_fields.last_name') })}`,
  ),
  email: userEmailSchema
    .transform((val: string) => {
      if (!val?.length) {
        return undefined;
      }

      return val;
    })
    .notRequired()
    .when('phone', {
      is: (phone: string | undefined) => {
        return Boolean(!phone || phone.length === 0);
      },
      then: () => {
        return userEmailSchema.required(
          `${t('form_validation_errors.either_one_or_other', { one: t('form_fields.email'), other: t('form_fields.phone') })}`,
        );
      },
    }),
  phone: userPhoneSchema
    .transform((val: string) => {
      if (!val?.length) {
        return undefined;
      }

      return val;
    })
    .notRequired(),
});

const primaryContactInvited = yup.object().shape({
  firstName: personNameSchema(`${t('form_fields.first_name')}`).required(
    `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
  ),
  lastName: personNameSchema(`${t('form_fields.last_name')}`).required(
    `${t('form_validation_errors.required', { field: t('form_fields.last_name') })}`,
  ),
  email: yup
    .string()
    .email()
    .test(
      'is-valid-email',
      `${t('form_validation_errors.invalid_email')}`,
      (value, context) => {
        const isValidEmail = emailRegex.test(value as string);

        const isRequired =
          context.from && context?.from[1].value.inviteTypes.includes('email');

        return !(isRequired && !isValidEmail);
      },
    )
    .test(
      'is-required',
      `${t('form_validation_errors.required', { field: t('form_fields.email') })}`,
      (value, context) => {
        const isRequired =
          context.from && context?.from[1].value.inviteTypes.includes('email');

        return !(isRequired && !value);
      },
    ),
  phone: phoneSchema.test(
    'is-required',
    `${t('form_validation_errors.required', { field: t('form_fields.phone') })}`,
    (value, context) => {
      const isRequired =
        context.from && context?.from[1].value.inviteTypes.includes('sms');

      return !(isRequired && !value);
    },
  ),
});

export const updateAccountFormSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.legal_name'),
    })}`,
  ),
  externalId: yup.string(),
  notes: yup.string().max(
    350,
    `${t('form_validation_errors.max_length', {
      field: t('form_fields.notes'),
      max: 350,
    })}`,
  ),
  companyType: yup.string(),
  address: yup
    .object()
    .shape({
      streetAddress: yup.string().notRequired(),
    })
    .notRequired(),
  isBillingAddressSame: yup.boolean(),
  isBillingContactSame: yup.boolean(),
  billingAddress: yup.object().when('isBillingAddressSame', {
    is: false,
    then: () => yup.object().notRequired(),
  }),
  primaryContact: contact,
  billingContact: yup.object().when('isBillingContactSame', {
    is: false,
    then: () => contact,
  }),
});

export const accountFormSchema = yup.object().shape({
  id: yup.string(),
  connected: yup
    .object()
    .shape({
      value: yup.boolean().oneOf([true, false], 'You must select a connection type'),
      label: yup.string(),
    })
    .required('Connection type is required'),
  name: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.legal_name'),
    })}`,
  ),
  externalId: yup.string(),
  notes: yup.string().max(
    350,
    `${t('form_validation_errors.max_length', {
      field: t('form_fields.notes'),
      max: 350,
    })}`,
  ),
  accountTypes: yup
    .array()
    .of(yup.string())
    .min(1)
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.role') })}`,
    ),
  companyType: yup.string(),
  address: yup.object().when('connected', {
    is: true,
    then: () =>
      yup
        .object()
        .shape({
          streetAddress: yup.string().required(
            `${t('form_validation_errors.required', {
              field: t('form_fields.address'),
            })}`,
          ),
        })
        .required(
          `${t('form_validation_errors.required', {
            field: t('form_fields.address'),
          })}`,
        ),
    otherwise: () =>
      yup
        .object()
        .shape({
          streetAddress: yup.string().notRequired(),
        })
        .notRequired(),
  }),
  isBillingAddressSame: yup.boolean(),
  isBillingContactSame: yup.boolean(),
  billingAddress: yup.object().when('isBillingAddressSame', {
    is: false,
    then: () => yup.object().notRequired(),
  }),
  primaryContact: contact,
  billingContact: yup.object().when('isBillingContactSame', {
    is: false,
    then: () => contact,
  }),
  truckCount: truckCountSchema,
  drivers: companySharesSchema,
  equipment: companySharesSchema,
  vendorDrivers: companySharesSchema,
  vendorEquipment: companySharesSchema,
});

export const connectedAccountFormSchema = yup.object().shape({
  id: yup.string(),
  connected: yup
    .object()
    .shape({
      value: yup.boolean().oneOf([true, false], 'You must select a connection type'),
      label: yup.string(),
    })
    .required('Connection type is required'),
  name: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.legal_name'),
    })}`,
  ),
  inviteTypes: yup
    .array()
    .of(yup.string())
    .when('id', {
      is: (id: string) => !id, // Only required for new accounts
      then: () =>
        yup.array().of(yup.string()).min(1, 'You must select at least one invite type'),
      otherwise: (schema) => schema.notRequired(),
    }),
  externalId: yup.string(),
  notes: yup.string().max(
    350,
    `${t('form_validation_errors.max_length', {
      field: t('form_fields.notes'),
      max: 350,
    })}`,
  ),
  accountTypes: yup
    .array()
    .of(yup.string())
    .min(1)
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.role') })}`,
    ),
  companyType: yup.string().required(),
  address: yup.object().when('connected', {
    is: (field?: { value: boolean }) => field?.value === true,
    then: () =>
      yup
        .object()
        .shape({
          streetAddress: yup
            .string()
            .trim()
            .min(1)
            .required(
              `${t('form_validation_errors.required', {
                field: t('form_fields.address'),
              })}`,
            ),
        })
        .required(
          `${t('form_validation_errors.required', {
            field: t('form_fields.address'),
          })}`,
        ),
    otherwise: () =>
      yup
        .object()
        .shape({
          streetAddress: yup.string().notRequired(),
        })
        .notRequired(),
  }),
  isBillingAddressSame: yup.boolean(),
  billingAddress: yup.object().when('isBillingAddressSame', {
    is: false,
    then: () => yup.object().notRequired(),
  }),
  primaryContact: primaryContactInvited,
  truckCount: truckCountSchema,
  drivers: companySharesSchema,
  equipment: companySharesSchema,
  vendorDrivers: companySharesSchema,
  vendorEquipment: companySharesSchema,
});

export const setDefaultAccountValues = (defaultAccount: Account) => {
  // Split the name into first and last names
  const splitPrimaryContactName = defaultAccount?.primaryContact?.name?.split(' ') || [];
  const splitBillingContactName = defaultAccount?.billingContact?.name?.split(' ') || [];
  const primaryContactFirstName = splitPrimaryContactName.slice(0, -1).join(' ');
  const primaryContactLastName = splitPrimaryContactName.slice(-1).join(' ');
  const billingContactFirstName = splitBillingContactName.slice(0, -1).join(' ');
  const billingContactLastName = splitBillingContactName.slice(-1).join(' ');

  // Vendor accounts should get data from connectedAccount
  // Customer accounts should get data from account
  const isCustomer = defaultAccount?.accountTypes?.includes(AccountType.CUSTOMER);
  const isVendor = defaultAccount?.accountTypes?.includes(AccountType.VENDOR);
  const truckCount =
    isVendor && !isCustomer
      ? get(defaultAccount, 'connectedAccount.truck_count', null)
      : defaultAccount?.truckCount;
  const vendorAutoAcceptOrders = get(
    defaultAccount,
    'connectedAccount.auto_accept_orders',
    null,
  );
  const customerAutoAcceptOrders = get(defaultAccount, 'autoAcceptOrders', null);
  const autoAcceptOrders =
    defaultAccount?.autoAcceptOrders !== undefined &&
    get(defaultAccount, 'connectedAccount.auto_accept_orders') !== undefined
      ? autoAcceptOrdersOptions.find(
          (option) =>
            option.value ===
            (isVendor && !isCustomer ? vendorAutoAcceptOrders : customerAutoAcceptOrders),
        )
      : autoAcceptOrdersOptions.find((option) => option.value === false);

  return {
    id: defaultAccount?.id || '',
    name: defaultAccount?.name || '',
    company: defaultAccount?.company || '',
    connectedCompany: defaultAccount?.connectedCompany || null,
    externalId: defaultAccount?.externalId || '',
    accountTypes: get(defaultAccount, 'accountTypes', []),
    companyType: get(defaultAccount, 'connectedCompany.companyType', ''),
    notes: get(defaultAccount, 'notes', ''),
    isBillingAddressSame: get(defaultAccount, 'isBillingAddressSame', true),
    isBillingContactSame: get(defaultAccount, 'isBillingContactSame', true),
    address: {
      streetAddress: get(defaultAccount, 'address.streetAddress', ''),
      premise: get(defaultAccount, 'address.premise', ''),
      locality: get(defaultAccount, 'address.locality', ''),
      administrativeArea: get(defaultAccount, 'address.administrativeArea', ''),
      postalCode: get(defaultAccount, 'address.postalCode', ''),
      country: get(defaultAccount, 'address.country', countries.default),
    },
    billingAddress: {
      streetAddress: get(defaultAccount, 'billingAddress.streetAddress', ''),
      premise: get(defaultAccount, 'billingAddress.premise', ''),
      locality: get(defaultAccount, 'billingAddress.locality', ''),
      administrativeArea: get(defaultAccount, 'billingAddress.administrativeArea', ''),
      postalCode: get(defaultAccount, 'billingAddress.postalCode', ''),
      country: get(defaultAccount, 'billingAddress.country', countries.default),
    },
    primaryContact: {
      name: get(defaultAccount, 'primaryContact.name', ''),
      firstName:
        primaryContactFirstName || get(defaultAccount, 'primaryContact.first_name', ''),
      lastName:
        primaryContactLastName || get(defaultAccount, 'primaryContact.last_name', ''),
      email: get(defaultAccount, 'primaryContact.email', ''),
      phone: get(defaultAccount, 'primaryContact.phone', ''),
    },
    billingContact: {
      name: get(defaultAccount, 'billingContact.name', ''),
      firstName:
        billingContactFirstName || get(defaultAccount, 'billingContact.first_name', ''),
      lastName:
        billingContactLastName || get(defaultAccount, 'billingContact.last_name', ''),
      email: get(defaultAccount, 'billingContact.email', ''),
      phone: get(defaultAccount, 'billingContact.phone', ''),
    },
    autoAcceptOrders,
    truckCount,
    vendorAutoAcceptOrders: autoAcceptOrdersOptions.find(
      (option) => option.value === vendorAutoAcceptOrders,
    ),
    vendorTruckCount: get(defaultAccount, 'connectedAccount.truck_count', null),
    inviteTypes: [],
  };
};
