import { ServiceClass_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { ServiceClass } from '~hooks/useServiceClass';
import { GetPaginationParams, PaginationLink } from '~interfaces';
import connection from '~services/connectionModule';
import { useStores } from '~store';

// API props
interface CreateUpdateServiceClassProps {
  serviceClass: ServiceClass;
  callBack?: (serviceClass: ServiceClass) => void;
}

interface DeleteServiceClassProps {
  serviceClassId: string;
  callBack?: () => void;
}

export const useServiceClass = () => {
  const { companyAssetsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // GET /v1/service_classes endpoint
  const getServiceClasses = (link?: PaginationLink) => {
    setIsLoading(true);
    const params: GetPaginationParams = {
      'page[limit]': companyAssetsStore.serviceClassPagination.limit,
    };
    if (link && companyAssetsStore.serviceClassPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.serviceClassPagination[link];
    }
    return connection
      .getPaginated<ServiceClass_Read>(
        `${API_VERSION}/service_classes`,
        {
          params,
        },
        $t('error_messages.service_classes.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        companyAssetsStore.setServiceClasses(data.map(ServiceClass.parse));
        companyAssetsStore.setServiceClassPagination(pagination);
        companyAssetsStore.updateServiceClassPageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // GET /v1/companies/{companyId}/service_classes endpoint
  const getServiceClassesByCompanyId = (companyId: string, link?: PaginationLink) => {
    setIsLoading(true);
    const params: GetPaginationParams = {
      'page[limit]': companyAssetsStore.serviceClassPagination.limit,
    };
    if (link && companyAssetsStore.serviceClassPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.serviceClassPagination[link];
    }
    return connection
      .getPaginated<ServiceClass_Read>(
        `${API_VERSION}/companies/${companyId}/service_classes`,
        {
          params,
        },
        $t('error_messages.service_classes.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        companyAssetsStore.setServiceClasses(data.map(ServiceClass.parse));
        companyAssetsStore.setServiceClassPagination(pagination);
        companyAssetsStore.updateServiceClassPageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // POST /v1/service_classes endpoint
  const createServiceClass = ({
    serviceClass,
    callBack,
  }: CreateUpdateServiceClassProps) => {
    setIsLoading(true);
    return connection
      .post<ServiceClass_Read>(
        `${API_VERSION}/service_classes`,
        ServiceClass.deparseCreate(serviceClass),
        {},
        $t('error_messages.service_classes.failed_to_create') as string,
      )
      .then((resp) => {
        const serviceClass = ServiceClass.parse(resp);
        companyAssetsStore.addServiceClass(serviceClass);
        callBack?.(serviceClass);
        return serviceClass;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // POST /v1/companies/{companyId}/service_class endpoint
  const createServiceClassByCompanyId = ({
    serviceClass,
    companyId,
    callBack,
  }: CreateUpdateServiceClassProps & { companyId: string }) => {
    setIsLoading(true);
    return connection
      .post<ServiceClass_Read>(
        `${API_VERSION}/companies/${companyId}/service_classes`,
        ServiceClass.deparseCreate(serviceClass),
        {},
        $t('error_messages.service_classes.failed_to_create') as string,
      )
      .then((resp) => {
        const serviceClass = ServiceClass.parse(resp);
        companyAssetsStore.addServiceClass(serviceClass);
        callBack?.(serviceClass);
        return serviceClass;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // PATCH /v1/service_classes/{serviceClassId} endpoint
  const updateServiceClass = ({
    serviceClass,
    callBack,
  }: CreateUpdateServiceClassProps) => {
    setIsUpdating(true);
    return connection
      .patch<ServiceClass_Read>(
        `${API_VERSION}/service_classes/${serviceClass.id}`,
        ServiceClass.deparseUpdate(serviceClass),
        {},
        $t('error_messages.service_classes.failed_to_update') as string,
      )
      .then((resp) => {
        const serviceClass = ServiceClass.parse(resp);
        companyAssetsStore.updateServiceClass(serviceClass);
        callBack?.(serviceClass);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  // DELETE /v1/service_classes/{serviceClassId} endpoint
  const deleteServiceClass = ({ serviceClassId, callBack }: DeleteServiceClassProps) => {
    setIsLoading(true);
    return connection
      .delete<ServiceClass_Read>(
        `${API_VERSION}/service_classes/${serviceClassId}`,
        {},
        $t('error_messages.service_classes.failed_to_delete') as string,
      )
      .then(() => {
        companyAssetsStore.deleteServiceClass(serviceClassId);
        callBack?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    isUpdating,
    getServiceClasses,
    createServiceClass,
    updateServiceClass,
    deleteServiceClass,
    createServiceClassByCompanyId,
    getServiceClassesByCompanyId,
  };
};
