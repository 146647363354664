import Box from '@mui/material/Box';
import { t as $t } from 'i18next';
import { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
import { FormStateChangeProps } from '~formsShared';
import { ServiceClass } from '~hooks/useServiceClass';
import { Nullable } from '~types/Nullable';

interface ServiceClassFormProps {
  defaultServiceClass: Nullable<ServiceClass>;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  onFormDirty?: (isDirty: boolean) => void;
}

interface ServiceClassFormRef {
  submit: (callBack: () => void) => void;
  resetForm: (callBack?: () => void) => void;
}

/**
 * This file contains the ServiceClassForm component.
 * The ServiceClassForm component is responsible for rendering and managing the form for creating or editing a service class.
 */
export const ServiceClassForm = forwardRef(function ServiceClassForm(
  { defaultServiceClass, onFormStateChange }: ServiceClassFormProps,
  ref: Ref<ServiceClassFormRef>,
) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      id: defaultServiceClass?.id || '',
      name: defaultServiceClass?.name || '',
    },
  });

  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    onFormStateChange({ isValid, isDirty });
  }, [isValid, isDirty]);

  return (
    <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%', flexGrow: 1 }}>
      <Box sx={{ width: '100%' }}>
        <TextFormField
          control={control}
          errors={errors}
          name="name"
          label={`${$t('form_fields.name')}`}
          isRequired={true}
        />
      </Box>
    </Box>
  );
});
