import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { memo } from 'react';

import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';
import { dateFormat } from '~utils/dateTime';

interface OrderInfoProps {
  order: Order;
  sx?: SxProps;
  width?: string;
}

const OrderInfo = memo(function OrderInfo({ order, sx, width }: OrderInfoProps) {
  return (
    <Box sx={sx} width={'100%'}>
      <Typography fontSize="12px" fontWeight={600}>
        {dateFormat(dayjs(order.loadAt), 'hh:mmA · MMM DD')}
        {` · ${order.quantity} ${order.unitOfMeasure?.name}`}
        {` · ${order.material?.name}`}
      </Typography>

      <Box display="flex" width="100%" flexDirection="column" gap={0} mt={1}>
        {order.company?.legalName && (
          <OrderAttribute
            name={`${t('live_map.assigning_company')}`}
            text={order.company.legalName}
          />
        )}

        <OrderAttribute
          name={`${t('common.customer')}`}
          text={order.account?.name ?? ''}
        />

        <OrderAttribute
          name={`${t('live_map.pickup_site')}`}
          text={order.pickUpWayPoints?.[0]?.site?.name ?? ''}
        />

        <OrderAttribute
          name={`${t('live_map.dropoff_site')}`}
          text={order.dropOffWayPoints?.[0]?.site?.name ?? ''}
        />
      </Box>
    </Box>
  );
});

interface OrderAttributeProps {
  name?: string;
  text: string;
}

const OrderAttribute = memo(function OrderAttribute({ name, text }: OrderAttributeProps) {
  return (
    <Typography
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      fontSize="12px"
    >
      {name && (
        <Typography fontSize="inherit" component="span" color={theme.palette.grey[600]}>
          {name}:
        </Typography>
      )}{' '}
      <Typography fontSize="inherit" component="span">
        {text}
      </Typography>
    </Typography>
  );
});

export default OrderInfo;
