import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useStores } from '~store';

// Import { ActionType, Events, EventType } from './types';
// Export const AnalyticsController = {
//   TrackClick: (action: ActionType, label?: string) => {
//     If (window.analytics) {
//       Window.analytics.track(Events.buttonClick, {
//         Action,
//         Label: label || null,
//       });
//     }
//   },
//   PushEvent: (event: EventType, action: ActionType, label?: string) => {
//     If (window.analytics) {
//       Window.analytics.track(event, {
//         Action,
//         Label: label || null,
//       });
//     }
//   },
// };
// Export const AnalyticsController;

export const useSegmentAnalytics = () => {
  const location = useLocation();
  const { userStore } = useStores();

  const user = userStore.user;
  const selectedCompany = userStore.userCompany;

  const identify = useCallback(() => {
    const { id, firstName, lastName, email, phone } = user || {};
    const company = {
      id: selectedCompany?.id,
      name: selectedCompany?.legalName,
    };

    if (window.analytics && id && company.id) {
      const userData = {
        name: `${firstName} ${lastName}`,
        email,
        phone,
        company,
      };

      // Set user identifier
      window.analytics.identify(user.id, userData);

      // Group by company
      window.analytics.group(company.id, company);
    }
  }, [window.analytics, user?.id, selectedCompany?.id]);

  useEffect(() => {
    identify();
  }, [identify]);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page(location.pathname);
    }
  }, [window.analytics, location?.pathname]);
};
