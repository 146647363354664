import { GridColDef } from '@mui/x-data-grid-premium';
import { t } from 'i18next';

import { TelematicsVehicle } from '~hooks/useTelematicsVehicles';

import { TelematicsVehicleEquipmentSelector } from './TelematicsVehicleEquipmentSelector';

export type RemoteVehicleRowDef = {
  row: {
    id: string;
    remoteVehicle: TelematicsVehicle;
  };
};

export const equipmentIDDef: GridColDef = {
  flex: 1,
  field: 'fleetTruckName',
  headerName: `${t('administration.integration.remote_vehicles_table.telematics_equipment_id')}`,
  valueGetter: ({ row }: RemoteVehicleRowDef) =>
    row.remoteVehicle.remoteVehicleExternalId,
};

export const equipmentNameDef: GridColDef = {
  flex: 1,
  field: 'equipmentName',
  headerName: `${t('administration.integration.remote_vehicles_table.telematics_equipment_name')}`,
  valueGetter: ({ row }: RemoteVehicleRowDef) =>
    row.remoteVehicle.remoteVehicleExternalName,
};

export const equipmentMappingDef: GridColDef = {
  flex: 1,
  field: 'equipmentID',
  headerName: `${t('administration.integration.remote_vehicles_table.tread_equipment_mapping')}`,
  renderCell: ({ row }: RemoteVehicleRowDef) => (
    <TelematicsVehicleEquipmentSelector remoteVehicle={row.remoteVehicle} />
  ),
};
