import { makeObservable, observable } from 'mobx';

import { Account } from '~hooks/useAccount';
import { Company } from '~hooks/useCompany';
import { Pagination, PaginationLink } from '~interfaces/pagination';
import { AdminCompany } from '~src/hooksAdmin/useCompaniesAdmin';
import { RootStore } from '~store/RootStore';
import { getPageNumber } from '~utils/pagination';

class CompanySelectOption {
  public static parse(company: AdminCompany | Company): CompanySelectOption {
    return new CompanySelectOption(company.id, company.legalName);
  }
  public get id(): string {
    return this._id;
  }
  public get label(): string {
    return this._legalName;
  }
  public get value(): string {
    return this._id;
  }
  constructor(
    private _id: string,
    private _legalName: string,
  ) {}
}
class CompanyAdminStore {
  companies: AdminCompany[] = [];
  accounts: Account[] = [];
  currentCompany: AdminCompany = {} as AdminCompany;

  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  accountsPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      currentCompany: observable,
      companies: observable,
      accounts: observable,
      pagination: observable,
      accountsPagination: observable,
    });
  }
  setCompanies(companies: AdminCompany[]) {
    this.companies = [...companies];
  }

  setAccounts(accounts: Account[]) {
    this.accounts = [...accounts];
  }

  setPagination(pagination: Pagination) {
    this.pagination = {
      ...this.pagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  setAccountsPagination(pagination: Pagination) {
    this.accountsPagination = {
      ...this.accountsPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  updatePageNumber(link: PaginationLink) {
    this.pagination = {
      ...this.pagination,
      page: getPageNumber(this.pagination.page, link),
    };
  }

  updateAccountsPageNumber(link: PaginationLink) {
    this.accountsPagination = {
      ...this.accountsPagination,
      page: getPageNumber(this.accountsPagination.page, link),
    };
  }

  public get companyDictionary(): CompanySelectOption[] {
    return this.companies.map(CompanySelectOption.parse);
  }
  addCompany(company: AdminCompany) {
    this.companies = [...this.companies, company];
  }
  setCurrentCompany(company: AdminCompany) {
    this.currentCompany = company;
  }

  updateCompany(company: AdminCompany) {
    this.companies = this.companies.map((c) => (c.id === company.id ? company : c));
  }

  removeCompany(id: string) {
    this.companies = this.companies.filter((c) => c.id !== id);
  }

  // Todo: updateCompany
}

export default CompanyAdminStore;
