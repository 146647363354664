import { InvoiceCategoryFilter, RateType } from '@treadinc/horizon-api-spec';
import _ from 'lodash';

import { InvoiceSearchParamName, InvoiceSearchParams } from '~store/InvoicesStore';
import {
  SettlementSearchParamName,
  SettlementSearchParams,
} from '~store/SettlementsStore';

import {
  GridQSParam,
  MaybeNullQSParams,
  payableInvoiceCategories,
  payableJobsStateTabs,
  payableSettlementsStateTabs,
  QSParams,
  receivableJobsStateTabs,
  receivableSettlementsStateTabs,
  TabQSParam,
} from './DriverPay';

export const parseQueryStringParams = (searchParams: URLSearchParams) => {
  const category = searchParams.get('category') as InvoiceCategoryFilter | null;
  const tab = searchParams.get('tab') as TabQSParam | null;
  const grid = searchParams.get('grid') as GridQSParam | null;
  const start_date = searchParams.get('start_date') as string | null;
  const end_date = searchParams.get('end_date') as string | null;
  const project_ids = searchParams.get('project_ids') as string | null;
  const customer_account_ids = searchParams.get('customer_account_ids') as string | null;
  const vendor_account_ids = searchParams.get('vendor_account_ids') as string | null;
  const truck_ids = searchParams.get('truck_ids') as string | null;
  const driver_ids = searchParams.get('driver_ids') as string | null;
  const dropoff_site_ids = searchParams.get('dropoff_site_ids') as string | null;
  const pickup_site_ids = searchParams.get('pickup_site_ids') as string | null;
  const rate_types = searchParams.get('rate_types') as string | null;

  const params: MaybeNullQSParams = {
    category,
    tab,
    grid,
    start_date,
    end_date,
    project_ids,
    customer_account_ids,
    vendor_account_ids,
    truck_ids,
    driver_ids,
    dropoff_site_ids,
    pickup_site_ids,
    rate_types,
  };

  return params;
};

export const deriveInvoiceSearchParamsFromQueryString = (params: QSParams) => {
  const {
    category,
    tab,
    start_date,
    end_date,
    project_ids,
    customer_account_ids,
    vendor_account_ids,
    truck_ids,
    driver_ids,
    dropoff_site_ids,
    pickup_site_ids,
    rate_types,
  } = params;

  const invoiceSearchParams: InvoiceSearchParams = {
    [InvoiceSearchParamName.INVOICE_CATEGORY]: category,
  };

  const tabs = payableInvoiceCategories.includes(category)
    ? payableJobsStateTabs
    : receivableJobsStateTabs;
  const selectedTab = tabs.find((invoiceStateTab) => invoiceStateTab.value === tab);

  if (!selectedTab) {
    throw new Error('Invalid invoice states tab selected');
  }

  invoiceSearchParams[InvoiceSearchParamName.GROUPED] =
    selectedTab.value === TabQSParam.GROUPED;
  invoiceSearchParams[InvoiceSearchParamName.INVOICE_STATES] = selectedTab.filters;

  if (start_date) {
    invoiceSearchParams[InvoiceSearchParamName.START_DATE] = start_date;
  }

  if (end_date) {
    invoiceSearchParams[InvoiceSearchParamName.END_DATE] = end_date;
  }

  if (project_ids) {
    invoiceSearchParams[InvoiceSearchParamName.PROJECTS] = project_ids.split('|');
  }

  if (customer_account_ids) {
    invoiceSearchParams[InvoiceSearchParamName.CUSTOMERS] =
      customer_account_ids.split('|');
  }

  if (vendor_account_ids) {
    invoiceSearchParams[InvoiceSearchParamName.VENDORS] = vendor_account_ids.split('|');
  }

  if (truck_ids) {
    invoiceSearchParams[InvoiceSearchParamName.TRUCKS] = truck_ids.split('|');
  }

  if (driver_ids) {
    invoiceSearchParams[InvoiceSearchParamName.DRIVERS] = driver_ids.split('|');
  }

  if (dropoff_site_ids) {
    invoiceSearchParams[InvoiceSearchParamName.DROPOFF_SITE] =
      dropoff_site_ids.split('|');
  }

  if (pickup_site_ids) {
    invoiceSearchParams[InvoiceSearchParamName.PICKUP_SITE] = pickup_site_ids.split('|');
  }

  if (rate_types) {
    invoiceSearchParams[InvoiceSearchParamName.RATE_TYPES] = rate_types.split(
      '|',
    ) as RateType[];
  }

  return invoiceSearchParams;
};

export const deriveSettlementsSearchParamsFromQueryString = (params: QSParams) => {
  const {
    category,
    tab,
    start_date,
    end_date,
    project_ids,
    customer_account_ids,
    vendor_account_ids,
    truck_ids,
    driver_ids,
    dropoff_site_ids,
    pickup_site_ids,
    rate_types,
  } = params;

  const settlementSearchParams: SettlementSearchParams = {
    [SettlementSearchParamName.SETTLEMENT_CATEGORY]: category,
  };

  const tabs = payableInvoiceCategories.includes(category)
    ? payableSettlementsStateTabs
    : receivableSettlementsStateTabs;
  const selectedTab = tabs.find((settlementStateTab) => settlementStateTab.value === tab);

  if (!selectedTab) {
    throw new Error('Invalid settlement states tab selected');
  }

  settlementSearchParams[SettlementSearchParamName.SETTLEMENT_STATES] =
    selectedTab.filters;

  if (start_date) {
    settlementSearchParams[SettlementSearchParamName.START_DATE] = start_date;
  }

  if (end_date) {
    settlementSearchParams[SettlementSearchParamName.END_DATE] = end_date;
  }

  if (project_ids) {
    settlementSearchParams[SettlementSearchParamName.PROJECTS] = project_ids.split('|');
  }

  if (customer_account_ids) {
    settlementSearchParams[SettlementSearchParamName.CUSTOMERS] =
      customer_account_ids.split('|');
  }

  if (vendor_account_ids) {
    settlementSearchParams[SettlementSearchParamName.VENDORS] =
      vendor_account_ids.split('|');
  }

  if (truck_ids) {
    settlementSearchParams[SettlementSearchParamName.TRUCKS] = truck_ids.split('|');
  }

  if (driver_ids) {
    settlementSearchParams[SettlementSearchParamName.DRIVERS] = driver_ids.split('|');
  }

  if (dropoff_site_ids) {
    settlementSearchParams[SettlementSearchParamName.DROPOFF_SITE] =
      dropoff_site_ids.split('|');
  }

  if (pickup_site_ids) {
    settlementSearchParams[SettlementSearchParamName.PICKUP_SITE] =
      pickup_site_ids.split('|');
  }

  if (rate_types) {
    settlementSearchParams[SettlementSearchParamName.RATE_TYPES] = rate_types.split(
      '|',
    ) as RateType[];
  }

  return settlementSearchParams;
};

export const isValidGridAndTabCombination = (params: MaybeNullQSParams) => {
  const { grid, category, tab } = params;

  const isTargetingSettlementsGrid = grid === GridQSParam.SETTLEMENTS;

  const isTargetingReceivablesCategory = category === InvoiceCategoryFilter.RECEIVABLES;

  const isTargetingGroupedTab = tab === TabQSParam.GROUPED;
  const isTargetingSentTab = tab === TabQSParam.SENT;

  const isTargetingReceivablesSettlements =
    isTargetingSettlementsGrid && isTargetingReceivablesCategory;

  // "GROUPED" tab only exists within the jobs grid
  if (isTargetingSettlementsGrid && isTargetingGroupedTab) {
    return false;
  }

  // "SENT" tab only exists within the settlements grid and receivables tab
  if (isTargetingSentTab && !isTargetingReceivablesSettlements) {
    return false;
  }

  return true;
};

export const areAllRequiredParamsSet = (
  params: MaybeNullQSParams,
): params is QSParams => {
  const categoryParamIsSet = !_.isNil(params.category);
  const gridParamIsSet = !_.isNil(params.grid);
  const tabParamIsSet = !_.isNil(params.tab);

  if (categoryParamIsSet && gridParamIsSet && tabParamIsSet) {
    return isValidGridAndTabCombination(params);
  }

  return false;
};
