import { JobState } from '@treadinc/horizon-api-spec';

const editableJobStates = [JobState.CREATED, JobState.ACCEPTED, JobState.REJECTED];
export const nonEditableJobStates = [
  JobState.REASSIGN,
  JobState.TO_PICKUP,
  JobState.ARRIVED_PICKUP,
  JobState.LOADED,
  JobState.TO_DROPOFF,
  JobState.ARRIVED_DROPOFF,
  JobState.UNLOADED,
  JobState.COMPLETED,
  JobState.CANCELED,
  JobState.SENT,
  JobState.IN_REVIEW,
  JobState.FLAGGED,
  JobState.SIGNED_OFF,
  JobState.PAID,
  JobState.LOAD_COMPLETED,
];

export const allJobStates = [...editableJobStates, ...nonEditableJobStates];
