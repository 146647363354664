import { ChipOwnProps } from '@mui/material/Chip';
import { JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';

import { Chip } from '~components/Chip/Chip';
import { snakeCaseToHuman } from '~utils/utilFunctions';

const propsByJobState: Partial<Record<JobState, Pick<ChipOwnProps, 'color' | 'label'>>> =
  {
    [JobState.CREATED]: { color: 'secondary', label: t('status.created') },
    [JobState.ASSIGNED]: { color: 'secondary', label: t('status.assigned') },
    [JobState.ACCEPTED]: { color: 'success', label: t('status.accepted') },
    [JobState.REJECTED]: { color: 'error', label: t('status.rejected') },
    [JobState.TO_PICKUP]: { color: 'info', label: t('status.to_pickup') },
    [JobState.ARRIVED_PICKUP]: { color: 'info', label: t('status.arrived_pickup') },
    [JobState.LOADED]: { color: 'info', label: t('status.loaded') },
    [JobState.TO_DROPOFF]: { color: 'info', label: t('status.to_dropoff') },
    [JobState.ARRIVED_DROPOFF]: { color: 'info', label: t('status.arrived_dropoff') },
    [JobState.UNLOADED]: { color: 'info', label: t('status.unloaded') },
    [JobState.COMPLETED]: { color: 'default', label: t('status.completed') },
    [JobState.CANCELED]: { color: 'error', label: t('status.canceled') },
    [JobState.SENT]: { color: 'primary', label: t('status.sent') },
    [JobState.IN_REVIEW]: { color: 'default', label: t('status.in_review') },
    [JobState.FLAGGED]: { color: 'default', label: t('status.flagged') },
    [JobState.SIGNED_OFF]: { color: 'default', label: t('status.signed_off') },
    [JobState.PAID]: { color: 'default', label: t('status.paid') },
    [JobState.LOAD_COMPLETED]: { color: 'info', label: t('status.unloaded') },
  };

interface JobStatusChipProps {
  hideDropdownArrow?: boolean;
  jobState: JobState;
}

export default function JobStatusChip({
  hideDropdownArrow,
  jobState,
}: JobStatusChipProps) {
  const { color, label } = propsByJobState[jobState] ?? {
    label: snakeCaseToHuman(jobState),
    color: 'info',
  };

  return <Chip color={color} hideDropdownArrow={hideDropdownArrow} label={label} />;
}
