import Check from '@mui/icons-material/Check';
import Send from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography, { TypographyOwnProps } from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import {
  InvoiceCategoryFilter,
  InvoiceState as InvoiceStateEnum,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { useMemo } from 'react';

import InvoiceState from '~components/Invoice/InvoiceState';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Invoice } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob';
import { dateFormat, minutesToHoursMins } from '~utils/dateTime';

import { payableInvoiceCategories, receivableInvoiceCategories } from './DriverPay';

const CellText = styled(Typography)<TypographyOwnProps>(() => ({
  '&.MuiTypography-root': {
    fontSize: '10px',
  },
}));

type CurrencyFormatterFn = ReturnType<typeof useCompanyCurrency>['currencyFormatter'];

type DriverPayRowDefUtils = {
  approveInvoice: (invoice: Invoice) => void;
  currencyFormatter: CurrencyFormatterFn;
  sendInvoice?: (invoice: Invoice) => void;
};

export type DriverPayRowDef = {
  row: {
    id: string;
    currentCategory: InvoiceCategoryFilter;
    invoice: Invoice;
    job: Job | null;
    userCompanyName: string;
    utils: DriverPayRowDefUtils;
  };
};

export const scheduledTimeColDef: GridColDef = {
  flex: 1,
  field: 'scheduledTime',
  headerName: `${t('form_fields.scheduled_time')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.job?.jobStartAt?.toISOString(),
  renderCell: ({ row }: DriverPayRowDef) => {
    const date = row.job?.jobStartAt;

    if (date) {
      return <CellText>{dateFormat(date, 'hh:mm A MM/DD/YY')}</CellText>;
    }
  },
};
export const customerColDef: GridColDef = {
  flex: 1,
  field: 'customer',
  headerName: `${t('form_fields.customer')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.customerAccount?.name ?? row.userCompanyName;
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    return (
      <CellText>{row.invoice.customerAccount?.name ?? row.userCompanyName}</CellText>
    );
  },
};
export const truckIdColDef: GridColDef = {
  flex: 1,
  field: 'truckId',
  headerName: `${t('form_fields.truck_id')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.truckId ?? '';
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.invoice.truckId ?? ''}</CellText>;
  },
};

export const dispatchNoColDef: GridColDef = {
  flex: 1,
  field: 'dispatchNo',
  headerName: `${t('order.form.dispatch_number_full')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.order?.dispatchNumber ?? '';
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.invoice.order?.dispatchNumber ?? ''}</CellText>;
  },
};
export const jobIdTimeColDef: GridColDef = {
  flex: 1,
  field: 'jobId',
  headerName: `${t('form_fields.job_id')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.job?.jobId,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.job?.jobId}</CellText>;
  },
};

export const vendorColDef: GridColDef = {
  flex: 1,
  field: 'vendor',
  headerName: `${t('form_fields.vendor')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.vendorAccount?.name ?? row.userCompanyName;
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.invoice.vendorAccount?.name ?? row.userCompanyName}</CellText>;
  },
};

export const driverNameColDef: GridColDef = {
  flex: 1,
  field: 'driverName',
  headerName: `${t('form_fields.driver_name')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.driver?.fullName,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText data-sentry-mask>{row.invoice.driver?.fullName}</CellText>;
  },
};

export const equipmentTypeColDef: GridColDef = {
  flex: 1,
  field: 'equipmentType',
  headerName: `${t('form_fields.equipment_type')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.equipment?.equipmentType.name,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.invoice.equipment?.equipmentType.name}</CellText>;
  },
};

export const pickupColDef: GridColDef = {
  flex: 1,
  field: 'pickup',
  headerName: `${t('form_fields.pick_up')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.pickupSite?.name,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.invoice.pickupSite?.name}</CellText>;
  },
};

export const dropoffColDef: GridColDef = {
  flex: 1,
  field: 'dropoff',
  headerName: `${t('form_fields.drop_off')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.dropoffSite?.name,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.invoice.dropoffSite?.name}</CellText>;
  },
};

export const driverJobStartColDef: GridColDef = {
  flex: 1,
  field: 'driverJobStart',
  headerName: `${t('approvals.driver_pay.driver_job_start')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.jobSummary?.loggingStartedAt.toDate();
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    if (row.invoice.jobSummary?.loggingStartedAt) {
      return (
        <CellText>
          {dateFormat(row.invoice.jobSummary.loggingStartedAt, 'hh:mm A')}
        </CellText>
      );
    }
  },
};

export const geofenceEntryColDef: GridColDef = {
  flex: 1,
  field: 'geofenceEntry',
  headerName: `${t('approvals.driver_pay.geofence_entry')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.jobSummary?.firstGeofenceEntryAt?.toDate();
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    if (row.invoice.jobSummary?.firstGeofenceEntryAt) {
      return (
        <CellText>
          {dateFormat(row.invoice.jobSummary.firstGeofenceEntryAt, 'hh:mm A')}
        </CellText>
      );
    }
  },
};

export const payStartColDef: GridColDef = {
  flex: 1,
  field: 'payStart',
  headerName: `${t('approvals.driver_pay.pay_start')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    const { firstGeofenceEntryAt, loggingStartedAt } = row.invoice.jobSummary ?? {};
    const date = firstGeofenceEntryAt || loggingStartedAt;

    return date?.toDate();
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    const { firstGeofenceEntryAt, loggingStartedAt } = row.invoice.jobSummary ?? {};
    const date = firstGeofenceEntryAt || loggingStartedAt;

    if (date) {
      return <CellText>{dateFormat(date, 'hh:mm A')}</CellText>;
    }
  },
};

export const driverJobEndColDef: GridColDef = {
  flex: 1,
  field: 'driverJobEnd',
  headerName: `${t('approvals.driver_pay.driver_job_end')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.jobSummary?.loggingEndedAt.toDate();
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    if (row.invoice.jobSummary?.loggingEndedAt) {
      return (
        <CellText>
          {dateFormat(row.invoice.jobSummary.loggingEndedAt, 'hh:mm A')}
        </CellText>
      );
    }
  },
};

export const geofenceExitColDef: GridColDef = {
  flex: 1,
  field: 'geofenceExit',
  headerName: `${t('approvals.driver_pay.geofence_exit')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.jobSummary?.lastGeofenceExitAt?.toDate();
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    if (row.invoice.jobSummary?.lastGeofenceExitAt) {
      return (
        <CellText>
          {dateFormat(row.invoice.jobSummary.lastGeofenceExitAt, 'hh:mm A')}
        </CellText>
      );
    }
  },
};

export const payEndColDef: GridColDef = {
  flex: 1,
  field: 'payEnd',
  headerName: `${t('approvals.driver_pay.pay_end')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    const { lastGeofenceExitAt, loggingEndedAt } = row.invoice.jobSummary ?? {};
    const date = lastGeofenceExitAt || loggingEndedAt;

    return date?.toDate();
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    const { lastGeofenceExitAt, loggingEndedAt } = row.invoice.jobSummary ?? {};
    const date = lastGeofenceExitAt || loggingEndedAt;

    if (date) {
      return <CellText>{dateFormat(date, 'hh:mm A')}</CellText>;
    }
  },
};

export const totalBreakTimeColDef: GridColDef = {
  flex: 1,
  field: 'totalBreakTime',
  headerName: `${t('approvals.driver_pay.tabs.driver_pay.total_break_time_hrs')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.jobSummary?.breakTimeMinutes ?? 0;
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    return (
      <CellText>
        {minutesToHoursMins(row.invoice.jobSummary?.breakTimeMinutes ?? 0)}
      </CellText>
    );
  },
};

export const totalHoursColDef: GridColDef = {
  flex: 1,
  field: 'totalHours',
  headerName: `${t('form_fields.total_hours')}`,
  valueGetter: ({ row }: DriverPayRowDef) => {
    return row.invoice.jobSummary?.workTimeMinutes ?? 0;
  },
  renderCell: ({ row }: DriverPayRowDef) => {
    return (
      <CellText>
        {minutesToHoursMins(row.invoice.jobSummary?.workTimeMinutes ?? 0)}
      </CellText>
    );
  },
};

export const loadsColDef: GridColDef = {
  flex: 1,
  field: 'loads',
  headerName: `${t('order.form.loads')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.jobSummary?.completedLoadsCount,
  renderCell: ({ row }: DriverPayRowDef) => {
    if (row.invoice.jobSummary?.completedLoadsCount) {
      return <CellText>{row.invoice.jobSummary.completedLoadsCount}</CellText>;
    }
  },
};

export const materialColDef: GridColDef = {
  flex: 1,
  field: 'material',
  headerName: `${t('form_fields.material')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.material?.name,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.invoice.material?.name}</CellText>;
  },
};

export const unitsColDef: GridColDef = {
  flex: 1,
  field: 'units',
  headerName: `${t('form_fields.units')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.job?.unitOfMeasure?.name,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.job?.unitOfMeasure?.name}</CellText>;
  },
};

export const quantityColDef: GridColDef = {
  flex: 1,
  field: 'quantity',
  headerName: `${t('form_fields.job_quantity')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.job?.quantity,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.job?.quantity}</CellText>;
  },
};

export const rateTypeColDef: GridColDef = {
  flex: 1,
  field: 'rateType',
  headerName: `${t('form_fields.rate_type')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.rateType,
  renderCell: ({ row }: DriverPayRowDef) => {
    if (row.invoice.rateType) {
      return (
        <CellText>
          {t(`administration.rates.${_.snakeCase(row.invoice.rateType)}`)}
        </CellText>
      );
    }
  },
};

export const rateColDef: GridColDef = {
  flex: 1,
  field: 'rate',
  headerName: `${t('form_fields.rate')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.rateValue,
  renderCell: ({ row }: DriverPayRowDef) => {
    if (!_.isNil(row.invoice.rateValue)) {
      return <CellText>{row.utils.currencyFormatter(row.invoice.rateValue)}</CellText>;
    }
  },
};

export const totalQtyColDef: GridColDef = {
  flex: 1,
  field: 'totalQty',
  headerName: `${t('approvals.driver_pay.tabs.driver_pay.total_quantity')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.invoiceLineItemsTotalQuantity,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.invoice.invoiceLineItemsTotalQuantity}</CellText>;
  },
};

export const totalPayColDef: GridColDef = {
  flex: 1,
  field: 'totalPay',
  headerName: `${t('form_fields.total_pay')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.totalAmount,
  renderCell: ({ row }: DriverPayRowDef) => {
    return <CellText>{row.utils.currencyFormatter(row.invoice.totalAmount)}</CellText>;
  },
};

export const statusColDef: GridColDef = {
  flex: 1,
  minWidth: 125,
  field: 'status',
  headerName: `${t('form_fields.status')}`,
  valueGetter: ({ row }: DriverPayRowDef) => row.invoice.state,
  renderCell: ({ row }: DriverPayRowDef) => {
    const { state } = row.invoice;

    const ctaToCall = useMemo(() => {
      const isPayableApprovable = Boolean(
        payableInvoiceCategories.includes(row.currentCategory) &&
          [InvoiceStateEnum.PENDING, InvoiceStateEnum.CUSTOMER_PENDING].includes(state),
      );

      const isReceivableApprovable = Boolean(
        receivableInvoiceCategories.includes(row.currentCategory) &&
          state === InvoiceStateEnum.PENDING,
      );

      if (isPayableApprovable || isReceivableApprovable) {
        return 'approve';
      }

      const isSendable = Boolean(
        state === InvoiceStateEnum.APPROVED && row.utils.sendInvoice,
      );

      if (isSendable && row.utils.sendInvoice) {
        return 'send';
      }
    }, [state, row.currentCategory, row.utils.approveInvoice, row.utils.sendInvoice]);

    if (ctaToCall === 'approve') {
      return (
        <Button
          color="success"
          onClick={() => row.utils.approveInvoice?.(row.invoice)}
          size="small"
          startIcon={<Check />}
          variant="contained"
        >
          {t('actions.approve')}
        </Button>
      );
    }

    if (ctaToCall === 'send') {
      return (
        <Button
          color="primary"
          onClick={() => row.utils.sendInvoice?.(row.invoice)}
          size="small"
          startIcon={<Send />}
          variant="contained"
        >
          {t('actions.send')}
        </Button>
      );
    }

    return <InvoiceState state={row.invoice.state} />;
  },
};

export const actionsColDef = (renderHeader: () => React.ReactNode): GridColDef => ({
  width: 96,
  field: 'actions',
  headerName: `${t('actions.actions')}`,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  hideable: false,
  renderHeader,
  renderCell: () => <></>,
});
