import { yupResolver } from '@hookform/resolvers/yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { AccountType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { startCase } from 'lodash';
import { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { data as enums } from '~constants/enums';
import { FormErrorType, FormStateChangeProps } from '~formsShared';
import { FormError } from '~formsShared/formSharedModels/models';
import { ConnectAccountByTreadIdParams } from '~hooks/useAccount';
import { Company } from '~hooks/useCompany/models';
import { connectionValidationSchema } from '~schemas/connectionValidationSchema';

import { SearchCompanyByTreadIdForm } from './SearchCompanyByTreadIdForm';

const FormWrapper = styled(Box)({
  paddingTop: '24px',
  paddingBottom: '24px',
});

interface ConnectAccountFormValues {
  senderCompanyId: string;
  receiverTreadId: string;
  receiverCompany?: Company;
  accountTypes: AccountType[];
  formSubmit?: FormErrorType;
}

interface ConnectAccountByTreadIdFormProps {
  senderCompanyId: string;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
}

export interface AddConnectionFormRef {
  submit: (callBack: (data: ConnectAccountByTreadIdParams) => void) => void;
  resetForm: (callBack?: () => void) => void;
}

type CompanyDetailsProps = Pick<Company, 'legalName'> & { treadId: string };

const CompanyDetailsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyledCompanyDetails = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const CompanyDetail = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

const CompanyDetailLabel = styled(Typography)<{
  component?: React.ElementType;
}>({
  fontWeight: 'bold',
});

const CompanyDetails = ({ legalName, treadId }: CompanyDetailsProps) => {
  return (
    <Box>
      <StyledCompanyDetails>
        <CompanyDetail>
          <CompanyDetailLabel component="p">
            {`${t('form_fields.company_name')}`}:
          </CompanyDetailLabel>
          <Typography component="p">{legalName}</Typography>
        </CompanyDetail>
        <CompanyDetail>
          <CompanyDetailLabel component="p">
            {`${t('form_fields.tread_id')}`}:
          </CompanyDetailLabel>
          <Typography component="p">{treadId}</Typography>
        </CompanyDetail>
      </StyledCompanyDetails>
    </Box>
  );
};

const ConnectAccountByTreadIdForm = forwardRef(function ConnectAccountByTreadIdForm(
  { onFormStateChange, senderCompanyId }: ConnectAccountByTreadIdFormProps,
  ref: Ref<AddConnectionFormRef>,
) {
  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    resetField,
    setError,
    setValue,
    watch,
  } = useForm<ConnectAccountFormValues>({
    resolver: yupResolver(connectionValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      senderCompanyId,
      accountTypes: [],
    },
  });

  const receiverCompany = watch('receiverCompany');
  const receiverTreadId = watch('receiverTreadId');
  const companyTreadIdError = errors.receiverTreadId?.message;
  const receiverCompanyFound = !companyTreadIdError && receiverCompany && receiverTreadId;
  const resetCompanyToConnect = () => resetField('receiverCompany');
  const onCompanySelect = (resp: Company, treadId: string) => {
    if (resp instanceof FormError) {
      setError('receiverTreadId', { type: '404', message: resp.message });
      resetCompanyToConnect();
    } else {
      setValue('receiverCompany', resp);
      setValue('receiverTreadId', treadId);
    }
  };

  const onSearchFormSubmit = () => {
    // Reset error message and company to connect value
    clearErrors();
    reset();
  };

  // Allows for ref to be used to call form methods
  // And submit form from parent component
  useImperativeHandle(ref, () => ({
    submit(callBack: (data: ConnectAccountByTreadIdParams) => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    onFormStateChange({ isDirty, isValid });
  }, [isValid, isDirty]);

  return (
    <>
      <Box>
        <SearchCompanyByTreadIdForm
          name="receiverTreadId"
          onFormSubmit={onSearchFormSubmit}
          formSubmitCallback={onCompanySelect}
        />

        {companyTreadIdError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {companyTreadIdError}
          </Alert>
        )}

        {receiverCompanyFound && (
          <>
            <CompanyDetailsWrapper>
              <Alert severity="success">
                {t('approvals.search_company_by_tread_id_success')}
              </Alert>
              <CompanyDetails
                legalName={receiverCompany.legalName}
                treadId={receiverTreadId}
              />
            </CompanyDetailsWrapper>

            <FormWrapper component="form" data-test-id="search-company-by-tread-id-form">
              <Typography variant="h6">
                {t('account.choose_account_types_heading')}
              </Typography>

              <AutocompleteFormField
                control={control}
                multiple={true}
                name="accountTypes"
                errors={errors}
                list={enums.account_type.values}
                placeholder={`${t('account.form.account_type')}`}
                isRequired={true}
                getValue={(item) => item}
                getLabel={(item) => startCase(item)}
                defaultValue={[]}
              />
            </FormWrapper>
          </>
        )}
      </Box>
    </>
  );
});

export { ConnectAccountByTreadIdForm };
