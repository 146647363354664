import { t } from 'i18next';
import * as yup from 'yup';

import { AddressOptionForNewSite } from '~components/Order/SiteSelection/types';
import {
  data as enums,
  geoFenceCircleOption,
  geofenceEquipmentOption,
  geofenceNoneOption,
  geofenceOptions,
  movingGeoFencesTagsOptions,
} from '~constants/enums';
import { latLonSchema } from '~constants/regexConst';
import { AddressItem } from '~hooks/useAddress';
import { Company } from '~hooks/useCompany';
import { Equipment } from '~hooks/useEquipment';
import { Geofence, Site } from '~hooks/useSites';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';
import { radiusInProperUnits } from '~utils/sites';

import { AdditionalGeoFenceField } from './GeoFenceForm';

export const formSchema = yup.object().shape({
  id: yup.string(),
  company: yup
    .object()
    .shape({
      legalName: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  name: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.name'),
    })}`,
  ),
  siteType: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.site_type'),
    })}`,
  ),
  externalId: yup.string().notRequired(),
  address: yup
    .object()
    .shape({
      streetAddress: yup.string(),
      placeId: yup.string(),
    })
    .notRequired(),
  notes: yup.string(),
  geoFence: yup.object().when('geofenceType.id', {
    is: (type: string) => ['circle', 'polygon'].includes(type),
    then: (schema) => {
      return schema.required();
    },
    otherwise: (schema) => {
      return schema.notRequired().nullable();
    },
  }),

  latLon: latLonSchema.notRequired(),
  geofenceType: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .notRequired()
    .nullable(),
  geoFenceInitialId: yup.string().notRequired(),
  radius: yup.number().when('geofenceType.id', {
    is: (type: string) => ['circle', 'equipment'].includes(type),
    then: (schema) => {
      return schema.required().min(1).max(50000).integer();
    },
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  isFeet: yup.boolean(),
  equipment: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .when('geofenceType.id', {
      is: 'equipment',
      then: (schema) => {
        return schema.required();
      },
      otherwise: (schema) => {
        return schema.notRequired().nullable();
      },
    }),

  additionalGeoFences: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      radius: yup.number().when('type.id', {
        is: (type: string) => ['circle', 'equipment'].includes(type),
        then: (schema) => {
          return schema.min(1).max(50000).integer().required();
        },
        otherwise: (schema) => {
          return schema.notRequired().nullable();
        },
      }),
      tag: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
      }),
      type: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
      }),
    }),
  ),
  additionalGeoFencesDetails: yup.array().of(yup.object()),
});

export const setDefaultSiteValues = (
  defaultSite: Nullable<Site>,
  equipmentOptions: Equipment[],
  company?: Company,
) => {
  const doesCompanyHaveAMovingSite = defaultSite?.nextBillionGeofence?.movingGeofence;
  const doesCompanyHasSiteRadius = company?.siteRadius;
  const isNewSite = !defaultSite?.id;
  const primaryGeoFenceType = defaultSite?.nextBillionGeofence?.type?.toLowerCase();

  const geoFenceType = doesCompanyHaveAMovingSite
    ? geofenceEquipmentOption
    : geofenceOptions.find((item) => item.id?.toLowerCase() === primaryGeoFenceType) ||
      // If company has siteRadius and this is a new site being created, then default to circle
      (doesCompanyHasSiteRadius && isNewSite ? geoFenceCircleOption : geofenceNoneOption);
  const equipmentOption = equipmentOptions.find(
    (item) => item.id === defaultSite?.movingSite?.equipment_id,
  );
  const { companyAssetsStore } = useStores();
  const defaultAdditionalGeofences = companyAssetsStore.activeSiteAdditionalGeoFences;
  const additionalGeoFences: AdditionalGeoFenceField[] = defaultAdditionalGeofences?.map(
    (site: Geofence) => {
      return {
        id: site.id,
        radius: radiusInProperUnits(company?.isFeet || false, site.circleRadius) || 0,
        tag:
          movingGeoFencesTagsOptions.find((tag) => tag.id === site.tag) ||
          movingGeoFencesTagsOptions[0],
        type:
          geofenceOptions.find((option) => option.id === site.type) ||
          geoFenceCircleOption,
      };
    },
  );
  return {
    company:
      {
        legalName: defaultSite?.company?.legalName || '',
        id: defaultSite?.company?.id || '',
      } || {},
    name: defaultSite?.name || '',
    externalId: defaultSite?.externalId || '',
    address: (defaultSite?.address || null) as AddressItem,
    siteType: defaultSite?.siteType || enums.site_type.default,
    notes: defaultSite?.notes || '',
    geoFence: defaultSite?.nextBillionGeofence?.id
      ? {
          id: defaultSite?.nextBillionGeofence?.id || '',
          name: defaultSite?.nextBillionGeofence?.name || '',
          type: defaultSite?.nextBillionGeofence?.type || '',
          geojson: defaultSite?.nextBillionGeofence?.geojson || null,
        }
      : null,
    placeId: defaultSite?.address?.id || null, // Keep here, for delete purpose we need to send placeId
    circleCenter: {
      lat: defaultSite?.nextBillionGeofence?.circleCenter?.lat || null,
      lon: defaultSite?.nextBillionGeofence?.circleCenter?.lon || null,
    },
    geoFenceInitialId: defaultSite?.nextBillionGeofence?.id || null,
    geofenceType: geoFenceType,
    radius:
      radiusInProperUnits(
        company?.isFeet || false,
        defaultSite?.nextBillionGeofence?.circleRadius || null,
      ) ||
      (!defaultSite?.id && company?.siteRadius) ||
      null,
    latLon: defaultSite?.latLon ? `${defaultSite?.latLon}` : '',
    // This flag determines whether we need to convert our radius to feet or not
    isFeet: company?.isFeet || false,
    additionalGeoFences: additionalGeoFences,
    equipment: equipmentOption || null,
  };
};

export const setDefaultSiteFormValuesFromAddressOption = (
  addressOption: Nullable<AddressOptionForNewSite>,
  company?: Company,
) => {
  return {
    company:
      {
        legalName: company?.legalName || '',
        id: company?.id || '',
      } || {},
    name: addressOption?.name || '',
    address: (addressOption?.address || null) as AddressItem,
    latLon: addressOption?.latLon ? `${addressOption?.latLon}` : '',
    placeId: addressOption?.placeId || null,
    // Set all to null
    siteType: enums.site_type.default,
    externalId: '',
    notes: '',
    radius: null,
    geoFence: null,
    geofenceType: geofenceNoneOption,
    geoFenceInitialId: null,
    circleCenter: {
      lat: null,
      lon: null,
    },
    // This flag determines whether we need to convert our radius to feet or not
    isFeet: company?.isFeet || false,
    moving_site: addressOption?.moving_site || undefined,
    additionalGeoFences: [],
    additionalGeoFencesCoords: [],
    equipment: null,
  };
};
