import { OmniReport_Read, OmniReportLink_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';

import { OmniReport } from './models';

export const useOmniReports = () => {
  const [isLoadingAllReports, setIsLoadingAllReports] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const getOmniReports = async () => {
    setIsLoadingAllReports(true);

    try {
      const resp = await connection.get<OmniReport_Read[]>(
        `${API_VERSION}/omni_reports`,
        {},
        $t('error_messages.omni.failed_to_fetch') as string,
      );
      return resp.map(OmniReport.parse);
    } catch (error) {
      console.error(error);
      throw new Error('Unable to retrieve omni reports');
    } finally {
      setIsLoadingAllReports(false);
    }
  };

  const getOmniReportById = async (id: string) => {
    setIsLoadingReport(true);

    try {
      const resp = await connection.get<OmniReportLink_Read>(
        `${API_VERSION}/omni_reports/${id}`,
        {},
        $t('error_messages.omni.failed_to_fetch_report', { id }) as string,
      );
      return resp.dashboard_url;
    } catch (error) {
      console.error(error);
      throw new Error(`Unable to retrieve omni report ${id}`);
    } finally {
      setIsLoadingReport(false);
    }
  };

  return { isLoadingAllReports, getOmniReports, isLoadingReport, getOmniReportById };
};
