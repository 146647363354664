import Box from '@mui/material/Box';
import { DesktopTimePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useMemo } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

interface NewTimeFormFieldProps {
  control: Control;
  errors: FieldErrors;
  format?: string;
  name: string;
  size?: 'medium' | 'small';
}

const DEFAULT_TIME_STEPS = { hours: 1, minutes: 1 };

const NewTimeFormField = ({
  control,
  errors,
  format = 'hh:mm a',
  name,
  size = 'medium',
}: NewTimeFormFieldProps) => {
  const errorMessage = useMemo(() => {
    return _.get(errors, `${name}.message`) as string | undefined;
  }, [errors, name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Box>
            <DesktopTimePicker
              timeSteps={DEFAULT_TIME_STEPS}
              format={format}
              onChange={(val) => {
                if (!val || !dayjs.isDayjs(val) || !val.isValid()) {
                  return onChange(null);
                }

                return onChange(val);
              }}
              value={dayjs.tz(value)}
              slotProps={{
                textField: {
                  ...(_.isUndefined(errorMessage)
                    ? {}
                    : {
                        helperText: errorMessage,
                        error: true,
                      }),
                  ...(size === 'small'
                    ? {
                        sx: {
                          '& .MuiOutlinedInput-root': { pr: 0, width: '88px' },
                          '& .MuiOutlinedInput-input': {
                            p: '5px 0 5px 7px',
                            fontSize: '12px',
                          },
                        },
                      }
                    : {}),
                },
                inputAdornment: {
                  ...(size === 'small'
                    ? {
                        sx: {
                          '&.MuiInputAdornment-root': {
                            p: 0,
                            minWidth: '18px',
                            mr: '5px',
                            ml: 0,
                          },
                          '& .MuiIconButton-root': { p: 0, mr: 0 },
                          '& .MuiSvgIcon-root': { width: '.75em', height: '.75em' },
                        },
                      }
                    : {}),
                },
              }}
            />
          </Box>
        );
      }}
    />
  );
};
export { NewTimeFormField };
