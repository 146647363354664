import { LngLat } from '@nbai/nbmap-gl';
import React from 'react';

import { FeatureFlags } from '~constants/featureFlags';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import { BaseMap, BaseMapProps } from './BaseMap';
import GoogleBaseMap from './GoogleBaseMap';

const HybridBaseMap = (baseMapProps: BaseMapProps) => {
  const [showGoogleMap, setShowGoogleMap] = React.useState(false);
  const hybridBaseMapEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.hybridBaseMap,
  });
  return hybridBaseMapEnabled ? (
    showGoogleMap ? (
      <GoogleBaseMap
        center={baseMapProps.center as LngLat}
        zoom={baseMapProps.zoom || 11}
        setShowGoogleMap={setShowGoogleMap}
        sx={baseMapProps.sx}
      />
    ) : (
      <BaseMap {...baseMapProps} setShowGoogleMap={setShowGoogleMap} />
    )
  ) : (
    <BaseMap {...baseMapProps} />
  );
};

export default HybridBaseMap;
