import { TelematicsFleet_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';

import { TelematicsFleet } from './models';

export const useTelematicsFleets = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getTelematicsFleets = () => {
    setIsLoading(true);
    return connection
      .get<TelematicsFleet_Read[]>(
        `${API_VERSION}/telematics/fleets`,
        {},
        $t('error_messages.telematics.failed_to_fetch_fleets') as string,
      )
      .then((resp) => resp.map(TelematicsFleet.parse))
      .finally(() => setIsLoading(false));
  };

  return { isLoading, getTelematicsFleets };
};
