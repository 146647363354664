import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import React, { CSSProperties } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as VirtualList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

interface ActiveItemsVirtualLazyLoadListProps {
  items: any[];
  hasNextPage: boolean;
  loadNextPage: () => void;
  onClickCallBack: (item: any) => void;
  activeItemId: string;
  titleProperty: string;
  itemHeight: number;
  itemCountInitial?: number;
}
interface ItemProps {
  index: number;
  style: CSSProperties;
  onClickCallBack: (item: any) => void;
  titleProperty: string;
}
const ActiveItemsVirtualLazyLoadList = ({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,
  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  // Array of items loaded so far.
  items,
  // Callback function responsible for loading the next page of items.
  loadNextPage,
  activeItemId,
  onClickCallBack,
  titleProperty,
  itemHeight,
  itemCountInitial,
}: ActiveItemsVirtualLazyLoadListProps) => {
  const theme = useTheme();
  const maxItemCount = 10000;
  const rowsFromBottomToStartLoading = 15;
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = hasNextPage
    ? () => {
        loadNextPage();
      }
    : () => {};

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => {
    return !hasNextPage || index < items.length + 1;
  };

  // Render an item or a loading indicator.
  const Item = ({ index, style }: ItemProps) => {
    const item = items[index];
    const isActive = activeItemId === item?.id;
    let content;
    if (!isItemLoaded(index)) {
      content = t('actions.loading');
    } else {
      content = item?.[titleProperty];
    }
    return (
      <ListItemButton
        selected={isActive}
        onClick={() => onClickCallBack(item)}
        key={index}
        sx={{
          borderRight: isActive ? `2px solid ${theme.palette.primary.main}` : '',
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
        style={style}
      >
        <ListItemText primary={content} />
      </ListItemButton>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }: any) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={maxItemCount}
          loadMoreItems={loadMoreItems}
          threshold={rowsFromBottomToStartLoading}
        >
          {({ onItemsRendered, ref }: any) => (
            <VirtualList
              className="List"
              height={height - itemHeight}
              width={width}
              itemCount={Math.max(itemCountInitial || 0, itemCount)}
              itemSize={itemHeight}
              onItemsRendered={onItemsRendered}
              ref={ref}
            >
              {/*@ts-ignore*/}
              {Item}
            </VirtualList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};
export { ActiveItemsVirtualLazyLoadList };
