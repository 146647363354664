import dayjs from 'dayjs';
import { useCallback } from 'react';

/**
  This hook returns a function that can be used to normalize a time value
  to an absolute position on the graph, given a time range. The second parameter
  is whether the value should be inverted (e.g. if you are calculating position from the right)
  Usage:
  const nrmlz = useTimeNormalization(minTime, maxTime);
  <Component style={{ left: nrmlz(timestamp) }} />
  <Component style={{ right: nrmlz(timestamp, true) }} />
 */
const useTimeNormalization = (minTime: string | number, maxTime: string | number) => {
  return useCallback(
    (time: string, inverted?: boolean): string => {
      const unixStart = dayjs(minTime).valueOf();
      const unixEnd = dayjs(maxTime).valueOf();
      const unixCurrent = dayjs(time).valueOf();

      const normalizedValue = (unixCurrent - unixStart) / (unixEnd - unixStart);
      const finalNormalizedValue = Math.max(
        inverted ? 1 - normalizedValue : normalizedValue,
        0,
      );
      return `${finalNormalizedValue * 100}%`;
    },
    [minTime, maxTime],
  );
};

export { useTimeNormalization };
