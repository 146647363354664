import { useMemo } from 'react';

import { Segment } from './useGetCycleSegments';

const useNoSignalBlocks = (segments: Segment[]) =>
  useMemo(() => {
    const noSignalBlocks = [];

    for (let i = 0; i < segments.length - 1; i++) {
      const currentSegment = segments[i];
      const nextSegment = segments[i + 1];

      // Check if the segments are valid
      if (
        !currentSegment ||
        !nextSegment ||
        !currentSegment.start ||
        !currentSegment.end ||
        !nextSegment.start ||
        !nextSegment.end
      ) {
        continue; // Skip if the segments are invalid
      }

      const lastEndCurrent = currentSegment.end.add(1, 'second');
      const firstStartNext = nextSegment.start.subtract(1, 'second');

      noSignalBlocks.push([lastEndCurrent, firstStartNext]);
    }

    return noSignalBlocks;
  }, [segments.length]);

export { useNoSignalBlocks };
