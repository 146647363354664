import { FileAttachment_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';

import { FileAttachment } from './models';

export type FileAttachableType = FileAttachment_Read.file_attachable_type;

export interface UpdateFileAttachmentParams {
  description?: string;
  category?: string;
}

export const useFileAttachment = () => {
  const getFileAttachmentById = async (id: string): Promise<FileAttachment> => {
    return await connection
      .get<FileAttachment_Read>(
        `${API_VERSION}/file_attachments/${id}`,
        {},
        $t('error_messages.file_attachment.failed_to_fetch') as string,
      )
      .then((response) => {
        const data = FileAttachment.parse(response);
        return data;
      });
  };

  const getAllFileAttachmentsById = async (
    fileAttachableId: string,
    fileAttachableType: FileAttachableType,
  ) => {
    return await connection
      .get<
        FileAttachment_Read[]
      >(`${API_VERSION}/${fileAttachableType.toLowerCase()}s/${fileAttachableId}/file_attachments`, {}, $t('error_messages.file_attachment.failed_to_fetch') as string)
      .then((response) => {
        const data = response.map((item) => FileAttachment.parse(item));
        return data;
      });
  };

  const getAllFileAttachmentsByProjectId = async (projectId: string) => {
    return await getAllFileAttachmentsById(
      projectId,
      FileAttachment_Read.file_attachable_type.PROJECT,
    );
  };

  const getAllFileAttachmentsByOrderId = async (orderId: string) => {
    return await getAllFileAttachmentsById(
      orderId,
      FileAttachment_Read.file_attachable_type.ORDER,
    );
  };

  const getAllFileAttachmentsByJobId = async (jobId: string) => {
    return await getAllFileAttachmentsById(
      jobId,
      FileAttachment_Read.file_attachable_type.JOB,
    );
  };

  const getAllFileAttachmentsByLoadId = async (loadId: string) => {
    return await getAllFileAttachmentsById(
      loadId,
      FileAttachment_Read.file_attachable_type.LOAD,
    );
  };

  const updateFileAttachmentById = async (
    id: string,
    data: UpdateFileAttachmentParams,
  ) => {
    return await connection
      .patch<FileAttachment_Read>(
        `${API_VERSION}/file_attachments/${id}`,
        { ...data },
        {},
        $t('error_messages.file_attachment.failed_to_update') as string,
      )
      .then((response) => {
        const data = FileAttachment.parse(response);
        return data;
      });
  };

  const deleteFileAttachmentById = async (id: string) => {
    return await connection.delete(
      `${API_VERSION}/file_attachments/${id}`,
      {},
      $t('error_messages.file_attachment.failed_to_delete') as string,
    );
  };

  const createFileAttachment = async (
    file: File,
    attachableId: string,
    attachableType: FileAttachableType,
  ) => {
    const formData = new FormData();
    formData.append('file', file);

    const prefix = attachableType.toLowerCase() + 's';

    return await connection
      .post<FileAttachment_Read>(
        `${API_VERSION}/${prefix}/${attachableId}/file_attachments/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        ($t('error_messages.file_attachment.failed_to_create') +
          `on ${attachableType}`) as string,
      )
      .then((response) => {
        const data = FileAttachment.parse(response);
        return data;
      });
  };

  const createFileAttachmentOnProject = async (
    file: File,
    projectId: string,
    callBack?:
      | Dispatch<SetStateAction<FileAttachment>>
      | ((FileAttachment: FileAttachment) => void),
  ) => {
    const response = await createFileAttachment(
      file,
      projectId,
      FileAttachment_Read.file_attachable_type.PROJECT,
    );
    callBack?.(response);
    return response;
  };

  const createFileAttachmentOnOrder = async (
    file: File,
    orderId: string,
    callBack?:
      | Dispatch<SetStateAction<FileAttachment>>
      | ((FileAttachment: FileAttachment) => void),
  ) => {
    const response = await createFileAttachment(
      file,
      orderId,
      FileAttachment_Read.file_attachable_type.ORDER,
    );
    callBack?.(response);
    return response;
  };

  const createFileAttachmentOnJob = async (
    file: File,
    jobId: string,
    callBack?:
      | Dispatch<SetStateAction<FileAttachment>>
      | ((FileAttachment: FileAttachment) => void),
  ) => {
    const response = await createFileAttachment(
      file,
      jobId,
      FileAttachment_Read.file_attachable_type.JOB,
    );
    callBack?.(response);
    return response;
  };

  const createFileAttachmentOnLoad = async (
    file: File,
    loadId: string,
    callBack?:
      | Dispatch<SetStateAction<FileAttachment>>
      | ((FileAttachment: FileAttachment) => void),
  ) => {
    const response = await createFileAttachment(
      file,
      loadId,
      FileAttachment_Read.file_attachable_type.LOAD,
    );
    callBack?.(response);
    return response;
  };

  return {
    getFileAttachmentById,
    getAllFileAttachmentsById,
    getAllFileAttachmentsByProjectId,
    getAllFileAttachmentsByOrderId,
    getAllFileAttachmentsByJobId,
    getAllFileAttachmentsByLoadId,
    createFileAttachment,
    createFileAttachmentOnProject,
    createFileAttachmentOnOrder,
    createFileAttachmentOnJob,
    createFileAttachmentOnLoad,
    updateFileAttachmentById,
    deleteFileAttachmentById,
  };
};
