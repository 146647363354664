import Box from '@mui/material/Box';
import IconButton, { IconButtonOwnProps } from '@mui/material/IconButton';
import { styled } from '@mui/system';
import { CalendarIcon } from '@mui/x-date-pickers/icons';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import DateRangeSelector from '~components/Invoice/DateRangeSelector';
import { CustomerSelector } from '~components/Order/Selectors/CustomerSelector';
import { DropoffSiteSelector } from '~components/Order/Selectors/DropoffSiteSelector';
import { PickupSiteSelector } from '~components/Order/Selectors/PickupSiteSelector';
import { ProjectSelector } from '~components/Order/Selectors/ProjectSelector';
import { RateTypeSelector } from '~components/Order/Selectors/RateTypeSelector';
import { VendorSelector } from '~components/Order/Selectors/VendorSelector';

import DriverTypeSelector from './components/DriverTypeSelector';
import { payableInvoiceCategories, receivableInvoiceCategories } from './DriverPay';

const SelectDateButton = styled(IconButton)<IconButtonOwnProps>(() => ({
  padding: '4px 16px',
  color: '#000',
  fontSize: '14px',
  fontWeight: 500,
  border: '1px solid #D0D5DD',
  borderRadius: '4px',
  background: 'transparent',
  '&:hover': {
    background: 'transparent',
    borderColor: '#000',
  },
}));

const DriverPayFilters = observer(() => {
  const [searchParams] = useSearchParams();
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);

  const allSearchParams = useMemo(() => {
    const res: Record<string, string> = {};

    for (const key of searchParams.keys()) {
      res[`${key}`] = searchParams.get(key) ?? '';
    }

    return res;
  }, [searchParams]);

  useEffect(() => {
    const hasCustomDateRange =
      searchParams.has('start_date') && searchParams.has('end_date');

    setShowCustomDateRange(hasCustomDateRange);
  }, [allSearchParams]);

  return (
    <Box display="flex" gap={1} height="40px">
      {showCustomDateRange ? (
        <Box sx={{ minWidth: '210px' }}>
          <DateRangeSelector defaultOpen={false} />
        </Box>
      ) : (
        <SelectDateButton
          onClick={() => {
            setShowCustomDateRange(true);
          }}
          sx={{ height: '41px', mt: '1px' }}
        >
          <CalendarIcon sx={{ mr: 1 }} />
          {t('form_fields.date_select')}
        </SelectDateButton>
      )}

      <ProjectSelector allSearchParams={allSearchParams} sx={{ mr: -1 }} />
      {allSearchParams['grid'] === 'jobs' && (
        <DriverTypeSelector allSearchParams={allSearchParams} sx={{ mr: -1 }} />
      )}
      {receivableInvoiceCategories.includes(
        allSearchParams['category'] as InvoiceCategoryFilter,
      ) && (
        <>
          <CustomerSelector allSearchParams={allSearchParams} sx={{ mr: -1 }} />
          <DropoffSiteSelector allSearchParams={allSearchParams} sx={{ mr: -1 }} />
        </>
      )}
      {payableInvoiceCategories.includes(
        allSearchParams['category'] as InvoiceCategoryFilter,
      ) && (
        <>
          <VendorSelector allSearchParams={allSearchParams} sx={{ mr: -1 }} />
          <PickupSiteSelector allSearchParams={allSearchParams} sx={{ mr: -1 }} />
        </>
      )}
      {/* Truck filtering requires backend implementation that will be completed in a subsequent PR */}
      {/* <TruckSelector allSearchParams={allSearchParams} sx={{ mr: -1 }} /> */}
      <RateTypeSelector allSearchParams={allSearchParams} sx={{ mr: -1 }} />
    </Box>
  );
});

export default DriverPayFilters;
