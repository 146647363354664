import Delete from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { GridColDef } from '@mui/x-data-grid-premium';
import { t } from 'i18next';

import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { AgaveLinkedAccount } from '~hooks/useAgave/models';

export type AgaveManagementRowDef = {
  row: {
    id: string;
    isDeleting?: boolean;
    linkedAccount: AgaveLinkedAccount;
    onDelete: () => void;
  };
};

export const sourceSystemNameColDef: GridColDef = {
  flex: 1,
  field: 'sourceSystemName',
  headerName: `${t('administration.integration.agave.grid.source_system_name')}`,
  valueGetter: ({ row }: AgaveManagementRowDef) => row.linkedAccount.sourceSystem.name,
};

export const sourceSystemSlugColDef: GridColDef = {
  flex: 1,
  field: 'sourceSystemSlug',
  headerName: `${t('administration.integration.agave.grid.source_system_slug')}`,
  valueGetter: ({ row }: AgaveManagementRowDef) => row.linkedAccount.sourceSystem.slug,
};

export const statusColDef: GridColDef = {
  flex: 1,
  field: 'status',
  headerName: `${t('administration.integration.agave.grid.status')}`,
  valueGetter: ({ row }: AgaveManagementRowDef) => row.linkedAccount.status,
  renderCell: ({ row }: AgaveManagementRowDef) => {
    return (
      <Chip
        size="small"
        color="success"
        label={t(`administration.integration.agave.status.${row.linkedAccount.status}`)}
        variant="outlined"
      />
    );
  },
};

export const actionsColDef = (renderHeader: () => React.ReactNode): GridColDef => ({
  width: 96,
  field: 'actions',
  headerName: `${t('actions.actions')}`,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  hideable: false,
  renderHeader,
  renderCell: ({ row }: AgaveManagementRowDef) => (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
      {row.isDeleting ? (
        <LoadingSpinner isVisible loadingIndicatorSize={20} />
      ) : (
        <IconButton size="small" color="error" onClick={row.onDelete}>
          <Delete />
        </IconButton>
      )}
    </Box>
  ),
});
