import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { t } from 'i18next';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { NavigationTabs } from '~components/LayoutComponents/NavigationTabs';
import { FeatureFlags } from '~constants/featureFlags';
import { useMaterials } from '~hooks/useMaterials';
import { routes } from '~router';

type NavItem = {
  title: string;
  link: string;
};

const conditionalNavItems = (conditionRes: boolean, items: NavItem[]) =>
  conditionRes ? items : [];

const getNavItems = (featureFlags: SplitIO.TreatmentsWithConfig) => {
  const driveDayFeatureFlagTreatment = featureFlags[FeatureFlags.driverDay].treatment;
  const drivePayFeatureFlagTreatment = featureFlags[FeatureFlags.driverPay].treatment;

  const items = [
    {
      title: t('page_headings.tickets_review'),
      link: `/${routes.approvals.base}`,
    },
    // NavigationTabs behaves unexpectedly when the base route is not the first item
    ...conditionalNavItems(driveDayFeatureFlagTreatment === 'on', [
      {
        title: t('page_headings.approve_work'),
        link: `${routes.approvals.driverDay}`,
      },
    ]),
    // Only show driver pay if feature flag is enabled
    ...conditionalNavItems(drivePayFeatureFlagTreatment === 'on', [
      {
        title: t('page_headings.payables'),
        link: `${routes.approvals.driverPay}?category=payables`,
      },
      {
        title: t('page_headings.receivables'),
        link: `${routes.approvals.driverPay}?category=receivables`,
      },
    ]),
  ];

  return items;
};

export const Approvals = () => {
  const { getAllMaterials } = useMaterials();
  useEffect(() => {
    getAllMaterials();
  }, []);

  const theme = useTheme();

  return (
    // Split Feature Flags
    <SplitTreatments names={[FeatureFlags.driverDay, FeatureFlags.driverPay]}>
      {(splitTreatment) => {
        return (
          <>
            <NavigationTabs
              navItems={getNavItems(splitTreatment.treatments)}
              sx={{ borderBottom: `1px solid ${theme.palette.divider}`, mt: 2 }}
            />
            <Box sx={{ py: 4 }}>
              <Outlet />
            </Box>
          </>
        );
      }}
    </SplitTreatments>
  );
};
