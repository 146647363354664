import { Material_Read, MaterialType_Read_Nested } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Material, MaterialTypeItem } from '~hooks/useMaterials/models';
import { GetPaginationParams, PaginationLink } from '~interfaces';
import connection from '~services/connectionModule';
import { useStores } from '~store';
interface CreateMaterialProps {
  material: Material;
  callBack?: (material: Material) => void;
}
interface GetMaterialTypesByCompanyIdProps {
  companyId: string;
  callBack?: (materialTypes: MaterialTypeItem[]) => void;
}

interface PostMaterialTypesByCompanyIdProps {
  companyId: string;
  material: Material;
  callBack?: (material: Material) => void;
}

interface DeleteMaterialProps {
  materialId: string;
  callBack?: () => void;
}
interface GetMaterialsTypeaheadProps {
  callback?: (materials: Material[]) => void;
  limit?: number;
  query?: string;
  cursor?: string;
}
interface GetMaterialsTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'page[after]': string;
}

interface GetCompanyMaterialsTypeaheadProps extends GetMaterialsTypeaheadProps {
  companyId: string;
}

interface GetCompanyMaterialsTypeaheadQueryProps
  extends GetMaterialsTypeaheadQueryProps {}

export const useMaterials = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { companyAssetsStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();

  const getAllMaterials = (link?: PaginationLink, searchQuery?: string) => {
    setIsLoading(true);
    let params: GetPaginationParams = {
      'page[limit]': companyAssetsStore.materialsPagination.limit,
    };
    params = addSearchHeaderParam({ searchValue: searchQuery, params });
    if (link && companyAssetsStore.materialsPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.materialsPagination[link];
    }
    return connection
      .getPaginated<Material_Read>(
        `${API_VERSION}/materials`,
        { params },
        $t('error_messages.materials.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(Material.parse);
        companyAssetsStore.setMaterials(formatted);
        companyAssetsStore.setMaterialsPagination(pagination);
        companyAssetsStore.updateMaterialsPageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const createMaterial = ({ material, callBack }: CreateMaterialProps) => {
    setIsLoading(true);
    return connection
      .post<Material_Read>(
        `${API_VERSION}/materials`,
        Material.deparse(material),
        {},
        $t('error_messages.materials.failed_to_create') as string,
      )
      .then((resp) => {
        const material = Material.parse(resp);
        companyAssetsStore.addMaterial(material);
        callBack?.(material);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const updateMaterial = ({ material, callBack }: CreateMaterialProps) => {
    setIsUpdating(true);
    return connection
      .patch<Material_Read>(
        `${API_VERSION}/materials/${material.id}`,
        Material.deparseUpdate(material),
        {},
        $t('error_messages.materials.failed_to_update') as string,
      )
      .then((resp) => {
        const materialFormatted = Material.parse(resp);
        companyAssetsStore.updateMaterial(materialFormatted);
        callBack?.(materialFormatted);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const deleteMaterial = ({ materialId, callBack }: DeleteMaterialProps) => {
    setIsLoading(true);
    return connection
      .delete<Material_Read>(
        `${API_VERSION}/materials/${materialId}`,
        {},
        $t('error_messages.materials.failed_to_delete') as string,
      )
      .then(() => {
        companyAssetsStore.deleteMaterial(materialId);
        callBack?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMaterialTypesByCompanyId = ({
    companyId,
    callBack,
  }: GetMaterialTypesByCompanyIdProps) => {
    setIsLoading(true);
    const params: GetPaginationParams = {
      'page[limit]': companyAssetsStore.materialsPagination.limit,
    };
    return connection
      .get<MaterialType_Read_Nested[]>(
        `${API_VERSION}/companies/${companyId}/material_types`,
        { params },
        $t('error_messages.materials.failed_to_fetch_material_types') as string,
      )
      .then((resp) => {
        const formatted = resp.map(MaterialTypeItem.parse);
        callBack?.(formatted);
        return formatted;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createMaterialByCompanyId = ({
    companyId,
    material,
    callBack,
  }: PostMaterialTypesByCompanyIdProps) => {
    setIsLoading(true);
    return connection
      .post<Material_Read>(
        `${API_VERSION}/companies/${companyId}/materials`,
        Material.deparse(material),
        {},
        $t('error_messages.materials.failed_to_create') as string,
      )
      .then((resp) => {
        const material = Material.parse(resp);
        // Todo: ensure this is correct
        companyAssetsStore.addMaterial(material);

        callBack?.(material);
        return material;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMaterialsByCompanyId = (companyId: string) => {
    setIsLoading(true);
    return connection
      .get<Material_Read[]>(
        `${API_VERSION}/companies/${companyId}/materials`,
        {},
        $t('error_messages.materials.failed_to_fetch') as string,
      )
      .then((resp) => {
        const materials = resp.map(Material.parse);
        companyAssetsStore.setMaterials(materials);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMaterialsByCompanyIdTypeahead = async (
    options: GetCompanyMaterialsTypeaheadProps,
  ) => {
    const params: Partial<GetCompanyMaterialsTypeaheadQueryProps> = {
      'page[limit]': options?.limit || 100,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoading(true);
      const { data, pagination } = await connection.getPaginated<Material_Read>(
        `${API_VERSION}/companies/${options.companyId}/materials/typeahead`,
        { params },
        $t('error_messages.materials.failed_to_fetch') as string,
      );
      const materials = data.map((material) => Material.parse(material));
      return { data: materials, pagination };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const activateMaterial = (companyid: string) => {
    setIsLoading(true);
    return connection
      .put<Material_Read>(
        `${API_VERSION}companies/${companyid}/materials`,
        {},
        {},
        $t('error_messages.materials.failed_to_activate') as string,
      )
      .then((resp) => {
        const mat = Material.parse(resp);
        companyAssetsStore.updateMaterial(mat);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const deactivateMaterial = (materialId: string) => {
    setIsUpdating(true);
    return connection
      .put<Material_Read>(
        `${API_VERSION}/materials/${materialId}/deactivate`,
        {},
        {},
        $t('error_messages.materials.failed_to_deactivate') as string,
      )
      .then((resp) => {
        const mat = Material.parse(resp);
        companyAssetsStore.updateMaterial(mat);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const getMaterialsTypeahead = async (options: GetMaterialsTypeaheadProps) => {
    const params: Partial<GetMaterialsTypeaheadQueryProps> = {
      'page[limit]': options.limit ?? 100,
    };

    if (options.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoading(true);
      const { data, pagination } = await connection.getPaginated<Material_Read>(
        `${API_VERSION}/materials/typeahead`,
        { params },
        $t('error_messages.materials.failed_to_fetch') as string,
      );
      const materials = data.map((material) => Material.parse(material));
      return { data: materials, pagination };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    isUpdating,
    createMaterial,
    deleteMaterial,
    updateMaterial,
    activateMaterial,
    deactivateMaterial,
    getMaterialTypesByCompanyId,
    createMaterialByCompanyId,
    getMaterialsByCompanyId,
    getMaterialsByCompanyIdTypeahead,
    getAllMaterials,
    getMaterialsTypeahead,
  };
};
