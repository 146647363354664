import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { EquipmentTypeahead, useEquipment } from '~hooks/useEquipment';
import { TelematicsVehicle, useTelematicsVehicles } from '~hooks/useTelematicsVehicles';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';

const equipmentSelectorSchema = yup.object().shape({
  equipment: yup
    .object()
    .shape({
      name: yup.string().notRequired(),
      id: yup.string().notRequired(),
      external_id: yup.string().notRequired(),
    })
    .nullable(),
});

type TelematicsVehicleEquipmentSelectorDTO = yup.InferType<
  typeof equipmentSelectorSchema
>;

interface TelematicsVehicleEquipmentSelectorProps {
  remoteVehicle: TelematicsVehicle;
}

export const TelematicsVehicleEquipmentSelector = observer(
  ({ remoteVehicle }: TelematicsVehicleEquipmentSelectorProps) => {
    const { getEquipmentByCompanyIdTypeahead } = useEquipment();
    const { userStore, toasterStore } = useStores();
    const companyId = userStore.userCompany?.id;
    const { updateTelematicsVehicleById } = useTelematicsVehicles();

    // Get user permissions to determine if user can edit telematics remote vehicles
    const userPermissions = useMemo(() => {
      return userStore.getPermissions();
    }, [userStore.getPermissions()]);

    const {
      control,
      setValue,
      formState: { errors },
    } = useForm<TelematicsVehicleEquipmentSelectorDTO>({
      resolver: yupResolver(equipmentSelectorSchema),
      reValidateMode: 'onChange',
      defaultValues: {
        equipment: remoteVehicle.equipment
          ? {
              id: remoteVehicle.equipment.id,
              name: remoteVehicle.equipment.name,
              external_id: remoteVehicle.equipment.externalId,
            }
          : null,
      },
    });

    const saveRemoteVehicleEquipment = (data: Nullable<EquipmentTypeahead>) => {
      updateTelematicsVehicleById(remoteVehicle.id, {
        equipment_id: data?.id ?? null,
      }).then(() => {
        setValue(
          'equipment',
          data
            ? {
                id: data?.id,
                name: data?.name,
                external_id: data?.externalId,
              }
            : null,
          { shouldValidate: false },
        );
        toasterStore.push(
          alert(
            t('administration.integration.vehicle_updated', {
              name: remoteVehicle.remoteVehicleExternalId,
            }),
            AlertTypes.success,
          ),
        );
      });
    };

    const getEquipmentLabel = (equipment?: EquipmentTypeahead) => {
      if (!equipment) return '';
      return (equipment.externalId ? `${equipment.externalId} - ` : '') + equipment.name;
    };

    return (
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
        <AutocompleteAsyncFormField
          control={control}
          errors={errors}
          name={'equipment'}
          getValue={(item) => item?.id || ''}
          getLabel={(item) =>
            item ? getEquipmentLabel(EquipmentTypeahead.parse(item)) : ''
          }
          asyncCallback={getEquipmentByCompanyIdTypeahead}
          extraRequestOptions={{
            companyId: companyId,
            shared: false,
          }}
          filterSelectedOptions={false}
          clearable
          hideLabel
          disablePortal={false}
          sx={{ mt: 0.5 }}
          onSelect={saveRemoteVehicleEquipment}
          onClear={() => saveRemoteVehicleEquipment(null)}
          disabled={!userPermissions?.canEditTelematicsRemoteVehicle}
        />
      </Box>
    );
  },
);
