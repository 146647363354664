import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { LngLat } from '@nbai/nbmap-gl';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { isNumber } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';

// Custom control so we can override the default map type control in Google Maps
function CustomControl({
  map,
  callback,
}: {
  map: google.maps.Map;
  callback: (x: boolean) => void;
}) {
  useEffect(() => {
    if (map) {
      // Create a container for the custom map type control
      const mapTypeControlDiv = document.createElement('div');
      mapTypeControlDiv.style.margin = '10px';

      // Style for the control container
      const controlUI = document.createElement('div');
      controlUI.style.display = 'inline-flex';
      controlUI.style.backgroundColor = 'white';
      controlUI.style.border = '0px solid #e5e7eb';
      controlUI.style.borderRadius = '4px';
      controlUI.style.boxSizing = 'inherit';
      controlUI.style.cursor = 'pointer';
      mapTypeControlDiv.appendChild(controlUI);

      // Create buttons for each map type
      const mapTypes = [
        { type: 'roadmap', label: 'Default' },
        { type: 'hybrid', label: 'Satellite' },
      ];

      mapTypes.forEach(({ type, label }) => {
        const button = document.createElement('div');
        button.style.display = 'inline-flex';
        button.style.fontFamily = 'Roboto,Arial,sans-serif';
        button.style.fontWeight = '500';
        button.style.backgroundColor =
          type === 'roadmap' ? 'white' : 'rgba(0, 0, 0, 0.08)';
        button.style.color =
          type === 'roadmap' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.87)';
        button.style.fontSize = '0.8125rem';
        button.style.lineHeight = '1.75';
        button.style.padding = '7px'; // Add padding to buttons
        button.style.textAlign = 'center';
        button.style.cursor = 'pointer';
        button.style.border = '1px solid rgba(0, 0, 0, 0.12)';
        button.style.borderRadius = '4px'; // Round button corners
        button.style.borderTopLeftRadius = type === 'roadmap' ? '4px' : '0px'; // Round top left corner of the first button
        button.style.borderBottomLeftRadius = type === 'roadmap' ? '4px' : '0px'; // Round bottom left corner of the first button
        button.style.borderTopRightRadius = type === 'hybrid' ? '4px' : '0px'; // Round top right corner of the second button
        button.style.borderBottomRightRadius = type === 'hybrid' ? '4px' : '0px'; // Round bottom right corner of the second button
        button.addEventListener('mouseenter', () => {
          button.style.backgroundColor = 'rgb(0 0 0 / 5%)';
        });

        button.addEventListener('mouseleave', () => {
          button.style.backgroundColor =
            type === 'roadmap' ? 'white' : 'rgba(0, 0, 0, 0.08)';
        });
        button.innerHTML = label;

        button.addEventListener('click', () => {
          if (label === 'Default' && map) {
            callback(false);
          }
        });
        controlUI.appendChild(button);
      });

      // Add custom controls to the map
      map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(
        mapTypeControlDiv,
      );

      // Cleanup on unmount
      return () => {
        if (map) {
          const controls = map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT];
          if (controls && controls.getLength() > 0) {
            controls.removeAt(0);
          }
        }
      };
    }
  }, [map]);

  return null;
}

const GoogleBaseMap = ({
  center,
  zoom,
  setShowGoogleMap,
  sx,
}: {
  center: LngLat;
  zoom: number;
  setShowGoogleMap: (show: boolean) => void;
  sx?: SxProps<Theme>;
}) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapInstance, setMapInstance] = React.useState<google.maps.Map | null>(null);
  const key = import.meta.env.TREAD__GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    if (mapInstance) {
      // Attach the event listener to detect changes in map type
      const listener = mapInstance.addListener('maptypeid_changed', () => {
        const currentMapType = mapInstance.getMapTypeId();
        if (currentMapType === 'roadmap') {
          setShowGoogleMap(false);
        }
      });

      // Cleanup the event listener on component unmount
      return () => {
        window.google.maps.event.removeListener(listener);
      };
    }
  }, [mapInstance]);

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setMapInstance(map);
    mapRef.current?.setMapTypeId('hybrid');
  };
  const onUnmount = useCallback(() => {
    setMapInstance(null);
  }, []);

  return (
    <LoadScript googleMapsApiKey={key}>
      <GoogleMap
        mapContainerStyle={sx as React.CSSProperties}
        center={{
          lat: isNumber(center.lat) ? center.lat : parseFloat(center.lat),
          lng: isNumber(center.lng) ? center.lng : parseFloat(center.lng),
        }}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          mapTypeId: 'hybrid',
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: true,
          rotateControl: true,
          fullscreenControl: false,
          mapTypeControlOptions: {
            position: window?.google?.maps.ControlPosition.BOTTOM_LEFT,
          },
          zoomControlOptions: {
            position: window?.google?.maps.ControlPosition.TOP_RIGHT,
          },
          fullscreenControlOptions: {
            position: window?.google?.maps.ControlPosition.BOTTOM_RIGHT,
          },
          disableDefaultUI: true,
        }}
      >
        {mapInstance && <CustomControl map={mapInstance} callback={setShowGoogleMap} />}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleBaseMap;
