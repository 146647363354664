import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { t as $t } from 'i18next';
import { CountryCode } from 'libphonenumber-js';
import { ControllerProps, FieldErrors } from 'react-hook-form';

import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { PhoneCodeFlagInput } from '~components/FormFields/PhoneCodeFlagInput';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';

interface Props {
  control: any;
  errors: any;
  selectedCountry: any;
  isBillingContactHidden: boolean;
  selectedBillingCountry: any;
}

const AccountContact = ({
  control,
  errors,
  selectedCountry,
  isBillingContactHidden,
  selectedBillingCountry,
}: Props) => (
  <Card sx={{ p: 2, mb: 2, overflow: 'visible' }}>
    {/*Account contact*/}
    <FormSectionTitle sx={{ mb: 2 }} title={$t('account.form.contact_information')} />
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextFormField
          control={control}
          errors={errors}
          name="primaryContact.name"
          label={`${$t('form_fields.name')}`}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFormField
          control={control}
          errors={errors}
          name="primaryContact.email"
          label={`${$t('form_fields.email')}`}
          sx={{ mr: 2 }}
        />
      </Grid>
      <Grid item xs={6}>
        <PhoneCodeFlagInput
          control={control as unknown as ControllerProps['control']}
          errors={errors as unknown as FieldErrors}
          name="primaryContact.phone"
          label={`${$t('form_fields.phone')}`}
          defaultCountryCode={
            selectedCountry && selectedCountry.code
              ? (selectedCountry.code as CountryCode)
              : undefined
          }
        />
      </Grid>
    </Grid>

    <CheckBoxFormField
      control={control}
      errors={errors}
      value={false}
      label={$t('account.form.billing_contact_same_as_primary') || ''}
      name={'isBillingContactSame'}
    />

    <Collapse in={!isBillingContactHidden}>
      <FormSectionTitle sx={{ mb: 2 }} title={$t('account.form.billing_contact')} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFormField
            control={control}
            errors={errors}
            name="billingContact.name"
            label={`${$t('form_fields.name')}`}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="billingContact.email"
            label={`${$t('form_fields.email')}`}
            sx={{ mr: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneCodeFlagInput
            control={control as unknown as ControllerProps['control']}
            errors={errors as unknown as FieldErrors}
            name="billingContact.phone"
            label={`${$t('form_fields.phone')}`}
            defaultCountryCode={
              selectedBillingCountry && selectedBillingCountry.code
                ? (selectedBillingCountry.code as CountryCode)
                : undefined
            }
          />
        </Grid>
      </Grid>
    </Collapse>
  </Card>
);

export { AccountContact };
