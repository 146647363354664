import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell';
import MuiTableRow, { TableRowOwnProps } from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import theme from '~theme/AppTheme';

interface LineItemsTableFooterItemProps {
  label: string;
  size?: 'medium' | 'large';
  value: string;
}

export const LineItemsTableFooterItem = ({
  label,
  size = 'medium',
  value,
}: LineItemsTableFooterItemProps) => {
  return (
    <Box
      px={1.5}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      gap={3}
    >
      <Typography
        flex={1}
        fontSize={size === 'medium' ? '13px' : '14px'}
        fontWeight={600}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {label}
      </Typography>

      <Typography
        flex={0}
        fontSize={size === 'medium' ? '13px' : '14px'}
        fontWeight={600}
      >
        {value}
      </Typography>
    </Box>
  );
};

interface LineItemsTableTableRowProps extends TableRowOwnProps {
  isHeader?: boolean;
}

export const LineItemsTableTableRow = styled(MuiTableRow, {
  shouldForwardProp: (propName) => propName !== 'isHeader',
})<LineItemsTableTableRowProps>(({ theme, isHeader }) => ({
  '&.MuiTableRow-root': {
    backgroundColor: isHeader ? theme.palette.grey[50] : 'white',
  },
}));

export interface LineItemsTableCellProps extends TableCellProps {
  isHeader?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  width?: string;
}

export const LineItemsTableCell = styled(MuiTableCell, {
  shouldForwardProp: (propName) =>
    !['isHeader', 'textAlign', 'width'].includes(String(propName)),
})<LineItemsTableCellProps>(({ theme, isHeader, textAlign = 'left', width }) => ({
  '&.MuiTableCell-root': {
    borderBottom: 0,
    borderTopColor: theme.palette.divider,
    borderTopStyle: 'solid',
    borderTopWidth: isHeader ? 0 : 1,
    fontSize: '11px',
    fontWeight: isHeader ? 600 : 400,
    padding: theme.spacing(1),
    textAlign,
    width,
  },
}));

export const LineItemsTableSkeleton = () => (
  <Box border={`solid 1px ${theme.palette.divider}`} borderRadius={0.5}>
    <LineItemsTableSkeletonRow />
    <Divider />
    <LineItemsTableSkeletonRow />
    <LineItemsTableSkeletonRow />
  </Box>
);

const LineItemsTableSkeletonRow = () => (
  <Box display="flex" gap={3} width="100%" p={1}>
    {Array.from({ length: 5 }).map((_, index) => (
      <Skeleton key={index} variant="text" sx={{ fontSize: '1rem', flex: 1 }} />
    ))}
  </Box>
);
