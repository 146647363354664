import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';

interface FormSectionTitleProps {
  title: string;
  sx?: SxProps;
}
const FormSectionTitle = ({ title, sx }: FormSectionTitleProps) => {
  return (
    <Typography variant={'h6'} fontWeight={600} sx={{ ...sx }}>
      {title}
    </Typography>
  );
};
export { FormSectionTitle };
