import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { t } from 'i18next';

import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

import CopyVendorAssignmentsForm from './CopyVendorAssignmentsForm';

interface CopyVendorAssignmentsDialogProps {
  companyId: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onSelectedOrderChange: (order: Order) => void;
  orderId?: string;
}

export default function CopyVendorAssignmentsDialog({
  companyId,
  isOpen,
  onClose,
  onConfirm,
  onSelectedOrderChange,
  orderId,
}: CopyVendorAssignmentsDialogProps) {
  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          height: '400px',
          maxWidth: '850px',
          '.MuiDialogContent-root': { p: 0, overflow: 'hidden' },
        },
      }}
      open={isOpen}
      onClose={(__: never, reason: DialogCloseReasonType) => {
        if (isActionClicked(reason)) {
          onClose();
        }
      }}
    >
      <DialogContent>
        <CopyVendorAssignmentsForm
          companyId={companyId}
          onSelectedOrderChange={onSelectedOrderChange}
          orderId={orderId}
        />
      </DialogContent>

      <DialogActions
        sx={{
          m: 0,
          p: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row-reverse',
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button disabled={!orderId} onClick={onConfirm} sx={{ px: 2 }} color="primary">
          {t('actions.copy_to')}
        </Button>

        <Button
          onClick={onClose}
          sx={{ mr: 2, px: 2 }}
          color="secondary"
          variant="outlined"
        >
          {t('actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
