import { styled } from '@mui/material/styles';
import MuiToggleButton, { ToggleButtonOwnProps } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const ToggleButton = styled(MuiToggleButton)<ToggleButtonOwnProps>(({ theme }) => ({
  textTransform: 'none',
  color: '#928B8B',
  fontSize: '14px',
  minWidth: '85px',
  fontWeight: 500,
  transition: 'all .3s ease',
  padding: '6px 8px',
  borderRadius: '8px',
  '&.Mui-selected': {
    background: '#F6CC46',
    color: '#000',
    '&:hover': {
      background: '#F6CC46',
    },
  },
  '&:hover': {
    background: theme.palette.action.hover,
  },
}));

export type Tab = {
  text: string;
  value: string;
};

interface TabsGroupProps {
  onChange: (tab: string) => void;
  searchParamName: string;
  tabs: Tab[];
}

export default function TabsGroup({ onChange, searchParamName, tabs }: TabsGroupProps) {
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get(searchParamName);

  const handleChange = useCallback(
    (_: React.MouseEvent, tab: string | null) => {
      if (!tab) {
        return;
      }

      onChange(tab);
    },
    [tabs, onChange],
  );

  return (
    <ToggleButtonGroup value={selectedTab} exclusive onChange={handleChange}>
      {tabs.map((tab) => (
        <ToggleButton key={tab.text} value={tab.value}>
          {tab.text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
