import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import theme from '~theme/AppTheme';

import { JobsTableColumnKey, jobsTableColumns } from './jobsTableColumns';

type ColumnProps = BoxProps & {
  columnKey: JobsTableColumnKey;
  isHeader?: boolean;
};

const StyledColumn = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isHeader',
})<Omit<ColumnProps, 'columnKey'>>(({ isHeader }) => {
  return {
    overflow: 'hidden',
    padding: theme.spacing(0, 1),
    ...(isHeader ? { display: 'flex', alignItems: 'center' } : {}),
  };
});

export function Column({ columnKey, ...rest }: ColumnProps) {
  return <StyledColumn sx={jobsTableColumns[columnKey].sx} {...rest} />;
}
