import PauseCircle from '@mui/icons-material/PauseCircle';
import Chip, { ChipOwnProps } from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { JobState } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import React, { useMemo } from 'react';

import { snakeCaseToHuman } from '~utils/utilFunctions';

interface JobStateExtended {
  label: string;
  color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}
interface Props {
  size?: ChipOwnProps['size'];
  status: JobState | undefined;
  sx?: SxProps<Theme>; // Style
  textOnly?: boolean;
  variant?: ChipOwnProps['variant'];
  isPaused?: boolean;
}

const Status = ({
  size = 'medium',
  status,
  sx,
  textOnly,
  variant = 'outlined',
  isPaused = false,
}: Props) => {
  const Statuses = {
    _: {
      label: snakeCaseToHuman(`${status}`),
      color: 'info',
    },
    [JobState.CREATED]: {
      label: $t('status.created'),
      color: 'secondary',
    },
    [JobState.ASSIGNED]: {
      label: $t('status.assigned'),
      color: 'secondary',
    },
    [JobState.ACCEPTED]: {
      label: $t('status.accepted'),
      color: 'success',
    },
    [JobState.REJECTED]: {
      label: $t('status.rejected'),
      color: 'error',
    },
    [JobState.TO_PICKUP]: {
      label: $t('status.to_pickup'),
      color: 'info',
    },
    [JobState.ARRIVED_PICKUP]: {
      label: $t('status.arrived_pickup'),
      color: 'info',
    },
    [JobState.LOADED]: {
      label: $t('status.loaded'),
      color: 'info',
    },
    [JobState.TO_DROPOFF]: {
      label: $t('status.to_dropoff'),
      color: 'info',
    },
    [JobState.ARRIVED_DROPOFF]: {
      label: $t('status.arrived_dropoff'),
      color: 'info',
    },
    [JobState.UNLOADED]: {
      label: $t('status.unloaded'),
      color: 'info',
    },
    [JobState.COMPLETED]: {
      label: $t('status.completed'),
      color: 'default',
    },
    [JobState.CANCELED]: {
      label: $t('status.canceled'),
      color: 'error',
    },
    [JobState.SENT]: {
      label: $t('status.sent'),
      color: 'primary',
    },
    [JobState.IN_REVIEW]: {
      label: $t('status.in_review'),
      color: 'default',
    },
    [JobState.FLAGGED]: {
      label: $t('status.flagged'),
      color: 'default',
    },
    [JobState.SIGNED_OFF]: {
      label: $t('status.signed_off'),
      color: 'default',
    },
    [JobState.PAID]: {
      label: $t('status.paid'),
      color: 'default',
    },
    [JobState.LOAD_COMPLETED]: {
      label: $t('status.unloaded'),
      color: 'info',
    },
  } as Record<JobState | string, JobStateExtended>;

  const value = useMemo(
    () => (status ? Statuses[status] || Statuses._ : Statuses._),
    [status],
  );

  return textOnly ? (
    <>{value.label}</>
  ) : (
    <Chip
      color={value.color}
      label={value.label}
      size={size}
      sx={{
        ...sx,
        span: {
          textOverflow: isPaused ? 'initial' : 'ellipsis',
        },
      }}
      variant={variant}
      icon={isPaused ? <PauseCircle sx={{ width: '16px', height: '16px' }} /> : undefined}
    />
  );
};

export { Status };
