import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { AccountType } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';

import { useAccount } from '~hooks/useAccount';
import { useStores } from '~store';

import DispatchSearchParam from './DispatchSearchParam';
import { TypeaheadSelector } from './TypeaheadSelector';

interface Props {
  allSearchParams: Record<string, any>;
  sx?: SxProps<Theme>; // Style
}

const VendorSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  const { getAccountsByCompanyIdTypeahead, getAccountByIdString } = useAccount();
  const { userStore } = useStores();

  const fetchAccountsByCompanyId = async (additionalProps = {}) => {
    const company = userStore.userCompany;

    if (!company) {
      return [];
    }

    const accounts = await getAccountsByCompanyIdTypeahead({
      companyId: company.id,
      accountTypes: [AccountType.VENDOR],
      ...additionalProps,
    });

    return accounts;
  };

  return (
    <>
      <Box sx={{ ...sx }}>
        <TypeaheadSelector
          asyncGet={getAccountByIdString}
          asyncCallback={fetchAccountsByCompanyId}
          fieldName={DispatchSearchParam.VENDOR_ACCOUNT}
          label={$t('form_fields.vendor')}
          defaultValues={[]}
          allSearchParams={allSearchParams}
          sx={{
            ...sx,
            ...{
              width: '150px',
              fontSize: '12px',
              mr: 1,
              '& label:not(.Mui-focused)': {
                color: '#000',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
              },
            },
          }}
          getOptionLabel={(item) => item.name || ''}
        />
      </Box>
    </>
  );
});

export { VendorSelector };
