import { Ticket_Read, Ticket_Read_Nested } from '@treadinc/horizon-api-spec';

import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

export class TicketBasic {
  constructor(private _id: Nullable<string>) {}

  public get id() {
    return this._id;
  }

  public static parse(proto: Ticket_Read_Nested) {
    return new TicketBasic(proto.id);
  }
}

export class Ticket {
  public static parse(proto: Ticket_Read): Ticket {
    return new Ticket(
      proto.id ?? '',
      proto.created_at ?? '',
      proto.updated_at ?? '',
      proto.quantity?.length ? proto.quantity : null,
      proto.ticket_number ?? '',
      proto.unit_of_measure
        ? ItemNameAndId.parse({
            name: proto.unit_of_measure,
            id: proto.unit_of_measure,
          })
        : null,
      proto.job_id ?? '',
      proto.load_id ?? '',
      proto.material?.id ? ItemNameAndId.parse(proto.material) : null,
      proto.image_url ?? '',
      proto?.ocr_processed ?? false,
      proto.ocr_ticket_number ?? '',
      proto.ocr_net_quantity_ton ?? '',
      proto.service_date ?? '',
      proto.source ?? '',
      proto.order_id ?? '',
      proto.customer_name ?? '',
      proto.project_name ?? '',
      proto.equipment_name ?? '',
      proto.driver_name ?? '',
      proto.vendor_name ?? '',
      proto.material_name ?? '',
      proto.pickup_site_name ?? '',
      proto.dropoff_site_name ?? '',
      proto.friendly_job_id ?? '',
      proto.state ?? '',
      proto.flagged ?? false,
      proto.missing_info ?? false,
    );
  }
  public static deparseUpdate(data: any): any {
    return {
      quantity: data.quantity,
      unit_of_measure: data.unitOfMeasure?.id,
      material_id: data.material?.id,
      ticket_number: data.ticketNumber,
      flagged: data.flagged,
      image_url: data.imageUrl,
    };
  }
  public get id(): string {
    return this._id;
  }
  public get createdAt(): string {
    return this._createdAt;
  }
  public get updatedAt(): string {
    return this._updatedAt;
  }
  public get quantity(): Nullable<string> {
    return this._quantity;
  }
  public set quantity(value: Nullable<string>) {
    this._quantity = value;
  }
  public get ticketNumber(): string {
    return this._ticketNumber;
  }
  public set ticketNumber(value: string) {
    this._ticketNumber = value;
  }
  public get unitOfMeasure(): Nullable<ItemNameAndId> {
    return this._unitOfMeasure;
  }
  public set unitOfMeasure(value: Nullable<ItemNameAndId>) {
    this._unitOfMeasure = value;
  }
  public get jobId(): string {
    return this._jobId;
  }
  public get loadId(): string {
    return this._loadId;
  }
  public get material(): Nullable<ItemNameAndId> {
    return this._material;
  }
  public set material(value: Nullable<ItemNameAndId>) {
    this._material = value;
  }
  public get imageUrl(): string {
    return this._imageUrl;
  }
  public set imageUrl(value: string) {
    this._imageUrl = value;
  }
  public get ocrProcessed(): boolean {
    return this._ocrProcessed;
  }
  public set ocrProcessed(value: boolean) {
    this._ocrProcessed = value;
  }
  public get ocrTicketNumber(): string {
    return this._ocrTicketNumber;
  }
  public get ocrNetQuantityTons(): string {
    return this._ocrNetQuantityTons;
  }
  public get serviceDate(): string {
    return this._serviceDate;
  }

  public get source(): Nullable<string> {
    return this._source;
  }

  public get orderId(): Nullable<string> {
    return this._orderId;
  }

  public get customerName(): Nullable<string> {
    return this._customer_name;
  }

  public get projectName(): Nullable<string> {
    return this._project_name;
  }

  public get equipmentName(): Nullable<string> {
    return this._equipment_name;
  }

  public get driverName(): Nullable<string> {
    return this._driver_name;
  }

  public get vendorName(): Nullable<string> {
    return this._vendor_name;
  }

  public get materialName(): Nullable<string> {
    return this._material_name;
  }

  public get pickupSiteName(): Nullable<string> {
    return this._pickup_site_name;
  }

  public get dropoffSiteName(): Nullable<string> {
    return this._dropoff_site_name;
  }

  public get friendlyJobId(): Nullable<string> {
    return this._friendly_job_id;
  }

  public get state(): Nullable<string> {
    return this._state;
  }

  public get flagged(): Nullable<boolean> {
    return this._flagged;
  }

  public set flagged(value: boolean) {
    this._flagged = value;
  }

  public get missingInfo(): boolean {
    return this._missing_info;
  }

  constructor(
    private _id: string,
    private _createdAt: string,
    private _updatedAt: string,
    private _quantity: Nullable<string>,
    private _ticketNumber: string,
    private _unitOfMeasure: Nullable<ItemNameAndId>,
    private _jobId: string,
    private _loadId: string,
    private _material: Nullable<ItemNameAndId>,
    private _imageUrl: string,
    private _ocrProcessed: boolean,
    private _ocrTicketNumber: string,
    private _ocrNetQuantityTons: string,
    private _serviceDate: string,
    private _source: Nullable<string>,
    private _orderId: Nullable<string>,
    private _customer_name: Nullable<string>,
    private _project_name: Nullable<string>,
    private _equipment_name: Nullable<string>,
    private _driver_name: Nullable<string>,
    private _vendor_name: Nullable<string>,
    private _material_name: Nullable<string>,
    private _pickup_site_name: Nullable<string>,
    private _dropoff_site_name: Nullable<string>,
    private _friendly_job_id: Nullable<string>,
    private _state: Nullable<string>,
    private _flagged: boolean = false,
    private _missing_info: boolean = false,
  ) {}
}
