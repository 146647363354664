import * as React from 'react';
import { SVGProps } from 'react';

interface LogoProps extends SVGProps<SVGSVGElement> {
  textFill?: string;
}

export const TreadLogoWithText = (props: LogoProps) => {
  const textFill = props.textFill || '#070606';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props} width={'140px'}>
      <path fill={textFill} d="M0 67.9h21.736v5.82h-7.344V97H7.346V73.719H0V67.9Z" />
      <path
        fill={textFill}
        fillRule="evenodd"
        d="M44.211 76.542c0-5.714-4.126-8.642-9.568-8.642H24.151V97h6.687V85.5L39.8 96.999h8.502L38.2 84.973c3.591-1.129 6.01-4.057 6.01-8.43Zm-7.043.464c0 3.634-3.022 3.81-5.547 3.81h-.783v-7.62h.782c2.526 0 5.548.175 5.548 3.81Z"
        clipRule="evenodd"
      />
      <path
        fill={textFill}
        d="M67.623 73.719H57.45v5.79h9.74v5.82h-9.74v5.85h10.17V97H50.718V67.9h16.904l.002 5.819Z"
      />
      <path
        fill={textFill}
        fillRule="evenodd"
        d="M93.885 97h7.549L88.991 67.9h-6.506L70.038 97h7.552l2.279-5.82h11.734L93.885 97Zm-8.186-20.105h.074l3.439 8.462H82.26l3.439-8.462ZM128 82.432c0-8.464-7.006-14.532-15.339-14.532h-8.812V97h8.812C121.031 97 128 90.934 128 82.432Zm-7.485.036c-.036 4.868-3.539 8.607-8.665 8.607h-1.068v-17.25h1.068c5.162 0 8.665 3.775 8.665 8.643Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M46.236 18.342 64.474 0l17.399 18.303-2.398 2.421-15.141-15.92L48.5 20.735l-2.264-2.392ZM64.418 7.573 46.18 25.915l2.264 2.391L64.28 12.373l15.146 15.92 2.402-2.41-17.41-18.31Zm0 8.125L46.18 34.04l2.264 2.39L64.28 20.499l15.146 15.92 2.402-2.41-17.41-18.31ZM48.104 43.823l-1.926-2.032L64.416 23.45l17.406 18.304-1.973 1.976-1.14-.313-14.431-15.174-15.229 15.33-.945.251Zm16.42-12.165 10.063 10.58-6.37-1.764-3.819-4.02-4.172 4.198-6.304 1.675 10.603-10.669Zm-22.098-9.266 2.386 2.517 2.402-2.41-2.417-2.553-2.371 2.446Zm2.064 10.412-6.12-6.436 2.372-2.44 6.15 6.467-2.402 2.41ZM34.7 30.13l9.916 10.424 2.402-2.41-9.936-10.46-2.382 2.446Zm6.542 15.48L30.567 34.38l2.375-2.443L45.01 44.635l-3.767.975Zm-2.251.606-9.686-10.188-17.23 17.322 6.363-1.675L29.16 40.82l6.076 6.388 3.755-.99Zm-9.697-1.633 3.203 3.358-8.888 2.361 5.685-5.72Zm53.913-24.854-2.422 2.546 2.402 2.41 2.386-2.517-2.366-2.44ZM81.11 30.178l6.15-6.468 2.373 2.441-6.12 6.436-2.403-2.41Zm9.809-2.702-9.935 10.45 2.401 2.41 9.916-10.424-2.382-2.436Zm-7.934 16.83 12.07-12.584 2.374 2.439-10.676 11.23-3.768-1.085Zm9.77 2.688 6.077-6.388L109.56 51.46l6.363 1.675-17.23-17.331-9.686 10.184 3.748 1.006Zm5.95-2.635 5.685 5.72-8.877-2.362 3.192-3.358Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1={44.362}
          x2={93.844}
          y1={57.012}
          y2={17.545}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAE907" />
          <stop offset={1} stopColor="#FABD06" />
        </linearGradient>
      </defs>
    </svg>
  );
};
