import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';

interface PageWrapWithBackGroundProps {
  children: ReactNode;
  backgroundImageUrl: string;
  sx?: SxProps;
}
import backgroundImage from '~images/tread-pattern.png';
const PageWrapWithBackGround = ({
  children,
  backgroundImageUrl,
  sx,
}: PageWrapWithBackGroundProps) => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
export default PageWrapWithBackGround;
