import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AuthMethod } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CommonLoader } from '~components/Loaders/CommonLoader';
import { useLogin } from '~hooks/useLogin';
import PageWrapWithBackGround from '~pages/Auth/PageWrapWithBackGround';

import Copyright from './Copyrigh';
import FormWrapperBox from './FormWrapperBox';
import SignInEmailForm from './SignInEmailForm';
import SignInPhoneNumberForm from './SignInPhoneNumberForm';
import SignInWithMagicLinkOrPasswordForm from './SignInWithMagicLinkOrPasswordForm';
import SignInWithOtpForm from './SignInWithOtpForm';
import TermsAndConditions from './TermsAndConditions';

const MAGIC_LINK_TOKEN_TYPE_KEY = 'stytch_token_type';
const MAGIC_LINK_TOKEN_TYPE_VALUE = 'multi_tenant_magic_links';
const MAGIC_LINK_TOKEN_KEY = 'token';

enum SignInStep {
  SELECT_STRATEGY = 'select_strategy',
  SIGN_IN_WITH_EMAIL = 'sign_in_with_email',
  ENTER_OTP = 'enter_otp',
}

interface StatusState {
  availableStrategies: AuthMethod[];
  currentStep: SignInStep;
  email: string;
  phone: string;
  phoneId: string;
}

const getMagicLinkToken = (searchParams: URLSearchParams) => {
  const stytchTokenType = searchParams.get(MAGIC_LINK_TOKEN_TYPE_KEY);
  const token = searchParams.get(MAGIC_LINK_TOKEN_KEY);

  if (token && stytchTokenType === MAGIC_LINK_TOKEN_TYPE_VALUE) {
    return token;
  }
};

const magicLinkOrPasswordStrategiesAvailable = (strategies: AuthMethod[]) => {
  return strategies.some((strategy) => {
    return [AuthMethod.MAGIC_LINK, AuthMethod.PASSWORD].includes(strategy);
  });
};

const SignIn = () => {
  const [status, setStatus] = useState<StatusState>({
    availableStrategies: [],
    currentStep: SignInStep.SELECT_STRATEGY,
    email: '',
    phone: '',
    phoneId: '',
  });
  const [searchParams] = useSearchParams();
  const [magicLinkToken, setMagicLinkToken] = useState(getMagicLinkToken(searchParams));
  const { loginWithMagicLink } = useLogin();

  useEffect(() => {
    if (magicLinkToken) {
      loginWithMagicLink({ magic_links_token: magicLinkToken }).catch(() => {
        setMagicLinkToken(undefined);
      });
    }
  }, [magicLinkToken]);

  if (magicLinkToken) {
    return <CommonLoader />;
  }

  return (
    <PageWrapWithBackGround
      backgroundImageUrl={'../../src/assets/images/trucks-image-grey.jpg'}
    >
      {status.currentStep === SignInStep.SELECT_STRATEGY && (
        <FormWrapperBox title={t('actions.signIn')}>
          <SignInEmailForm
            email={status.email}
            onFetchAvailableStrategies={({ email, strategies }) => {
              if (magicLinkOrPasswordStrategiesAvailable(strategies)) {
                setStatus({
                  ...status,
                  email,
                  availableStrategies: strategies,
                  currentStep: SignInStep.SIGN_IN_WITH_EMAIL,
                });
              }
            }}
          />

          <Divider flexItem sx={{ my: 3 }}>
            <Typography variant="body2">{t('common.or').toUpperCase()}</Typography>
          </Divider>

          <SignInPhoneNumberForm
            phone={status.phone}
            onOtpSent={({ phone, phoneId }) => {
              setStatus({
                ...status,
                phone,
                phoneId,
                currentStep: SignInStep.ENTER_OTP,
              });
            }}
          />
          <TermsAndConditions sx={{ mt: 2 }} />
          <Copyright sx={{ mt: 2, mb: 4 }} />
        </FormWrapperBox>
      )}

      {status.currentStep === SignInStep.SIGN_IN_WITH_EMAIL && (
        <SignInWithMagicLinkOrPasswordForm
          availableStrategies={status.availableStrategies}
          email={status.email}
          onBack={() => {
            setStatus({ ...status, currentStep: SignInStep.SELECT_STRATEGY });
          }}
        />
      )}

      {status.currentStep === SignInStep.ENTER_OTP && (
        <SignInWithOtpForm
          phone={status.phone}
          phoneId={status.phoneId}
          onBack={() => {
            setStatus({ ...status, currentStep: SignInStep.SELECT_STRATEGY });
          }}
        />
      )}
    </PageWrapWithBackGround>
  );
};
export default SignIn;
