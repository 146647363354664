import * as React from 'react';
import { SVGProps } from 'react';
interface GeoFenceMovingIconProps extends SVGProps<SVGSVGElement> {
  textFill?: string;
}
export const SubtitlesOff = ({
  textFill = '#132732',
  ...props
}: GeoFenceMovingIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0667 2.74577H6.09172L7.75839 4.41244H17.0667V13.7208L18.5334 15.1874C18.6584 14.9541 18.7334 14.6958 18.7334 14.4124V4.41244C18.7334 3.49577 17.9834 2.74577 17.0667 2.74577Z"
      fill={textFill}
    />
    <path d="M15.4001 7.74577H11.0917L12.7584 9.41244H15.4001V7.74577Z" fill={textFill} />
    <path
      d="M1.26672 2.63743L2.26672 3.63744C2.14172 3.87077 2.06672 4.1291 2.06672 4.41244V14.4124C2.06672 15.3291 2.81672 16.0791 3.73339 16.0791H14.7084L17.1751 18.5458L18.3501 17.3708L2.44172 1.4541L1.26672 2.63743ZM3.73339 5.1041L6.37506 7.74577H5.40006V9.41244H7.06672V8.43744L9.70839 11.0791H5.40006V12.7458H11.3751L13.0417 14.4124H3.73339V5.1041Z"
      fill={textFill}
    />
  </svg>
);
