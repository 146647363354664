import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/system';

const DialogActionsWrapper = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

export { DialogActionsWrapper };
