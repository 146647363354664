import Box from '@mui/material/Box';
import React from 'react';

import { SegmentTypes } from './hooks/useGetCycleSegments';

type SegmentProps = {
  startPosition: string;
  endPosition: string;
  type: string;
  hasLogging: boolean;
};

const CycleSegment = ({ startPosition, endPosition, type, hasLogging }: SegmentProps) => {
  const segmentStyles = {
    [SegmentTypes.PICK_UP]: {
      backgroundColor: '#106FEE',
      top: '0%',
    },
    [SegmentTypes.EN_ROUTE]: {
      backgroundColor: '#78B2FF',
      top: hasLogging ? '25%' : '33%',
    },
    [SegmentTypes.DROP_OFF]: {
      backgroundColor: '#133080',
      top: hasLogging ? '50%' : '66%',
    },
    [SegmentTypes.LOGGING]: {
      backgroundColor: 'black',
      top: '75%',
    },
  };

  const style = segmentStyles[type];

  return style ? (
    <Box
      position="absolute"
      left={startPosition}
      right={endPosition}
      height="16px"
      borderRadius="2px"
      top={style.top}
      sx={{ backgroundColor: style.backgroundColor, transform: 'translateY(25%)' }}
    />
  ) : null;
};

export { CycleSegment };
