export class LatLongItem {
  public static parse(proto: Record<any, any>): LatLongItem {
    return new LatLongItem(proto.lat, proto.lon);
  }
  public get lat(): number {
    return this._lat;
  }
  public get lon(): number {
    return this._lon;
  }
  constructor(
    private _lat: number,
    private _lon: number,
  ) {}
}
