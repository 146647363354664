import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React from 'react';

interface PageTitleProps {
  text: string;
  sx?: SxProps<Theme>; // Style
}
export const PageTitle = ({ text, sx }: PageTitleProps) => {
  return (
    <Typography variant={'h4'} sx={{ ...sx }}>
      {text}
    </Typography>
  );
};
