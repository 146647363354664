import { yupResolver } from '@hookform/resolvers/yup';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { t } from 'i18next';
import _ from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { TextFormField } from '~components/FormFields/TextFormField';
import { AddOnCharge } from '~hooks/useAddOns';
import { InvoiceLineItem } from '~hooks/useInvoiceLineItems';
import { Invoice } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob';
import * as colsDef from '~pages/Approvals/DriverPay/driversPayDataGridColumnsDefinition';
import { useStores } from '~store';
import { dateFormat } from '~utils/dateTime';

import AddOnChargesTableRows, { AddOnChargesFormHandler } from './AddOnChargesTableRows';
import {
  LineItemsTableCell,
  LineItemsTableCellProps,
  LineItemsTableTableRow,
} from './components/lineItemsTableComponents';
import { LoadLineItemDTO, loadlLineItemSchema } from './helpers';
import { LineItemsFormHandler } from './LineItemsTable';
import { LineItemStrategy, LoadLineItemConcreteStrategy } from './LineItemStrategy';

const headers: Array<{
  text: string;
  textAlign: LineItemsTableCellProps['textAlign'];
  width?: string;
}> = [
  {
    text: 'Line Item',
    textAlign: 'left',
    width: '100%',
  },
  {
    text: `${t('common.date')}`,
    textAlign: 'left',
    width: '85px',
  },
  {
    text: `${t('common.scheduled')}`,
    textAlign: 'right',
    width: '75px',
  },
  {
    text: `${t('form_fields.material')}`,
    textAlign: 'right',
    width: '120px',
  },
  {
    text: `${t('approvals.driver_pay.tabs.driver_pay.truck_type')}`,
    textAlign: 'right',
    width: '150px',
  },
  {
    text: `${t('form_fields.unit')}`,
    textAlign: 'right',
    width: '80px',
  },
  {
    text: `${t('approvals.driver_pay.tabs.driver_pay.total_quantity')}`,
    textAlign: 'right',
    width: '90px',
  },
  {
    text: `${t('common.rate')}`,
    textAlign: 'right',
    width: '100px',
  },
  {
    text: `${t('common.total')}`,
    textAlign: 'right',
    width: '100px',
  },
];

const lineItemsSchema = yup.object().shape({
  lineItems: yup.array().of(loadlLineItemSchema),
});

type LineItemsDTO = yup.InferType<typeof lineItemsSchema>;

interface LoadRateLineItemsTableProps {
  addOnCharges: AddOnCharge[];
  invoice: Invoice;
  isEditing?: boolean;
  job: Job;
  lineItems: InvoiceLineItem[];
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

const LoadRateLineItemsTable = forwardRef<
  LineItemsFormHandler,
  LoadRateLineItemsTableProps
>(function LoadRateLineItemsTable(
  { addOnCharges, invoice, isEditing, job, lineItems, utils },
  ref,
) {
  const invoiceId = String(invoice.id);
  const strategy = useRef(LineItemStrategy.create(invoice))
    .current as LoadLineItemConcreteStrategy;
  const { invoiceLineItemsStore } = useStores();

  const addOnChargesRef = useRef<AddOnChargesFormHandler>({});

  const initialLineItems = useMemo(() => {
    return lineItems.map((lineItem) => {
      return {
        id: lineItem.id,
        rate: lineItem.rate.toString(),
      } as unknown as LoadLineItemDTO;
    });
  }, [lineItems]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LineItemsDTO>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(lineItemsSchema),
    defaultValues: { lineItems: _.cloneDeep(initialLineItems) },
  });

  useFieldArray({ control, name: 'lineItems' });
  const watchedLineItems = useWatch({ control, name: 'lineItems' });

  const consolidatedLineItemsList = useMemo(() => {
    if (isEditing) {
      return lineItems.map((lineItem, index) => {
        return strategy.consolidate(lineItem, watchedLineItems?.[index]);
      });
    }

    return lineItems;
  }, [isEditing, lineItems, watchedLineItems]);

  useEffect(() => {
    const invoiceSubtotal = consolidatedLineItemsList.reduce((sum, lineItem) => {
      return sum + strategy.calculateTotal(lineItem);
    }, 0);

    invoiceLineItemsStore.setInvoiceSubtotalByInvoiceId(invoiceId, invoiceSubtotal);
  }, [consolidatedLineItemsList, invoiceId]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: () => {
        return new Promise((resolve) => {
          handleSubmit(async (data) => {
            const lineItemsToSubmit = strategy.diffItemsCollection(
              initialLineItems as unknown as (LoadLineItemDTO & { id: string })[],
              data.lineItems ?? [],
            );

            const addOnChargesToSubmit =
              (await addOnChargesRef.current.onSubmit?.()) ?? [];

            resolve({ lineItems: lineItemsToSubmit, addOnCharges: addOnChargesToSubmit });
          })();
        });
      },
    }),
    [initialLineItems],
  );

  return (
    <>
      <TableHead>
        <LineItemsTableTableRow isHeader>
          {headers.map(({ text, width, ...rest }) => (
            <LineItemsTableCell key={text} isHeader width={width} {...rest}>
              {text}
            </LineItemsTableCell>
          ))}
        </LineItemsTableTableRow>
      </TableHead>

      <TableBody>
        {consolidatedLineItemsList.map((lineItem, index) => {
          const lineItemTotal = strategy.calculateTotal(lineItem);

          return (
            <LineItemsTableTableRow key={lineItem.id}>
              <LineItemsTableCell textAlign={headers[0].textAlign}>
                {lineItem.name}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[1].textAlign}>
                {job.jobStartAt ? dateFormat(job.jobStartAt) : null}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[2].textAlign}>
                {job.jobStartAt ? dateFormat(job.jobStartAt, 'hh:mm a') : null}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[3].textAlign}>
                {job.material?.name}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[4].textAlign}>
                {job.equipment?.equipmentType.name}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[5].textAlign}>
                {job.unitOfMeasure?.name}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[6].textAlign}>
                {lineItem.quantity}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[7].textAlign}>
                {isEditing ? (
                  <TextFormField
                    control={control}
                    errors={errors}
                    type="number"
                    name={`lineItems[${index}].rate`}
                    sx={{
                      ml: 'auto',
                      '& .MuiFormControl-root': { mb: 0 },
                      '& .MuiOutlinedInput-root': { pr: 0 },
                      '& .MuiOutlinedInput-input': { p: '5px 7px', fontSize: '12px' },
                    }}
                    inputProps={{ sx: { textAlign: 'right' } }}
                  />
                ) : (
                  utils.currencyFormatter(lineItem.rate)
                )}
              </LineItemsTableCell>

              <LineItemsTableCell textAlign={headers[8].textAlign}>
                {utils.currencyFormatter(lineItemTotal)}
              </LineItemsTableCell>
            </LineItemsTableTableRow>
          );
        })}

        <AddOnChargesTableRows
          ref={addOnChargesRef}
          key={addOnCharges.map((addOnCharge) => addOnCharge.id).join()}
          addOnCharges={addOnCharges}
          invoice={invoice}
          isEditing={isEditing}
          lineItemColSpan={1}
          lineItemsTableColumnsCount={headers.length}
          utils={utils}
        />
      </TableBody>
    </>
  );
});

export default LoadRateLineItemsTable;
