import { OmniReport_Read } from '@treadinc/horizon-api-spec';

export class OmniReport {
  public static parse(proto: OmniReport_Read): OmniReport {
    return new OmniReport(proto.id, proto.name);
  }

  public get name() {
    return this._name;
  }
  public get id() {
    return this._id;
  }
  constructor(
    private _id: string,
    private _name: string,
  ) {}
}
