import { t as $t } from 'i18next';
import { Trans } from 'react-i18next';

import LinkToForgotPassword from '~pages/Auth/LinkToForgotPassword';

// Will need to update this to use i18n
const returnToForgotPasswordMessage = (message: string) => (
  <Trans
    i18nKey="errors.stytch_message"
    values={{ message: message }}
    components={{ link: <LinkToForgotPassword /> }}
  ></Trans>
);

export const ERROR_TYPES = {
  member_reset_password: returnToForgotPasswordMessage(
    $t('error_type.unauthorized.member_reset_password'),
  ),
  breached_password: returnToForgotPasswordMessage(
    $t('error_type.unauthorized.breached_password'),
  ),
  unable_to_auth_magic_link: returnToForgotPasswordMessage(
    $t('error_type.unauthorized.unable_to_auth_magic_link'),
  ),
  member_password_not_found: returnToForgotPasswordMessage(
    $t('error_type.unauthorized.member_password_not_found'),
  ),
  weak_password: $t('error_type.unauthorized.weak_password'),
};
