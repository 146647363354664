import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  NotificationMethod,
  UserPreference_Notification_Read,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { FormStateChangeProps } from '~formsShared';
import { User, UserPreferenceNotification } from '~hooks/useUsers';

export type UserPreferenceNotificationKey = keyof UserPreference_Notification_Read;

type NotificationsLookup = Partial<
  Record<
    UserPreferenceNotificationKey,
    (preference?: UserPreferenceNotification) => NotificationMethod[]
  >
>;

const notifications: NotificationsLookup = {
  pending_job_reminder: (preference) => preference?.pendingJobReminder ?? [],
  new_job_request: (preference) => preference?.newJobRequest ?? [],
  start_job_reminder: (preference) => preference?.startJobReminder ?? [],
  no_location_alert: (preference) => preference?.noLocationAlert ?? [],
};
const notificationMethods = Object.values(NotificationMethod);
const notificationMethodLabel = notificationMethods.reduce(
  (obj, method) => {
    obj[method] = t(`settings.user_profile.notification_methods.${method}`);

    return obj;
  },
  {} as Record<NotificationMethod, string>,
);

const requiredBooleanSchema = yup.bool().required();
const notificationMethodsSchema = yup.object().shape(
  notificationMethods.reduce(
    (obj, method) => {
      obj[method] = requiredBooleanSchema;

      return obj;
    },
    {} as Record<NotificationMethod, typeof requiredBooleanSchema>,
  ),
);
const notificationsSchema = yup
  .object()
  .shape(
    Object.keys(notifications).reduce(
      (obj, notification) => {
        obj[notification as UserPreferenceNotificationKey] =
          notificationMethodsSchema.required();

        return obj;
      },
      {} as Record<UserPreferenceNotificationKey, typeof notificationMethodsSchema>,
    ),
  )
  .required();

type NotificationMethodsDTO = yup.InferType<typeof notificationMethodsSchema>;
export type UserNotificationsPreferenceDTO = yup.InferType<typeof notificationsSchema>;

const setFormDefaultValues = (user?: User) => {
  const defaultValues = Object.entries(notifications).reduce(
    (obj, [notification, getValue]) => {
      const selectedMethods = getValue(user?.preferences?.notifications ?? undefined);
      const notificationKey = notification as UserPreferenceNotificationKey;

      obj[notificationKey] = notificationMethods.reduce((methods, method) => {
        methods[method] = selectedMethods.includes(method) ?? false;

        return methods;
      }, {} as NotificationMethodsDTO);

      return obj;
    },
    {} as UserNotificationsPreferenceDTO,
  );

  return defaultValues;
};

const columnsCount = notificationMethods.length;
const gridTemplateColumns = ['1fr', ...new Array(columnsCount).fill('auto')].join(' ');

export interface UserNotificationsPreferenceHandler {
  onReset?: (user?: User) => void;
  onSubmit?: (callback: (data: UserNotificationsPreferenceDTO) => void) => void;
}

interface UserNotificationsPreferenceFormProps {
  editable?: boolean;
  onFormStateChange?: (args: FormStateChangeProps) => void;
  user?: User;
}

const UserNotificationsPreferenceForm = forwardRef<
  UserNotificationsPreferenceHandler,
  UserNotificationsPreferenceFormProps
>(function UserNotificationsPreferenceForm({ editable, onFormStateChange, user }, ref) {
  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
  } = useForm<UserNotificationsPreferenceDTO>({
    resolver: yupResolver(notificationsSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setFormDefaultValues(user),
  });

  useImperativeHandle(
    ref,
    () => ({
      onReset: (user?: User) => {
        reset(user ? setFormDefaultValues(user) : undefined);
      },
      onSubmit: (callback) => {
        handleSubmit((data) => {
          callback(data);
        })();
      },
    }),
    [],
  );

  useEffect(() => {
    onFormStateChange?.({ isDirty });
  }, [onFormStateChange, isDirty]);

  return (
    <Box mt={2} maxWidth="300px">
      <Typography variant="h6">{t('settings.user_profile.notifications')}</Typography>

      <Box
        alignItems="center"
        display="grid"
        gap={2}
        gridTemplateColumns={gridTemplateColumns}
      >
        <Box />
        {notificationMethods.map((method) => (
          <Typography
            key={method}
            fontSize="12px"
            fontWeight="bold"
            textAlign="center"
            sx={{ textDecoration: 'underline' }}
          >
            {notificationMethodLabel[method]}
          </Typography>
        ))}

        {Object.keys(notifications).map((notification) => (
          <React.Fragment key={notification}>
            <Typography fontSize="12px">
              {t(`settings.user_profile.notification_keys.${notification}`)}
            </Typography>

            {notificationMethods.map((method) => (
              <Box key={`${notification}-${method}`} textAlign="center">
                <CheckBoxFormField
                  sx={{
                    '& .MuiFormControlLabel-root': { mx: 0 },
                    '& .MuiButtonBase-root': { p: 0 },
                  }}
                  disabled={!editable}
                  control={control}
                  name={`${notification}.${method}`}
                  value={getValues(
                    `${notification as UserPreferenceNotificationKey}.${method}`,
                  )}
                />
              </Box>
            ))}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
});

export default UserNotificationsPreferenceForm;
