import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

import { Nullable } from '~types/Nullable';
import { usePrevious } from '~utils/hooks/usePrevious';

const StyledButton = styled(Button)`
  border: none;
  padding: 0;
  position: relative;
  background-color: transparent;
  width: 100%;
  justify-content: space-between;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    background-color: transparent;
  }

  .MuiAlert-icon {
    margin-right: 6px;
  }
`;

const StyledAlert = styled(Alert)`
  padding: 0;
  background-color: transparent;
`;

interface CopyToClipboardProps {
  children: React.ReactNode;
  textToCopy: string;
  alertMessageDuration?: number;
}

const CopyToClipboard = ({
  textToCopy,
  children,
  alertMessageDuration = 2000,
}: CopyToClipboardProps) => {
  const [error, setError] = useState<Nullable<string>>(null);
  const [showMessage, setShowMessageState] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const prevShowMessageState = usePrevious(showMessage);
  const handleCopy = (text: string) => {
    copyToClipboard(text)
      .then(() => setShowMessageState(true))
      .catch((error) => setError(`${t('approvals.copy_error')}: ${error}`));
  };
  const onLinkClick = () => {
    setError(null);
    handleCopy(textToCopy);
  };

  useEffect(() => {
    if (!prevShowMessageState && showMessage) {
      const fadeMessage = setTimeout(() => {
        setShowMessageState(false);
      }, alertMessageDuration);
      return () => {
        clearTimeout(fadeMessage);
      };
    }
  }, [prevShowMessageState, showMessage]);

  return (
    <StyledButton onClick={onLinkClick}>
      {children}

      {error ? (
        <StyledAlert severity="error">{error}</StyledAlert>
      ) : (
        showMessage && (
          <StyledAlert severity="success">{t('approvals.copy_successful')}</StyledAlert>
        )
      )}
    </StyledButton>
  );
};

export { CopyToClipboard };
