import { t } from 'i18next';
import * as yup from 'yup';

import {
  nameIdNonRequiredSchema,
  notesSchema,
  personNameSchema,
  phoneSchema,
  waypointSchema,
} from '~constants/regexConst';
import { Job } from '~hooks/useJob';
import { JobFormProps } from '~hooks/useJob/models';
import { EmptyContactItem } from '~pages/Sales/Projects/projectFormSchema';

import { fileAttachmentSchema } from '../../formsShared/components/FileAttachments';

export const jobFormSchema = yup.object().shape({
  id: yup.string(),
  jobStartAt: yup.string().required(),
  material: yup.object().required(),
  equipment: yup.object().notRequired(),
  additionalEquipment: yup.object().notRequired(),
  quantity: yup
    .number()
    .positive()
    .required()
    .typeError(
      t('form_validation_errors.should_be_number', {
        field: t('form_fields.quantity'),
      }) || '',
    ),
  unitOfMeasure: nameIdNonRequiredSchema.required(),
  priority: yup
    .number()
    .min(
      0,
      `${t('form_validation_errors.min', {
        min: 0,
        field: t('form_fields.priority'),
      })}`,
    )
    .notRequired()
    .typeError(
      t('form_validation_errors.should_be_number', {
        field: t('form_fields.priority'),
      }) || '',
    ),
  pickUpWayPoint: waypointSchema.required(),
  dropOffWayPoint: waypointSchema.required(),
  notes: notesSchema,
  jobNotes: notesSchema,
  orderNotes: notesSchema,
  projectNotes: notesSchema,
  internalNotes: notesSchema,
  allContacts: yup.array().of(
    yup.object().shape({
      name: personNameSchema(`${t('form_fields.name')}`).notRequired(),
      phone: phoneSchema,
      type: nameIdNonRequiredSchema,
      isDeleted: yup.boolean().notRequired(),
    }),
  ),
  vendorAccount: yup.object().notRequired(),
  loads: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      quantity: yup
        .number()
        .positive()
        .required()
        .typeError(
          t('form_validation_errors.should_be_number', {
            field: t('form_fields.quantity'),
          }) || '',
        ),
    }),
  ),
  foremen: yup.array().of(yup.object().shape({ id: yup.string().required() }).required()),
  vendor: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .nullable(),
  driver: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .nullable(),
  fileAttachments: fileAttachmentSchema,
});

export const setDefaultJobValues = (job: Job) => {
  const pickUpWayPoint = job?.pickUpWayPoints?.[0];
  const dropOffWayPoint = job?.dropOffWayPoints?.[0];

  return {
    id: job.id,
    notes: job.notes,
    jobNotes: job.notes || '',
    projectNotes: '',
    orderNotes: '',
    internalNotes: '',
    quantity: job.quantity,
    unitOfMeasure: job.unitOfMeasure,
    service: job.service,
    material: job.material,
    equipment: job.equipment,
    additionalEquipment: job.additionalEquipment,
    jobId: job.jobId,
    jobStartAt: job.jobStartAt || '',
    pickUpWayPoint: pickUpWayPoint?.id
      ? {
          id: pickUpWayPoint?.id || '',
          type: pickUpWayPoint?.type || '',
          ordinality: pickUpWayPoint?.ordinality ?? 10,
          contact: {
            name: pickUpWayPoint?.contact?.name || '',
            phone: pickUpWayPoint?.contact?.phone || '',
          },
          site: {
            id: pickUpWayPoint?.site?.id || '',
            name: pickUpWayPoint?.site?.name || '',
            lat: pickUpWayPoint?.site?.lat,
            lon: pickUpWayPoint?.site?.lon,
          },
        }
      : null,
    dropOffWayPoint: dropOffWayPoint?.id
      ? {
          id: dropOffWayPoint?.id || '',
          type: dropOffWayPoint?.type || '',
          ordinality: dropOffWayPoint?.ordinality ?? 10,
          contact: {
            name: dropOffWayPoint?.contact?.name || '',
            phone: dropOffWayPoint?.contact?.phone || '',
          },
          site: {
            id: dropOffWayPoint?.site?.id || '',
            name: dropOffWayPoint?.site?.name || '',
            lat: dropOffWayPoint?.site?.lat,
            lon: dropOffWayPoint?.site?.lon,
          },
        }
      : null,
    priority: job.priority || '',
    vendorAccount: {
      name: job?.vendorJobAssignment?.vendorAccount?.name || '',
      id: job?.vendorJobAssignment?.vendorAccount?.id || '',
    },
    loads: job.loads || [],
    foremen: job?.foremen || [],
    allContacts: job?.allContacts?.length
      ? job.allContacts.map((single) => {
          return {
            id: single.id || undefined,
            name: single.name || '',
            phone: single.phone || '',
            type: single.type || undefined,
            isDeleted: false,
          };
        })
      : [EmptyContactItem],
    vendor: job?.vendorJobAssignment?.vendorAccount
      ? {
          label: String(job.vendorJobAssignment.vendorAccount.name),
          value: String(job.vendorJobAssignment.vendorAccount.id),
        }
      : null,
    driver: job?.driver ? { label: job.driver.fullName, value: job.driver.id } : null,
  } as unknown as JobFormProps;
};
