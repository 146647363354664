export const LIVE_MAP_TRUCK_LAYER_ID = 'live-map-trucks-layer';
export const LIVE_MAP_TRUCK_SOURCE_ID = 'live-map-trucks-source';
export const LIVE_MAP_PINS_LAYER_ID = 'live-map-pins-layer';
export const LIVE_MAP_PINS_SOURCE_ID = 'live-map-pins-source';
export const LIVE_MAP_ROUTE_LAYER_ID = 'live-map-route-layer';
export const LIVE_MAP_ROUTE_SOURCE_ID = 'live-map-route-source';
export const LIVE_MAP_SITES_LAYER_ID = 'live-map-sites-layer';
export const LIVE_MAP_SITES_SOURCE_ID = 'live-map-sites-source';
export const LIVE_MAP_GEOFENCE_FILL_LAYER_ID = 'live-map-geofence-fill-layer';
export const LIVE_MAP_GEOFENCE_LINE_LAYER_ID = 'live-map-geofence-line-layer';
export const LIVE_MAP_GEOFENCE_SOURCE_ID = 'live-map-geofence-source';
export const LIVE_MAP_HIGHLIGHT_LAYER_ID = 'live-map-highlight-layer';
export const LIVE_MAP_HIGHLIGHT_HALO_LAYER_ID = 'live-map-highlight_halo-layer';
export const LIVE_MAP_HIGHLIGHT_SOURCE_ID = 'live-map-highlight-source';
export const LIVE_MAP_MOVING_SITE_SOURCE_ID = 'live-map-moving-site-source';
export const LIVE_MAP_MOVING_SITE_PIN_LAYER_ID = 'live-map-moving-site-pin-layer';
export const LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_SOURCE_ID =
  'live-map-moving-site-geofence-fill-source';
export const LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_LAYER_ID =
  'live-map-moving-site-geofence-fill-layer';

export const SOURCE_DATA_IDS = [
  LIVE_MAP_GEOFENCE_SOURCE_ID,
  LIVE_MAP_HIGHLIGHT_SOURCE_ID,
  LIVE_MAP_ROUTE_SOURCE_ID,
  LIVE_MAP_PINS_SOURCE_ID,
  LIVE_MAP_SITES_SOURCE_ID,
  LIVE_MAP_TRUCK_SOURCE_ID,
  LIVE_MAP_MOVING_SITE_SOURCE_ID,
  LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_SOURCE_ID,
];

export const INITIAL_ZOOM_LEVEL = 11;
export const DEFAULT_BEARING = 0;
export const DEFAULT_PITCH = 0;
export const DEFAULT_MAX_ZOOM = 20;
export const DEFAULT_MIN_ZOOM = 1;
export const MAX_PING_TTL = 10;
export const CLEAR_OLD_TRUCK_LOCATIONS_INTERVAL_IN_MS = 60000;
export const VERTICES_IN_CIRCLE = 64;

export const EMPTY_FEATURE_COLLECTION = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
};
