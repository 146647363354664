import { useMemo, useState } from 'react';

import { AccountTypesFilter } from '~constants/enums';

export const useAccountFilters = () => {
  const [accountTypeFilter, setAccountTypeFilter] = useState<AccountTypesFilter>(
    AccountTypesFilter.CUSTOMER,
  );
  const accountTypeParam = useMemo(() => {
    if (accountTypeFilter === AccountTypesFilter.ALL) {
      return undefined;
    }

    return [accountTypeFilter];
  }, [accountTypeFilter]);
  return {
    accountTypeFilter,
    setAccountTypeFilter,
    accountTypeParam,
  };
};
