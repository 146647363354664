import Card from '@mui/material/Card';
import { t as $t } from 'i18next';
import React from 'react';

import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';

interface Props {
  control: any;
  errors: any;
}
const Notes = ({ control, errors }: Props) => (
  <Card sx={{ p: 2, mb: 2 }}>
    <FormSectionTitle title={$t('form_fields.notes')} />
    <TextFormField
      control={control}
      multiline={true}
      rows={2}
      errors={errors}
      name="notes"
      label=""
      isRequired={false}
    />
  </Card>
);

export { Notes };
