import Typography from '@mui/material/Typography';
import React from 'react';

const PaymentPage = () => {
  return (
    <>
      <Typography variant="h4" sx={{ my: 4 }}>
        Payments page
      </Typography>
    </>
  );
};

export default PaymentPage;
