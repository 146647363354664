import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import nextbillion from '@nbai/nbmap-gl';
import { LngLatLike } from 'maplibre-gl';
import React, { useEffect, useMemo, useRef } from 'react';

import { FeatureFlags } from '~constants/featureFlags';
import {
  LIVE_MAP_GEOFENCE_FILL_LAYER_ID,
  LIVE_MAP_PINS_LAYER_ID,
  LIVE_MAP_SITES_LAYER_ID,
  LIVE_MAP_TRUCK_LAYER_ID,
} from '~hooks/useLiveMap/constants';
import { LiveMapFeature } from '~hooks/useLiveMap/models';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import { JobDetails } from './JobDetails';
import { NewJobDetails } from './NewJobDetails';
import { SiteDetails } from './SiteDetails';

interface LivePopUpProps {
  lngLat: LngLatLike;
  onClose: () => void;
  map: maplibregl.Map;
  feature: LiveMapFeature;
}

/**
 * Represents a popup component for NB maps.
 *
 * @param {LngLatLike} lngLat - The longitude and latitude coordinates of the popup.
 * @param {Function} onClose - The function to be called when the popup is closed.
 * @param {maplibregl.Map} map - The NB map instance where the popup is rendered.
 * @param {LiveMapFeature} feature - The livemap feature details that was clicked.
 *
 * @returns {JSX.Element} The rendered popup component.
 */
const LivePopUp = ({ lngLat, onClose, map, feature }: LivePopUpProps) => {
  const theme = useTheme();
  const popupRef = useRef(null);

  const treadLiveMapEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.treadLivePage,
  });

  // Handles where the popup is rendered on the map
  useEffect(() => {
    const popup = new nextbillion.maps.Popup({ className: 'live-map-popup' })
      .setLngLat(lngLat)
      .setDOMContent(popupRef.current as unknown as Node)
      .addTo(map);

    popup.on('close', onClose);
    return () => {
      popup.remove();
    };
  }, [lngLat]);

  /**
   * Grabs the content for the popup based on the layer id.
   *
   * @returns The JSX element representing the popup content or an empty fragment for supported features.
   */
  const popUpContent = useMemo(() => {
    if (
      [LIVE_MAP_PINS_LAYER_ID, LIVE_MAP_TRUCK_LAYER_ID].includes(feature.layer?.id || '')
    ) {
      return treadLiveMapEnabled ? (
        <NewJobDetails feature={feature} />
      ) : (
        <JobDetails feature={feature} />
      );
    } else if (
      [LIVE_MAP_SITES_LAYER_ID, LIVE_MAP_GEOFENCE_FILL_LAYER_ID].includes(
        feature.layer?.id || '',
      )
    ) {
      return <SiteDetails feature={feature} />;
    }

    return <React.Fragment />;
  }, [feature.layer?.id]);

  return (
    <Box style={{ display: 'none' }}>
      <Grid
        ref={popupRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.secondary.main,
          position: 'absolute',
          width: '300px',
          borderRadius: '8px',
          padding: theme.spacing(1),
        }}
      >
        {popUpContent}
      </Grid>
    </Box>
  );
};

export default LivePopUp;
