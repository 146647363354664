import Box from '@mui/material/Box';

import { DriverDayDateFilter } from './DriverDayDateFilter';
import { DriverDayMainFilters } from './DriverDayMainFilters';

export function DriverDayFilters() {
  return (
    <Box aria-label="filter area" sx={{ display: 'flex', mb: 3, gap: 1 }}>
      <DriverDayDateFilter />
      <DriverDayMainFilters />
    </Box>
  );
}
