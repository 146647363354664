import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AuthMethod } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  UserForm,
  UserFormHandler,
  UserValidationDTO,
} from '~components/UserForm/UserForm';
import UserNotificationsPreferenceForm, {
  UserNotificationsPreferenceDTO,
  UserNotificationsPreferenceHandler,
} from '~components/UserForm/UserNotificationsPreferenceForm';
import { FormStateChangeProps } from '~formsShared';
import { useCompany } from '~hooks/useCompany';
import { RoleItem, User, useUsers } from '~hooks/useUsers';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

const UserProfile = () => {
  const [userFormIsDirty, setUserFormIsDirty] = useState(false);
  const [
    userNotificationsPreferenceFormIsDirty,
    setUserNotificationsPreferenceFormIsDirty,
  ] = useState(false);

  const formIsDirty = userFormIsDirty || userNotificationsPreferenceFormIsDirty;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { updateUser, isUpdating } = useUsers();
  const { toasterStore, userStore } = useStores();
  const userProfileRef = useRef<UserFormHandler>(null);
  const userNotificationsPreferenceRef = useRef<UserNotificationsPreferenceHandler>(null);
  const [availableAuthMethodsForUserCompany, setAvailableAuthMethodsForUserCompany] =
    useState<AuthMethod[]>([]);
  const { getCompanyById } = useCompany();
  const currentUser = useMemo(() => userStore.user, [userStore.user?.id]);
  const onSuccess = (user?: User) => {
    const name = `${user?.firstName} ${user?.lastName}`;
    toasterStore.push(alert($t('user.user_updated', { name }), AlertTypes.success));

    setUserNotificationsPreferenceFormIsDirty(false);
    userNotificationsPreferenceRef.current?.onReset?.(user);
  };
  const onCancel = () => {
    setIsEditing(false);
    userProfileRef.current?.resetForm();
    userNotificationsPreferenceRef.current?.onReset?.();
  };
  const onUserFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setUserFormIsDirty(isDirty);
  };

  const onUserNotificationsPreferenceFormStateChange = useCallback(
    ({ isDirty }: FormStateChangeProps) => {
      setUserNotificationsPreferenceFormIsDirty(isDirty);
    },
    [],
  );

  const onSubmitCallback = (
    userData: UserValidationDTO,
    userNotificationsPreferenceData: UserNotificationsPreferenceDTO,
  ) => {
    updateUser(
      { id: currentUser?.id, ...userData } as User,
      userNotificationsPreferenceData,
    ).then(onSuccess);
    setIsEditing(false);
  };

  const onSubmitForm = async () => {
    const userData = await new Promise<UserValidationDTO | undefined>((resolve) => {
      userProfileRef.current?.submit((data) => resolve(data));
    });

    const userNotificationsPreferenceData = await new Promise<
      UserNotificationsPreferenceDTO | undefined
    >((resolve) => {
      userNotificationsPreferenceRef.current?.onSubmit?.((data) => resolve(data));
    });

    if (userData && userNotificationsPreferenceData) {
      onSubmitCallback(userData, userNotificationsPreferenceData);
    }
  };

  useEffect(() => {
    if (currentUser?.company?.id && !availableAuthMethodsForUserCompany.length) {
      getCompanyById({
        id: String(currentUser.company.id),
        callBack: (company) => {
          setAvailableAuthMethodsForUserCompany(company.authMethods);
        },
      });
    }
  }, [currentUser?.company?.id, availableAuthMethodsForUserCompany.length]);

  if (!availableAuthMethodsForUserCompany) {
    return <Typography>{$t('actions.loading')}</Typography>;
  }

  return (
    <Box
      display={'flex'}
      width={'100%'}
      sx={{ maxWidth: '800px' }}
      flexDirection={'column'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        sx={{
          py: 2,
          mt: -2,
          backgroundColor: 'background.paper',
        }}
        className="sticky-top"
      >
        <Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
          {isEditing ? (
            <Button
              onClick={onCancel}
              sx={{ mr: 2, px: 2 }}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          ) : (
            <Button
              onClick={() => setIsEditing((prev) => !prev)}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating}
              color="primary"
              variant="contained"
            >
              {$t(`actions.${isEditing ? 'cancel' : 'edit'}`)}
            </Button>
          )}
          <LoadingButton
            disabled={isUpdating || !formIsDirty}
            loading={isUpdating}
            loadingPosition="start"
            startIcon={<></>}
            onClick={onSubmitForm}
            type="button"
            variant="contained"
            color="primary"
            sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
          >
            {$t(`actions.update`)}
          </LoadingButton>
        </Box>
      </Box>
      {currentUser?.id && (
        <>
          <UserForm
            ref={userProfileRef}
            disableUserRolesField
            editable={isEditing}
            userRolesOptions={currentUser?.userRoles as RoleItem[]}
            defaultUser={currentUser}
            onFormStateChange={onUserFormStateChange}
            availableAuthMethods={availableAuthMethodsForUserCompany}
          />

          <UserNotificationsPreferenceForm
            ref={userNotificationsPreferenceRef}
            editable={isEditing}
            onFormStateChange={onUserNotificationsPreferenceFormStateChange}
            user={currentUser}
          />
        </>
      )}
    </Box>
  );
};
export default observer(UserProfile);
