import dayjs from 'dayjs';
import { useMemo } from 'react';

// Get the whole hour intervals that exist between a certain date range
const useWholeHours = (minTime: string, maxTime: string) =>
  useMemo(() => {
    let start = dayjs(minTime);
    const end = dayjs(maxTime);

    const wholeHours = [];
    // Mutate the moment each iteration
    while (start.startOf('hour').isBefore(end)) {
      // Only add the hour if it is after the minTime
      if (start.startOf('hour').isAfter(dayjs(minTime))) {
        wholeHours.push(start.startOf('hour').toString());
      }

      start = start.add(1, 'hour');
    }

    const determineInterval = (maxMarkers: number = 12) => {
      const MILLISECONDS_IN_HOUR = 1000 * 60 * 60;
      const totalHours =
        (new Date(maxTime).getTime() - new Date(minTime).getTime()) /
        MILLISECONDS_IN_HOUR;

      const minInterval = 1;
      let interval = Math.ceil(totalHours / maxMarkers);
      if (interval < minInterval) {
        interval = minInterval;
      }
      return interval;
    };

    return { wholeHours, determineInterval };
  }, [minTime, maxTime]);

export { useWholeHours };
