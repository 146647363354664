import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '../../NewDispatch';
import { datetimeToFormattedDateAndTime } from '../../utils';

dayjs.extend(isToday);

const getDateString = (dateAsString?: string) => {
  const date = dayjs.tz(dateAsString);
  const startOfWeek = dayjs.tz().startOf('week');

  if (date.isSame(startOfWeek, 'week')) {
    return { date: t('date.this_week') };
  }

  return datetimeToFormattedDateAndTime(date);
};

const DateFilter = observer(() => {
  const { calendarDispatchStore } = useStores();
  const isMounted = useRef(false);

  const applyDateFilter = useCallback((date: Dayjs) => {
    const formattedDate = date.format('YYYY-MM-DD');

    calendarDispatchStore.setFilters(
      { startDate: formattedDate, endDate: date.add(7, 'day').toISOString() },
      true,
    );
  }, []);

  const handlePreviousClick = useCallback(() => {
    let newDate = dayjs.tz(calendarDispatchStore.filters.startDate).add(-1, 'week');
    if (newDate.day() === 0) {
      // If today is Sunday, we want to start the week on Monday
      newDate = newDate.add(1, 'day');
    }
    applyDateFilter(newDate);
  }, [calendarDispatchStore.filters.startDate]);

  const handleNextClick = useCallback(() => {
    let newDate = dayjs.tz(calendarDispatchStore.filters.startDate).add(1, 'week');
    if (newDate.day() === 0) {
      // If today is Sunday, we want to start the week on Monday
      newDate = newDate.add(1, 'day');
    }
    applyDateFilter(newDate);
  }, [calendarDispatchStore.filters.startDate]);

  useEffect(() => {
    if (!calendarDispatchStore.filters.startDate && !isMounted.current) {
      const storedFilters = calendarDispatchStore.retrieveStoredFilters();

      let startOfWeek = storedFilters.startDate
        ? dayjs.tz(storedFilters.startDate)
        : dayjs.tz().startOf('week');

      if (startOfWeek.day() === 0) {
        // If today is Sunday, we want to start the week on Monday
        startOfWeek = startOfWeek.add(1, 'day');
      }

      applyDateFilter(startOfWeek);
    }
  }, [calendarDispatchStore.filters.startDate]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, []);

  return (
    <Box flex={0}>
      <Box
        alignItems="center"
        bgcolor="white"
        border={`solid 1px ${theme.brandV2.colors.treadGray7}`}
        borderRadius={theme.brandV2.borderRadius}
        boxShadow="none"
        display="flex"
        height={NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}
      >
        <DateFilterButton onClick={handlePreviousClick} icon={<ChevronLeft />} />

        <Typography fontSize="12px" fontWeight={600} whiteSpace="nowrap">
          {getDateString(calendarDispatchStore.filters.startDate).date}
        </Typography>

        <DateFilterButton onClick={handleNextClick} icon={<ChevronRight />} />
      </Box>
    </Box>
  );
});

interface DateFilterButtonProps {
  icon: ReactNode;
  onClick: () => void;
}

function DateFilterButton({ icon, onClick }: DateFilterButtonProps) {
  return (
    <SmallButton
      onClick={onClick}
      startIcon={icon}
      sx={{
        '&.MuiButton-root': {
          backgroundColor: 'transparent',
          border: 0,
          boxShadow: 'none',
          height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
          width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
          '.MuiButton-startIcon': { mr: 0 },
        },
      }}
    />
  );
}

export default DateFilter;
