import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { t as $t } from 'i18next';

import { GridCardWrapper } from '~components/Cards/GridCardWrapper';
import { SimpleCard } from '~components/Cards/SimpleCard';
import { JobAuditLog } from '~components/Job/AuditDetails/JobAuditLog';
import { JobLoadTimeline } from '~components/Job/AuditDetails/JobLoadTimeline';
import { Loads } from '~components/Job/Loads';
import { RouteMap } from '~components/Job/RouteMap';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { Job } from '~hooks/useJob';

interface Props {
  details: Job;
  reload: () => void;
}

const LoadDetails = ({ details, reload }: Props) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <GridCardWrapper sx={{ border: 'none', pt: 1.5 }}>
            <Paper
              elevation={0}
              sx={{ flexGrow: 1, background: 'transparent', borderRadius: 0 }}
            >
              <Box sx={{ p: 2, pt: 0 }}>
                <Loads details={details} reload={reload} />
              </Box>
            </Paper>
          </GridCardWrapper>
        </Grid>

        <Grid item xs={3.5}>
          <GridCardWrapper sx={{ border: 'none', m: -2, width: '100%' }}>
            <SimpleCard
              key={`loads__${details.id}}`}
              sx={{ flexGrow: 1, borderRadius: 0, pr: 0, mr: -2 }}
            >
              <Box>
                <RouteMap job={details} />
              </Box>
            </SimpleCard>
          </GridCardWrapper>
        </Grid>

        <Grid item xs={3.5}>
          <GridCardWrapper sx={{ border: 'none', m: -2, width: '100%' }}>
            <SimpleCard
              key={`jobEvents__${details.id}`}
              sx={{ flexGrow: 1, borderRadius: 0, pr: 0, mr: -2 }}
            >
              <SmallTabs
                sx={{ mr: -2 }}
                panelHeight={400}
                tabs={[
                  {
                    label: `${$t('dispatch.timeline.job_audit_log')}`,
                    id: `details_${details.id}_events`,
                    content: (
                      <JobAuditLog jobId={details.id} orderId={details.order?.id} />
                    ),
                  },
                  {
                    label: `${$t('dispatch.timeline.job_load_timeline')}`,
                    id: `details_${details.id}_timeline`,
                    content: <JobLoadTimeline id={details.id} sx={{ mx: -2, mt: -1 }} />,
                  },
                ]}
              />
            </SimpleCard>
          </GridCardWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export { LoadDetails };
