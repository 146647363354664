// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

import { isMobileBrowser } from '~utils/isMobileBrowser';

const firebaseConfig = {
  apiKey: import.meta.env.TREAD__FIREBASE_API_KEY,
  authDomain: import.meta.env.TREAD__FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.TREAD__FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.TREAD__FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.TREAD__FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.TREAD__FIREBASE_APP_ID,
  measurementId: import.meta.env.TREAD__FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const generateToken = async () => {
  if (isMobileBrowser()) {
    console.log('FCM is not initialized on mobile browsers.');
    return;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: import.meta.env.TREAD__FIREBASE_VAPID_KEY,
      });
      return token;
    } else {
      console.log('Unable to get permission to notify.');
    }
  } catch (error) {
    console.error(error);
  }
};
