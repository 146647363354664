import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { RateType } from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';

import { SearchParams } from '~constants/enums';

import { GenericSelector } from './GenericSelector';

interface Props {
  allSearchParams: Record<string, any>;
  sx?: SxProps<Theme>; // Style
}

const rateTypeLabels = Object.entries({
  [RateType.RATE_PER_HOUR]: t('administration.rates.rate_per_hour'),
  [RateType.RATE_PER_DAY]: t('administration.rates.rate_per_day'),
  [RateType.RATE_PER_LOAD]: t('administration.rates.rate_per_load'),
  [RateType.RATE_PER_TON]: t('administration.rates.rate_per_ton'),
  [RateType.RATE_PER_TONNE]: t('administration.rates.rate_per_tonne'),
  [RateType.RATE_PER_YARD]: t('administration.rates.rate_per_yard'),
  [RateType.RATE_PER_BUSHEL]: t('administration.rates.rate_per_bushel'),
  [RateType.RATE_COMMISSION]: t('administration.rates.rate_commission'),
}).map(([value, name]) => ({ value, name }));

const RateTypeSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  return (
    <GenericSelector
      isShrinkingLabel
      fieldName={SearchParams.RATE_TYPE}
      label={$t('form_fields.rate_type')}
      defaultValues={[]}
      allSearchParams={allSearchParams}
      sx={{
        ...sx,
        ...{
          width: '150px',
          fontSize: '12px',
          mr: 1,
          '& label:not(.Mui-focused)': {
            color: '#949494',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px',
          },
        },
      }}
      getOptionLabel={(item) => item.name}
      getOptionValue={(item) => item.value}
      options={rateTypeLabels}
    />
  );
});

export { RateTypeSelector };
