export interface FormInputProps {
  name: string;
  label: string;
  type: string;
  showIcon?: boolean;
  id?: string;
}

export const inputTypes = {
  email: 'email',
  password: 'password',
  passwordConfirmation: 'passwordConfirmation',
  text: 'text',
};
export interface ResetSignupProps {
  password: string;
  passwordConfirmation: string;
}
