import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';

import { OverflowAwareText } from '~components/Order/ordersDispatchStyledComponents';
import { Driver } from '~hooks/useDrivers';
import { hexToRgba } from '~utils/utilFunctions';

interface DriverCardProps {
  driver: Driver;
  style?: CSSProperties;
  jobCount?: number;
}

export const DriverCard = ({ driver, style, jobCount = 0 }: DriverCardProps) => {
  const theme = useTheme();
  const { firstName, lastName, equipment, company } = driver;

  const equipmentNumber = equipment?.companyShare?.externalId || equipment?.externalId;
  const truckType = equipment?.equipmentType?.name;
  const fullName = `${firstName} ${lastName}`;
  const vendor = company?.legalName;
  return (
    <Box
      sx={{
        ...style,
        background: (theme) => hexToRgba(theme.brandV2.colors.treadGray8, 0.5),
        p: '12px 16px',
        borderRight: `1px solid ${theme.brandV2.colors.treadGray7}`,
        borderBottom: `1px solid ${theme.brandV2.colors.treadGray7}`,
      }}
      aria-label={'driver-card'}
    >
      <Box position={'relative'}>
        <Box display={'flex'} alignItems={'center'}>
          {equipmentNumber && (
            <Typography variant={'body2'} sx={{ fontWeight: 600, mr: 0.5 }}>
              {equipmentNumber}
            </Typography>
          )}

          <OverflowAwareText sx={{ width: 'auto' }}>{fullName}</OverflowAwareText>

          <Typography variant={'body2'} sx={{ ml: 0.5, mr: 0.5 }}>
            •
          </Typography>
          <Typography variant={'body2'}>{jobCount}</Typography>
        </Box>
        {truckType && (
          <Box mt={0.5}>
            <OverflowAwareText sx={{ color: theme.brandV2.colors.treadGray2 }}>
              {truckType}
            </OverflowAwareText>
          </Box>
        )}

        {vendor && (
          <Box mt={0.5}>
            <OverflowAwareText sx={{ color: theme.brandV2.colors.treadGray2 }}>
              {vendor}
            </OverflowAwareText>
          </Box>
        )}
      </Box>
    </Box>
  );
};
