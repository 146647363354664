import {
  DriverDay_Read,
  MaterialDeliveredQuantity,
  ShiftAdjustmentType,
} from '@treadinc/horizon-api-spec';

import { CompanyBasic } from '~hooks/useCompany/models';
import { DriverBasic } from '~hooks/useDrivers/models';

export class DriverDay {
  public static parse({
    id,
    started_shift_at,
    ended_shift_at,
    jobs_count,
    loads_count,
    tickets_count,
    first_geofence_entry_at,
    last_geofence_exit_at,
    first_ticket_at,
    last_ticket_at,
    total_break_minutes,
    total_gps_minutes,
    total_delivered_quantities,
    driver,
    company,
    vendor_account,
    equipment_external_ids,
    driver_day_date,
    created_at,
    updated_at,
    total_shift_minutes,
    shift_adjustment_minutes,
    shift_adjustment_type,
  }: DriverDay_Read): DriverDay {
    return new DriverDay(
      id,
      started_shift_at ? new Date(started_shift_at) : null,
      ended_shift_at ? new Date(ended_shift_at) : null,
      jobs_count,
      loads_count,
      tickets_count,
      first_geofence_entry_at ? new Date(first_geofence_entry_at) : null,
      last_geofence_exit_at ? new Date(last_geofence_exit_at) : null,
      first_ticket_at ? new Date(first_ticket_at) : null,
      last_ticket_at ? new Date(last_ticket_at) : null,
      total_break_minutes,
      total_gps_minutes,
      total_delivered_quantities,
      DriverBasic.parse(driver),
      CompanyBasic.parse(company),
      vendor_account?.name ?? null,
      equipment_external_ids,
      new Date(driver_day_date),
      new Date(created_at),
      new Date(updated_at),
      total_shift_minutes,
      shift_adjustment_minutes,
      shift_adjustment_type,
    );
  }

  public get id() {
    return this._id;
  }

  public get startedShiftAt() {
    return this._started_shift_at;
  }

  public get endedShiftAt() {
    return this._ended_shift_at;
  }

  public get jobCount() {
    return this._jobs_count;
  }

  public get loadsCount() {
    return this._loads_count;
  }

  public get ticketsCount() {
    return this._tickets_count;
  }

  public get firstGeofenceEntryAt() {
    return this._first_geofence_entry_at;
  }

  public get lastGeofenceExitAt() {
    return this._last_geofence_exit_at;
  }

  public get firstTicketAt() {
    return this._first_ticket_at;
  }

  public get lastTicketAt() {
    return this._last_ticket_at;
  }

  public get totalBreakMinutes() {
    return this._total_break_minutes;
  }

  public get totalGpsMinutes() {
    return this._total_gps_minutes;
  }

  public get totalDeliveredQuantities() {
    return this._total_delivered_quantities;
  }

  public get driver() {
    return this._driver;
  }

  public get company() {
    return this._company;
  }

  public get vendorName() {
    return this._vendor_name;
  }

  public get equipmentExternalIds() {
    return this._equipment_external_ids;
  }

  public get driverDayDate() {
    return this._driver_day_date;
  }

  public get createdAt() {
    return this._created_at;
  }

  public get updatedAt() {
    return this._updated_at;
  }

  public get totalShiftMinutes() {
    return this._total_shift_minutes;
  }

  public get shiftAdjustmentMinutes() {
    return this._shift_adjustment_minutes;
  }

  public get shiftAdjustmentType() {
    return this._shift_adjustment_type;
  }

  constructor(
    private _id: string,
    private _started_shift_at: Date | null,
    private _ended_shift_at: Date | null,
    private _jobs_count: number,
    private _loads_count: number,
    private _tickets_count: number,
    private _first_geofence_entry_at: Date | null,
    private _last_geofence_exit_at: Date | null,
    private _first_ticket_at: Date | null,
    private _last_ticket_at: Date | null,
    private _total_break_minutes: number,
    private _total_gps_minutes: number,
    private _total_delivered_quantities: MaterialDeliveredQuantity[],
    private _driver: DriverBasic,
    private _company: CompanyBasic,
    private _vendor_name: string | null,
    private _equipment_external_ids: string[],
    private _driver_day_date: Date,
    private _created_at: Date,
    private _updated_at: Date,
    private _total_shift_minutes: number,
    private _shift_adjustment_minutes: number,
    private _shift_adjustment_type: ShiftAdjustmentType | null,
  ) {}
}
