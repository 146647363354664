import React from 'react';

interface CustomToastContentProps {
  title: string;
  body: string;
}

const CustomToastContent: React.FC<CustomToastContentProps> = ({ title, body }) => {
  return (
    <div>
      <strong>{title}</strong>
      <div>{body}</div>
    </div>
  );
};

export default CustomToastContent;
