import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import theme from '~theme/AppTheme';

interface ButtonItemProps {
  title: string;
  value: string;
  action: () => void;
}
interface QuickFilterButtonGroupProps {
  buttons: Array<ButtonItemProps>;
  currentActiveFilter?: string;
  color?: string;
}
const QuickFilterButtonGroup = ({
  buttons,
  currentActiveFilter,
  color,
}: QuickFilterButtonGroupProps) => {
  return (
    <ButtonGroup size="small" aria-label="small button group">
      {buttons.map((item) => {
        const isActive = currentActiveFilter === item.value;
        return (
          <Button
            sx={{
              height: '40px',
              marginTop: '4px',
              backgroundColor: isActive ? color : theme.palette.secondary.contrastText,
              color: isActive ? 'secondary.main' : 'action.disabled',
              border: `1px solid rgba(0,0,0,0.23)`,
            }}
            variant={isActive ? 'outlined' : 'outlined'}
            key={item?.title}
            onClick={() => {
              item?.action();
            }}
          >
            {item.title}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
export { QuickFilterButtonGroup };
