import { useAgaveLink } from '@agave-api/react-agave-link';
import Add from '@mui/icons-material/Add';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import Save from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { FeatureFlags } from '~constants/featureFlags';
import { AgaveLinkedAccount } from '~hooks/useAgave/models';
import useAgave from '~hooks/useAgave/useAgave';
import { Pagination, PaginationLink } from '~interfaces';
import * as colsDef from '~pages/Settings/Integrations/agaveManagementDataGridColumnsDefinition';
import { routes } from '~router';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { useAwaitableFeatureFlag } from '~utils/hooks/useFeatureFlag';

const AgaveManagement = observer(() => {
  const { userStore } = useStores();
  const navigate = useNavigate();

  const userCanManageAccountingIntegration =
    userStore.getPermissions().canManageAccountingIntegration;

  const agaveIntegrationEnabledFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.agaveIntegrationEnabled,
  });

  const hasAccess =
    userCanManageAccountingIntegration &&
    agaveIntegrationEnabledFeatureFlag.isReady &&
    agaveIntegrationEnabledFeatureFlag.isFeatureFlagEnabled;

  if (!hasAccess) {
    navigate(routes.base);
  }

  return <AgaveManagementGrid />;
});

const AgaveManagementGrid = observer(() => {
  const { toasterStore, agaveIntegrationStore } = useStores();
  const { getLinkedAccounts, deleteLinkedAccount } = useAgave();
  const pagination = useRef<Pagination>({ limit: 25, page: 1 });

  const createLinkedAccount = useCreateAgaveLinkedAccount();

  const isDeletingLinkedAccount = JSON.stringify(
    Object.fromEntries(agaveIntegrationStore.isDeletingLinkedAccount),
  );

  const handleDeleteLinkedAccountClick = useCallback(async (linkedAccountId: string) => {
    try {
      agaveIntegrationStore.deleteLinkedAccountStart(linkedAccountId);
      await deleteLinkedAccount(linkedAccountId);
      agaveIntegrationStore.deleteLinkedAccountEnd(linkedAccountId, true);
      toasterStore.push(
        alert(
          t('administration.integration.agave.grid.linked_account_deleted'),
          AlertTypes.success,
        ),
        false,
        true,
      );
    } catch {
      agaveIntegrationStore.deleteLinkedAccountEnd(linkedAccountId);
      toasterStore.push(
        alert(
          t('error_messages.agave.failed_to_delete_linked_account'),
          AlertTypes.error,
        ),
        false,
        true,
      );
    }
  }, []);

  const fetchLinkedAccounts = async () => {
    try {
      agaveIntegrationStore.fetchLinkedAccountsStart();

      const response = await getLinkedAccounts(pagination.current);

      if (response) {
        agaveIntegrationStore.fetchLinkedAccountsEnd(response.data);

        pagination.current = {
          ...pagination.current,
          before: '',
          after: '',
          ...response.pagination,
        };
      } else {
        throw new Error();
      }
    } catch {
      agaveIntegrationStore.fetchLinkedAccountsEnd();
      toasterStore.push(
        alert(
          t('error_messages.agave.failed_to_fetch_linked_accounts'),
          AlertTypes.error,
        ),
        false,
        true,
      );
    }
  };

  const fetchPreviousPage = () => {
    pagination.current = _.pick(pagination.current, ['page', 'limit', 'before']);
    pagination.current.page -= 1;

    fetchLinkedAccounts();
  };

  const fetchNextPage = () => {
    pagination.current = _.pick(pagination.current, ['page', 'limit', 'after']);
    pagination.current.page += 1;

    fetchLinkedAccounts();
  };

  const columns = useMemo(
    () => [
      colsDef.sourceSystemNameColDef,
      colsDef.sourceSystemSlugColDef,
      colsDef.statusColDef,
      colsDef.actionsColDef(() => (
        <HeaderNavigation
          count={(agaveIntegrationStore.linkedAccounts ?? []).length}
          loading={agaveIntegrationStore.isLoadingLinkedAccounts}
          pagination={pagination.current}
          callback={(ref: PaginationLink) => {
            if (ref === 'before') {
              fetchPreviousPage();
            } else {
              fetchNextPage();
            }
          }}
        />
      )),
    ],
    [
      agaveIntegrationStore.linkedAccounts?.length,
      agaveIntegrationStore.isLoadingLinkedAccounts,
    ],
  );

  const rows = useMemo(() => {
    return (agaveIntegrationStore.linkedAccounts ?? []).map((linkedAccount) => {
      const rows: colsDef.AgaveManagementRowDef['row'] = {
        id: linkedAccount.id,
        isDeleting: agaveIntegrationStore.isDeletingLinkedAccount.get(linkedAccount.id),
        linkedAccount,
        onDelete: () => handleDeleteLinkedAccountClick(linkedAccount.id),
      };

      return rows;
    });
  }, [
    JSON.stringify(agaveIntegrationStore.linkedAccounts),
    handleDeleteLinkedAccountClick,
    isDeletingLinkedAccount,
  ]);

  useEffect(() => {
    fetchLinkedAccounts();
  }, []);

  return (
    <Box maxWidth="1000px">
      <Button
        color="secondary"
        component={Link}
        startIcon={<ArrowBackIosNew fontSize="small" />}
        to={`/${routes.settings.base}/${routes.settings.integrations.base}`}
        variant="text"
      >
        {t('administration.integration.title')}
      </Button>

      <CardContent>
        <Box display="flex" flexDirection="row">
          <Typography variant="h5">
            {t(`administration.integration.agave.name`)}
          </Typography>
        </Box>
      </CardContent>

      <DataGrid
        id="agave-management-grid"
        columns={columns}
        disableColumnFilter
        hideQuickFilter
        loading={agaveIntegrationStore.isLoadingLinkedAccounts}
        rows={rows}
        headerActionsComponent={
          <LoadingButton
            color="primary"
            loading={agaveIntegrationStore.isSavingLinkedAccount}
            loadingPosition="start"
            onClick={createLinkedAccount}
            size="medium"
            startIcon={agaveIntegrationStore.isSavingLinkedAccount ? <Save /> : <Add />}
          >
            {t('administration.integration.agave.grid.create_linked_account')}
          </LoadingButton>
        }
      />
    </Box>
  );
});

function useCreateAgaveLinkedAccount() {
  const { toasterStore, agaveIntegrationStore } = useStores();
  const { createLinkedAccount, getLinkToken } = useAgave();

  const [linkToken, setLinkToken] = useState('');

  const onSuccess = (token: string) => {
    createLinkedAccount(token)
      .then((response) => {
        if (response) {
          const linkedAccount = AgaveLinkedAccount.parse(response);

          agaveIntegrationStore.createLinkedAccountEnd(linkedAccount);
          refreshToken();
          toasterStore.push(
            alert(
              t('administration.integration.agave.grid.linked_account_created'),
              AlertTypes.success,
            ),
            false,
            true,
          );
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        agaveIntegrationStore.createLinkedAccountEnd();
        refreshToken();
        toasterStore.push(
          alert(
            t('error_messages.agave.failed_to_create_linked_account'),
            AlertTypes.error,
          ),
          false,
          true,
        );
      });
  };

  const onExit = (error?: string) => {
    agaveIntegrationStore.createLinkedAccountEnd();
    refreshToken();

    if (error) {
      console.error('Failed to create Agave Linked Account', error);
      toasterStore.push(
        alert(
          t('error_messages.agave.failed_to_create_linked_account'),
          AlertTypes.error,
        ),
        false,
        true,
      );
    }
  };

  const refreshToken = async () => {
    try {
      const token = await getLinkToken();

      if (token) {
        setLinkToken(token);
      } else {
        throw new Error();
      }
    } catch {
      toasterStore.push(
        alert(t('error_messages.agave.failed_to_fetch_link_token'), AlertTypes.error),
        false,
        true,
      );
    }
  };

  const { isReady, openLink } = useAgaveLink({ linkToken, onSuccess, onExit });

  const createAgaveLinkedAccount = useCallback(() => {
    if (isReady) {
      agaveIntegrationStore.createLinkedAccountStart();
      openLink();
    }
  }, [isReady, openLink]);

  useEffect(() => {
    refreshToken();
  }, []);

  return createAgaveLinkedAccount;
}

export default AgaveManagement;
