import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { t } from 'i18next';

import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { SettlementLineItemGroup } from '~hooks/useSettlementLineItems';

export interface TotalsProps {
  lineItemGroups: SettlementLineItemGroup[];
}

export default function Totals({ lineItemGroups }: TotalsProps) {
  const { currencyFormatter } = useCompanyCurrency();

  const styles = StyleSheet.create({
    root: {
      backgroundColor: '#F6F6F6',
      display: 'flex',
      paddingBottom: 8,
      paddingHorizontal: 8,
      paddingTop: 3,
      width: '100%',
      marginTop: lineItemGroups.some((group) => group.group) ? 20 : 0,
    },
    line: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      fontSize: 9,
      justifyContent: 'space-between',
      marginTop: 5,
      width: '100%',
    },
    bold: {
      fontWeight: 'bold',
    },
    totalLine: {
      fontSize: 11,
      fontWeight: 'bold',
      marginTop: 7,
    },
  });

  const subtotal = lineItemGroups.reduce((sum, group) => (sum += group.subtotal ?? 0), 0);
  const addOns = 0;
  const total = subtotal + addOns;

  return (
    <View style={styles.root}>
      <View style={styles.line}>
        <Text>{t('approvals.driver_pay.settlements.pdf.subtotal')}</Text>
        <Text style={styles.bold}>{currencyFormatter(subtotal)}</Text>
      </View>

      <View style={styles.line}>
        <Text>{t('approvals.driver_pay.settlements.pdf.add_ons')}</Text>
        <Text style={styles.bold}>{currencyFormatter(0)}</Text>
      </View>

      <View style={[styles.line, styles.totalLine]}>
        <Text>{t('approvals.driver_pay.settlements.pdf.total')}</Text>
        <Text style={styles.bold}>{currencyFormatter(total)}</Text>
      </View>
    </View>
  );
}
