import Box from '@mui/material/Box';
import React, { memo } from 'react';

type SpeedSegmentProps = {
  position: string;
  speed: number;
  maxSpeed: number;
};

const SpeedSegment = ({ position, speed, maxSpeed }: SpeedSegmentProps) => {
  const speedTop = `${(1 - speed / maxSpeed) * 100}%`;

  return (
    <Box
      position={'absolute'}
      left={position}
      top={speedTop}
      width={'1px'}
      bottom={0}
      sx={{
        backgroundColor: '#78B2FF',
      }}
    />
  );
};

export default memo<SpeedSegmentProps>(SpeedSegment);
