import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import Sync from '@mui/icons-material/Sync';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography, { TypographyOwnProps } from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import { t } from 'i18next';
import _ from 'lodash';
import React from 'react';

import { SimpleMenu } from '~components/Menu/SimpleMenu';
import SettlementState from '~components/Settlements/SettlementState';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Settlement } from '~hooks/useSettlements';
import { dateFormat } from '~utils/dateTime';

const CellText = styled(Typography)<TypographyOwnProps>(() => ({
  '&.MuiTypography-root': {
    fontSize: '10px',
  },
}));

type CurrencyFormatterFn = ReturnType<typeof useCompanyCurrency>['currencyFormatter'];

type SettlementsRowDefUtils = {
  currencyFormatter: CurrencyFormatterFn;
  onEditButtonClick: (settlement: Settlement) => void;
  onExportToPDFButtonClick: (settlement: Settlement) => void;
};

export type SettlementsRowDef = {
  row: {
    id: string;
    isGeneratingPdfForSettlementId?: string;
    settlement: Settlement;
    utils: SettlementsRowDefUtils;
  };
};

export const createdDateColDef: GridColDef = {
  flex: 1,
  field: 'createdDate',
  headerName: `${t('common.created_date')}`,
  valueGetter: ({ row }: SettlementsRowDef) => row.settlement.createdAt.toISOString(),
  renderCell: ({ row }: SettlementsRowDef) => {
    return (
      <CellText>{dateFormat(row.settlement.createdAt, 'hh:mm A MM/DD/YY')}</CellText>
    );
  },
};

export const settlementIdColDef: GridColDef = {
  flex: 1,
  field: 'settlementId',
  headerName: `${t('approvals.driver_pay.settlements.settlement_id')}`,
  valueGetter: ({ row }: SettlementsRowDef) => row.settlement.settlementId,
  renderCell: ({ row }: SettlementsRowDef) => {
    return <CellText>{row.settlement.settlementId}</CellText>;
  },
};

export const forColDef: GridColDef = {
  flex: 1,
  field: 'for',
  headerName: `${t('approvals.driver_pay.settlements.for')}`,
  valueGetter: ({ row }: SettlementsRowDef) => {
    const { driver, customerAccount, vendorAccount } = row.settlement;

    return driver?.fullName ?? customerAccount?.name ?? vendorAccount?.name;
  },
  renderCell: ({ row }: SettlementsRowDef) => {
    const { driver, customerAccount, vendorAccount } = row.settlement;

    if (driver) {
      return <CellText>{driver.fullName}</CellText>;
    }

    if (customerAccount) {
      return <CellText>{customerAccount.name}</CellText>;
    }

    if (vendorAccount) {
      return <CellText>{vendorAccount.name}</CellText>;
    }
  },
};

export const orderIdsColDef: GridColDef = {
  flex: 1,
  field: 'orderIds',
  headerName: `${t('approvals.driver_pay.settlements.order_ids')}`,
  valueGetter: ({ row }: SettlementsRowDef) => {
    return row.settlement.data.friendlyOrderIds?.sort().join(', ');
  },
  renderCell: ({ row }: SettlementsRowDef) => {
    return <CellText>{row.settlement.data.friendlyOrderIds?.sort().join(', ')}</CellText>;
  },
};

export const jobsColDef: GridColDef = {
  flex: 1,
  field: 'jobs',
  headerName: `${t('form_fields.jobs')}`,
  valueGetter: ({ row }: SettlementsRowDef) => row.settlement.jobsCount,
  renderCell: ({ row }: SettlementsRowDef) => {
    return <CellText>{row.settlement.jobsCount}</CellText>;
  },
};

export const qtysAndUomsColDef: GridColDef = {
  flex: 1,
  field: 'qtysAndUoms',
  headerName: `${t('approvals.driver_pay.settlements.qtys_and_uoms')}`,
  valueGetter: ({ row }: SettlementsRowDef) => {
    return row.settlement.data.quantitiesPerUom?.join(', ');
  },
  renderCell: ({ row }: SettlementsRowDef) => {
    return <CellText>{row.settlement.data.quantitiesPerUom?.join(', ')}</CellText>;
  },
};

export const adjustmentsColDef: GridColDef = {
  flex: 1,
  field: 'adjustments',
  headerName: `${t('approvals.driver_pay.settlements.adjustments')}`,
  valueGetter: ({ row }: SettlementsRowDef) => row.settlement.adjustmentsTotalAmount,
  renderCell: ({ row }: SettlementsRowDef) => {
    return (
      <CellText>
        {row.utils.currencyFormatter(row.settlement.adjustmentsTotalAmount)}
      </CellText>
    );
  },
};

export const totalColDef: GridColDef = {
  flex: 1,
  field: 'total',
  headerName: `${t('common.total')}`,
  valueGetter: ({ row }: SettlementsRowDef) => row.settlement.totalAmount,
  renderCell: ({ row }: SettlementsRowDef) => {
    return <CellText>{row.utils.currencyFormatter(row.settlement.totalAmount)}</CellText>;
  },
};

export const stateColDef: GridColDef = {
  flex: 1,
  minWidth: 125,
  field: 'state',
  headerName: `${t('form_fields.status')}`,
  valueGetter: ({ row }: SettlementsRowDef) => row.settlement.state,
  renderCell: ({ row }: SettlementsRowDef) => {
    return <SettlementState state={row.settlement.state} />;
  },
};

export const exportedColDef: GridColDef = {
  flex: 1,
  field: 'exported',
  headerName: `${t('approvals.driver_pay.tabs.exported')}`,
  valueGetter: ({ row }: SettlementsRowDef) => row.settlement.exportedAt?.toISOString(),
  renderCell: ({ row }: SettlementsRowDef) => {
    if (row.settlement.exportedAt) {
      return (
        <CellText>{dateFormat(row.settlement.exportedAt, 'hh:mm A MM/DD/YY')}</CellText>
      );
    }
  },
};

export const actionsColDef = (renderHeader: () => React.ReactNode): GridColDef => ({
  width: 96,
  field: 'actions',
  headerName: `${t('actions.actions')}`,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  hideable: false,
  renderHeader,
  renderCell: ({ row }: SettlementsRowDef) => {
    const actions = [
      {
        callBack: () => row.utils.onExportToPDFButtonClick(row.settlement),
        icon: <PictureAsPdf />,
        title: t('approvals.driver_pay.settlements.generate_pdf'),
      },
    ];

    const isGeneratingPdf = !_.isNil(row.isGeneratingPdfForSettlementId);
    const isGeneratingPdfForThisSettlement =
      row.isGeneratingPdfForSettlementId &&
      row.isGeneratingPdfForSettlementId === String(row.settlement.id);

    return (
      <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <IconButton
          onClick={() => row.utils.onEditButtonClick(row.settlement)}
          size="small"
        >
          <Edit />
        </IconButton>

        <SimpleMenu options={actions} disabled={isGeneratingPdf}>
          {isGeneratingPdfForThisSettlement ? (
            <Sync
              sx={{
                '@keyframes generate-pdf-spinner': {
                  from: { transform: 'rotate(0deg)' },
                  to: { transform: 'rotate(-360deg)' },
                },
                animation: 'generate-pdf-spinner 2s linear infinite',
              }}
            />
          ) : (
            <MoreHoriz />
          )}
        </SimpleMenu>
      </Box>
    );
  },
});
