import * as React from 'react';
import { SVGProps } from 'react';
interface GeoFenceMovingIconProps extends SVGProps<SVGSVGElement> {
  textFill?: string;
}
export const GeoFenceMovingIcon = ({
  textFill = '#00A11A',
  ...props
}: GeoFenceMovingIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="6" cy="15" r="2.25" stroke={textFill} strokeWidth="1.5" />
    <circle cx="14" cy="14" r="3.25" stroke={textFill} strokeWidth="1.5" />
    <path d="M8.66669 15.3335H10.6667" stroke={textFill} strokeWidth="1.5" />
    <path d="M6 3.77783L6 2.00005" stroke={textFill} strokeWidth="1.5" />
    <path
      d="M3.99998 15.3333H2.79999L2.79999 11L4.99998 8.5L9.49998 8.5L12.5 11"
      stroke={textFill}
      strokeWidth="1.5"
    />
    <path d="M5 8.57143V4H9.5V9" stroke={textFill} strokeWidth="1.5" />
  </svg>
);
