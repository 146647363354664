import { FormErrorType } from '../types';
export class FormError {
  public static parse(proto: FormErrorType): FormErrorType {
    return new FormError(proto.message);
  }

  public get message(): string {
    return this._message;
  }

  constructor(private _message: string) {}
}
