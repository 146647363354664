import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { t } from 'i18next';
import { useCallback, useState } from 'react';

import { Order } from '~hooks/useOrders';
import TreadLiveMap from '~pages/LiveMap/TreadLiveMap';
import theme from '~theme/AppTheme';

import { OrderDetails } from '../OrderDetails';
import JobsTable from './JobsTable';
import OrderNotes from './OrderNotes';

interface OrderDispatchCardExpandedBlockProps {
  checkedJobs?: string[];
  onCheckedJobStateChange?: (jobId: string) => void;
  order: Order;
}

enum OrderDispatchCardExpandedBlockTab {
  JOBS,
  ORDER_DETAILS,
  LIVE_MAP,
}

export default function OrderDispatchCardExpandedBlock({
  checkedJobs,
  onCheckedJobStateChange,
  order,
}: OrderDispatchCardExpandedBlockProps) {
  const [selectedTab, setSelectedTab] = useState(OrderDispatchCardExpandedBlockTab.JOBS);

  const handleSelectedTabChange = useCallback(
    (__: React.SyntheticEvent, value: OrderDispatchCardExpandedBlockTab) => {
      setSelectedTab(value);
    },
    [],
  );

  return (
    <Box>
      <OrderNotes order={order} />

      <Tabs
        value={selectedTab}
        onChange={handleSelectedTabChange}
        sx={{
          '&.MuiTabs-root': { minHeight: 'auto', px: 2, mt: 2 },
          '& .MuiTabs-flexContainer': { gap: 1 },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.brandV2.colors.treadBlack,
            borderRadius: 0,
            height: '2px',
          },
          '& .MuiTab-root': {
            fontSize: '12px',
            lineHeight: '32px',
            minHeight: 'auto',
            minWidth: 'auto',
            px: 1,
            py: 0,
            '&.Mui-selected': {
              color: theme.brandV2.colors.treadBlack,
            },
          },
        }}
      >
        <Tab label={t('form_fields.jobs')} />
        <Tab label={t('order.form.order_details')} />
        <Tab label={t('live_map.live_map')} />
      </Tabs>

      <Box>
        {selectedTab === OrderDispatchCardExpandedBlockTab.JOBS && (
          <JobsTable
            order={order}
            checkedJobs={checkedJobs}
            onCheckedStateChange={onCheckedJobStateChange}
          />
        )}

        {selectedTab === OrderDispatchCardExpandedBlockTab.ORDER_DETAILS && (
          <Box p={1}>
            <OrderDetails order={order} />
          </Box>
        )}

        {selectedTab === OrderDispatchCardExpandedBlockTab.LIVE_MAP && (
          <Box
            m={1}
            borderRadius={1}
            overflow={'hidden'}
            border={1}
            sx={{
              background: '#fff',
              height: 400,
              borderColor: theme.brandV2.colors.treadGray7,
            }}
          >
            <TreadLiveMap order={order} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
