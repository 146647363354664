import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';

import { useProjects } from '~hooks/useProjects';
import { useStores } from '~store';

import DispatchSearchParam from './DispatchSearchParam';
import { TypeaheadSelector } from './TypeaheadSelector';

interface Props {
  allSearchParams: Record<string, any>;
  sx?: SxProps<Theme>; // Style
}

const ProjectSelector = observer(({ allSearchParams, sx = {} }: Props) => {
  const { userStore } = useStores();
  const { getProjectById, getProjectsByCompanyIdTypeahead } = useProjects();

  const fetchProjectsByCompanyId = async (additionalProps = {}) => {
    const company = userStore.userCompany;

    if (!company) {
      return [];
    }

    const projects = await getProjectsByCompanyIdTypeahead({
      companyId: company.id,
      ...additionalProps,
    });

    return projects;
  };

  return (
    <>
      <Box sx={{ ...sx }}>
        <TypeaheadSelector
          asyncGet={getProjectById}
          asyncCallback={fetchProjectsByCompanyId}
          fieldName={DispatchSearchParam.PROJECT}
          label={$t('form_fields.project')}
          defaultValues={[]}
          allSearchParams={allSearchParams}
          sx={{
            ...sx,
            ...{
              width: '150px',
              fontSize: '12px',
              mr: 1,
              '& label:not(.Mui-focused)': {
                color: '#000',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '22px',
              },
            },
          }}
          getOptionLabel={(item) => item.name || ''}
        />
      </Box>
    </>
  );
});

export { ProjectSelector };
