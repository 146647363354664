import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

import { PageTitle } from '~components/typographyTitles/PageTitle';
import { useStores } from '~store';
import UserStore from '~store/UserStore';

import AutoOrderCompletionSetting from './components/AutoOrderCompletionSetting';
import GeofenceCreationSetting from './components/GeofenceCreationSetting';

type SettingDefinition = {
  element: {
    component: () => JSX.Element;
    canAccess?: (userStore: UserStore) => boolean;
  };
  label: string;
};

const settings: SettingDefinition[] = [
  {
    element: AutoOrderCompletionSetting,
    label: t('administration.configuration.settings.auto_order_completion.setting_title'),
  },
  {
    element: GeofenceCreationSetting,
    label: t('administration.configuration.settings.geofence_creation.setting_title'),
  },
];

export const userHasAccessToConfigurationManagement = (userStore: UserStore) => {
  return settings.some((item) => {
    return item.element.canAccess?.(userStore) ?? true;
  });
};

export default function ConfigurationManagement() {
  const { userStore } = useStores();

  return (
    <Box maxWidth="800px">
      <PageTitle text={`${t('administration.configuration.title')}`} />

      <Box mt={5} display="flex" flexDirection="column" gap={3}>
        {settings.map((setting) => {
          const hasAccess = setting.element.canAccess?.(userStore) ?? true;

          return hasAccess ? (
            <Box key={setting.label}>
              <Typography variant="h6" fontWeight="normal">
                {setting.label}
              </Typography>

              <setting.element.component />

              <Divider sx={{ mt: 2 }} />
            </Box>
          ) : null;
        })}
      </Box>
    </Box>
  );
}
