import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import { AccountType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import FilterMenuItem, {
  FilterMenuItemLoadingReason,
} from '~components/Filters/FilterMenuItem';
import Menu from '~components/Menu/Menu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { Account, useAccount } from '~hooks/useAccount';
import theme from '~theme/AppTheme';

import { CONTROLS_HEIGHT_IN_PX } from '../CopyVendorAssignmentsForm';

interface CustomerSelectorProps {
  companyId: string;
  onChange: (customerAccountIds: string[]) => void;
}

export default function CustomerSelector({ companyId, onChange }: CustomerSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>();

  const { getAccountsByCompanyIdTypeahead } = useAccount();
  const [queryValue, setQueryValue] = useState('');
  const [fetchAccountsState, setFetchAccountsState] = useState<{
    cursor?: string;
    data: Account[];
    hasMore?: boolean;
    loadingReason?: `${FilterMenuItemLoadingReason}`;
    query: string;
  }>({ data: [], query: '', loadingReason: 'search_value' });

  const applyDebouncedFilter = _.debounce((callback: () => void) => {
    callback();
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  const getAccounts = async () => {
    try {
      const response = await getAccountsByCompanyIdTypeahead({
        accountTypes: [AccountType.CUSTOMER],
        companyId,
        cursor: fetchAccountsState.cursor,
        query: fetchAccountsState.query,
        searchParams: { limit: 10 },
      });

      if (!response) {
        throw new Error('Unable to fetch customers.');
      }

      const { data, pagination } = response;

      setFetchAccountsState((state) => ({
        ...state,
        cursor: pagination.after,
        data: state.data.concat(data),
        hasMore: Boolean(pagination.after),
        loadingReason: undefined,
      }));
    } catch (error) {
      console.error(error);
      throw new Error('Unable to fetch customers.');
    }
  };

  useEffect(() => {
    if (isOpen && fetchAccountsState.loadingReason) {
      getAccounts();
    }
  }, [isOpen, fetchAccountsState.loadingReason]);

  useEffect(() => {
    if (selectedAccountIds) {
      onChange(selectedAccountIds);
    }
  }, [onChange, selectedAccountIds]);

  useEffect(() => {
    applyDebouncedFilter(() => {
      setFetchAccountsState((state) => ({
        ...state,
        data: [],
        query: queryValue,
        loadingReason: 'search_value',
      }));
      setSelectedAccountIds(undefined);
    });

    return () => {
      applyDebouncedFilter.cancel();
    };
  }, [queryValue]);

  return (
    <Box flex={0}>
      <Menu
        sx={{ '& .MuiPaper-root': { width: '250px' } }}
        onOpenStateChanged={setIsOpen}
        menuTrigger={
          <SmallButton
            size="small"
            endIcon={
              <KeyboardArrowDown sx={{ color: theme.brandV2.colors.treadGray4 }} />
            }
            sx={{
              '&.MuiButton-root': {
                backgroundColor: 'white',
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                borderRadius: theme.brandV2.borderRadius,
                fontWeight: 400,
                height: CONTROLS_HEIGHT_IN_PX,
              },
            }}
          >
            {t('form_fields.select_customer')}
          </SmallButton>
        }
      >
        <FilterMenuItem
          focusSearchFieldOnMount
          searchValue={queryValue}
          onSearchValueChange={setQueryValue}
          loadingReason={fetchAccountsState.loadingReason}
          selectAllOptionLabel={`${t('dispatch.dispatch_v2.filters.all_entities', { entity: t(`dispatch.dispatch_v2.filters.customers`) })}`}
          options={fetchAccountsState.data.map((account) => ({
            label: account.name,
            value: account.id,
          }))}
          selectedOptions={selectedAccountIds ?? []}
          onSelectedOptionsChange={setSelectedAccountIds}
          onFetchMore={
            fetchAccountsState.hasMore
              ? () => {
                  setFetchAccountsState((state) => ({
                    ...state,
                    loadingReason: 'infinite_scroll',
                  }));
                }
              : undefined
          }
        />
      </Menu>
    </Box>
  );
}
