import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useRef } from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import { Order } from '~hooks/useOrders';

interface MoreOrdersMenuProps {
  orders: Order[];
  selectedOrder: Order;
  onChange?: (newOrder: Order) => void;
}
export const MoreOrdersMenu = (props: MoreOrdersMenuProps) => {
  const { orders, selectedOrder, onChange } = props;
  const theme = useTheme();
  const menuHandler = useRef<MenuHandler>(null);

  return (
    <>
      <Menu
        ref={menuHandler}
        menuTrigger={
          orders?.length > 1 ? (
            <IconButton
              size="small"
              sx={{
                '&.MuiIconButton-root': {
                  border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                  borderRadius: theme.spacing(0.5),
                  color: theme.brandV2.colors.treadBlack,
                  px: '6px',
                  py: '0px',
                },
              }}
            >
              <Typography variant="subtitle1SemiBold">
                {t('dispatch.dispatch_v2.calendar.more_orders', {
                  count: orders.length - 1,
                })}
              </Typography>
            </IconButton>
          ) : (
            <></>
          )
        }
      >
        {orders.map((state) => (
          <MenuItem
            key={state.orderId}
            onClick={() => {
              onChange?.(state);
              menuHandler.current?.onClose?.();
            }}
          >
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Typography
                color={theme.brandV2.colors.treadBlack}
                fontSize="12px"
                fontWeight={600}
              >
                {state.orderId}
              </Typography>

              {selectedOrder.orderId === state.orderId && (
                <Check sx={{ fontSize: '16px' }} />
              )}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
