import {
  ApproachType,
  AvoidType,
  DistanceMatrixService,
  GeometryType,
  TravelMode,
} from '@nbai/nbmap-gl';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TORONTO_OFFICE_COORDINATES } from '~constants/mapConsts';
import { AddressItem } from '~hooks/useAddress';
import { LatLng, LatLngType } from '~interfaces/map';
import { useStores } from '~store';

type NBParams = {
  query: string;
};

interface DistanceParams {
  origins: LatLng[];
  destinations: LatLng[];
  departureTime?: Date;
  mode?: TravelMode;
  avoid?: AvoidType;
  approaches?: ApproachType[];
}

interface AssetLocationParams {
  assetId: string;
  startTime: number;
  endTime: number;
  geometryType: GeometryType;
  pageNumber?: number;
}

interface AssetIdParams {
  userId: string;
}

export const useGecode = () => {
  const token = import.meta.env.TREAD__NEXTBILLION_KEY;
  const baseUrl = 'https://api.nextbillion.io';
  const distanceMatrixService = useRef(new DistanceMatrixService());
  const $http = axios.create();

  const [isLoading, setIsLoading] = useState(false);
  const controller = new AbortController();
  const [startSearchPosition, setStartSearchPosition] = useState<LatLng>(
    TORONTO_OFFICE_COORDINATES,
  );
  const limit = useMemo(() => 12, []);
  const countryCode = 'CAN,MEX,USA';
  const { userStore } = useStores();
  const company = userStore?.userCompany || {};

  const setDefaultPosition = useCallback(() => {
    if (company?.defaultLat && company?.defaultLon) {
      setStartSearchPosition({
        lat: company.defaultLat,
        lng: company.defaultLon,
      });
    }
  }, [company]);

  useEffect(() => {
    setDefaultPosition();
  }, [setDefaultPosition]);

  const getNBPlaces = (params: NBParams): Promise<AddressItem[]> | undefined => {
    const url = `${baseUrl}/multigeocode/search?key=${token}`;

    // ASYNC(!!!) cancel previous request if any
    if (isLoading) {
      setTimeout(() => {
        if (controller) {
          controller.abort();
        }
      }, 1);
    }
    //Handle here, due to the NB api not accepting empty query as an input
    if (!params.query?.length) {
      return;
    }
    setIsLoading(true);

    return $http
      .post(
        url,
        {
          query: params.query,
          at: startSearchPosition,
          limit,
          country: countryCode,
        },
        {
          signal: controller.signal,
        },
      )
      .then((resp) => {
        return (resp.data?.entities || []).map((item: any) =>
          AddressItem.decodeNBSuggestion(item),
        );
      })
      .catch((error) => {
        if (error?.code !== 'ERR_CANCELED') {
          throw error;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const encodeNBPlace = (coordinates: string) => {
    const url = `${baseUrl}/h/revgeocode`;

    return $http
      .get(url, {
        params: {
          key: token,
          at: coordinates,
        },
      })
      .then((resp) => {
        const { items } = resp.data;
        if (items.length) {
          return AddressItem.decodeNBPlace(items[0]);
        }
        return {} as AddressItem;
      });
  };

  const getNBPlaceDetails = (placeId: string) => {
    const url = `${baseUrl}/h/lookup`;

    return $http
      .get(url, {
        params: {
          key: token,
          id: placeId,
        },
      })
      .then((resp) => {
        const { items } = resp.data;
        if (items.length) {
          return AddressItem.decodeNBPlace(items[0]);
        }
        return {} as AddressItem;
      });
  };

  const getDistance = ({
    origins,
    destinations,
    avoid,
    departureTime,
    mode,
    approaches,
  }: DistanceParams) => {
    const options = {
      origins: origins,
      destinations: destinations,
      departureTime: departureTime ?? +new Date(),
      mode: mode || TravelMode.FOUR_WHEELS,
      // Avoid: AvoidType.HIGHWAY,
      approaches: approaches?.length ? approaches : [ApproachType.CURB],
    } as any;

    if (avoid) {
      options.avoid = avoid;
    }

    return distanceMatrixService.current.getDistanceMatrix(options).then((response) => {
      const { status, rows } = response;

      if (status === 'Ok') {
        return rows;
      } else {
        console.error('invalid request');
      }
    });
  };

  /**
   * Retrieves the location data from NextBillion for a specific asset within a given time range.
   * @param assetId - The ID of the asset.
   * @param startTime - The start time of the time range.
   * @param endTime - The end time of the time range.
   * @param geometryType - The type of geometry.
   * @param pageNumber - The page number for pagination.
   */
  const getNBAssetLocation = ({
    assetId,
    startTime,
    endTime,
    geometryType,
    pageNumber,
  }: AssetLocationParams) => {
    const url = `${baseUrl}/skynet/asset/${assetId}/location/list`;
    return $http
      .get(url, {
        params: {
          key: token,
          start_time: startTime,
          end_time: endTime,
          geometry_type: geometryType,
          pn: pageNumber,
          ps: 100,
        },
      })
      .then((resp) => {
        return resp.data;
      });
  };

  /**
   * Retrieves the NB Asset ID based on the provided userId.
   * @param userId - The ID of the user.
   * @returns The asset id for the user, empty string if not found.
   */
  const getNBAssetId = ({ userId }: AssetIdParams): Promise<string> => {
    const url = `${baseUrl}/skynet/asset/list`;
    // Format of the include_all_of_attributes param: key1:value1|key2:value2
    const attributesParams = `user_id:${userId}`;

    return $http
      .get(url, {
        params: {
          key: token,
          include_all_of_attributes: attributesParams,
          ps: 1,
        },
      })
      .then((resp) => {
        return resp.data.data.list[0]?.id || '';
      });
  };

  return {
    isLoading,
    getPlaces: getNBPlaces,
    encodeLngLat: encodeNBPlace,
    getPlaceDetails: getNBPlaceDetails,
    getDistance,
    getDriverRoute: getNBAssetLocation,
    getAssetId: getNBAssetId,
  };
};
