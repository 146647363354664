import React from 'react';

import AddOnsStore from '~store/AddOnsStore';
import CompanyAdminStore from '~store/AdminStores/CompanyAdminStore';
import UserAdminStore from '~store/AdminStores/UserAdminStore';
import AgaveIntegrationStore from '~store/AgaveIntegrationStore';
import ApprovalsStore from '~store/ApprovalsStore';
import AuthStore from '~store/AuthStore';
import CalendarDispatchStore from '~store/CalendarDispatchStore';
import CompanyAssetsStore from '~store/CompanyAssetsStore';
import DriverSchedulerStore from '~store/DriverSchedulerStore';
import GeneralStore from '~store/GeneralStore';
import InvoiceLineItemsStore from '~store/InvoiceLineItemsStore';
import InvoicesStore from '~store/InvoicesStore';
import JobStore from '~store/JobStore';
import LiveMapStore from '~store/LiveMapStore';
import OrdersStore from '~store/OrdersStore';
import ProjectsStore from '~store/ProjectsStore';
import SettlementLineItemsStore from '~store/SettlementLineItemsStore';
import SettlementsStore from '~store/SettlementsStore';
import TelematicsStore from '~store/TelematicsStore';
import TicketsStore from '~store/TicketsStore';
import ToasterStore from '~store/ToasterStore';
import UserStore from '~store/UserStore';

import DriverDayStore from './DriverDayStore';
import LiveMapStoreNew from './LiveMapStoreNew';
import OrdersDispatchStore from './OrdersDispatchStore';

export class RootStore {
  public addOnsStore: AddOnsStore;
  public agaveIntegrationStore: AgaveIntegrationStore;
  public approvalsStore: ApprovalsStore;
  public authStore: AuthStore;
  public calendarDispatchStore: CalendarDispatchStore;
  public companyAdminStore: CompanyAdminStore;
  public companyAssetsStore: CompanyAssetsStore;
  public driverDayStore: DriverDayStore;
  public driverSchedulerStore: DriverSchedulerStore;
  public generalStore: GeneralStore;
  public invoiceLineItemsStore: InvoiceLineItemsStore;
  public invoicesStore: InvoicesStore;
  public jobStore: JobStore;
  public liveMapStore: LiveMapStore;
  public liveMapStoreNew: LiveMapStoreNew;
  public ordersDispatchStore: OrdersDispatchStore;
  public ordersStore: OrdersStore;
  public projectsStore: ProjectsStore;
  public settlementLineItemsStore: SettlementLineItemsStore;
  public settlementsStore: SettlementsStore;
  public telematicsStore: TelematicsStore;
  public ticketsStore: TicketsStore;
  public toasterStore: ToasterStore;
  public userAdminStore: UserAdminStore;
  public userStore: UserStore;

  constructor() {
    this.addOnsStore = new AddOnsStore();
    this.agaveIntegrationStore = new AgaveIntegrationStore();
    this.approvalsStore = new ApprovalsStore(this);
    this.authStore = new AuthStore(this);
    this.calendarDispatchStore = new CalendarDispatchStore();
    this.companyAdminStore = new CompanyAdminStore(this);
    this.companyAssetsStore = new CompanyAssetsStore(this);
    this.driverDayStore = new DriverDayStore();
    this.driverSchedulerStore = new DriverSchedulerStore();
    this.generalStore = new GeneralStore(this);
    this.invoiceLineItemsStore = new InvoiceLineItemsStore();
    this.invoicesStore = new InvoicesStore();
    this.jobStore = new JobStore(this);
    this.liveMapStore = new LiveMapStore(this);
    this.liveMapStoreNew = new LiveMapStoreNew();
    this.ordersDispatchStore = new OrdersDispatchStore();
    this.ordersStore = new OrdersStore(this);
    this.projectsStore = new ProjectsStore(this);
    this.settlementLineItemsStore = new SettlementLineItemsStore();
    this.settlementsStore = new SettlementsStore();
    this.telematicsStore = new TelematicsStore();
    this.ticketsStore = new TicketsStore(this);
    this.toasterStore = new ToasterStore(this);
    this.userAdminStore = new UserAdminStore(this);
    this.userStore = new UserStore(this);
  }
}

export const rootStore = new RootStore();
const StoresContext = React.createContext(rootStore);

// This will be the function available for the app to connect to the stores
export const useStores = () => React.useContext(StoresContext);
