import Typography from '@mui/material/Typography';
import React from 'react';

const LivePage = () => {
  return (
    <>
      <Typography variant="h4" sx={{ my: 4 }}>
        Reconciliation
      </Typography>
    </>
  );
};

export default LivePage;
