import { useSplitTreatments } from '@splitsoftware/splitio-react';

import { FeatureFlags } from '~constants/featureFlags';

interface UseFeatureFlagProps {
  featureFlagKey: FeatureFlags;
}

const useFeatureFlag = ({ featureFlagKey }: UseFeatureFlagProps) => {
  const { treatments, isReady } = useSplitTreatments({
    names: [featureFlagKey],
  });
  const featureFlag = treatments[featureFlagKey];

  return isReady && featureFlag.treatment === 'on';
};

const useAwaitableFeatureFlag = ({ featureFlagKey }: UseFeatureFlagProps) => {
  const { treatments, isReady } = useSplitTreatments({
    names: [featureFlagKey],
  });
  const featureFlag = treatments[featureFlagKey];

  return { isReady, isFeatureFlagEnabled: featureFlag.treatment === 'on' };
};

export { useAwaitableFeatureFlag, useFeatureFlag };
