import Box from '@mui/material/Box';
import React from 'react';

type NoSignalProps = {
  startPosition: string;
  endPosition: string;
};

const NoSignal = ({ startPosition, endPosition }: NoSignalProps) => {
  return (
    <Box
      position={'absolute'}
      left={startPosition}
      right={endPosition}
      bottom={0}
      height={'100%'}
      sx={{ backgroundColor: 'lightgray' }}
    />
  );
};

export default NoSignal;
