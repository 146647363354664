import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useMemo } from 'react';

interface Props {
  children: React.ReactNode;
  doubleBorder?: boolean;
  sx?: SxProps<Theme>; // Style
}

const GridCardWrapper = ({ children, doubleBorder = false, sx = {} }: Props) => {
  const theme = useTheme();

  const $border = useMemo(
    () => `${doubleBorder ? 2 : 1}px solid ${theme.palette.divider}`,
    [theme, doubleBorder],
  );
  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        justifyContent: 'space-between',
        background: 'white',
        height: '100%',
        width: '100%',
        border: $border,
        borderRadius: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export { GridCardWrapper };
