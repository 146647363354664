import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import React, {
  cloneElement,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import theme from '~theme/AppTheme';

export interface MenuHandler {
  onClose?: () => void;
}

interface MenuProps extends Omit<MuiMenuProps, 'open' | 'onClose'> {
  menuTrigger: ReactElement<HTMLElement & { onClick: React.MouseEventHandler }>;
  onOpenStateChanged?: (isOpen: boolean) => void;
  preventCloseCondition?: boolean;
}

const Menu = forwardRef<MenuHandler, MenuProps>(function Menu(
  { menuTrigger, onOpenStateChanged, preventCloseCondition, sx, ...rest },
  ref,
) {
  const [anchor, setAnchor] = useState<Element | null>(null);
  const isOpen = Boolean(anchor);
  const rootSx = (sx && '& .MuiPaper-root' in sx ? sx['& .MuiPaper-root'] : {}) as object;

  useImperativeHandle(
    ref,
    () => ({
      onClose() {
        setAnchor(null);
      },
    }),
    [],
  );

  useEffect(() => {
    onOpenStateChanged?.(isOpen);
  }, [isOpen, onOpenStateChanged]);

  return (
    <>
      {cloneElement(menuTrigger, {
        onClick: (event: React.MouseEvent) => {
          event.stopPropagation();
          setAnchor(event.currentTarget);
        },
      })}

      <MuiMenu
        anchorEl={anchor}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();

          if (!preventCloseCondition) {
            setAnchor(null);
          }
        }}
        open={isOpen}
        sx={{
          ...sx,
          '& .MuiPaper-root': {
            border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            boxShadow: '0px 1px 10px 0px rgba(60, 64, 67, 0.15)',
            minWidth: '150px',
            mt: '3px',
            ...rootSx,
          },
          '& .MuiItem-root': { px: theme.spacing(1.5) },
        }}
        {...rest}
      />
    </>
  );
});

export default Menu;
