import { Access_Read } from '@treadinc/horizon-api-spec';

interface AccessProps {
  name: string;
}

export class Access {
  public static parse(proto: Access_Read): Access {
    return new Access(proto.name);
  }

  public static deparse(access: AccessProps): {
    name: string;
  } {
    return {
      name: access.name,
    };
  }

  public get name(): string {
    return this._name;
  }

  constructor(private _name: string) {}
}
