import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { unitOfMeasureOptions } from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { EquipmentTypeItem } from '~hooks/useEquipment';
import { equipmentTypeFormValidationSchema } from '~pages/Settings/EquipmentTypeManagement/equipmentTypeFormValidationSchema';
import { Nullable } from '~types/Nullable';

export interface EquipmentTypeFormHandler {
  submit: (callback: (data: any) => void) => void;
  resetForm: (callback?: () => void) => void;
}

interface EquipmentTypeFormProps {
  defaultEquipementType: Nullable<EquipmentTypeItem>;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  onFormDirty?: (isDirty: boolean) => void;
}

export const EquipmentTypeForm = forwardRef(function EquipmentTypeForm(
  { defaultEquipementType, onFormStateChange }: EquipmentTypeFormProps,
  ref: Ref<EquipmentTypeFormHandler>,
) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(equipmentTypeFormValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      id: defaultEquipementType?.id || '',
      name: defaultEquipementType?.name || '',
      externalId: defaultEquipementType?.externalId || '',
      grossCapacity: defaultEquipementType?.grossCapacity,
      grossCapacityUnitOfMeasure: defaultEquipementType?.grossCapacityUnitOfMeasure,
    },
  });

  useImperativeHandle(ref, () => ({
    submit(callBack) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    onFormStateChange({ isValid, isDirty });
  }, [isValid, isDirty]);

  return (
    <form>
      <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%', flexGrow: 1 }}>
        <Box display={'flex'} gap={2}>
          <Box flex={1}>
            <TextFormField
              control={control}
              errors={errors}
              name="name"
              label={`${t('form_fields.name')}`}
              isRequired={true}
            />
          </Box>
          <Box flex={1}>
            <TextFormField
              control={control}
              errors={errors}
              name="externalId"
              label={`${t('form_fields.external_id')}`}
              isRequired={false}
            />
          </Box>
        </Box>

        <Box display={'flex'} gap={2}>
          <Box flex={1}>
            <TextFormField
              control={control}
              errors={errors}
              name="grossCapacity"
              label={`${t('form_fields.gross_capacity')}`}
            />
          </Box>
          <Box flex={1}>
            <AutocompleteFormField
              control={control}
              name="grossCapacityUnitOfMeasure"
              errors={errors}
              list={unitOfMeasureOptions}
              label={`${t('form_fields.unit')}`}
              getValue={(item) => item.id}
              getLabel={(item) => item.name || ''}
              clearOnBlur={true}
              blurOnSelect={true}
              clearable
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
});
