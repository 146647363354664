/**
 * Block DnD event propagation if element have "data-no-dnd" attribute.
 * Reference: https://github.com/clauderic/dnd-kit/issues/477
 *
 * Usage:
 *  1. DnD sensors setup:
 *    const dndSensors = useSensors(useSensor(NoDnDDataAttributeMouseSensor));
 *    ...
 *    <DndContext sensors={dndSensors}>...</DnDContext>
 *
 *  2. Attach "data-no-dnd" attribute to element
 *    <MyComponent data-no-dnd="true">...</MyComponent>
 */

import { MouseSensor } from '@dnd-kit/core';
import { MouseEvent } from 'react';

const handler = ({ nativeEvent }: MouseEvent) => {
  let currentElement = nativeEvent.target as HTMLElement;

  while (currentElement) {
    if (currentElement.dataset?.noDnd) {
      return false;
    }

    currentElement = currentElement.parentElement as HTMLElement;
  }

  return true;
};

export default class NoDnDDataAttributeMouseSensor extends MouseSensor {
  static activators = [
    { eventName: 'onMouseDown', handler },
  ] as (typeof MouseSensor)['activators'];
}
