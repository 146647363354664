import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import MUIChip, { ChipOwnProps } from '@mui/material/Chip';
import { ReactNode } from 'react';

interface ChipProps {
  color: ChipOwnProps['color'];
  label: ReactNode;
  hideDropdownArrow?: boolean;
}

const Chip = ({ color, label, hideDropdownArrow }: ChipProps) => {
  return (
    <MUIChip
      color={color}
      icon={hideDropdownArrow ? undefined : <ArrowDropDown />}
      label={label}
      size="small"
      variant="outlined"
      sx={{
        '&.MuiChip-root': {
          textTransform: 'none',
          '&.MuiChip-outlined': { flexDirection: 'row-reverse', borderRadius: 1 },
          '.MuiChip-label': {
            fontSize: '12px',
            fontWeight: 700,
            ...(hideDropdownArrow ? { px: 0.5 } : { pl: 0.5, pr: 0 }),
          },
          '.MuiChip-icon': { mx: 0 },
        },
      }}
    />
  );
};

export { Chip };
