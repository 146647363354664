import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import {
  GridPrintExportMenuItem,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-premium';
import { t } from 'i18next';
interface DataGridCustomizedExportProps {
  onCsvExport?: () => void;
  onExcelExport?: () => void;
}
const DataGridCustomizedExport = ({
  onCsvExport,
  onExcelExport,
}: DataGridCustomizedExportProps) => {
  return (
    <GridToolbarExportContainer variant={'text'} color={'secondary'} sx={{ ml: 2 }}>
      <GridPrintExportMenuItem />
      <MenuItem onClick={onCsvExport}>{t('actions.export_csv')}</MenuItem>
      <MenuItem onClick={onExcelExport}>{t('actions.export_excel')}</MenuItem>
    </GridToolbarExportContainer>
  );
};
export { DataGridCustomizedExport };
