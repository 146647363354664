import Tooltip from '@mui/material/Tooltip';
import React, { ReactElement } from 'react';

interface BasicTooltipProps {
  children: ReactElement;
  title: string;
  [x: string]: any;
}
const BasicTooltip = ({ children, title, ...rest }: BasicTooltipProps) => {
  const text = typeof title === 'string' ? title : JSON.stringify(title);
  return (
    <Tooltip title={text} {...rest}>
      {children}
    </Tooltip>
  );
};
export { BasicTooltip };
