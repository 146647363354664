import React, { createContext, ReactNode, useContext, useState } from 'react';

type IndicatorValues = {
  indicatorVisible: boolean;
  indicatorPosition: number;
  indicatorTime: number;
};

type TimeIndicatorContextProps = {
  indicatorValues: IndicatorValues;

  setIndicatorValues: (indicatorValues: IndicatorValues) => void;
};

const TimeIndicatorContext = createContext<TimeIndicatorContextProps>({
  indicatorValues: {
    indicatorVisible: false,
    indicatorPosition: 0,
    indicatorTime: 0,
  },
  setIndicatorValues: () => {},
});

export const useTimeIndicatorContext = () => useContext(TimeIndicatorContext);

type TimeIndicatorContextProviderProps = {
  children: ReactNode;
};

export const TimeIndicatorContextProvider = ({
  children,
}: TimeIndicatorContextProviderProps) => {
  const [indicatorValues, setIndicatorValues] = useState({
    indicatorVisible: false,
    indicatorPosition: 0,
    indicatorTime: 0,
  });

  return (
    <TimeIndicatorContext.Provider value={{ indicatorValues, setIndicatorValues }}>
      {children}
    </TimeIndicatorContext.Provider>
  );
};
