import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { JobState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { Status as JobStatus } from '~components/Job/Status';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { Job, useJob } from '~hooks/useJob';
import { JobFeatureProperties, LiveMapFeature } from '~hooks/useLiveMap/models';
import { formatDuration } from '~utils/dateTime';

interface Props {
  feature: LiveMapFeature;
}

/**
 * Renders the details of a job fetched by jobId in a popup.
 *
 * @param {LiveMapFeature} feature - The feature object representing the job.
 *
 * @returns {JSX.Element} The rendered popup content component.
 */
const NewJobDetails = ({ feature }: Props) => {
  const theme = useTheme();
  const details = feature.properties as JobFeatureProperties;

  const { isLoading, getJobById } = useJob();
  const [jobDetails, setJobDetails] = useState<Job | null>(null);

  useEffect(() => {
    if (!details?.jobId) {
      return;
    }

    getJobById(details.jobId).then((job) => setJobDetails(job));
  }, [details.jobId]);

  if (isLoading) {
    return (
      <Box display={'flex'} justifyContent={'center'}>
        <CircularProgress size={30} />
      </Box>
    );
  }

  return (
    <>
      {/* Popup header with job id, material and equipment */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
          mb: 1,
        }}
      >
        <Grid item xs={4}>
          <Typography variant="body2">{jobDetails?.jobId}</Typography>
        </Grid>
        <Grid item xs={8}>
          <BasicTooltip
            title={`${jobDetails?.material?.name} - ${jobDetails?.equipment?.name}`}
            placement="top"
          >
            <Typography variant="body2" noWrap>
              {jobDetails?.material?.name} - {jobDetails?.equipment?.name}
            </Typography>
          </BasicTooltip>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 1 }}></Divider>

      {/* Job Status with duration since startAt */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }}
      >
        <Grid item xs={4}>
          <Typography variant="body2">{t('live_map.pop_up.job_status')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            {formatDuration(dayjs().diff(jobDetails?.createdAt, 'minutes'), false)}
          </Typography>
          <JobStatus textOnly={true} status={jobDetails?.status as JobState} />
        </Grid>
      </Grid>

      {/* Driver Name */}
      <Grid
        container
        spacing={2}
        sx={{
          color: theme.palette.common.white,
          fontWeight: 'bold',
          fontSize: '0.8rem',
        }}
      >
        <Grid item xs={4}>
          <Typography variant="body2">{t('live_map.pop_up.driver')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{jobDetails?.driverFullName}</Typography>
        </Grid>
      </Grid>

      {/* Updated At timestamp */}
      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', fontSize: '0.8rem', color: theme.palette.common.white }}
      >
        <Grid item xs={4}>
          <Typography variant="body2">{t('live_map.pop_up.updated_at')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            {jobDetails?.updatedAt.format('YYYY-MM-DD HH:mm A')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export { NewJobDetails };
