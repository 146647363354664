import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { OrderState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { ReactElement, ReactNode, useCallback, useRef } from 'react';
import React from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import {
  LoadingSpinner,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import OrderStatusChip from '~components/Order/OrderStatusChip';
import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';

const propsByNextOrderState: Partial<
  Record<
    OrderState,
    { color: keyof typeof theme.brandV2.colors; icon: ReactElement; label: string }
  >
> = {
  [OrderState.COMPLETED]: {
    color: 'treadGreen',
    icon: <CheckCircleOutlined />,
    label: t('dispatch.order.complete_order'),
  },
  [OrderState.CANCELED]: {
    color: 'treadRed',
    icon: <CancelOutlined />,
    label: t('dispatch.order.cancel_order'),
  },
} as const;

interface OrderStatusTransitionerProps {
  closeOnTransitionComplete?: boolean;
  isTransitioningToStatus?: OrderState;
  onTransitionToStatus: (state: OrderState) => Promise<void>;
  order: Order;
  transitionableStates: OrderState[];
}

export default function OrderStatusTransitioner({
  closeOnTransitionComplete,
  isTransitioningToStatus,
  onTransitionToStatus,
  order,
  transitionableStates,
}: OrderStatusTransitionerProps) {
  const menuHandler = useRef<MenuHandler>(null);
  const isTransitioning = Boolean(isTransitioningToStatus);

  const handleMenuItemClick = useCallback(
    async (state: OrderState) => {
      if (isTransitioning) {
        return;
      }

      try {
        await onTransitionToStatus(state);
      } finally {
        if (closeOnTransitionComplete) {
          menuHandler.current?.onClose?.();
        }
      }
    },
    [isTransitioning, onTransitionToStatus, closeOnTransitionComplete],
  );

  const Icon = ({ state }: { state: OrderState }) => {
    const { icon, color } = propsByNextOrderState[state] ?? {};

    if (icon && color) {
      return React.cloneElement(icon, {
        sx: { color: theme.brandV2.colors[color], fontSize: '15px' },
      });
    }

    return (
      <ArrowCircleDown
        sx={{
          color: theme.brandV2.colors.treadGray3,
          fontSize: '15px',
          transform: 'rotate(-90deg)',
        }}
      />
    );
  };

  if (transitionableStates.length === 0) {
    return <OrderStatusChip order={order} hideDropdownArrow />;
  }

  return (
    <Menu
      ref={menuHandler}
      disableAutoFocusItem
      preventCloseCondition={isTransitioning}
      menuTrigger={
        <Box sx={{ cursor: 'pointer', display: 'inline-block' }}>
          <OrderStatusChip order={order} />
        </Box>
      }
    >
      {transitionableStates.map((state) => (
        <OrderStatusTransitionerMenuItem
          key={state}
          isDisabled={isTransitioning}
          isLoading={isTransitioningToStatus === state}
          leftIcon={<Icon state={state} />}
          onClick={() => handleMenuItemClick(state)}
          text={propsByNextOrderState[state]?.label ?? state}
        />
      ))}
    </Menu>
  );
}

interface OrderStatusTransitionerMenuItemProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  leftIcon?: ReactNode;
  onClick: () => void;
  text: string;
}

function OrderStatusTransitionerMenuItem({
  isDisabled,
  isLoading,
  leftIcon,
  onClick,
  text,
}: OrderStatusTransitionerMenuItemProps) {
  return (
    <MenuItem
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();

        if (!isDisabled) {
          onClick();
        }
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        gap={1}
        justifyContent="space-between"
        width="100%"
      >
        {leftIcon}

        <OverflowAwareText
          flex={1}
          sx={{
            color: isDisabled ? theme.brandV2.colors.treadGray6 : undefined,
          }}
        >
          {text}
        </OverflowAwareText>

        <LoadingSpinner
          isVisible={isLoading}
          loadingIndicatorSize={15}
          sx={{ flex: 0, minWidth: '15px' }}
        />
      </Box>
    </MenuItem>
  );
}
