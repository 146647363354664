import { Job } from '~hooks/useJob';

import { JobDetailsContainer } from '../JobDetailsContainer';

interface JobsTableExpandedBlockProps {
  job: Job;
}

export default function JobsTableExpandedBlock({ job }: JobsTableExpandedBlockProps) {
  return <JobDetailsContainer details={job} />;
}
