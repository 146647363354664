import { yupResolver } from '@hookform/resolvers/yup';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import Flag from '@mui/icons-material/Flag';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { FileAttachment_Read, TicketState } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { Body } from '~components/Drawer/src/ui/Body';
import { Footer } from '~components/Drawer/src/ui/Footer';
import { Header } from '~components/Drawer/src/ui/Header';
import { Job } from '~hooks/useJob';
import { Ticket } from '~hooks/useTickets';
import { ImageUpload, ImageUploadHandler } from '~pages/Admin/Companies/ImageUpload';
import { canEditTicket } from '~utils/tickets/ticket-utils';

import FileAttachments, {
  fileAttachmentSchema,
} from '../../../formsShared/components/FileAttachments';
import { TicketFormDTO } from '../ApprovalsComponents/ticketFormSchema';
import { Field } from './Field';
import { TicketDetailForm } from './TicketDetailForm';

interface TicketDetailsProps {
  job: Job;
  loadId?: string;
  onApprove: (ticket: Ticket) => void;
  onChange: (ticket: TicketFormDTO) => Promise<Ticket>;
  onClose: () => void;
  onFlag: (ticke: Ticket) => Promise<Ticket>;
  onRemoveImage: (ticket: Ticket) => void;
  onUploadImage: (ticket: Ticket | undefined, image: File) => void;
  ticket?: Ticket;
}

const fileAttachmentsFormSchema = yup.object().shape({
  fileAttachments: fileAttachmentSchema,
});

export const TicketDetails = ({
  job,
  loadId,
  onApprove,
  onChange,
  onClose,
  onFlag,
  onRemoveImage,
  onUploadImage,
  ticket,
}: TicketDetailsProps) => {
  const deleteImageModalDialogRef = useRef<ModalDialogHandler>(null);
  const imageUploadRef = useRef<ImageUploadHandler>(null);
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm({
    resolver: yupResolver(fileAttachmentsFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const {
    control,
    formState: { errors },
  } = form;

  const handleEditModeChange = useCallback((isEditing: boolean) => {
    setIsEditing(isEditing);
  }, []);

  const handleFlagTicket = useCallback(async () => {
    if (ticket) {
      try {
        setIsEditing(true);
        await onFlag(ticket);
      } finally {
        setIsEditing(false);
      }
    }
  }, [ticket, onFlag]);

  return (
    <>
      <ModalDialog
        ref={deleteImageModalDialogRef}
        title={$t('approvals.ticket_details.remove_image')}
        content={$t('approvals.ticket_details.remove_image_confirmation')}
        confirmButtonText={`${$t('actions.confirm')}`}
        confirmButtonColor={'error'}
        callBack={() => {
          if (ticket) {
            imageUploadRef.current?.reset();
            onRemoveImage(ticket);
            deleteImageModalDialogRef.current?.close();
          }
        }}
      />
      <Header
        title={
          ticket ? (
            <>
              <span className="text-base font-semibold mr-4">
                {$t('approvals.ticket_details.ticket_title', {
                  number: `${ticket?.ticketNumber}`,
                })}
              </span>{' '}
              <span className="text-base text-gray-500">{`${ticket?.orderId}`}</span>
            </>
          ) : null
        }
        actions={
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        }
      />
      <Body>
        <div className="flex flex-1">
          <div className="p-2 border-r border-gray-200 w-[455px] space-y-2">
            <ImageUpload
              ref={imageUploadRef}
              isCanBeMagnified={true}
              maxSize={10}
              url={ticket?.imageUrl}
              secondaryText={null}
              allowDelete={false}
              disabled={ticket && !canEditTicket(ticket.state as TicketState)}
              removeCallback={() => {
                if (ticket) {
                  onRemoveImage(ticket);
                }
              }}
              onChange={(image) => {
                onUploadImage(ticket, image);
              }}
              sx={{ minHeight: '450px', width: '100%' }}
            />
            {ticket && canEditTicket(ticket.state as TicketState) && ticket.imageUrl && (
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<Delete />}
                onClick={() => {
                  deleteImageModalDialogRef.current?.open();
                }}
              >
                {$t('approvals.ticket_details.remove_image')}
              </Button>
            )}
            <Box pt={2}>
              <FileAttachments
                fileAttachableId={loadId}
                fileAttachableType={FileAttachment_Read.file_attachable_type.LOAD}
                control={control}
                errors={errors}
                form={form}
                name="fileAttachments"
                allowPerItemUpdates
              />
            </Box>
          </div>
          <div className="flex flex-col self-start  w-[455px]">
            <TicketDetailForm
              key={ticket?.id}
              jobId={job.id}
              onChange={onChange}
              onEditModeChange={handleEditModeChange}
              ticket={ticket}
            />

            <Header
              title={
                <span className="text-sm font-bold">
                  {$t('approvals.ticket_details.load_details')}
                </span>
              }
            />

            <Field label={$t('common.project')} value={job.projectName} />
            <Field label={$t('common.order')} value={job.orderId} />
            <Field label={$t('form_fields.job')} value={job.jobId} />
            <Field label={$t('common.customer')} value={job.order?.account?.name} />
            <Field
              label={$t('form_fields.drop_off')}
              value={job.dropOffWayPoints[0]?.site?.name}
            />
            <Field
              label={$t('common.vendor')}
              value={job.vendorJobAssignment?.vendorAccount?.name}
            />
            <Field label={$t('form_fields.driver')} value={job.driver?.fullName} />
            <Field label={$t('form_fields.equipment')} value={job.equipment?.name} />
          </div>
        </div>
      </Body>
      <Footer
        actions={
          <>
            {ticket && canEditTicket(ticket.state as TicketState) && (
              <>
                <Button
                  color="inherit"
                  disabled={isEditing}
                  onClick={handleFlagTicket}
                  startIcon={ticket.flagged ? <Flag /> : <FlagOutlined />}
                  variant="outlined"
                >
                  {ticket.flagged
                    ? $t('approvals.remove_flag')
                    : $t('approvals.add_flag')}
                </Button>
                <Button
                  color="success"
                  disabled={isEditing}
                  onClick={() => onApprove(ticket)}
                  startIcon={<Check />}
                  variant="contained"
                >
                  {$t('approvals.approve')}
                </Button>
              </>
            )}
          </>
        }
      />
    </>
  );
};
