import { Active, DragEndEvent, DragStartEvent, Over } from '@dnd-kit/core';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _, { uniqBy } from 'lodash';
import { runInAction } from 'mobx';
import { useState } from 'react';

import { Driver } from '~hooks/useDrivers';
import { Job } from '~hooks/useJob';
import { useDriverSchedulerFetch } from '~pages/Dispatch/hooks/useDriverSchedulerFetch';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';

import {
  INCREMENTS_PER_HOUR,
  MINUTES_PER_INCREMENT,
} from '../components/drivers/constants';

export enum ConfirmChangeReason {
  DRIVER_CHANGE = 'DRIVER_CHANGE',
  DRIVER_REMOVAL = 'DRIVER_REMOVAL',
}

type ConfirmChangeProps =
  | {
      reason: ConfirmChangeReason.DRIVER_CHANGE;
      props: {
        over: Over;
        active: Active;
      };
    }
  | {
      reason: ConfirmChangeReason.DRIVER_REMOVAL;
      props: {
        active: Active;
      };
    };

type ConfirmChangeState = {
  state: boolean;
  content: string;
} & ConfirmChangeProps;

export const useDriverScheduler = () => {
  const { toasterStore, driverSchedulerStore } = useStores();
  const { unassignDriver, updateJob } = useDriverSchedulerFetch();
  const [unassignedJobs, setUnassignedJobs] = useState<Job[]>([]);
  // Used to preserve the original order of unassigned jobs
  // Allows us to sort the unassigned jobs by their original order when unassigning a job
  const [originalUnassignedJobs, setOriginalUnassignedJobs] = useState<Job[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [isConfirmingChange, setIsConfirmingChange] = useState<ConfirmChangeState>({
    state: false,
    content: '',
    reason: ConfirmChangeReason.DRIVER_CHANGE,
    props: {
      over: undefined as unknown as Over,
      active: undefined as unknown as Active,
    },
  });

  const columnIndexToDateString = (
    columnIndex: number,
    startDate: string | undefined,
  ): string => {
    const selectedDate = dayjs.tz(startDate);
    const hour = Math.floor(columnIndex / INCREMENTS_PER_HOUR);
    const minute = (columnIndex % INCREMENTS_PER_HOUR) * MINUTES_PER_INCREMENT;
    const date = selectedDate.hour(hour).minute(minute).startOf('minute');
    return date.toISOString();
  };

  const assignDriverAndUpdateJob = async (
    job: Job,
    driverId: string,
    newStartDate: string,
    columnIndex: number,
  ) => {
    const updatedJobData = {
      ...job,
      driver_id: driverId,
      jobStartAt: newStartDate,
    };

    // Optimistic update start
    const previousAssignments = { ...driverSchedulerStore.jobAssignments };
    const previousUnassignedJobs = [...driverSchedulerStore.unassignedJobs];
    const previousAssignedJobs = [...driverSchedulerStore.assignedJobs];

    const newAssignments: Record<string, Nullable<Job>> = {
      ...driverSchedulerStore.jobAssignments,
    };

    Object.keys(newAssignments).forEach((key) => {
      if (newAssignments[key] && newAssignments[key]?.id === job.id) {
        newAssignments[key] = null;
      }
    });
    newAssignments[`${driverId}:${columnIndex}`] = job;

    runInAction(() => {
      driverSchedulerStore.setJobAssignments(newAssignments);
    });

    // Optimistic update end

    try {
      const [updatedJob] = await Promise.all([updateJob(job.id, updatedJobData, true)]);

      if (!updatedJob) {
        throw new Error('Updated job is null or undefined');
      }

      runInAction(() => {
        // Update the assigned jobs
        driverSchedulerStore.assignedJobs = uniqBy(
          [...driverSchedulerStore.assignedJobs, updatedJob],
          (j) => j.id,
        ) as Job[];

        // Remove the job from unassigned jobs
        driverSchedulerStore.unassignedJobs = driverSchedulerStore.unassignedJobs.filter(
          (unassignedJob) => unassignedJob.id !== job.id,
        );
      });

      toasterStore.removeFirst();
      toasterStore.push(
        alert(t('dispatch.drivers.job_successfully_assigned'), AlertTypes.success),
      );

      return { updatedJob };
    } catch (error) {
      console.error('Failed to assign driver and update job:', error);

      // Rollback optimistic update
      runInAction(() => {
        driverSchedulerStore.setJobAssignments(previousAssignments);
        driverSchedulerStore.unassignedJobs = previousUnassignedJobs;
        driverSchedulerStore.assignedJobs = previousAssignedJobs;
      });

      throw error;
    }
  };

  const assignDriverAndUpdateJobTransaction = async (over: Over, active: Active) => {
    const draggedJob = driverSchedulerStore.draggedJob;
    if (!draggedJob) {
      return;
    }
    const [driverId, columnIndex] = String(over.id).split(':');

    const previousAssignments = { ...driverSchedulerStore.jobAssignments };
    const previousUnassignedJobs = [...driverSchedulerStore.unassignedJobs];
    const previousAssignedJobs = [...driverSchedulerStore.assignedJobs];
    const newAssignments: Record<string, Nullable<Job>> = {
      ...driverSchedulerStore.jobAssignments,
    };

    Object.keys(newAssignments).forEach((key) => {
      if (newAssignments[key] && newAssignments[key]?.id === active.id) {
        newAssignments[key] = null;
      }
    });
    newAssignments[`${driverId}:${columnIndex}`] = draggedJob;

    runInAction(() => {
      driverSchedulerStore.jobAssignments = newAssignments;
      driverSchedulerStore.unassignedJobs = driverSchedulerStore.unassignedJobs.filter(
        (job) => job.id !== active.id,
      );
      driverSchedulerStore.assignedJobs = uniqBy(
        [...driverSchedulerStore.assignedJobs, draggedJob],
        (job) => job.id,
      );
    });

    const selectedStartDate = driverSchedulerStore.dateFilters.startDate;
    const newStartDate = columnIndexToDateString(Number(columnIndex), selectedStartDate);

    setIsConfirmingChange({
      state: false,
      content: '',
      reason: ConfirmChangeReason.DRIVER_CHANGE,
      props: {
        over: undefined as unknown as Over,
        active: undefined as unknown as Active,
      },
    });

    try {
      if (draggedJob.driver?.id !== driverId) {
        await unassignDriver(draggedJob.id);
      }
      await assignDriverAndUpdateJob(
        draggedJob,
        driverId,
        newStartDate,
        Number(columnIndex),
      );
    } catch (error) {
      console.error('Failed to assign driver and update job:', error);
      // Rollback the optimistic update if there's an error
      runInAction(() => {
        driverSchedulerStore.jobAssignments = previousAssignments;
        driverSchedulerStore.unassignedJobs = previousUnassignedJobs;
        driverSchedulerStore.assignedJobs = previousAssignedJobs;
      });
    }
  };

  const unassignDriverTransaction = async (active: Active) => {
    const unassignedJob = driverSchedulerStore.unassignedJobs.find(
      (job) => job.id === active.id,
    );

    const draggedJob = driverSchedulerStore.draggedJob;

    if (!draggedJob) {
      return;
    }

    if (unassignedJob) {
      // Job was previously in the unassigned jobs column, no need to unassign
      driverSchedulerStore.setDraggedJob(null);
      return;
    }

    // Optimistically update unassignedJobs and jobAssignments
    const previousAssignments = { ...driverSchedulerStore.jobAssignments };
    const previousUnassignedJobs = [...driverSchedulerStore.unassignedJobs];
    const previousAssignedJobs = [...driverSchedulerStore.assignedJobs];

    const newAssignments: Record<string, Nullable<Job>> = { ...previousAssignments };
    const newAssignedJobs = previousAssignedJobs.filter((job) => job.id !== active.id);

    Object.keys(newAssignments).forEach((key) => {
      if (newAssignments[key] && newAssignments[key]?.id === active.id) {
        newAssignments[key] = null; // Or delete newAssignments[key];
      }
    });

    runInAction(() => {
      driverSchedulerStore.jobAssignments = newAssignments;
      driverSchedulerStore.assignedJobs = newAssignedJobs;
      driverSchedulerStore.unassignedJobs = uniqBy(
        [...driverSchedulerStore.unassignedJobs, draggedJob],
        (job) => job.id,
      ).sort(
        (a, b) =>
          originalUnassignedJobs.findIndex((job) => job.id === a.id) -
          originalUnassignedJobs.findIndex((job) => job.id === b.id),
      );
    });

    setIsConfirmingChange({
      state: false,
      content: '',
      reason: ConfirmChangeReason.DRIVER_CHANGE,
      props: {
        over: undefined as unknown as Over,
        active: undefined as unknown as Active,
      },
    });

    // Perform the actual unassignDriver call
    try {
      await unassignDriver(draggedJob.id);
      toasterStore.removeFirst();
      toasterStore.push(
        alert(t('dispatch.drivers.job_successfully_unassigned'), AlertTypes.success),
      );
    } catch (error) {
      console.error('Failed to unassign driver:', error);
      // Rollback the optimistic update if there's an error
      runInAction(() => {
        driverSchedulerStore.jobAssignments = previousAssignments;
        driverSchedulerStore.unassignedJobs = previousUnassignedJobs;
        driverSchedulerStore.assignedJobs = previousAssignedJobs;
      });
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const unassignedJob = driverSchedulerStore.unassignedJobs.find(
      (job) => job.id === active.id,
    );
    const assignedJob = driverSchedulerStore.assignedJobs.find(
      (job) => job.id === active.id,
    );

    const draggedJob = assignedJob || unassignedJob;
    driverSchedulerStore.setDraggedJob(draggedJob || null);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      driverSchedulerStore.setDraggedJob(null);
      return;
    }

    const draggedJob = driverSchedulerStore.draggedJob;

    if (!draggedJob) {
      driverSchedulerStore.setDraggedJob(null);
      return;
    }

    if (over.id === 'jobs-column') {
      if (draggedJob.driver?.id) {
        setIsConfirmingChange({
          state: true,
          reason: ConfirmChangeReason.DRIVER_REMOVAL,
          content: t('dispatch.drivers.confirm_driver_removal'),
          props: { active },
        });
      } else {
        await unassignDriverTransaction(active);
        driverSchedulerStore.setDraggedJob(null);
      }
    } else {
      const [driverId] = String(over.id).split(':');

      if (!_.isNil(draggedJob.driver?.id) && draggedJob.driver?.id !== driverId) {
        setIsConfirmingChange({
          state: true,
          reason: ConfirmChangeReason.DRIVER_CHANGE,
          content: t('dispatch.drivers.confirm_driver_change'),
          props: { over, active },
        });
      } else {
        await assignDriverAndUpdateJobTransaction(over, active);
        driverSchedulerStore.setDraggedJob(null);
      }
    }
  };

  const handleDragCancel = () => {
    driverSchedulerStore.setDraggedJob(null);
  };

  const handleCellDrop = (driverId: string, columnIndex: number) => {
    const draggedJob = driverSchedulerStore.draggedJob;
    if (draggedJob) {
      const newAssignments: Record<string, Nullable<Job>> = {
        ...driverSchedulerStore.jobAssignments,
      };

      // Remove the job from its previous assignment
      Object.keys(newAssignments).forEach((key) => {
        if (newAssignments[key]?.id === draggedJob.id) {
          newAssignments[key] = null; // Or delete newAssignments[key];
        }
      });

      // Add the job to its new assignment
      newAssignments[`${driverId}:${columnIndex}`] = draggedJob;

      runInAction(() => {
        driverSchedulerStore.jobAssignments = newAssignments;
      });

      runInAction(() => {
        driverSchedulerStore.unassignedJobs = driverSchedulerStore.unassignedJobs.filter(
          (job) => job.id !== draggedJob.id,
        );
      });
    }
  };

  return {
    unassignedJobs,
    setUnassignedJobs,
    originalUnassignedJobs,
    setOriginalUnassignedJobs,
    drivers,
    setDrivers,
    isConfirmingChange,
    setIsConfirmingChange,
    assignDriverAndUpdateJobTransaction,
    unassignDriverTransaction,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    handleCellDrop,
  };
};
