import * as React from 'react';
import { SVGProps } from 'react';

const EditPolygon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#D6DDE1" />
    <path
      d="M21.6396 10.25L20.9443 8L11.0557 8L8 17.8885L16 24L22 19.4164"
      stroke="#132732"
      strokeWidth="1.5"
    />
    <circle cx="16" cy="15" r="2" fill="#132732" />
    <path
      d="M18.5025 18.3079L18.3838 16.2289L24.4734 9.41524C24.5661 9.31998 24.6702 9.24451 24.7856 9.18884C24.9011 9.13318 25.024 9.10162 25.1545 9.09418C25.2849 9.08673 25.4127 9.10398 25.5378 9.14592C25.6629 9.18786 25.7731 9.25519 25.8683 9.34791L26.58 9.9944C26.683 10.0785 26.7604 10.1804 26.8122 10.3002C26.864 10.4199 26.8934 10.5409 26.9004 10.6632C26.9079 10.7937 26.8925 10.9193 26.8544 11.0401C26.8163 11.1609 26.7512 11.273 26.6589 11.3764L20.5816 18.1893L18.5025 18.3079ZM25.2762 11.443L25.922 10.7191L25.1981 10.0733L24.5523 10.7972L25.2762 11.443Z"
      fill="#132732"
    />
  </svg>
);
export default EditPolygon;
