import Link from '@mui/material/Link';
import { t } from 'i18next';
import React from 'react';

import { routes } from '~router';

const LinkToForgotPassword = () => {
  return (
    <Link href={routes.forgot} variant="body2" color={'secondary.main'}>
      {t('page_headings.forgot_password')}
    </Link>
  );
};
export default LinkToForgotPassword;
