import { AddOn_Read, AddOnCharge_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { AddOn, AddOnCharge } from '~hooks/useAddOns';
import { GetPaginationParams, PaginationLink } from '~interfaces';
import { AddOnChargeDTO } from '~pages/Approvals/DriverPay/helpers';
import { AddOnDTO } from '~pages/Settings/RatesManagement/AddOnCharges/components/addOnFormSchema';
import connection from '~services/connectionModule';
import { useStores } from '~store';

const useAddOns = () => {
  const { addOnsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const batchUpdateAddOnCharges = async (invoiceId: string, addOns: AddOnChargeDTO[]) => {
    try {
      setIsUpdating(true);

      const payload = {
        add_on_charges: addOns.map((addOn) => {
          return AddOnCharge.deparse({ ...addOn, invoiceId });
        }),
      };

      const response = await connection.patch<AddOnCharge_Read[]>(
        `${API_VERSION}/invoices/${invoiceId}/add_on_charges`,
        payload,
        {},
        $t('error_messages.add_ons.failed_to_update') as string,
      );

      const updatedAddOnCharges = response.map((addOnCharge) => {
        return AddOnCharge.parse(addOnCharge);
      });
      addOnsStore.setAddOnChargesByInvoiceId(invoiceId, updatedAddOnCharges);

      return updatedAddOnCharges;
    } finally {
      setIsUpdating(false);
    }
  };

  const createAddOn = async (data: AddOnDTO) => {
    try {
      setIsCreating(true);

      const response = await connection.post<AddOn_Read>(
        `${API_VERSION}/add_ons`,
        AddOn.deparse(data),
        {},
        $t('error_messages.add_ons.failed_to_create') as string,
      );

      const newAddOn = AddOn.parse(response);
      addOnsStore.addAddOn(newAddOn);

      return newAddOn;
    } finally {
      setIsCreating(false);
    }
  };

  const getAllAddOns = async (companyId: string) => {
    setIsLoading(true);

    const fetchAddOnsPage = (params: GetPaginationParams) => {
      return connection.getPaginated<AddOn_Read>(
        `${API_VERSION}/companies/${companyId}/add_ons`,
        { params },
        $t('error_messages.add_ons.failed_to_fetch') as string,
      );
    };

    let shouldFetchNextPage = true;
    let allAddOns: AddOn_Read[] = [];

    const params: GetPaginationParams = {
      'page[limit]': addOnsStore.pagination.limit,
    };

    while (shouldFetchNextPage) {
      const { data, pagination } = await fetchAddOnsPage(params);

      allAddOns = [...allAddOns, ...data];
      shouldFetchNextPage = Boolean(pagination.after);

      if (shouldFetchNextPage) {
        params['page[after]'] = pagination.after;
      }
    }

    const parsedAddOns = allAddOns.map((addOn) => AddOn.parse(addOn));
    addOnsStore.setAddOns(parsedAddOns);

    setIsLoading(false);

    return parsedAddOns;
  };

  const getAddOns = async (companyId: string, link?: PaginationLink) => {
    try {
      setIsLoading(true);

      const params: GetPaginationParams = { 'page[limit]': addOnsStore.pagination.limit };

      if (link && addOnsStore.pagination[link]) {
        params[`page[${link}]`] = addOnsStore.pagination[link];
      }

      const response = await connection.getPaginated<AddOn_Read>(
        `${API_VERSION}/companies/${companyId}/add_ons`,
        { params },
        $t('error_messages.add_ons.failed_to_fetch') as string,
      );

      const { data, pagination } = response;
      const addOns = data.map((addOn) => AddOn.parse(addOn));

      addOnsStore.setAddOns(addOns);
      addOnsStore.setPagination(pagination);
      addOnsStore.updatePageNumber(link);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllInvoiceAddOnCharges = async (invoiceId: string) => {
    setIsLoading(true);

    const fetchAddOnChargesPage = (params: GetPaginationParams) => {
      return connection.getPaginated<AddOnCharge_Read>(
        `${API_VERSION}/invoices/${invoiceId}/add_on_charges`,
        { params },
        $t('error_messages.add_ons.failed_to_fetch_charges') as string,
      );
    };

    let shouldFetchNextPage = true;
    let allAddOnCharges: AddOnCharge_Read[] = [];

    const params: GetPaginationParams = {
      'page[limit]': addOnsStore.pagination.limit,
    };

    while (shouldFetchNextPage) {
      const { data, pagination } = await fetchAddOnChargesPage(params);

      allAddOnCharges = [...allAddOnCharges, ...data];
      shouldFetchNextPage = Boolean(pagination.after);

      if (shouldFetchNextPage) {
        params['page[after]'] = pagination.after;
      }
    }

    const parsedAddOnCharges = allAddOnCharges.map((addOnCharge) =>
      AddOnCharge.parse(addOnCharge),
    );
    addOnsStore.setAddOnChargesByInvoiceId(invoiceId, parsedAddOnCharges);

    setIsLoading(false);

    return parsedAddOnCharges;
  };

  const updateAddOn = async (addOnId: string, data: AddOnDTO) => {
    try {
      setIsUpdating(true);

      const response = await connection.patch<AddOn_Read>(
        `${API_VERSION}/add_ons/${addOnId}`,
        AddOn.deparseUpdate(data),
        {},
        $t('error_messages.add_ons.failed_to_update') as string,
      );

      const updatedAddOn = AddOn.parse(response);
      addOnsStore.updateAddOn(updatedAddOn);

      return updatedAddOn;
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    batchUpdateAddOnCharges,
    createAddOn,
    getAddOns,
    getAllAddOns,
    getAllInvoiceAddOnCharges,
    isCreating,
    isLoading,
    isUpdating,
    updateAddOn,
  };
};

export default useAddOns;
